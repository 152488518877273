// Models
import { IAction } from 'lib/redux/models'
import {
  ICreateExercisePayload,
  IGetExercisesPayload,
  IGetExercisesResponse,
  IEquipmentResponse,
  IExerciseDeletePayload,
  IPresignedData,
  ILoadExercise,
} from 'services/exercise/@types'
import { SagaIterator } from '@redux-saga/types'
import { IExerciseData } from '../models'

// Libraries
import { all, call, put, takeLatest } from 'redux-saga/effects'

// Misc
import {
  failure,
  fulfill,
  removeExerciseFromState,
  request,
  success,
  successAddToState,
  successLoadMore,
  Types,
} from '../duck'
import {
  loadExercises,
  createExercise,
  removeExercise,
  loadEquipments,
  partialUpdateExercise,
  updateExercise,
  uploadFile,
  loadExercise,
  loadExercisesLibrary,
} from 'services/exercise'
import {
  triggerToastError,
  triggerToastSuccess,
  triggerToastWarning,
} from 'storage/general/duck'

export function* loadExercisesSaga(action: IAction<IGetExercisesPayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IGetExercisesResponse = yield call(
        loadExercises,
        action.payload,
      )
      yield put(success({ exercises: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* loadExercisesLibrarySaga(
  action: IAction<IGetExercisesPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IGetExercisesResponse = yield call(
        loadExercisesLibrary,
        action.payload,
      )
      yield put(success({ exercisesLibrary: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* loadEquipmentSaga() {
  yield put(request())
  try {
    const response: IEquipmentResponse[] = yield call(loadEquipments)
    yield put(success({ equipments: response }))
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* loadMoreExercisesSaga(action: IAction<IGetExercisesPayload>) {
  try {
    if (action.payload) {
      const response: IGetExercisesResponse = yield call(
        loadExercises,
        action.payload,
      )
      yield put(successLoadMore(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* loadMoreExercisesLibrarySaga(
  action: IAction<IGetExercisesPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IGetExercisesResponse = yield call(
        loadExercisesLibrary,
        action.payload,
      )
      yield put(successLoadMore(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* loadExerciseSaga(action: IAction<ILoadExercise>) {
  yield put(request())
  try {
    if (action.payload) {
      const { id, successCallback } = action.payload
      const response: IExerciseData = yield call(loadExercise, id)
      yield put(success({ exercise: response }))
      successCallback?.(response)
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* createExerciseSaga(action: IAction<ICreateExercisePayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const { exercise } = action.payload
      const { file, ...rest } = exercise
      const response: IExerciseData = yield call(createExercise, rest)
      if (response) {
        yield put(successAddToState(response))
        yield put(
          triggerToastSuccess({
            message: 'Seu exercício foi salvo!',
          }),
        )
        yield call(
          uploadFile,
          file as File,
          response.pre_signed_url as IPresignedData,
        )
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    // TODO: quando o erro do file upload for atualizado voltar com esse erro:
    // yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* updateExerciseSaga(action: IAction<ICreateExercisePayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const { exercise } = action.payload
      const { file, ...rest } = exercise
      const response: IExerciseData = yield call(updateExercise, rest)
      if (response) {
        yield put(successAddToState(response))
        yield put(
          triggerToastSuccess({
            message: 'Seu exercício foi atualizado!',
          }),
        )
        yield call(
          uploadFile,
          file as File,
          response.pre_signed_url as IPresignedData,
        )
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    // TODO: quando o erro do file upload for atualizado voltar com esse erro:
    // yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* partialUpdateExerciseSaga(
  action: IAction<ICreateExercisePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { exercise } = action.payload
      const { file, ...rest } = exercise
      const response: IExerciseData = yield call(partialUpdateExercise, rest)
      if (response) {
        yield put(successAddToState(response))
        yield put(
          triggerToastSuccess({
            message: 'Seu exercício foi atualizado!',
          }),
        )
        yield call(
          uploadFile,
          file as File,
          response.pre_signed_url as IPresignedData,
        )
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    // TODO: quando o erro do file upload for atualizado voltar com esse erro:
    // yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* deleteExerciseSaga(action: IAction<IExerciseDeletePayload>) {
  yield put(request())
  try {
    if (action.payload) {
      yield call(removeExercise, action.payload)
      yield put(removeExerciseFromState(action.payload))
      yield put(
        triggerToastWarning({
          message: 'Seu exercício foi apagado!',
        }),
      )
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export default function* exerciseSaga(): SagaIterator {
  yield all([takeLatest(Types.CREATE_EXERCISE, createExerciseSaga)])
  yield all([takeLatest(Types.DELETE_EXERCISE, deleteExerciseSaga)])
  yield all([takeLatest(Types.GET_EQUIPMENTS, loadEquipmentSaga)])
  yield all([takeLatest(Types.GET_EXERCISES, loadExercisesSaga)])
  yield all([takeLatest(Types.GET_EXERCISES_LIBRARY, loadExercisesLibrarySaga)])
  yield all([takeLatest(Types.GET_EXERCISE, loadExerciseSaga)])
  yield all([takeLatest(Types.GET_MORE_EXERCISES, loadMoreExercisesSaga)])
  yield all([
    takeLatest(Types.GET_MORE_EXERCISES_LIBRARY, loadMoreExercisesLibrarySaga),
  ])
  yield all([takeLatest(Types.UPDATE_EXERCISE, updateExerciseSaga)])
  yield all([
    takeLatest(Types.PARTIAL_UPDATE_EXERCISE, partialUpdateExerciseSaga),
  ])
}
