// React
import { FC, ReactNode, useRef } from 'react'

// Libraries
import {
  ConnectDragPreview,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd'

// Constants
const ItemTypes = {
  ITEM: 'item',
}

interface Props {
  index: number
  moveItem: (dragIndex: number, hoverIndex: number) => void
  renderItem: (
    ref: React.RefObject<HTMLDivElement>,
    isDragging: boolean,
    dragPreview: ConnectDragPreview,
  ) => ReactNode
}

const DraggableWrapper: FC<Props> = ({ index, moveItem, renderItem }) => {
  const dragRef = useRef<HTMLDivElement>(null)
  const dropRef = useRef<HTMLDivElement>(null)

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'item',
    item: { type: ItemTypes.ITEM, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    hover(item: Props, monitor: DropTargetMonitor<Props>) {
      if (!dropRef.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) return

      const hoverRect = dropRef.current.getBoundingClientRect()

      const hoverMiddleY = (hoverRect.bottom - hoverRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = clientOffset ? clientOffset.y - hoverRect.top : 0

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      moveItem(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  drop(dropRef)
  drag(dragRef)

  return (
    <div data-testid="draggablewrapper" ref={dropRef}>
      {renderItem(dragRef, isDragging, preview)}
    </div>
  )
}

export default DraggableWrapper
