// Models
import { IFinancialSubscriptionList } from 'services/financialSubscription/@types'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from 'utils/helpers/classess'
import { generatePath, useNavigate } from 'react-router-dom'
import { urls } from 'routes/paths'
import { useMediaQuery } from 'hooks'

// Components
import Desktop from './desktop'
import Mobile from './mobile'
import { useDispatch } from 'react-redux'
import {
  triggerActivateSubscription,
  triggerDeactivateSubscription,
} from 'storage/financialSubscription/duck'

// Constants
export const STATUS_VARIATION = {
  pending: cn('bg-[#FFCEC9] text-tag-critical'),
  due_in: cn('bg-[#FFF9E4] text-tag-warning'),
  paid: cn('bg-border-successDepressed text-tag-success'),
  canceled: cn('bg-surface-neutralHovered text-text-subdued'),
}

export interface IFinancialAthleteProps {
  onChangeAthelteStatus?: () => void
  onClick: () => void
  subscription: IFinancialSubscriptionList
}

const FinancialAthleteItem: FC<IFinancialAthleteProps> = (props) => {
  const { onChangeAthelteStatus, onClick, subscription } = props
  const { breakpoints, colors } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isActive = subscription.status === 'active'

  const navigateToAthleteProfile = () =>
    navigate(generatePath(urls.athleteProfile, { id: subscription.athlete }))

  const activateDeactivatePayload = {
    id: subscription.id,
    successCallback: onChangeAthelteStatus,
  }

  const options: TMenuDropdownOption[] = [
    {
      icon: 'user',
      label: 'Perfil do aluno',
      onClick: navigateToAthleteProfile,
    },
    {
      icon: 'money',
      label: 'Financeiro',
      onClick,
    },
    isActive
      ? {
          icon: 'userOff',
          color: colors.text.critical,
          label: 'Desativar perfil',
          onClick: () =>
            dispatch(triggerDeactivateSubscription(activateDeactivatePayload)),
        }
      : {
          icon: 'user',
          color: colors.text.default,
          label: 'Reativar perfil',
          onClick: () =>
            dispatch(triggerActivateSubscription(activateDeactivatePayload)),
        },
  ]

  const commonProps = {
    ...props,
    options,
  }

  return isDesktop ? <Desktop {...commonProps} /> : <Mobile {...commonProps} />
}

export default FinancialAthleteItem
