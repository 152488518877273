// Models
import { SetContentProps } from '..'

// React
import { FC, Fragment, useContext, useEffect } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useFormContext } from 'react-hook-form'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from '../styled'
import { Aligner, IconButton, InputCheckbox } from 'heeds-ds'
import { CustomFields, GenericInput } from '../../primitives'
import { Exercise } from '../../../'

// Constants
const INVALID_INPUTS = [
  'exercise',
  'fields',
  'id',
  'interval',
  'isometric_time',
  'last_execution_weight',
  'repetitions',
  'speed',
  'time',
  'weight',
]

type Props = Omit<SetContentProps, 'type'> & {
  amountOfExercise: number
}

const MultiSetContent: FC<Props> = ({
  addCustomField,
  amountOfExercise,
  handleCheckboxChange,
  name,
  onClickImage,
  openExercisesModal,
  readonly,
  removeExerciseFromWorkoutSet,
  specialSet,
  visible,
  workoutSet,
}) => {
  const { breakpoints } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const { setValue, getFieldState, watch } = useFormContext()

  const { isDirty: someFieldIsDirty } = getFieldState(name)
  const { isDirty: repeatFieldIsDirty } = getFieldState(`${name}.repeat`)

  const editMode = specialSet && specialSet.id === workoutSet.id
  const emptySpaces = amountOfExercise - workoutSet.exercises.length
  const showEmptySpaces = editMode && emptySpaces > 0

  const commonProps = {
    fixed: true,
    prefix: name,
    readonly,
  }

  useEffect(() => {
    if (someFieldIsDirty && !repeatFieldIsDirty) {
      setValue(`${name}.repeat`, false)
    }
  }, [name, repeatFieldIsDirty, setValue, someFieldIsDirty])

  return (
    <Aligner flex="column" padding="1.6rem" gap="1.6rem">
      {visible && (
        <Styled.InputContent>
          <GenericInput {...commonProps} type="quantity" />

          <GenericInput {...commonProps} type="interval" />
        </Styled.InputContent>
      )}

      {!readonly && visible && (
        <Styled.Label>
          <InputCheckbox
            checked={Boolean(watch(`${name}.repeat`))}
            onChange={handleCheckboxChange}
          />
          Repetir para todos os exercícios
        </Styled.Label>
      )}

      {workoutSet.exercises.map(({ exercise }, workoutSetIndex) => {
        const prefix = `${name}.exercises.${workoutSetIndex}`
        const setProps = {
          ...commonProps,
          prefix,
        }
        return (
          <Fragment key={workoutSetIndex}>
            <Aligner>
              <Exercise exercise={exercise} onClick={onClickImage} />

              {visible && !readonly && workoutSetIndex > 0 && (
                <IconButton
                  iconName="delete"
                  size="small"
                  cancel
                  onClick={() =>
                    removeExerciseFromWorkoutSet?.(workoutSetIndex)
                  }
                />
              )}
            </Aligner>

            {visible && (
              <Styled.InputContent>
                <GenericInput {...setProps} type="weight" />

                <GenericInput {...setProps} type="repetitions" />

                <CustomFields
                  addOrRemoveCustomField={addCustomField}
                  index={workoutSetIndex}
                  invalidInputs={INVALID_INPUTS}
                  name={name}
                  readonly={readonly}
                />
              </Styled.InputContent>
            )}
          </Fragment>
        )
      })}

      {showEmptySpaces &&
        Array(emptySpaces)
          .fill(0)
          .map((_, index) => (
            <Styled.EmptyArea key={index}>
              {isDesktop ? (
                <Styled.EmptyText>
                  Próximo exercício escolhido será mostrado aqui
                </Styled.EmptyText>
              ) : (
                <Styled.MobileExerciseButton
                  onClick={openExercisesModal}
                  track={buttonClickTracking}
                  trackName="open_mobile_exercises_modal"
                >
                  Escolher exercício
                </Styled.MobileExerciseButton>
              )}
            </Styled.EmptyArea>
          ))}
    </Aligner>
  )
}

export default MultiSetContent
