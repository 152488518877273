// React
import { FC } from 'react'

// Misc
import { theme } from 'lib/styled-components/theme'
import { useMenu } from 'hooks/useMenu'
import { useNavigate } from 'react-router-dom'

// Custom
import * as Styled from './styled'
import { Icon } from 'heeds-ds'

type Props = {
  goBack?: boolean
}

const Header: FC<Props> = ({ goBack = true }) => {
  const { openMenu, pagename, path, color } = useMenu()
  const navigate = useNavigate()

  return (
    <Styled.Container data-testid="header-container" color={color}>
      <Styled.IconContainer
        onClick={() => goBack && (path !== '' ? navigate(path) : navigate(-1))}
      >
        {goBack && (
          <Icon
            iconName="arrowBack"
            color={theme.colors.icon.default}
            size={40}
          />
        )}
      </Styled.IconContainer>
      <Styled.Pagetitle>{pagename}</Styled.Pagetitle>
      <Styled.IconContainer onClick={openMenu}>
        <Icon
          iconName="hamburger"
          color={theme.colors.icon.default}
          size={40}
        />
      </Styled.IconContainer>
    </Styled.Container>
  )
}

export default Header
