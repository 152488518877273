// Models
import {
  ESetTypeToPortuguese,
  EWorkoutSetFields,
  IModelInput,
  TWorkoutSetType,
} from 'models'
import { IExerciseData } from 'storage/exercise/models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useMemo, useState } from 'react'

// Misc
import { toPascalCase } from 'utils/helpers/string'

// Components
import * as Styled from './styled'
import { Aligner, DropdownMenu, IconButton } from 'heeds-ds'
import {
  FieldItem,
  FieldsContainer,
  Text,
} from '../WorkoutSetToExerciseReview/styled'
import WorkoutSetToExerciseReview from '../WorkoutSetToExerciseReview'

interface Props {
  headerVisible?: boolean
  openGifModal?: (exercise: IExerciseData) => void
  options?: TMenuDropdownOption[]
  shadow?: boolean
  startsExpanded?: boolean
  workoutModel: IModelInput
}

const WorkoutModelReviewCard: FC<Props> = ({
  headerVisible = true,
  openGifModal,
  options,
  shadow = false,
  startsExpanded = false,
  workoutModel,
}) => {
  const { workout_set } = workoutModel

  const [expanded, setExpanded] = useState(startsExpanded)

  const toggleExpandContent = () => setExpanded((oldValue) => !oldValue)

  const muscleGroups = useMemo(() => {
    const muscleSet = new Set()
    Object.values(workout_set).forEach((set) =>
      set.exercises.forEach((setToExercise) =>
        muscleSet.add(toPascalCase(setToExercise?.exercise?.muscle || '')),
      ),
    )

    return Array.from(muscleSet).join(' - ')
  }, [workout_set])

  return (
    <Styled.Container $shadow={shadow}>
      {headerVisible && (
        <Styled.Header>
          <Aligner flex="column" gap="0.4rem">
            <Styled.Title>{workoutModel.name}</Styled.Title>

            <Styled.MuscleGroups>{muscleGroups}</Styled.MuscleGroups>
          </Aligner>

          <Styled.ActionButtons>
            <IconButton
              iconName={expanded ? 'expandLess' : 'expandMore'}
              onClick={toggleExpandContent}
              size="small"
            />

            {options && (
              <DropdownMenu items={options}>
                <IconButton iconName="moreVertical" size="small" />
              </DropdownMenu>
            )}
          </Styled.ActionButtons>
        </Styled.Header>
      )}

      <Styled.Content expanded={expanded}>
        {Object.values(workoutModel.workout_set)?.map((workoutSet) => {
          const workoutSetType =
            ESetTypeToPortuguese[workoutSet.type as TWorkoutSetType]
          return (
            <Styled.WorkoutSet key={workoutSet.id}>
              <Styled.Badge>
                <Styled.BadgeText>{workoutSetType}</Styled.BadgeText>
              </Styled.Badge>

              {workoutSet.type !== 'normal' && (
                <FieldsContainer>
                  {workoutSet.quantity && (
                    <FieldItem key="quantity">
                      <Text header>{EWorkoutSetFields.quantity}</Text>
                      <Text>{workoutSet.quantity}</Text>
                    </FieldItem>
                  )}

                  {workoutSet.interval?.value && (
                    <FieldItem key="interval">
                      <Text header>{EWorkoutSetFields.interval}</Text>
                      <Text>{workoutSet.interval?.value} seg</Text>
                    </FieldItem>
                  )}
                </FieldsContainer>
              )}

              {workoutSet.exercises.map((setTo) => {
                return (
                  <WorkoutSetToExerciseReview
                    interval={workoutSet.interval}
                    key={workoutSet.id}
                    openGifModal={openGifModal}
                    quantity={workoutSet.quantity}
                    setToExercise={setTo}
                    type={workoutSet.type}
                  />
                )
              })}
            </Styled.WorkoutSet>
          )
        })}
      </Styled.Content>
    </Styled.Container>
  )
}

export default WorkoutModelReviewCard
