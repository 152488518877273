// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Icon } from 'heeds-ds'

// Assets
import workoutImage from 'assets/images/exercise-223.png'

type Props = {
  thumbnail?: string
}

export const Container = styled(Aligner).attrs({
  flex: 'row',
  gap: '0.8rem',
  padding: '2.4rem 1.6rem',
  align: 'flex-start',
})`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 1.5rem;
  box-shadow: 0px 2px 5px 0px rgba(43, 42, 99, 0.12);
  width: auto;
  border: 1px solid #ededed;
  margin: 8px 24px;
  overflow: hidden;
`

export const Creator = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  type: 'copy3',
  weight: 400,
}))`
  margin-bottom: 4px;
`

export const Title = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.secondary,
  type: 'copy3',
  weight: 600,
}))`
  margin-bottom: 8px;
`

export const TitlesContainer = styled.div`
  flex: 1;
  width: 40%;
`

export const OptionsContainer = styled.div`
  margin: 0 0 0 auto;
`

export const Media = styled.div<Props>`
  align-items: center;
  background: linear-gradient(rgba(43, 42, 99, 0.45), rgba(43, 42, 99, 0.45)),
    url(${({ thumbnail }) => thumbnail || workoutImage});
  background-size: contain;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 126px;
  justify-content: center;
  min-width: 112px;
`

export const PlayIcon = styled(Icon).attrs((props) => ({
  iconName: 'playCircle',
  color: props.theme.colors.icon.onPrimary,
}))``
