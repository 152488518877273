// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import { Skeleton } from 'heeds-ds'
import { Cell, Row } from './desktop/styled'
import {
  Container,
  TitlesContainer,
  Title,
  Creator,
  OptionsContainer,
} from './mobile/styled'

const LoaderLibraryExerciseItem: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return isDesktop ? (
    <Row>
      <Cell>
        <Skeleton width="4rem" height="4rem" />
      </Cell>
      <Cell>
        <Skeleton width="15.25rem" height="1.6rem" />
      </Cell>
      <Cell>
        <Skeleton width="15.25rem" height="1.6rem" />
      </Cell>
      <Cell>
        <Skeleton width="11.25rem" height="1.6rem" />
      </Cell>
      <Cell>
        <Skeleton width="9.625rem" height="1.6rem" />
      </Cell>
      <Cell>
        <Skeleton width="11.25rem" height="1.6rem" />
      </Cell>
      <Cell>
        <Skeleton width="3.6rem" height="3.6rem" />
      </Cell>
    </Row>
  ) : (
    <Container>
      <Skeleton width="112px" height="126px" />
      <TitlesContainer>
        <Title>
          <Skeleton width="9rem" height="1.6rem" />
        </Title>
        <Creator>
          <Skeleton width="8rem" height="1.6rem" />
        </Creator>
        <Creator>
          <Skeleton width="6rem" height="1.6rem" />
        </Creator>
        <Creator>
          <Skeleton width="12rem" height="1.6rem" />
        </Creator>
        <Creator>
          <Skeleton width="9rem" height="1.6rem" />
        </Creator>
      </TitlesContainer>
      <OptionsContainer>
        <Skeleton width="3.6rem" height="3.6rem" />
      </OptionsContainer>
    </Container>
  )
}

export default LoaderLibraryExerciseItem
