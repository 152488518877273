// React
import { FC, useMemo } from 'react'

// Libraries
import { useParams } from 'react-router-dom'

// Components
import TabNavigator from 'components/TabNavigator'

interface Props {
  blocked?: boolean
  steps: Array<{ label: string; to: string }>
}

const WorkoutTabs: FC<Props> = ({ blocked, steps }) => {
  const { routine_id = '' } = useParams()

  const rules = useMemo(
    () => ({
      [steps[0].to]: false,
      [steps[1].to]: Boolean(routine_id === ''),
      [steps[2].to]: Boolean(blocked),
    }),
    [steps, blocked, routine_id],
  )

  const blockedIndexes = useMemo(
    () =>
      Object.values(rules)
        ?.map((rule, index) => {
          if (rule) return index
          return -1
        })
        ?.filter((rule) => rule > -1),
    [rules],
  )

  return (
    <div className="flex w-full rounded-[8px] border border-border-input bg-surface shadow-sm lg:h-[50px]">
      <TabNavigator
        blockedIndexes={blockedIndexes}
        className="p-4 after:right-8 lg:px-6 lg:py-[13px]"
        itemClassName="pr-4 pb-5 lg:px-0 lg:pb-[6px] lg:text-copy4 lg:after:h-[2px]"
        items={steps}
        progress
      />
    </div>
  )
}

export default WorkoutTabs
