// React
import { ReactNode, useEffect, useState } from 'react'

// Libraries
import InfiniteScroll from 'react-infinite-scroll-component'

// Misc
import { cn } from '../../utils/classes'

// Components
import { InputCheckbox } from '../../components/Inputs'
import Icon from '../../components/UI/Icons'

type TColumns = {
  label: ReactNode | ReactNode[]
  filter?: string
}

export type Props<T> = {
  activeFilter?: string
  bottomDistance?: string
  columns: TColumns[]
  columnsFormat?: string
  columnsGap?: string
  data: T[]
  hasMore: boolean
  height?: string
  listEmptyComponent?: ReactNode | ReactNode[]
  loaderComponent?: ReactNode | ReactNode[]
  onEndReached: () => void
  orderingFilter?: (name: string) => void
  padding?: string
  refreshing?: boolean
  renderItem: (item: T) => ReactNode
  rowsGap?: string
  scrollableDiv?: string
  showHeader?: boolean
  selectable?: boolean
  onSelectAll?: (item: T[]) => void
  onSelect?: (item: T) => void
  resetSelection?: boolean
}

const CommonList = <T extends object>({
  activeFilter,
  bottomDistance,
  columns,
  columnsFormat,
  columnsGap,
  data,
  hasMore,
  height,
  listEmptyComponent,
  loaderComponent,
  onEndReached,
  orderingFilter,
  padding,
  refreshing,
  renderItem,
  rowsGap,
  scrollableDiv = 'scrollableDiv',
  showHeader = true,
  selectable,
  onSelect,
  onSelectAll,
  resetSelection,
}: Props<T>) => {
  const hasData = data.length > 0

  const [selectedItems, setSelectedItems] = useState<Record<number, boolean>>(
    {},
  )

  const handleOnSelect = (item: T, index: number) => {
    setSelectedItems((prev) => {
      const updated = { ...prev, [index]: !prev[index] }
      return updated
    })
    onSelect?.(item)
  }

  const handleOnSelectAll = () => {
    const anySelected = Object.values(selectedItems).some(
      (isSelected) => isSelected,
    )

    const updatedItems = data.reduce((acc, _, index) => {
      acc[index] = !anySelected
      return acc
    }, {} as Record<number, boolean>)

    setSelectedItems(updatedItems)
    if (!anySelected) {
      onSelectAll?.(data)
    } else {
      onSelectAll?.([])
    }
  }

  useEffect(() => {
    if (resetSelection) {
      setSelectedItems({})
    }
  }, [resetSelection])

  return (
    <div id="scrollableDiv" className="min-h-0 grow overflow-y-auto">
      {showHeader && (
        <div className="flex border border-solid border-border-input bg-surface-hovered">
          {selectable && (
            <label className="flex cursor-pointer items-center justify-center pl-6">
              <InputCheckbox
                checked={Object.values(selectedItems).some(
                  (isSelected) => isSelected,
                )}
                onChange={handleOnSelectAll}
                iconName="subtract"
              />
            </label>
          )}
          <div
            className={cn(
              'sticky top-0 grid w-full cursor-pointer p-0',
              columnsFormat,
              columnsGap,
            )}
          >
            {columns.map((column, index) => (
              <div key={index} className="flex">
                <div
                  className={cn(
                    'flex select-none content-center gap-4',
                    padding,
                  )}
                  onClick={() => orderingFilter?.(column?.filter || '')}
                >
                  <h3 className="m-0 truncate text-left font-roboto text-copy4 font-bold text-text-subdued">
                    {column.label}
                  </h3>
                  {column?.filter && (
                    <Icon
                      iconName={
                        activeFilter === column.label
                          ? 'unfoldMore'
                          : 'unfoldMore'
                      }
                      size={16}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!refreshing && hasData && (
        <InfiniteScroll
          className={cn('flex flex-col', rowsGap, bottomDistance)}
          dataLength={data.length}
          next={onEndReached}
          hasMore={hasMore}
          loader={loaderComponent}
          scrollThreshold="200px"
          scrollableTarget={scrollableDiv}
          height={height}
        >
          {data.map((item, index) => (
            <div key={index} className="flex">
              {selectable && (
                <label className="flex cursor-pointer items-center justify-center pl-6 xl:border-b xl:border-border-input">
                  <InputCheckbox
                    checked={!!selectedItems[index]}
                    onChange={() => handleOnSelect(item, index)}
                  />
                </label>
              )}
              <div
                className={cn('w-full md:ml-0', {
                  'ml-[-8px]': selectable,
                })}
              >
                {renderItem(item)}
              </div>
            </div>
          ))}
        </InfiniteScroll>
      )}
      {refreshing && loaderComponent}
      {!refreshing && !hasData && listEmptyComponent}
    </div>
  )
}

export default CommonList
