// Models
import {
  EPlanModelToPortuguese,
  EPlanPeriodicityToPortuguese,
  TAthleteCalendar,
} from 'models'
import { FormInputs as AthleteGeneralFormInputs } from 'views/dashboard/athlete/AthleteGeneral'
import { IAthleteFinancialInputs } from 'views/dashboard/athlete/AthleteFinancial'
import { IAthleteInformationPayload, IAthletes } from './models'
import { IAthletePlan } from 'services/athletePlans/@types'
import { IFinancialSubscription } from 'storage/financialSubscription/models'
import { IUpdateAthlete } from 'services/athleteInfo/@types'

// Libs
import moment from 'moment'

// Misc
import { currencyMask } from 'utils/masks'
import {
  dateFormatEnglishToPortuguese,
  dateFormatPortugueseToEnglish,
} from 'utils/functions'
import {
  ConvertRecurringTime,
  handleDisplayDays,
  handleSelectedDays,
} from 'utils/calendarUtils'

export const prepareAthleteGeneralToDisplay = (
  athleteInformation: IUpdateAthlete,
) => {
  const display = {
    address: athleteInformation.address || '',
    birthdate:
      dateFormatEnglishToPortuguese(athleteInformation.birthdate) || '',
    cep: athleteInformation.cep || '',
    city: athleteInformation.city || '',
    comment: athleteInformation.comment || '',
    complement: athleteInformation.complement || '',
    email: athleteInformation.email || '',
    emergency_contact_name: athleteInformation.emergency_contact_name || '',
    emergency_contact_phone_number:
      athleteInformation.emergency_contact_phone_number || '',
    gender: athleteInformation.gender,
    instagram: athleteInformation.instagram || '',
    name: athleteInformation.name,
    phone_number: athleteInformation.phone_number || '',
    state: athleteInformation.state || '',
  }

  return display
}

export const prepareAthleteGeneralToPayload = (
  data: AthleteGeneralFormInputs,
) => {
  const { birthdate, ...dataRest } = data

  const payload: IAthleteInformationPayload = {
    birthdate: dateFormatPortugueseToEnglish(birthdate),
    ...dataRest,
    type: 'athlete',
  }
  return payload
}

export const prepareAthletePlansToDisplay = (data: IAthletePlan) => {
  const {
    due_date,
    end_date,
    plan,
    plan_model,
    // TODO: mudanca para tela de aluno
    //  plan_type,
    start_date,
    total,
    ...rest
  } = data

  return {
    ...rest,
    plan_model: EPlanModelToPortuguese[plan_model],
    // TODO: mudanca para tela de aluno
    // plan_type: EPlanTypeToPortuguese[plan_type],
    start_date: dateFormatEnglishToPortuguese(start_date),
    end_date: dateFormatEnglishToPortuguese(end_date),
    due_date,
    total: currencyMask(`${total}`),
    plan: plan
      ? {
          ...plan,
          plan_model: EPlanModelToPortuguese[plan.plan_model],
          plan_periodicity: EPlanPeriodicityToPortuguese[plan.plan_periodicity],
        }
      : plan,
  }
}

export const prepareAthleteFinancialToPayload = (
  data: IAthleteFinancialInputs,
  id: string,
  profileId: number,
) => {
  const extra_workouts = data.extra_workouts?.map((extra) => {
    return JSON.parse(extra).id
  })

  const spread =
    data.instalmentPayment === undefined ||
    data.instalmentPayment === '0' ||
    data.plan_type === 'hourly_rate'
      ? false
      : true

  const handleDisplayDueDateDateFormat = () => {
    if (data.plan_type === 'hourly_rate' && !data.autoRenovation) {
      return true
    }
    if (data.plan_type !== 'hourly_rate' && !spread) {
      return true
    }
    return false
  }

  const payload: IFinancialSubscription = {
    id: data.id,
    auto_renew: data.autoRenovation,
    discount: data.discount,
    frequency:
      data.plan_model === 'online'
        ? '1'
        : data.plan_type === 'hourly_rate'
        ? data?.frequency
        : data.selected_plan?.frequency || '1',
    due_date: handleDisplayDueDateDateFormat()
      ? moment(data.due_date_date)?.format('YYYY-MM-DD')
      : data.due_date_day,
    start_date: moment(data.start_date)?.format('YYYY-MM-DD'),
    status: 'active',
    athlete: parseInt(id),
    personal: profileId || 0,
    plan: data?.selected_plan?.planID || 0,
    plans_extras: extra_workouts,
    spread: spread,
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { plans_extras, ...rest } = payload

  return extra_workouts?.length !== 0 ? payload : rest
}

enum EPlanModelMultiply {
  monthly = 1,
  quarterly = 3,
  semiannual = 6,
  yearly = 12,
}

export const prepareAthleteFinancialToDisplay = (
  planData: IAthletePlan[],
): IAthleteFinancialInputs => {
  const subscription = planData[0]

  if (!subscription) {
    return {} as IAthleteFinancialInputs
  }

  const plan = subscription.plan
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/
  const extra_workouts = subscription.plans_extras?.map((item) =>
    JSON.stringify(item),
  )

  const data = {
    id: subscription?.id,
    plan_model: plan?.plan_model,
    plan_type: plan?.plan_type,
    selected_plan: {
      label:
        plan?.plan_type === 'custom'
          ? `${plan?.plan_type}_${plan?.plan_periodicity}${subscription.frequency}`
          : plan?.plan_type === 'hourly_rate'
          ? `${plan?.plan_type}`
          : `${plan?.plan_type}_${plan?.plan_periodicity}`,
      value:
        plan?.value.standard?.toString() ||
        plan?.value.custom?.[subscription.frequency].toString() ||
        '',
      planID: plan?.id,
      periodicity: plan?.plan_periodicity,
    },
    instalmentPayment: subscription.spread
      ? EPlanModelMultiply[plan?.plan_periodicity].toString()
      : '0',
    start_date: moment(subscription.start_date).format('DD/MM/YYYY'),
    autoRenovation: subscription.auto_renew,
    frequency: subscription.frequency?.toString(),
    due_date_day: !isoDateRegex.test(subscription.due_date)
      ? subscription.due_date?.padStart(2, '0')
      : undefined,
    due_date_date: isoDateRegex.test(subscription.due_date)
      ? moment(subscription.due_date).format('DD/MM/YYYY')
      : undefined,
    discount: subscription.discount === 0 ? undefined : subscription.discount,
    value: plan?.value.standard || plan?.value.custom?.[subscription.frequency],
    extra_workouts: extra_workouts,
  }

  return data
}

export const handleExtraInformationToAthlete = (
  athlete: IAthletes[],
  athleteId: string,
) => {
  const extraInformation: TAthleteCalendar[] = athlete?.map((value) => {
    const {
      id,
      start,
      athlete,
      end,
      location,
      recurring_schedule,
      athlete_name,
      athlete_active_subscription_end_date,
      athlete_active_subscription_start_date,
    } = value
    const selected = handleSelectedDays(
      ConvertRecurringTime(recurring_schedule),
    )

    const isNew = parseInt(athleteId ?? '') === parseInt(athlete.toString())
    const extra = {
      id: id,
      athletePk: athlete,
      athlete: athlete_name || '',
      date: athlete_active_subscription_start_date || '',
      start: start?.slice(0, -3),
      end: end?.slice(0, -3),
      location: location,
      recurringTimes: ConvertRecurringTime(recurring_schedule) || [],
      recurringTimesList: recurring_schedule,
      day: handleDisplayDays(selected),
      new: isNew,
      endCompare: 0,
      startCompare: 0,
      initialDate: athlete_active_subscription_start_date || '',
      dueDate: athlete_active_subscription_end_date || '',
    }
    return extra
  })
  return extraInformation
}
