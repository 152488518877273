// Models
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// React
import { FC, useMemo } from 'react'

// Libraries
import { generatePath, useNavigate, useParams } from 'react-router-dom'

// Misc
import { prepareWorkoutRoutineToDisplay } from 'filters/workouts'
import { intervalDateToString } from 'utils/helpers/date'
import { urls } from 'routes/paths'

// Components
import { IconButton } from 'heeds-ds'

export type Props = {
  item: IWorkoutRoutine
}

const Mobile: FC<Props> = ({ item }) => {
  const navigate = useNavigate()
  const { id: athleteId } = useParams()
  const { name, start_date, end_date, id } = item
  const { goal, difficulty } = prepareWorkoutRoutineToDisplay(item)

  const period = useMemo(
    () => intervalDateToString(start_date, end_date),
    [end_date, start_date],
  )

  return (
    <div className="mb-4 flex w-full cursor-pointer items-start justify-start rounded-[15px] border border-b-border-input bg-surface p-4">
      <div>
        <p className="mb-2 text-copy3 font-semibold text-text-secondary">
          {name}
        </p>
        <p className="mb-1 text-copy3 text-text-subdued">{period}</p>
        <p className="mb-1 text-copy3 text-text-subdued">{goal}</p>
        <p className="mb-1 text-copy3 text-text-subdued">{difficulty}</p>
      </div>
      <div className="ml-auto">
        <IconButton
          iconName="analytics"
          size="medium"
          onClick={() =>
            navigate(
              generatePath(urls.athleteWorkoutsReport, {
                id: athleteId,
                model_id: id,
              }),
            )
          }
        />
      </div>
    </div>
  )
}

export default Mobile
