// Models
import { IAthleteInfo } from 'services/athleteInfo/@types'

// React
import { FC, useContext, useState } from 'react'

// Lib
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from 'utils/helpers/classess'

// Components
import { Icon, LinkButton, StylizedIcons } from 'heeds-ds'
import { Body } from 'components/UI/Typography'

type Props = {
  info: IAthleteInfo
}

const FormattedTitles: Record<string, string> = {
  phone_number: 'Telefone',
  email: 'Email',
  address: 'Endereço',
  emergency_contact_name: 'Contato de emergência',
}

const GeneralInfo: FC<Props> = ({ info }) => {
  const theme = useContext(ThemeContext)
  const {
    phone_number,
    email,
    address,
    emergency_contact_name,
    emergency_contact_phone_number,
  } = info
  const generalInfo = {
    phone_number,
    email,
    address,
    emergency_contact_name:
      emergency_contact_name &&
      emergency_contact_phone_number &&
      `${emergency_contact_name} • ${emergency_contact_phone_number}`,
  }
  const infoTitles = Object.keys(generalInfo)

  const [open, setOpen] = useState(false)
  const [openEmergency, setOpenEmergency] = useState(false)

  return (
    <div className="flex flex-col gap-2 ">
      <div className="rounded-[8px] border border-border-input bg-surface p-4">
        <div className="flex w-full flex-col gap-4">
          <div
            className={cn('mb-1 flex justify-between md:hidden', {
              'mb-0': !open,
            })}
            onClick={() => setOpen(!open)}
          >
            <p className="text-copy3 font-semibold">Dados Gerais</p>
            <div className="md:hidden">
              <Icon iconName={open ? 'expandMore' : 'expandLess'} />
            </div>
          </div>

          {infoTitles.map((title) => (
            <div
              key={title}
              data-testid={`info-${title}`}
              className={cn('flex flex-col', { hidden: !open }, 'md:flex')}
            >
              <div
                className={cn('flex-col', {
                  hidden: !generalInfo[title as keyof typeof generalInfo],
                })}
              >
                <Body
                  type="copy4"
                  margin="0 0 4px"
                  color={theme.colors.text.subdued}
                >
                  {FormattedTitles[title]}
                </Body>
                <Body type="copy4" weight={600}>
                  {generalInfo[title as keyof typeof generalInfo] || '-'}
                </Body>
              </div>
            </div>
          ))}
        </div>
      </div>

      {emergency_contact_phone_number && (
        <div className="flex rounded-[8px] border border-border-input bg-surface p-4 md:hidden">
          <div className="flex w-full flex-col gap-4">
            <div
              className={cn('mb-1 flex justify-between md:hidden', {
                'mb-0': !open,
              })}
              onClick={() => setOpenEmergency(!openEmergency)}
            >
              <div className="flex items-center gap-2">
                <StylizedIcons iconName="emergency" size={24} viewBox={24} />
                <p className="text-copy3 font-semibold">
                  Contato de emergência
                </p>
              </div>
              <div className="md:hidden">
                <Icon iconName={openEmergency ? 'expandMore' : 'expandLess'} />
              </div>
            </div>
            <div
              className={cn(
                'flex flex-col',
                { hidden: !openEmergency },
                'md:flex',
              )}
            >
              <div className="flex-col md:hidden">
                <Body type="copy3" margin="0 0 4px" weight={600}>
                  {emergency_contact_name}
                </Body>
                <Body type="copy4" color={theme.colors.text.subdued}>
                  {emergency_contact_phone_number || '-'}
                </Body>

                <div className="mt-4 flex w-full justify-center gap-4">
                  <LinkButton
                    variation="main"
                    size="large"
                    to={`https://wa.me/${emergency_contact_phone_number?.replace(
                      /\D/g,
                      '',
                    )}`}
                    target="_blank"
                    onlyIcon
                  >
                    <Icon
                      iconName="whatsApp"
                      color={theme.colors.surface.default}
                      size={24}
                    />
                  </LinkButton>
                  <LinkButton
                    variation="main"
                    size="large"
                    to={`tel:${phone_number}`}
                    target="_blank"
                    onlyIcon
                  >
                    <Icon
                      iconName="phone"
                      color={theme.colors.surface.default}
                      size={24}
                    />
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default GeneralInfo
