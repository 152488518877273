// Libraries
import styled from 'styled-components'

// Components
import { Button, Icon } from 'heeds-ds'

export const GoBackButton = styled(Button).attrs(() => ({
  margin: '0',
  size: 'small',
  variation: 'borderless',
}))``

export const GoBackIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.interactive.default,
  iconName: 'chevronLeft',
}))``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  flex-grow: 1;
  gap: 2.4rem;
  overflow-y: auto;
  align-items: flex-start;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 4rem 4.8rem 0 4rem;
  }
`
