// Models
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import {
  IWorkoutRoutine,
  IWorkoutRoutineState,
} from 'storage/workoutRoutine/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect } from 'react'

// Libs
import { ThemeContext } from 'styled-components'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import { success } from 'storage/workoutRoutine/duck'
import { triggerLoadAthlete } from 'storage/athleteInfo/duck'
import {
  triggerLoadListWorkoutRoutines,
  triggerLoadMoreListWorkoutRoutine,
} from 'storage/workoutRoutine/duck'
import { useMediaQuery, useMenu } from 'hooks'

// Components
import { Button, Loading, Avatar, CommonList, Icon } from 'heeds-ds'
import { RoutineReportItem, RoutineReportItemLoader } from 'components'

// Constants
const page_size = 20

const headerGridTemplate = 'grid-cols-[3.5fr_2.5fr_2.7fr_2.0fr_0.8fr]'

const TABLE_PADDING = 'py-4 px-6'

const COLUMNS = [
  { label: 'Nome da rotina' },
  { label: 'Período' },
  { label: 'Objetivo' },
  { label: 'Nível' },
  { label: '' },
]

const AthleteRoutinesReport: FC = () => {
  const { id = '' } = useParams()
  const { workoutRoutines, loading, next } = useSelector<
    IStore,
    IWorkoutRoutineState
  >((state) => state.workoutRoutine)
  const { info, loading: infoAthleteLoading } = useSelector<
    IStore,
    IAthleteInfoState
  >((state) => state.athleteInfo)
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const handleEndReached = () => {
    if (workoutRoutines && !loading && next) {
      const query = {
        athlete: id,
        page: next,
        page_size,
      }
      dispatch(triggerLoadMoreListWorkoutRoutine(query))
    }
  }

  const renderLogItems = (item: IWorkoutRoutine) => {
    return (
      <RoutineReportItem
        item={item}
        gridTemplate={headerGridTemplate}
        padding={TABLE_PADDING}
      />
    )
  }

  useLayoutEffect(() => {
    if (id) {
      dispatch(triggerLoadAthlete({ id: Number(id) }))
    }
  }, [dispatch, id])

  useLayoutEffect(() => {
    if (id) {
      const query = {
        athlete: id,
        page_size,
      }
      dispatch(triggerLoadListWorkoutRoutines(query))
    }
  }, [dispatch, id])

  useLayoutEffect(() => {
    setPagename('RELATÓRIOS')

    return () => {
      setPagename('Dashboard')
      dispatch(success({ workoutRoutines: undefined }))
    }
  }, [dispatch, setPagename])

  if (infoAthleteLoading) {
    return <Loading active={true} />
  }

  return (
    <div className="flex h-screen flex-col gap-6 md:px-10 md:pb-6 md:pt-8">
      <div className="mx-6 flex gap-4 rounded-[8px] border border-border-input bg-surface p-4 md:mx-0 md:justify-between md:gap-0">
        <div className="hidden md:flex">
          <Button
            size="xsmall"
            variation="borderless"
            onClick={() => navigate(-1)}
            track={buttonClickTracking}
            trackName="navigate_to_athletes"
          >
            <Icon
              iconName="chevronLeft"
              color={theme.colors.interactive.default}
            />
            Voltar para alunos
          </Button>
        </div>
        <div className="flex md:hidden">
          <Avatar name={info?.name || ''} scale="small" />
        </div>
        <div className="flex flex-col justify-center gap-1 md:items-center">
          <p className="text-copy2 font-medium md:text-copy3">{info?.name}</p>
          <p className="text-copy3 font-normal text-text-subdued">Rotinas</p>
        </div>
        <div className="hidden w-[250px] md:flex" />
      </div>
      <div
        className={cn(
          'flex w-full flex-col gap-4 overflow-y-auto rounded-[8px] border px-6 pt-4',
          'h-full border-border-input bg-surface md:gap-0 md:px-0 md:pt-0',
        )}
      >
        <CommonList<IWorkoutRoutine>
          columnsFormat={headerGridTemplate}
          columns={COLUMNS}
          data={workoutRoutines || []}
          onEndReached={handleEndReached}
          loaderComponent={
            <RoutineReportItemLoader
              gridTemplate={headerGridTemplate}
              padding={TABLE_PADDING}
            />
          }
          refreshing={
            workoutRoutines && workoutRoutines?.length === 0 && loading
          }
          padding={TABLE_PADDING}
          showHeader={isDesktop}
          renderItem={renderLogItems}
          hasMore={!!next}
        />
      </div>
    </div>
  )
}

export default AthleteRoutinesReport
