// React
import { FC, useContext } from 'react'

// Libs
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'hooks'

// Components
import { Skeleton } from 'heeds-ds'

const ProfileTabsLoader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  return (
    <div className="flex w-full flex-row gap-2 p-4 md:flex-col">
      <div className="flex w-full flex-col gap-2 md:flex-row">
        <Skeleton
          height="100px"
          width={isDesktop ? '425px' : '100%'}
          radius="8px"
        />
        <Skeleton
          height="100px"
          width={isDesktop ? '425px' : '100%'}
          radius="8px"
        />
      </div>
      <div className="hidden flex-row gap-2 md:flex">
        <Skeleton height="100px" width="425px" radius="8px" />
        <Skeleton height="100px" width="425px" radius="8px" />
      </div>
    </div>
  )
}

export default ProfileTabsLoader
