// Models
import { EAnamneseTemplateActionTypes, IAnamneseTemplateState } from '../models'
import { IAction } from 'lib/redux/models'
import {
  ISendAnamneseTemplatesPayload,
  ICreateAnamneseTemplatePayload,
  ILoadAnamneseTemplatePayload,
  ILoadAnamneseTemplateResponse,
  IUpdateAnamneseTemplatePayload,
  IPartialUpdateAnamneseTemplatePayload,
  IDeleteAnamneseTemplatePayload,
  ILoadAnamnese,
} from 'services/assessmentAnamneseTemplate/@types'

// ACTION TYPES
export const Types = {
  CLEANUP: EAnamneseTemplateActionTypes.CLEANUP,
  FAILURE: EAnamneseTemplateActionTypes.FAILURE,
  FULFILL: EAnamneseTemplateActionTypes.FULFILL,
  REQUEST: EAnamneseTemplateActionTypes.REQUEST,
  SUCCESS: EAnamneseTemplateActionTypes.SUCCESS,
  CREATE: EAnamneseTemplateActionTypes.CREATE,
  GET: EAnamneseTemplateActionTypes.GET,
  UPDATE: EAnamneseTemplateActionTypes.UPDATE,
  PARTIAL_UPDATE: EAnamneseTemplateActionTypes.PARTIAL_UPDATE,
  DELETE: EAnamneseTemplateActionTypes.DELETE,
  LOAD: EAnamneseTemplateActionTypes.LOAD,
  LOAD_MORE: EAnamneseTemplateActionTypes.LOAD_MORE,
  LOAD_SENT: EAnamneseTemplateActionTypes.LOAD_SENT,
  SEND: EAnamneseTemplateActionTypes.SEND,
  SUCCESS_LOAD_MORE: EAnamneseTemplateActionTypes.SUCCESS_LOAD_MORE,
}

// INITIAL STATE
const initialState: IAnamneseTemplateState = {}

// REDUCER
export default (
  state: IAnamneseTemplateState = initialState,
  action?: IAction<unknown>,
): IAnamneseTemplateState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        anamneseTemplate: undefined,
        anamneseTemplates: undefined,
        athletesSendTemplate: undefined,
        count: undefined,
        next: undefined,
        previous: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        error: undefined,
        ...(action?.payload as IAnamneseTemplateState),
      }
    case Types.SUCCESS_LOAD_MORE: {
      const { results, ...paginatedParams } =
        action.payload as ILoadAnamneseTemplateResponse
      const templatesFromState = state.anamneseTemplates || []
      return {
        ...state,
        ...paginatedParams,
        anamneseTemplates: [...templatesFromState, ...results],
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<unknown> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<unknown> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (
  payload: IAnamneseTemplateState,
): IAction<IAnamneseTemplateState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const triggerCreateAnamneseTemplate = (
  payload: ICreateAnamneseTemplatePayload,
): IAction<ICreateAnamneseTemplatePayload> => {
  return {
    type: Types.CREATE,
    payload,
  }
}

export const triggerGetAnamneseTemplate = (
  payload: ILoadAnamnese,
): IAction<ILoadAnamnese> => {
  return {
    type: Types.GET,
    payload,
  }
}

export const triggerUpdateAnamneseTemplate = (
  payload: IUpdateAnamneseTemplatePayload,
): IAction<IUpdateAnamneseTemplatePayload> => {
  return {
    type: Types.UPDATE,
    payload,
  }
}

export const triggerPartialUpdateAnamneseTemplate = (
  payload: IPartialUpdateAnamneseTemplatePayload,
): IAction<IPartialUpdateAnamneseTemplatePayload> => {
  return {
    type: Types.PARTIAL_UPDATE,
    payload,
  }
}

export const triggerDeleteAnamneseTemplate = (
  payload: IDeleteAnamneseTemplatePayload,
): IAction<IDeleteAnamneseTemplatePayload> => {
  return {
    type: Types.DELETE,
    payload,
  }
}

export const triggerLoadAnamneseTemplates = (
  payload: ILoadAnamneseTemplatePayload,
): IAction<ILoadAnamneseTemplatePayload> => {
  return {
    type: Types.LOAD,
    payload,
  }
}

export const triggerLoadMoreAnamneseTemplates = (
  payload: ILoadAnamneseTemplatePayload,
): IAction<ILoadAnamneseTemplatePayload> => {
  return {
    type: Types.LOAD_MORE,
    payload,
  }
}

export const successLoadMore = (
  payload: ILoadAnamneseTemplateResponse,
): IAction<ILoadAnamneseTemplateResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE,
    payload,
  }
}

export const triggerLoadAnamneseTemplatesSent = (
  payload: string,
): IAction<string> => {
  return {
    type: Types.LOAD_SENT,
    payload,
  }
}

export const triggerSendAnamneseTemplates = (
  payload: ISendAnamneseTemplatesPayload,
): IAction<ISendAnamneseTemplatesPayload> => {
  return {
    type: Types.SEND,
    payload,
  }
}
