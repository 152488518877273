// React
import { FC } from 'react'

// Components
import { Container, Hint, Label } from './styled'

type Props = {
  children?: React.ReactNode | React.ReactNode[]
  color?: string
  disabled?: boolean
  displayError?: boolean
  error?: string
  label?: string
  name?: string
  mandatory?: boolean
  margin?: string
  size?: 'small' | 'medium' | 'large'
  type?: string
  width?: string
  justify?: string
}

const InputWrapper: FC<Props> = (props: Props) => {
  const {
    children,
    color,
    disabled,
    displayError = true,
    error,
    justify,
    label,
    name,
    mandatory,
    margin,
    size = 'medium',
    type,
    width,
  } = props

  return (
    <Container margin={margin} width={width} justify={justify}>
      {label && (
        <Label
          color={color}
          data-testid="label"
          disabled={disabled}
          scale={size}
          htmlFor={name}
        >
          {label}
          {mandatory && (
            <p
              data-testid="mandatory-sign"
              className="ml-1 text-copy4 text-text-critical"
            >
              (obrigatório)
            </p>
          )}
        </Label>
      )}
      {children}
      {type !== 'password' && displayError && (
        <Hint data-testid="error-text" color={color} scale={size}>
          {error}
        </Hint>
      )}
    </Container>
  )
}

export default InputWrapper
