// Models
import { TDisplayModes } from '..'

// React
import { FC } from 'react'

// Misc
import { cn } from '../../../utils/classes'

// Constants
import { createYearsRange } from '../../../utils/helpers/date'

type Props = {
  displayMode: TDisplayModes
  displayingYear: number
  setDisplayMode: React.Dispatch<React.SetStateAction<TDisplayModes>>
  setDisplayingYear: React.Dispatch<React.SetStateAction<number>>
  startDate?: Date | null
  today: Date
}

const CalendarYarnPage: FC<Props> = ({
  displayMode,
  displayingYear,
  setDisplayMode,
  setDisplayingYear,
  startDate,
  today,
}) => {
  return (
    <>
      {displayMode === 'year' &&
        createYearsRange(displayingYear).map((year) => {
          const isSelected = year === startDate?.getFullYear()
          const isToday = today.getFullYear() === year

          return (
            <button
              aria-selected={isSelected}
              key={year.toString()}
              onClick={() => {
                setDisplayingYear(year)
                setDisplayMode('month')
              }}
              className={cn(
                'h-[52px] w-[62px] cursor-pointer rounded font-roboto text-copy4 font-normal text-text',
                'hover:bg-interactive-hovered hover:font-semibold hover:text-white',
                'focus:border focus:border-black',
                {
                  'text-badge-purpleText font-semibold bg-badge-purpleBackground':
                    isToday,
                  'bg-action-secondaryDefault font-semibold text-white':
                    isSelected,
                },
              )}
            >
              {year}
            </button>
          )
        })}
    </>
  )
}

export default CalendarYarnPage
