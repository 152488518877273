// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import { Skeleton } from 'heeds-ds'

const AthleteProfileLoader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  return (
    <div className="mx-6 mb-7 md:ml-10 md:mr-12 md:mt-10">
      {isDesktop ? (
        <div>
          <div className="mb-1 flex justify-between">
            <Skeleton height="40px" width="91px" radius="8px" />
            <Skeleton height="40px" width="122px" radius="8px" />
          </div>
          <div className="flex w-full gap-4">
            <div className="flex w-[34%] flex-col gap-2">
              <Skeleton height="100px" width="100%" radius="8px" />
              <Skeleton height="200px" width="100%" radius="8px" />
              <Skeleton height="64px" width="100%" radius="8px" />
              <Skeleton height="171px" width="100%" radius="8px" />
            </div>
            <div className="flex w-[67%] flex-col gap-2">
              <Skeleton height="260px" width="100%" radius="8px" />
              <Skeleton height="360px" width="100%" radius="8px" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-col gap-4">
          <Skeleton height="50px" width="100%" radius="8px" />
          <Skeleton height="50px" width="100%" radius="8px" />
          <Skeleton height="50px" width="100%" radius="8px" />
          <Skeleton height="50px" width="100%" radius="8px" />
          <Skeleton height="50px" width="100%" radius="8px" />
          <Skeleton height="200px" width="100%" radius="8px" />
        </div>
      )}
    </div>
  )
}

export default AthleteProfileLoader
