// Models
import {
  IAnamneseTemplate,
  IAnamneseTemplateState,
} from 'storage/assessmentAnamneseTemplate/models'
import InputSearch, {
  IFilter,
} from 'heeds-ds/src/components/Inputs/InputSearch'
import { ILoadAnamneseTemplatePayload } from 'services/assessmentAnamneseTemplate/@types'
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import {
  triggerCreateAnamneseTemplate,
  triggerDeleteAnamneseTemplate,
  triggerLoadAnamneseTemplates,
  triggerLoadMoreAnamneseTemplates,
  triggerSendAnamneseTemplates,
} from 'storage/assessmentAnamneseTemplate/duck'
import { prepareAnamneseTemplateToDuplicate } from 'filters/assessment'
import { triggerToastSuccess } from 'storage/general/duck'
import { urls } from 'routes/paths'
import { useDebounceFunction, useFilters, useMediaQuery, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Button,
  CommonList,
  DropdownMenu,
  Icon,
  Loading,
} from 'heeds-ds'
import {
  AnamneseTemplateItem,
  LibraryHeader,
  ModalConfirmation,
  ModalDelete,
  ModalMoreOptions,
  ModalShareAnamneses,
} from 'components'
import Loader from 'components/lists/AnamneseTemplateItem/loader'

// Constants
const COLUMNS_HEADERS = [
  { label: 'Nome da anamnese' },
  { label: 'Criado por' },
  { label: 'Data de criação' },
  { label: '' },
]

const ORDER_FILTERS = [
  { label: 'Mais recentes', value: '-date', param: 'ordering' },
  { label: 'A - Z', value: 'title', param: 'ordering' },
  { label: 'Z - A', value: '-title', param: 'ordering' },
]

const MODAL_DELETE_CONTENT = {
  confirmTitle: 'Excluir',
  description:
    'Você está excluindo uma anamnese da biblioteca, mas não do perfil do aluno. Para excluir a versão do aluno você precisa ir ao perfil do seu aluno.',
  title: 'Tem certeza que deseja excluir?',
}

const MODAL_EDIT_CONTENT = {
  cancelTitle: 'Cancelar',
  confirmTitle: 'Editar',
  title: 'Editar anamnese?',
  description:
    'As mudanças que você fizer neste formulário não altera a anamnese que foi enviada aos seus alunos. Lembre-se de reenviar o documento com as novas atualizações.',
}

const page_size = 20

const LibraryAnamneseTemplates: FC = () => {
  const { anamneseTemplates, next, error, loading } = useSelector<
    IStore,
    IAnamneseTemplateState
  >(({ assessmentAnamneseTemplate }) => assessmentAnamneseTemplate)
  const { closeModal, openModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)
  const navigate = useNavigate()

  const [selected, setSelected] = useState<IAnamneseTemplate>()

  const { filters, toggleFilter, setFilter } =
    useFilters<ILoadAnamneseTemplatePayload>(window.location.search)

  // TODO: implementar navegação para o preview
  const navigateToAnamneseTemplate = useCallback(() => undefined, [])

  const navigateToEditAnamnese = () => {
    selected &&
      navigate(generatePath(urls.libraryAnamneseEdit, { id: selected.id }))
  }

  const handleDuplicateAnamnese = useCallback(
    (anamnese: IAnamneseTemplate) => {
      const successCallback = () => {
        dispatch(triggerLoadAnamneseTemplates({ page_size, ...filters }))
        dispatch(
          triggerToastSuccess({ message: 'Modelo de anamnese duplicado!' }),
        )
      }

      const formatedData = prepareAnamneseTemplateToDuplicate(anamnese)

      dispatch(
        triggerCreateAnamneseTemplate({
          ...formatedData,
          title: `Cópia de ${anamnese.title}`,
          date: undefined,
          successCallback,
        }),
      )

      !isTablet && closeModal()
    },
    [closeModal, dispatch, filters, isTablet],
  )

  const handleDeleteAnamnese = useCallback(
    (anamneseId: string) => {
      const successCallback = () => {
        dispatch(triggerLoadAnamneseTemplates({ page_size, ...filters }))
      }
      dispatch(
        triggerDeleteAnamneseTemplate({ id: anamneseId, successCallback }),
      )
    },
    [dispatch, filters],
  )

  const handleShareAnamnese = (athleteIds: number[]) => {
    if (selected) {
      const successCallback = () =>
        dispatch(
          triggerToastSuccess({
            message: 'Seu formulário foi enviado aos seus alunos.',
          }),
        )
      dispatch(
        triggerSendAnamneseTemplates({
          athlete_ids: athleteIds,
          id: selected.id,
          successCallback,
        }),
      )
    }
  }

  const renderAnamneseItem = useCallback(
    (item: IAnamneseTemplate) => {
      const actionButton = (modalName: string) => {
        setSelected(item)
        openModal(modalName)
      }
      return (
        <AnamneseTemplateItem
          anamneseTemplate={item}
          key={item.id}
          onClick={navigateToAnamneseTemplate}
          onDuplicated={handleDuplicateAnamnese}
          onEdit={() => actionButton('edit-modal')}
          onRemove={() => actionButton('delete-modal')}
          onShare={() => actionButton('share-modal')}
          onVisualize={(anamneseId) =>
            navigate(
              generatePath(urls.libraryAnamneseDisplay, {
                anamnese_id: anamneseId,
              }),
            )
          }
          openOptions={() => actionButton('anamnese-options-modal')}
        />
      )
    },
    [handleDuplicateAnamnese, navigate, navigateToAnamneseTemplate, openModal],
  )

  const handleEndReached = () => {
    if (anamneseTemplates && !loading && next) {
      dispatch(
        triggerLoadMoreAnamneseTemplates({
          ...filters,
          page_size,
          page: next,
        }),
      )
    }
  }

  const handleReloadAnamnese = useCallback(
    (newFilters: ILoadAnamneseTemplatePayload) => {
      dispatch(
        triggerLoadAnamneseTemplates({
          ...newFilters,
          page_size,
        }),
      )
    },
    [dispatch],
  )

  const debouncedLoad = useDebounceFunction<
    (newFilters: ILoadAnamneseTemplatePayload) => void
  >(handleReloadAnamnese, 1000)

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('title', event.target.value, true)
    debouncedLoad(filters)
  }

  const onClickFilter = useCallback(
    (filter: IFilter) => {
      const newFilters = toggleFilter(filter.param, filter.value, true)
      handleReloadAnamnese({
        ...newFilters,
        page_size,
      })
    },
    [handleReloadAnamnese, toggleFilter],
  )

  const orderingFilters = useMemo(
    () =>
      ORDER_FILTERS.map((orderItem) => ({
        ...orderItem,
        active: filters?.ordering?.toString() === orderItem.value,
        onClick: () => {
          if (!isDesktop) closeModal()
          onClickFilter(orderItem)
        },
      })),
    [closeModal, filters?.ordering, isDesktop, onClickFilter],
  )

  const mobileOptions: TMenuDropdownOption[] = [
    {
      icon: 'edit',
      label: 'Editar',
      onClick: () => selected && openModal('edit-modal'),
    },
    {
      icon: 'contentCopy',
      label: 'Duplicar',
      onClick: () => selected && handleDuplicateAnamnese(selected),
    },
    {
      icon: 'share',
      label: 'Compartilhar',
      onClick: () => selected && openModal('share-modal'),
    },
    {
      color: theme.colors.text.critical,
      disabled: selected?.is_being_used,
      icon: 'delete',
      label: 'Excluir',
      onClick: () => selected && openModal('delete-modal'),
    },
  ]

  useLayoutEffect(() => {
    if (!anamneseTemplates && !loading && !error) {
      const query = {
        ...filters,
        page_size,
      }
      dispatch(triggerLoadAnamneseTemplates(query))
    }
  }, [anamneseTemplates, dispatch, error, filters, loading])

  useLayoutEffect(() => {
    const isFiltersEmpty = Object.keys(filters).length === 0

    if (isFiltersEmpty) {
      const query = {
        page_size,
      }
      dispatch(triggerLoadAnamneseTemplates(query))
    }
  }, [dispatch, filters])

  if (loading && anamneseTemplates === undefined) {
    return <Loading active />
  }

  return (
    <Styled.Container>
      <LibraryHeader page="Anamneses">
        <InputSearch
          placeholder="Pesquisar"
          scale="small"
          onChange={onSearchChange}
          value={filters?.title || ''}
        />
      </LibraryHeader>
      <Aligner padding="0.8rem 1.6rem" justify="flex-end">
        {isTablet && (
          <DropdownMenu items={orderingFilters}>
            <Button
              size="xsmall"
              track={buttonClickTracking}
              trackName="open_ordering_filters_menu"
              variation="borderless"
            >
              <Icon
                iconName="swapVert"
                color={theme.colors.interactive.default}
              />
              Ordernar
            </Button>
          </DropdownMenu>
        )}
      </Aligner>

      <CommonList<IAnamneseTemplate>
        bottomDistance={isTablet ? 'mb-0' : 'mb-24'}
        columns={COLUMNS_HEADERS}
        columnsFormat="grid-cols-[repeat(3,minmax(20rem,1fr))minmax(7rem,0.33fr)]"
        data={anamneseTemplates || []}
        hasMore={!!next}
        loaderComponent={<Loader />}
        onEndReached={handleEndReached}
        padding="py-4 pr-4 pl-6"
        renderItem={renderAnamneseItem}
        rowsGap="gap-y-2"
        showHeader={isTablet}
      />

      {isVisible === 'anamnese-options-modal' && (
        <ModalMoreOptions options={mobileOptions} />
      )}

      {isVisible === 'ordering-options-modal' && (
        <ModalMoreOptions options={orderingFilters} />
      )}

      {isVisible === 'edit-modal' && (
        <ModalConfirmation
          {...MODAL_EDIT_CONTENT}
          onConfirm={navigateToEditAnamnese}
          onCancel={() => {
            setSelected(undefined)
            closeModal()
          }}
        />
      )}

      {isVisible === 'share-modal' && (
        <ModalShareAnamneses
          onSend={handleShareAnamnese}
          anamneseTemplateId={selected?.id}
        />
      )}

      {isVisible === 'delete-modal' && (
        <ModalDelete
          {...MODAL_DELETE_CONTENT}
          onConfirm={() => selected && handleDeleteAnamnese(selected.id)}
          onClose={() => setSelected(undefined)}
        />
      )}
    </Styled.Container>
  )
}

export default LibraryAnamneseTemplates
