// Libraries
import styled from 'styled-components'

import { Subtitle, Body } from 'components/UI/Typography'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.default};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border: 1px solid ${({ theme }) => theme.colors.border.input};
    border-radius: 8px;
  }
`

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: 23px;
  width: 50%;
  z-index: 2;
  ${Subtitle} {
    display: none;
  }
  ${Body} {
    :nth-child(3) {
      font-size: ${({ theme }) => theme.fontSize.copy5};
      margin-top: 8px;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: auto;
    ${Subtitle} {
      display: block;
    }
    ${Body} {
      :nth-child(2) {
        display: none;
      }
      :nth-child(3) {
        font-size: ${({ theme }) => theme.fontSize.copy4};
      }
    }
  }
`

export const ProfileBackground = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface.default};
  display: flex;
  position: relative;
  padding: 1.6rem 2.4rem;
  margin-top: 0px;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    background-color: ${({ theme }) => theme.colors.surface.default};
    border-radius: 15px;
    max-height: 8.4rem;
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 1.6rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    right: 3.2rem;
  }
`
