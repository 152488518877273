// Models
import { IAthleteItem } from 'services/athletes/@types'
import { IAthletesState } from 'storage/athletes/models'
import { FormProvider, useForm } from 'react-hook-form'
import IStore from 'lib/redux/models'

// React
import { FC, MouseEvent, useContext, useLayoutEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import {
  triggerLoadAthletes,
  triggerLoadMoreAthletes,
} from 'storage/athletes/duck'
import { useDebounceFunction, useModal } from 'hooks'
import { anamneseModalNewRoutineSchema } from 'schemas'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Body, Button, CommonList, InputSearch, Subtitle } from 'heeds-ds'
import { Modal } from 'components/modals'
import RadioTag, { labeledOptions } from 'components/RadioTag'

export interface IModalNewRoutineInputs {
  athleteRoutine: labeledOptions
}

type Props = {
  infoCancel?: boolean
  onCancel?: () => void
  onNewRoutine: (id: number) => void
  width?: string
}

const page_size = 7

const ModalNewRoutine: FC<Props> = ({
  infoCancel,
  onCancel,
  onNewRoutine,
  width,
}) => {
  const { closeModal } = useModal()
  const dispatch = useDispatch()
  const { athletes, loading, error } = useSelector<IStore, IAthletesState>(
    (state) => state.athletes,
  )

  const [name, setName] = useState('')

  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  const formMethods = useForm<IModalNewRoutineInputs>({
    resolver: yupResolver(anamneseModalNewRoutineSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 800,
  })
  const { watch } = formMethods

  const athleteRoutine = watch('athleteRoutine')

  const stopPropagation = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const getVariation = () => {
    if (isDesktop || infoCancel) return 'borderless'
    return 'outlined'
  }

  const handleCancel = () => {
    if (onCancel) return onCancel()
    closeModal()
  }

  const handleConfirm = (id: number) => {
    onNewRoutine(id)
    closeModal()
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    debouncedLoad(event.target.value)
  }

  function renderItems(item: IAthleteItem) {
    return (
      <Styled.ScrollContainer>
        <RadioTag
          name="athleteRoutine"
          options={[
            {
              label: item.name || '',
              value: item.id.toString(),
              model: item.plan_model,
            },
          ]}
          column
        />
      </Styled.ScrollContainer>
    )
  }

  const handleEndReached = () => {
    if (athletes && !loading && athletes?.next) {
      const query = {
        name: name,
        page: athletes.next,
        page_size,
      }
      dispatch(triggerLoadMoreAthletes(query))
    }
  }

  const handleReloadAthletes = (name: string) => {
    const query = {
      name: name,
      page_size,
    }
    dispatch(triggerLoadAthletes(query))
  }

  const debouncedLoad = useDebounceFunction<(newFilters: string) => void>(
    handleReloadAthletes,
    1000,
  )

  useLayoutEffect(() => {
    if (!athletes?.results && !loading && !error) {
      const query = {
        name: name,
        page_size,
      }
      dispatch(triggerLoadAthletes(query))
    }
  }, [athletes?.results, dispatch, error, loading, name])

  return (
    <Modal onClose={handleCancel}>
      <FormProvider {...formMethods}>
        <Styled.Container onClick={stopPropagation} width={width}>
          <Styled.CardTop>
            <Subtitle type="sh2" weight={600}>
              Criar uma nova rotina
            </Subtitle>
          </Styled.CardTop>
          <Body type="copy1" color={theme.colors.text.subdued}>
            Selecione o aluno que receberá a nova rotina.
          </Body>
          <InputSearch onChange={onSearchChange} value={name} margin="32px 0" />
          <Styled.AthleteList>
            <CommonList<IAthleteItem>
              columns={[]}
              columnsGap="gap-x-12"
              data={athletes?.results ?? []}
              hasMore={!!athletes?.next}
              height="30.5rem"
              onEndReached={handleEndReached}
              renderItem={renderItems}
              padding="py-4 pr-[1px] pl-6"
              showHeader={false}
            />
          </Styled.AthleteList>
          <Styled.ButtonsContainer>
            <Button
              cancel={!infoCancel}
              onClick={handleCancel}
              size="small"
              track={buttonClickTracking}
              trackName={`cancel_modal_new_routine`}
              variation={getVariation()}
            >
              Cancelar
            </Button>
            <Button
              disabled={athleteRoutine === undefined}
              onClick={() => handleConfirm(parseInt(athleteRoutine.label))}
              size="small"
              track={buttonClickTracking}
              trackName={`create_routine_modal`}
              className="w-full"
            >
              Criar rotina
            </Button>
          </Styled.ButtonsContainer>
        </Styled.Container>
      </FormProvider>
    </Modal>
  )
}

export default ModalNewRoutine
