// React
import { FC } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'

// Components
import * as Styled from './styled'
import { Aligner } from 'components/UI/Containers'
import { Body } from 'components/UI/Typography'
import { Button, Icon } from 'heeds-ds'
import { theme } from 'lib/styled-components/theme'

interface Props {
  buttonText: string
  colorIcon: string
  hideButton: boolean
  text: string
  title: string
  onClick: () => void
}

const PendingSummary: FC<Props> = ({
  buttonText,
  colorIcon,
  hideButton = false,
  text,
  title,
  onClick,
}) => {
  return (
    <Styled.Container>
      <Styled.CheckContainer>
        <Icon iconName="circleCheck" color={colorIcon} size={24} />
      </Styled.CheckContainer>
      <Aligner flex="column">
        <Body type="copy3" weight={700}>
          {title}
        </Body>
        <Body
          type="copy4"
          weight={500}
          margin="0.8rem 0 0 0"
          color={theme.colors.text.subdued}
        >
          {text}
        </Body>
        {!hideButton && (
          <Button
            onClick={onClick}
            variation="borderless"
            size="xsmall"
            track={buttonClickTracking}
            trackName="navigate_to_create_athlete"
            className="mt-4"
          >
            {buttonText}
          </Button>
        )}
      </Aligner>
    </Styled.Container>
  )
}

export default PendingSummary
