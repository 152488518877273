// Models
import { EInstallmentStatusToPortuguese } from 'models'
import { IFinancialAthleteProps } from '..'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC } from 'react'

// Misc
import { BRL } from 'lib/currency'
import { cn } from 'utils/helpers/classess'
import { dateFormatEnglishToPortuguese } from 'utils/functions'

// Components
import { DropdownMenu, IconButton } from 'heeds-ds'

// Constants
import { STATUS_VARIATION } from '..'

interface Props extends IFinancialAthleteProps {
  options: TMenuDropdownOption[]
}

const Mobile: FC<Props> = ({
  installment: { id, status, value, due_date },
  options,
}) => {
  return (
    <div
      className={cn(
        'flex w-full flex-col gap-4 rounded-3xl border border-border-input',
        'bg-surface p-4',
      )}
      data-testid={`financial-athlete-item-${id}`}
    >
      {status !== undefined && (
        <div
          className={cn(
            'w-fit rounded-full px-4 py-2',
            STATUS_VARIATION[status],
          )}
        >
          <p className={cn('text-copy5 font-semibold leading-6')}>
            {EInstallmentStatusToPortuguese[status]}
          </p>
        </div>
      )}

      <div
        className="flex items-center gap-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mr-auto flex flex-col gap-1">
          <p
            className={cn(
              'max-w-[200px] break-words text-copy3 font-semibold',
              'leading-7 sm:max-w-full',
            )}
          >
            {BRL(value).format()}
          </p>
          <p className="text-copy3 text-text-subdued">
            {due_date &&
              `Vencimento: ${dateFormatEnglishToPortuguese(due_date)}`}
          </p>
        </div>

        <DropdownMenu items={options}>
          <IconButton iconName="moreVertical" size="small" />
        </DropdownMenu>
      </div>
    </div>
  )
}

export default Mobile
