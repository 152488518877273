// Libraries
import styled from 'styled-components'

// Components
import { Button } from 'heeds-ds'

export const CancelButton = styled(Button).attrs(() => ({
  cancel: true,
  margin: '0',
  size: 'xsmall',
  variation: 'outlined',
}))``

export const Content = styled.div`
  flex: 1;
  display: flex;
  height: calc(100vh - 29.1rem);
  position: relative;
`

export const GoBackButton = styled(Button).attrs(() => ({
  margin: '0',
  size: 'xsmall',
  variation: 'outlined',
}))``

export const NextButton = styled(Button).attrs(() => ({
  margin: '0',
  size: 'xsmall',
}))``
