// Models
import { IExerciseData } from 'storage/exercise/models'
import { IFormInputs } from 'models'
import { IWorkoutModelState } from 'storage/workoutModel/models'
import IStore from 'lib/redux/models'

// React
import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

// Libraries
import { ThemeContext } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { prepareModelsToPayload } from 'filters/workouts'
import {
  triggerClearWorkoutModels,
  triggerCreateWorkoutModelTemplate,
  triggerListWorkoutModelTemplate,
  triggerUpdateWorkoutsModel,
} from 'storage/workoutModel/duck'
import { triggerToastSuccess } from 'storage/general/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useModal } from 'hooks'
import { workoutModelsSchema } from 'schemas'

// Components
import * as Styled from './styled'
import { IconButton, InputText, Loading } from 'heeds-ds'
import { ModalExerciseImage, WorkoutModelReviewCard } from 'components'

const LibraryWorkoutModelReview: FC = () => {
  const { loading, workoutModels } = useSelector<IStore, IWorkoutModelState>(
    (state) => state.workoutModel,
  )
  const { breakpoints } = useContext(ThemeContext)
  const { closeModal, openModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)
  const navigate = useNavigate()

  const [selectedExercise, setSelectedExercise] = useState<IExerciseData>()

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(workoutModelsSchema),
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { models: workoutModels || [] },
  })

  const openExerciseGifModal = useCallback(
    (exercise: IExerciseData) => {
      setSelectedExercise(exercise)
      openModal('mobile-exercise-gif-modal')
    },
    [openModal],
  )

  const handleGoBack = () => navigate(-1)

  const onSubmit = (dataForm: IFormInputs) => {
    if (workoutModels) {
      const updatedData = prepareModelsToPayload(dataForm.models)[0]

      const successCallback = () => {
        dispatch(triggerClearWorkoutModels())
        dispatch(triggerListWorkoutModelTemplate({ page_size: 20 }))
        navigate(urls.LibraryWorkoutModels, { replace: true })
        dispatch(triggerToastSuccess({ customTitle: 'Seu modelo foi salvo!' }))
      }

      dispatch(
        updatedData.id
          ? triggerUpdateWorkoutsModel({
              ...updatedData,
              model_pk: updatedData.id,
              successCallback,
            })
          : triggerCreateWorkoutModelTemplate({
              ...updatedData,
              successCallback,
            }),
      )
    }
  }

  const handleCancelClick = useCallback(() => {
    navigate(urls.LibraryWorkoutModels, {
      replace: true,
    })
  }, [navigate])

  const renderWorkoutModels = useMemo(
    () =>
      workoutModels?.map((model) => (
        <WorkoutModelReviewCard
          headerVisible={false}
          key={model.id}
          openGifModal={openExerciseGifModal}
          startsExpanded
          workoutModel={model}
        />
      )),
    [openExerciseGifModal, workoutModels],
  )

  if (loading) {
    return <Loading active />
  }

  return (
    <Styled.Container>
      <Styled.ContainerHeader>
        {isDesktop && (
          <IconButton
            size="large"
            iconName="arrowBack"
            onClick={handleGoBack}
          />
        )}
        <Styled.Header>
          <Styled.ModelNameLabel>Nome do modelo: </Styled.ModelNameLabel>
          <FormProvider {...methods}>
            <InputText
              displayError={false}
              margin="0"
              name="models.0.name"
              placeholder="Digite o nome do modelo de treino"
            />
          </FormProvider>
        </Styled.Header>
      </Styled.ContainerHeader>

      <Styled.Content>
        <FormProvider {...methods}>
          <Styled.Form onSubmit={methods.handleSubmit(onSubmit)}>
            {renderWorkoutModels}
          </Styled.Form>
        </FormProvider>

        <Styled.ButtonContainer>
          <Styled.CancelButton
            onClick={handleCancelClick}
            size={isDesktop ? 'xsmall' : 'small'}
            track={buttonClickTracking}
            trackName="navigate_to_athlete_routines"
          >
            Cancelar
          </Styled.CancelButton>

          <Styled.NextButton
            size={isDesktop ? 'xsmall' : 'small'}
            track={buttonClickTracking}
            trackName="create_or_update_models"
            onClick={methods.handleSubmit(onSubmit)}
          >
            Salvar modelo
          </Styled.NextButton>
        </Styled.ButtonContainer>
      </Styled.Content>

      {isVisible === 'mobile-exercise-gif-modal' && (
        <ModalExerciseImage
          exercise={selectedExercise}
          handleClose={closeModal}
        />
      )}
    </Styled.Container>
  )
}

export default LibraryWorkoutModelReview
