import { IExerciseData } from 'storage/exercise/models'
import {
  EQUIPMENTS_OPTIONS,
  MUSCLE_GROUP_LIST_TYPE_FIELD_OPTIONS,
  MUSCLE_LIST_TYPE_FIELD_OPTIONS,
} from 'utils/constants'

export interface IExerciseOption {
  id?: number
  label?: string
  value?: string
}

export interface IExerciseDisplay {
  name: string
  file?: string | File | Blob
  thumbnail?: string
  equipment?: string
  force_type?: string
  difficulty?: string
  exercise_type?: string
  mechanic?: string
  agonists_muscle?: IExerciseOption[]
  agonists_muscle_specific?: IExerciseOption[]
  synergists_muscle?: IExerciseOption[]
  synergists_muscle_specific?: IExerciseOption[]
  instructions?: string
}

export const prepareExerciseToDisplay = (exercise: IExerciseData) => {
  const {
    equipment,
    name,
    agonists_muscle,
    synergists_muscle,
    file,
    thumbnail,
    force_type,
    difficulty,
    exercise_type,
    mechanic,
    instructions,
  } = exercise

  const newData = {
    name: name,
    file: file,
    thumbnail: thumbnail,
    instructions: instructions,
    equipment: equipment,
    agonists_muscle: MUSCLE_GROUP_LIST_TYPE_FIELD_OPTIONS.filter((item) =>
      agonists_muscle?.includes(item.id),
    ),
    agonists_muscle_specific: MUSCLE_LIST_TYPE_FIELD_OPTIONS.filter((item) =>
      agonists_muscle?.includes(item.id),
    ),
    synergists_muscle: MUSCLE_GROUP_LIST_TYPE_FIELD_OPTIONS.filter((item) =>
      synergists_muscle?.includes(item.id),
    ),
    synergists_muscle_specific: MUSCLE_LIST_TYPE_FIELD_OPTIONS.filter((item) =>
      synergists_muscle?.includes(item.id),
    ),
    force_type: force_type,
    difficulty: difficulty,
    exercise_type: exercise_type,
    mechanic: mechanic,
  }

  return newData
}

export const prepareExerciseToPayload = (
  data: IExerciseDisplay,
  profileId: number,
  id: string,
) => {
  let fileFormatted: Blob | undefined
  let fileExtension: string | undefined

  if (data.file instanceof File) {
    fileFormatted = data.file as Blob
    fileExtension = fileFormatted?.type?.split('/')[1]
  } else if (typeof data.file === 'string') {
    const fileNameParts = data.file.split('.')
    fileExtension = fileNameParts.length > 1 ? fileNameParts.pop() : undefined
  }

  const payload = {
    athlete_pk: profileId || 0,
    exercise: {
      name: data.name,
      id: Number(id) || 0,
      file: data.file,
      file_extension: fileExtension,
      instructions: data.instructions,
      agonists_muscle: [
        ...((data.agonists_muscle &&
          data.agonists_muscle?.map((agonists) => agonists.id)) ||
          []),
        ...((data.agonists_muscle_specific &&
          data.agonists_muscle_specific?.map((agonists) => agonists.id)) ||
          []),
      ].filter((muscle): muscle is number => muscle !== undefined),
      synergists_muscle: [
        ...((data.synergists_muscle &&
          data.synergists_muscle?.map((synergist) => synergist.id)) ||
          []),
        ...((data.synergists_muscle_specific &&
          data.synergists_muscle_specific?.map((synergist) => synergist.id)) ||
          []),
      ].filter((muscle): muscle is number => muscle !== undefined),
      equipment_id: EQUIPMENTS_OPTIONS.filter(
        (item) => item.label === data.equipment,
      )[0]?.id,
      equipment: data.equipment,
      force_type: data.force_type,
      difficulty: data.difficulty,
      exercise_type: data.exercise_type,
      mechanic: data.mechanic,
    },
  }

  return payload
}

export const handleDisplayMuscleGroup = (
  muscleIds?: number[],
  variation: 'list' | 'size' = 'list',
) => {
  const muscles = MUSCLE_GROUP_LIST_TYPE_FIELD_OPTIONS.filter((muscleGroup) =>
    muscleIds?.includes(muscleGroup.id),
  ).map(({ label }) => label.split(' ').reverse()[0])

  return variation === 'list' ? muscles.join() : muscles.length
}

export const handleDisplayMuscleQuantity = (muscleIds?: number[]) => {
  return MUSCLE_LIST_TYPE_FIELD_OPTIONS.filter((muscleGroup) =>
    muscleIds?.includes(muscleGroup.id),
  ).length
}
