// Models
import { IExerciseData } from 'storage/exercise/models'
import {
  ESetTypeToPortuguese,
  IWorkoutSetInput,
  TSetSpecialSet,
  TSpecialSetInput,
  TWorkoutSetType,
} from 'models'

// React
import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useFormContext } from 'react-hook-form'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'

// Components
import { Aligner, Button, DropdownMenu, Icon, IconButton } from 'heeds-ds'
import SetContent, { SetType } from './contents'

// Cosntants
import { SET_TYPE_OPTIONS } from 'utils/constants'

const fieldLabels = {
  cadence: 'Cadência',
  comments: 'Comentários',
  repetitions: 'Repetição',
  speed: 'Velocidade',
  time: 'Tempo',
  weight: 'Carga',
}

export type FieldType = keyof typeof fieldLabels

type DivAttributes = React.HTMLAttributes<HTMLDivElement>

type Props = DivAttributes & {
  addOrRemoveFieldFromWorkoutSetToExercise?: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  displayMode?: boolean
  dragRef?: React.ForwardedRef<HTMLDivElement>
  isDragging?: boolean
  modelId?: string
  modelIndex: number
  name: string
  onClickImage?: (exercise: IExerciseData) => void
  openExercisesModal?: () => void
  onDragging?: (isDragging: boolean) => void
  onRemove?: () => void
  readonly?: boolean
  removeExerciseFromWorkoutSet?: (exerciseIndex: number) => void
  replicateWorkoutSetFields?: (set: IWorkoutSetInput) => void
  setSpecialSet?: TSetSpecialSet
  showBorder?: boolean
  specialSet?: TSpecialSetInput
  uncheckAllRepeats?: () => void
  updateWorkoutSet?: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
  visible?: boolean
  workoutSet: IWorkoutSetInput & { index?: number }
}

const WorkoutSetFormTag: FC<Props> = (props) => {
  const {
    addOrRemoveFieldFromWorkoutSetToExercise,
    className,
    dragRef,
    isDragging,
    modelIndex,
    name,
    onDragging,
    onRemove,
    readonly,
    removeExerciseFromWorkoutSet,
    replicateWorkoutSetFields,
    setSpecialSet,
    showBorder,
    specialSet,
    uncheckAllRepeats,
    updateWorkoutSet,
    visible,
    workoutSet,
    ...containerProps
  } = props
  const { colors } = useContext(ThemeContext)
  const { setValue, getFieldState, watch, resetField } = useFormContext()

  const [expandContent, setExpandContent] = useState(true)

  const { isDirty: someFieldIsDirty } = getFieldState(name)
  const { isDirty: repeatFieldIsDirty } = getFieldState(`${name}.repeat`)

  const blocked = specialSet && specialSet.id !== workoutSet.id
  const editMode = specialSet && specialSet.id === workoutSet.id
  const viewMode = readonly || blocked

  const visibleContent = useMemo(
    () => expandContent && !isDragging,
    [expandContent, isDragging],
  )

  const currentWorkoutSet = useMemo(
    () =>
      specialSet?.id === workoutSet.id
        ? {
            ...specialSet,
            exercises: Object.values(specialSet.exercises),
          }
        : workoutSet,
    [specialSet, workoutSet],
  )

  const typeLabel =
    ESetTypeToPortuguese[currentWorkoutSet.type as TWorkoutSetType]

  const convertToType = (type: string) => {
    const maxExercises = {
      normal: 1,
      bi_set: 2,
      tri_set: 3,
    }

    return {
      ...workoutSet,
      type: type,
      exercises: [
        ...workoutSet.exercises.slice(
          0,
          maxExercises[(type as keyof typeof maxExercises) ?? 'normal'],
        ),
      ],
    }
  }

  const changeType = (type: string) => {
    const updatedWorkoutSet = convertToType(type as string)
    if (type !== 'normal') {
      setSpecialSet?.({
        ...updatedWorkoutSet,
        exercises: updatedWorkoutSet.exercises.reduce(
          (acc, exerc) => ({
            ...acc,
            [exerc.id]: exerc,
          }),
          {},
        ),
      })
    } else {
      props.modelId &&
        updateWorkoutSet?.(props.modelId, workoutSet.id, updatedWorkoutSet)
      setSpecialSet?.(undefined)
    }
  }

  const setOptions = SET_TYPE_OPTIONS.map(({ label, value }) => ({
    label,
    onClick: () => changeType(value),
  }))

  const addCustomField = useCallback(
    (field: FieldType, index: number, remove = false) => {
      const { modelId } = props
      if (!modelId) return

      addOrRemoveFieldFromWorkoutSetToExercise?.(
        modelId,
        workoutSet.id,
        index,
        field,
        !remove,
      )
      setValue(`${name}.exercises.${index}.${field}`, remove ? undefined : '')
    },
    [
      addOrRemoveFieldFromWorkoutSetToExercise,
      name,
      props,
      setValue,
      workoutSet.id,
    ],
  )

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    setExpandContent((prev) => !prev)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(`${name}.repeat`, event.target.checked)

    if (event.target.checked) {
      resetField(name, { defaultValue: watch(name) })
      const set = watch(name) as IWorkoutSetInput
      replicateWorkoutSetFields?.(set)
    } else {
      uncheckAllRepeats?.()
    }
  }

  const handleUpdateWorkoutSet = () => {
    if (!props.modelId || !specialSet) return
    const updatedExercises = Object.values(specialSet.exercises).map(
      (setToExercise, setIndex) => {
        return {
          ...setToExercise,
          ...watch(`${name}.exercises.${setIndex}`),
        }
      },
    )
    updateWorkoutSet?.(props.modelId, workoutSet.id, {
      ...workoutSet,
      type: specialSet.type,
      exercises: updatedExercises,
    })
    setSpecialSet?.(undefined)
  }

  const handleRemoveExerciseFromWorkoutSet = (exerciseIndex: number) => {
    if (specialSet) return removeExerciseFromWorkoutSet?.(exerciseIndex)
    setSpecialSet?.({
      ...workoutSet,
      exercises: workoutSet.exercises.reduce((acc, exerc, index) => {
        if (index === exerciseIndex) return acc
        return {
          ...acc,
          [exerc.id]: exerc,
        }
      }, {}),
    })
  }

  const contentProps = {
    ...props,
    addCustomField,
    fieldLabels,
    handleCheckboxChange,
    modelIndex,
    readonly: viewMode,
    removeExerciseFromWorkoutSet: handleRemoveExerciseFromWorkoutSet,
    workoutSet: currentWorkoutSet,
  }

  useEffect(() => {
    if (someFieldIsDirty && !repeatFieldIsDirty) {
      setValue(`${name}.repeat`, false)
    }
  }, [someFieldIsDirty, name, repeatFieldIsDirty, setValue])

  return (
    <div
      {...containerProps}
      className={cn(
        'hidden w-full border-l-icon bg-surface xl:shadow-none',
        {
          'border-l-2': showBorder,
          'border-l-icon-disabled': blocked,
          'flex flex-row shadow-md': visible,
        },
        className,
      )}
    >
      <div
        className="flex cursor-grab items-center justify-center xl:p-2"
        onDragEnd={() => onDragging?.(false)}
        onDragEnter={() => onDragging?.(true)}
        onTouchEnd={() => onDragging?.(false)}
        onTouchStart={() => onDragging?.(true)}
        ref={dragRef}
      >
        <Icon
          color={colors.icon.disabled}
          iconName="dragIndicatorVertical"
          size={24}
        />
      </div>
      <div
        className="w-full"
        data-testid="series-form-tag-container"
        draggable={false}
      >
        <div className="flex w-full items-center justify-between gap-4 px-4 py-2">
          <DropdownMenu items={setOptions} disabled={viewMode}>
            <button
              className={cn(
                'inline-flex h-[34px] cursor-pointer items-center justify-center',
                'gap-4 whitespace-nowrap rounded-full border border-badge-purpleText',
                'bg-badge-purpleBackground px-4 py-2 ',
                'hover:bg-action-highlightSofblue',
                'focus:shadow-[0px_0px_5px_0px_#2b2a63] focus:outline-1 focus:outline-badge-purpleText',
              )}
            >
              <p className="text-copy5 font-bold leading-none text-text">
                {typeLabel}
              </p>

              <Icon iconName="expandMore" />
            </button>
          </DropdownMenu>

          {!viewMode && (
            <Aligner justify="flex-end" gap="1.6rem">
              <IconButton
                iconName={visibleContent ? 'expandLess' : 'expandMore'}
                onClick={handleClick}
                size="small"
                track={buttonClickTracking}
                trackName={`${visibleContent ? 'hide' : 'show'}_workout_fields`}
              />

              <IconButton
                cancel
                data-testid="exercise-add-button"
                iconName="delete"
                onClick={onRemove}
                size="small"
                track={buttonClickTracking}
                trackName="remove_workout_set"
              />
            </Aligner>
          )}
        </div>

        <SetContent
          {...contentProps}
          visible={visibleContent}
          type={currentWorkoutSet.type as SetType}
        />

        {editMode && (
          <Aligner justify="space-between" padding="1.6rem">
            <Button
              cancel
              onClick={() => setSpecialSet?.(undefined)}
              size="small"
              track={buttonClickTracking}
              trackName="cancel_update_workout_set"
              variation="borderless"
            >
              Cancelar
            </Button>

            <Button
              onClick={handleUpdateWorkoutSet}
              size="small"
              track={buttonClickTracking}
              trackName="update_workout_set"
              variation="borderless"
            >
              Salvar
            </Button>
          </Aligner>
        )}
      </div>
    </div>
  )
}

export default WorkoutSetFormTag
