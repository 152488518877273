// Libraries
import styled from 'styled-components'

enum EScales {
  default = 1.6,
  large = 2.4,
}

type RadioProps = {
  scale: 'default' | 'large'
}

export const Radio = styled.input.attrs({
  type: 'radio',
})<RadioProps>`
  cursor: inherit;
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: ${({ theme }) => theme.colors.icon.default};
  width: ${({ scale }) => EScales[scale]}rem;
  height: ${({ scale }) => EScales[scale]}rem;
  border: 1px solid ${({ theme }) => theme.colors.icon.default};
  border-radius: 50%;
  display: grid;
  place-content: center;
  transition: 0.2s all ease-in-out;

  :focus {
    outline: 1px solid ${({ theme }) => theme.colors.icon.disabled};
    outline-offset: 1px;
  }

  :disabled {
    color: ${({ theme }) => theme.colors.icon.disabled};
    border-color: ${({ theme }) => theme.colors.icon.disabled};

    ::before {
      background-color: ${({ theme }) => theme.colors.icon.disabled};
    }
  }

  ::before {
    content: '';
    width: ${({ scale }) => EScales[scale] / 2}rem;
    height: ${({ scale }) => EScales[scale] / 2}rem;
    background-color: ${({ theme }) => theme.colors.icon.default};
    border-radius: 50%;
    transform: scale(0);
    transition: 0.2s all ease-in-out, 0.1s transform ease-in-out;
  }
  :checked:before {
    transform: scale(1);
  }
`

export const Label = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text.default};

  :hover {
    ${Radio} {
      border-color: ${({ theme }) => theme.colors.icon.hovered};
      ::before {
        background-color: ${({ theme }) => theme.colors.icon.hovered};
      }
    }
  }

  &[aria-disabled='true'] {
    pointer-events: none;
    color: ${({ theme }) => theme.colors.text.disabled};

    :hover {
      ${Radio} {
        border-color: ${({ theme }) => theme.colors.icon.disabled};
        ::before {
          background-color: ${({ theme }) => theme.colors.icon.disabled};
        }
      }
    }
  }
`
