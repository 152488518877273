// Models
import { TButtonTrakingEvent } from '../../../models'

// React
import { forwardRef } from 'react'

// Misc
import { buttonStyles } from '../BaseButton/styles'
import { cn } from '../../../utils/classes'

// Libraries
import { NavLinkProps, useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

// Components
import { Props as ButtonProps } from '../BaseButton'

export type Props = ButtonProps &
  NavLinkProps & {
    track?: (event: TButtonTrakingEvent) => void
    trackName?: string
    className?: string
  }

const LinkButton = forwardRef<HTMLAnchorElement, Props>(
  (
    { track, trackName, className, size, cancel, onlyIcon, disabled, ...props },
    ref,
  ) => {
    const { search } = useLocation()
    const { variation, children } = props

    const tracking = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation()
      track?.({
        eventName: 'click',
        action: `click_link_${trackName}`,
        location: window.location.pathname + search,
      })
    }

    return (
      <NavLink
        ref={ref}
        variation={variation}
        data-testid="linkButton"
        onClick={tracking}
        {...props}
        className={cn(
          buttonStyles({
            size,
            variation,
            cancel,
            onlyIcon,
            disabled,
          }),
          className,
        )}
      >
        {children}
      </NavLink>
    )
  },
)
LinkButton.displayName = 'LinkButton'

export default LinkButton
