export const capitalizeFirstLetter = (text?: string) => {
  if (!text) return ''
  return text[0].toUpperCase() + text.slice(1).toLowerCase()
}

export const toPascalCase = (text?: string) => {
  if (!text) return ''
  return text
    .replace(/[-_]+/g, ' ')
    .split(' ')
    .map(capitalizeFirstLetter)
    .join(' ')
}
