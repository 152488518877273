// Models
import { FieldType } from 'components/WorkoutSetFormTag'
import { IExerciseData } from 'storage/exercise/models'
import {
  IModelInput,
  IWorkoutSetInput,
  TMuscleGroupFilter,
  TSetSpecialSet,
  TSpecialSetInput,
} from 'models'
import { TSetStateModels } from 'hooks/useWorkoutModelsForm/@types'

// React
import { FC, useLayoutEffect, useMemo, useState } from 'react'

// Misc
import { getWorkoutModelsExerciseLenght, uid } from 'utils/functions'
import { useMenu, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import {
  ExerciseItem,
  ModalExercises,
  ModalExerciseImage,
  ModalMuscleGroups,
  WorkoutSets,
} from 'components'

type Props = {
  addExerciseToWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exercise: IExerciseData,
  ) => void
  addOrRemoveFieldFromWorkoutSetToExercise?: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  addWorkoutSet: (modelId: string, set: IWorkoutSetInput) => void
  changePage: (page: string) => void
  filters: TMuscleGroupFilter[]
  inputValue: string
  loadMoreExercises: () => void
  onChangeFilters: (filters: TMuscleGroupFilter[]) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  removeExerciseFromWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
  ) => void
  removeFilter: (label: string) => void
  removeWorkoutSet: (modelId: string, workoutSetId: string) => void
  replicateWorkoutSetFields?: (modelId: string, set: IWorkoutSetInput) => void
  selectedModelIndex: number
  setSpecialSet: TSetSpecialSet
  setStateModels: TSetStateModels
  specialSet?: TSpecialSetInput
  stateModels: IModelInput[]
  updateWorkoutModel: (modelId: string, updatedModel: IModelInput) => void
  updateWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
}

const WorkoutModelsMobileSets: FC<Props> = ({
  addExerciseToWorkoutSet,
  addOrRemoveFieldFromWorkoutSetToExercise,
  changePage,
  filters,
  inputValue,
  loadMoreExercises,
  onChangeFilters,
  onSearchChange,
  removeExerciseFromWorkoutSet,
  removeFilter,
  removeWorkoutSet,
  replicateWorkoutSetFields,
  selectedModelIndex,
  setSpecialSet,
  setStateModels,
  specialSet,
  stateModels,
  updateWorkoutModel,
  updateWorkoutSet,
}) => {
  const { closeModal, openModal, isVisible } = useModal()
  const { setPagename } = useMenu()

  const [selectedExercise, setSelectedExercise] = useState<
    IExerciseData | undefined
  >()

  const addExercise = (exercise: IExerciseData) => {
    const modelId = stateModels[selectedModelIndex].id
    const setId = uid()

    if (specialSet) {
      addExerciseToWorkoutSet(modelId, setId, exercise)
    }
  }

  const renderExercise = (exercise: IExerciseData) => {
    const hasInclude =
      specialSet?.exercises &&
      Object.values(specialSet?.exercises).find(
        (setExercise) => setExercise?.exercise?.name === exercise?.name,
      ) !== undefined
    return (
      <ExerciseItem
        checked={hasInclude}
        exercise={exercise}
        key={exercise.id}
        onClick={addExercise}
        onClickImage={openIncludeExerciseGifModal}
        shadow
      />
    )
  }

  const openExercisesModal = () => openModal('mobile-exercises-modal')

  const openExerciseGifModal = (exercise: IExerciseData) => {
    setSelectedExercise(exercise)
    openModal('mobile-exercise-gif-modal')
  }

  const openIncludeExerciseGifModal = (exercise: IExerciseData) => {
    setSelectedExercise(exercise)
    openModal('mobile-include-exercise-gif-modal')
  }

  const closeExerciseGifModal = () => {
    setSelectedExercise(undefined)
    if (isVisible === 'mobile-include-exercise-gif-modal') {
      return openExercisesModal()
    }
    closeModal()
  }

  const countSets = useMemo(
    () => getWorkoutModelsExerciseLenght(stateModels[selectedModelIndex]),
    [selectedModelIndex, stateModels],
  )

  const exercisesLengthText = useMemo(
    () => (countSets === 1 ? '1 exercício' : `${+countSets} exercícios`),
    [countSets],
  )

  useLayoutEffect(() => {
    setPagename(stateModels?.[selectedModelIndex].name ?? 'Treino')

    return () => setPagename('Rotinas')
  }, [selectedModelIndex, setPagename, stateModels])

  return (
    <Styled.Container>
      <Styled.CountExercises>{exercisesLengthText}</Styled.CountExercises>

      <WorkoutSets
        removeExerciseFromWorkoutSet={removeExerciseFromWorkoutSet}
        removeWorkoutSet={removeWorkoutSet}
        addOrRemoveFieldFromWorkoutSetToExercise={
          addOrRemoveFieldFromWorkoutSetToExercise
        }
        replicateWorkoutSetFields={replicateWorkoutSetFields}
        specialSet={specialSet}
        updateWorkoutSet={updateWorkoutSet}
        changePage={changePage}
        formIndex={selectedModelIndex}
        openExerciseGifModal={openExerciseGifModal}
        selectedModelIndex={selectedModelIndex}
        setSpecialSet={setSpecialSet}
        setStateModels={setStateModels}
        stateModels={stateModels}
        openExercisesModal={openExercisesModal}
        updateWorkoutModel={updateWorkoutModel}
      />

      {isVisible === 'mobile-exercises-modal' && (
        <ModalExercises
          filters={filters}
          inputValue={inputValue}
          loadMoreExercises={loadMoreExercises}
          onClickFilter={() => openModal('muscle-groups-modal')}
          onSearchChange={onSearchChange}
          removeFilter={removeFilter}
          renderExercise={renderExercise}
        />
      )}

      {(isVisible === 'mobile-exercise-gif-modal' ||
        isVisible === 'mobile-include-exercise-gif-modal') && (
        <ModalExerciseImage
          exercise={selectedExercise}
          handleClose={closeExerciseGifModal}
        />
      )}

      {isVisible === 'muscle-groups-modal' && (
        <ModalMuscleGroups
          filters={filters}
          handleClose={openExercisesModal}
          onChange={onChangeFilters}
        />
      )}
    </Styled.Container>
  )
}

export default WorkoutModelsMobileSets
