// Models
import { IAction } from 'lib/redux/models'
import { IAthletePlan } from 'services/athletePlans/@types'
import {
  ICreateAthletePlansPayload,
  ILoadAthletePlansPayload,
  IUpdateAthletePlansPayload,
} from '../models'

// Libraries
import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'

// Misc
import {
  createAthletePlans,
  loadAthletePlans,
  updateAthletePlans,
} from 'services/athletePlans'
import { failure, fulfill, request, success, Types } from '../duck'

export function* createAthletePlansSaga(
  action: IAction<ICreateAthletePlansPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IAthletePlan = yield call(createAthletePlans, payload)

      yield put(success({ plans: [response] }))

      if (successCallback) {
        successCallback()
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadAthletePlansSaga(
  action: IAction<ILoadAthletePlansPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { id, successCallback } = action.payload
      const response: IAthletePlan = yield call(loadAthletePlans, {
        id,
      })
      if (response) {
        yield put(success({ plans: [response] }))
      }
      if (successCallback) {
        successCallback()
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(success({ plans: [], error: '404' }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* updateAthletePlansSaga(
  action: IAction<IUpdateAthletePlansPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IAthletePlan = yield call(updateAthletePlans, payload)

      yield put(success({ plans: [response] }))

      if (successCallback) {
        successCallback()
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* athleteSaga(): SagaIterator {
  yield all([
    takeLatest(Types.CREATE_PLANS, createAthletePlansSaga),
    takeLatest(Types.LOAD_PLANS, loadAthletePlansSaga),
    takeLatest(Types.UPDATE_PLANS, updateAthletePlansSaga),
  ])
}
