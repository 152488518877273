import { Aligner } from 'heeds-ds'
import styled from 'styled-components'

export const Container = styled(Aligner)`
  flex-direction: column;
  flex: 1;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 4rem 4.8rem 2.4rem 4rem;
  }
`

export const Header = styled(Aligner)`
  flex-direction: column;
  gap: 0.8rem;
  padding: 0 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    align-items: center;
    flex-direction: row;
    gap: 2.4rem;
    padding: 0;
  }
`

export const Content = styled(Aligner)`
  flex-direction: column;
  flex: 1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: row;
    max-height: calc(100vh - 14rem);
  }
`
