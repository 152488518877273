// Models
import { IBaseState } from 'lib/redux/models'
import { TRoutineDificulty, TRoutineGoal } from 'models'
import { IPaginatedResponse } from 'models/api'
import { IWorkoutModelData } from 'storage/workoutModel/models'

export enum EWorkoutRoutineActionTypes {
  CLEANUP = 'WORKOUT_ROUTINE/CLEANUP',
  FAILURE = 'WORKOUT_ROUTINE/FAILURE',
  FULFILL = 'WORKOUT_ROUTINE/FULFILL',
  REQUEST = 'WORKOUT_ROUTINE/REQUEST',
  SUCCESS = 'WORKOUT_ROUTINE/SUCCESS',
  CREATE_WORKOUT_ROUTINE = 'WORKOUT_ROUTINE/CREATE',
  DELETE_WORKOUT_ROUTINE = 'WORKOUT_ROUTINE/DELETE',
  DELETE_WORKOUT_ROUTINE_TEMPLATE = 'WORKOUT_ROUTINE/DELETE_ROUTINE_TEMPLATE',
  GET_LATEST_WORKOUT_ROUTINE = 'WORKOUT_ROUTINE/GET_LATEST',
  GET_DETAIL_WORKOUT_ROUTINE = 'WORKOUT_ROUTINE/GET_DETAIL',
  PARTIAL_UPDATE_WORKOUT_ROUTINE = 'WORKOUT_ROUTINE/PARTIAL_UPDATE',
  UPDATE_WORKOUT_ROUTINE = 'WORKOUT_ROUTINE/UPDATE',
  SUCCESS_LOAD_MORE = 'WORKOUT_ROUTINE/SUCCESS_LOAD_MORE',
  SUCCESS_LOAD_MORE_ROUTINE_TEMPLATE = 'WORKOUT_ROUTINE/SUCCESS_LOAD_MORE_ROUTINE_TEMPLATE',
  GET_LIST_WORKOUT_ROUTINE = 'WORKOUT_ROUTINE/GET_LIST_WORKOUT_ROUTINE',
  GET_LIST_WORKOUT_ROUTINE_TEMPLATE = 'WORKOUT_ROUTINE/GET_LIST_WORKOUT_ROUTINE_TEMPLATE',
  GET_LIST_MORE_WORKOUT_ROUTINE = 'WORKOUT_ROUTINE/GET_LIST_MORE_WORKOUT_ROUTINE',
  GET_WORKOUT_ROUTINE_TEMPLATE = 'WORKOUT_ROUTINE/GET_TEMPLATE',
  PARTIAL_UPDATE_WORKOUT_ROUTINE_TEMPLATE = 'WORKOUT_ROUTINE/PARTIAL_UPDATE_TEMPLATE',
  REMOVE_ROUTINE_TEMPLATE_FROM_STATE = 'WORKOUT_ROUTINE/REMOVE_ROUTINE_TEMPLATE_FROM_STATE',
  GET_LIST_MORE_WORKOUT_ROUTINE_TEMPLATE = 'WORKOUT_ROUTINE/GET_LIST_MORE_WORKOUT_ROUTINE_TEMPLATE',
}

export interface IWorkoutRoutinesResponse extends IPaginatedResponse {
  results: IWorkoutRoutine[]
}

export interface IWorkoutRoutineTemplateResponse extends IPaginatedResponse {
  results: IWorkoutRoutine[]
}

export interface IWorkoutRoutineState extends IBaseState, IPaginatedResponse {
  success?: boolean
  workoutRoutine?: IWorkoutRoutine
  workoutRoutineTemplate?: IWorkoutRoutine
  workoutRoutines?: IWorkoutRoutine[]
  workoutRoutineTemplates?: IWorkoutRoutine[]
  latestWorkoutRoutine?: IWorkoutRoutine
}

export interface IWorkoutRoutine {
  id: number
  name: string
  goal: TRoutineGoal
  difficulty: TRoutineDificulty
  start_date: string
  end_date: string
  comments: string
  personal_profile: number
  athlete_profile?: number
  is_active?: boolean
  athlete?: string
  athlete_name?: string
  workout_models?: IWorkoutModelData[]
}

export interface IWorkoutRoutinePresentation
  extends Omit<IWorkoutRoutine, 'goal' | 'difficulty'> {
  goal: string
  difficulty: string
}
