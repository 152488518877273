// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

export const Container = styled(Aligner)`
  flex-direction: column;
  flex: 1;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 4rem 4.8rem 2.4rem 4rem;
  }
`

export const Content = styled(Aligner)`
  flex-direction: column;
  flex: 1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: row;
    max-height: calc(100vh - 14rem);
  }
`

export const ContainerHeader = styled.div`
  align-items: center;
  display: flex;
  gap: 1.6rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 0 2.4rem;
  }
`

export const Header = styled(Aligner)`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  column-gap: 1.6rem;
  flex-direction: column;
  padding: 1.6rem;
  row-gap: 0.8rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    align-items: center;
    flex-direction: row;
  }
`

export const ModelNameLabel = styled(Body).attrs({
  type: 'copy4',
  weight: 600,
})`
  white-space: nowrap;
`
