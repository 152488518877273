// Models
import { IAction } from 'lib/redux/models'
import {
  EExerciseActionTypes,
  IExerciseData,
  IExerciseListPayload,
  IExerciseState,
} from '../models'
import {
  ICreateExercisePayload,
  IGetExercisesResponse,
  IEquipmentResponse,
  IExerciseDeletePayload,
  ILoadExercise,
} from 'services/exercise/@types'

// ACTION TYPES
export const Types = {
  CLEANUP: EExerciseActionTypes.CLEANUP,
  CREATE_EXERCISE: EExerciseActionTypes.CREATE_EXERCISE,
  DELETE_EXERCISE: EExerciseActionTypes.DELETE_EXERCISE,
  FAILURE: EExerciseActionTypes.FAILURE,
  FULFILL: EExerciseActionTypes.FULFILL,
  GET_EQUIPMENTS: EExerciseActionTypes.GET_EQUIPMENTS,
  GET_EXERCISE: EExerciseActionTypes.GET_EXERCISE,
  GET_EXERCISES: EExerciseActionTypes.GET_EXERCISES,
  GET_EXERCISES_LIBRARY: EExerciseActionTypes.GET_EXERCISES_LIBRARY,
  GET_MORE_EXERCISES: EExerciseActionTypes.GET_MORE_EXERCISES,
  GET_MORE_EXERCISES_LIBRARY: EExerciseActionTypes.GET_MORE_EXERCISES_LIBRARY,
  PARTIAL_UPDATE_EXERCISE: EExerciseActionTypes.PARTIAL_UPDATE_EXERCISE,
  REMOVE_FROM_STATE: EExerciseActionTypes.REMOVE_FROM_STATE,
  REQUEST: EExerciseActionTypes.REQUEST,
  SUCCESS_LOAD_MORE: EExerciseActionTypes.SUCCESS_LOAD_MORE,
  SUCCESS_LOAD_MORE_EXERCISES_LIBRARY:
    EExerciseActionTypes.SUCCESS_LOAD_MORE_EXERCISES_LIBRARY,
  SUCCESS: EExerciseActionTypes.SUCCESS,
  UPDATE_EXERCISE: EExerciseActionTypes.UPDATE_EXERCISE,
  SUCCESS_ADD_TO_STATE: EExerciseActionTypes.SUCCESS_ADD_TO_STATE,
}

// INITIAL STATE
const initialState: IExerciseState = {}

// REDUCER
export default (
  state: IExerciseState = initialState,
  action?: IAction<unknown>,
): IExerciseState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IExerciseState),
      }
    case Types.SUCCESS_LOAD_MORE: {
      const { results, ...paginatedPayload } =
        action.payload as IGetExercisesResponse
      const exercisesFromState = state.exercises?.results || []
      const exercisesFromPayload = results || []

      return {
        ...state,
        exercises: {
          ...paginatedPayload,
          results: [...exercisesFromState, ...exercisesFromPayload],
        },
      }
    }
    case Types.SUCCESS_LOAD_MORE_EXERCISES_LIBRARY: {
      const { results, ...paginatedPayload } =
        action.payload as IGetExercisesResponse
      const exercisesFromState = state.exercisesLibrary?.results || []
      const exercisesFromPayload = results || []

      return {
        ...state,
        exercisesLibrary: {
          ...paginatedPayload,
          results: [...exercisesFromState, ...exercisesFromPayload],
        },
      }
    }
    case Types.SUCCESS_ADD_TO_STATE: {
      const newExercise = action.payload as IExerciseData
      const exercisesFromState = state.exercisesLibrary?.results || []

      const updatedResults = exercisesFromState.some(
        (exercise) => exercise.id === newExercise.id,
      )
        ? exercisesFromState.map((exercise) =>
            exercise.id === newExercise.id ? newExercise : exercise,
          )
        : [...exercisesFromState, newExercise]

      return {
        ...state,
        exercisesLibrary: {
          ...state.exercisesLibrary,
          results: updatedResults,
        },
      }
    }
    case Types.REMOVE_FROM_STATE: {
      const { id } = action.payload as IExerciseDeletePayload
      const newExercises =
        state.exercisesLibrary?.results?.filter(
          (exercise) => exercise.id !== id,
        ) || []
      return {
        ...state,
        exercisesLibrary: {
          ...state.exercisesLibrary,
          results: [...newExercises],
        },
      }
    }
    default:
      return state
  }
}

// BASE ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<undefined> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<undefined> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (payload: IExerciseState): IAction<IExerciseState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const removeExerciseFromState = (
  payload: IExerciseDeletePayload,
): IAction<IExerciseDeletePayload> => {
  return {
    type: Types.REMOVE_FROM_STATE,
    payload,
  }
}

export const successLoadMore = (
  payload: IGetExercisesResponse,
): IAction<IGetExercisesResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE,
    payload,
  }
}

export const successLoadMoreExercisesLibrary = (
  payload: IGetExercisesResponse,
): IAction<IGetExercisesResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE_EXERCISES_LIBRARY,
    payload,
  }
}

export const successAddToState = (
  payload: IExerciseData,
): IAction<IExerciseData> => {
  return {
    type: Types.SUCCESS_ADD_TO_STATE,
    payload,
  }
}

export const triggerLoadExercises = (
  payload: IExerciseListPayload,
): IAction<IExerciseListPayload> => {
  return {
    type: Types.GET_EXERCISES,
    payload,
  }
}

export const triggerLoadExercisesLibrary = (
  payload: IExerciseListPayload,
): IAction<IExerciseListPayload> => {
  return {
    type: Types.GET_EXERCISES_LIBRARY,
    payload,
  }
}

export const triggerLoadExercise = (
  payload: ILoadExercise,
): IAction<ILoadExercise> => {
  return {
    type: Types.GET_EXERCISE,
    payload,
  }
}

export const triggerLoadEquipment = (): IAction<IEquipmentResponse> => {
  return {
    type: Types.GET_EQUIPMENTS,
  }
}

export const triggerLoadMoreExercises = (
  payload: IExerciseListPayload,
): IAction<IExerciseListPayload> => {
  return {
    type: Types.GET_MORE_EXERCISES,
    payload,
  }
}

export const triggerLoadMoreExercisesLibrary = (
  payload: IExerciseListPayload,
): IAction<IExerciseListPayload> => {
  return {
    type: Types.GET_MORE_EXERCISES_LIBRARY,
    payload,
  }
}

export const triggerCreateExercise = (
  payload: ICreateExercisePayload,
): IAction<ICreateExercisePayload> => {
  return {
    type: Types.CREATE_EXERCISE,
    payload,
  }
}

export const triggerUpdateExercise = (
  payload: ICreateExercisePayload,
): IAction<ICreateExercisePayload> => {
  return {
    type: Types.UPDATE_EXERCISE,
    payload,
  }
}

export const triggerPartialUpdateExercise = (
  payload: ICreateExercisePayload,
): IAction<ICreateExercisePayload> => {
  return {
    type: Types.PARTIAL_UPDATE_EXERCISE,
    payload,
  }
}

export const triggerDeleteExercise = (
  payload: IExerciseDeletePayload,
): IAction<IExerciseDeletePayload> => {
  return {
    type: Types.DELETE_EXERCISE,
    payload,
  }
}
