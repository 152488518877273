// React
import { FC, MouseEvent, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { toSnakeCase } from 'utils/functions'
import { useModal } from 'hooks'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import {
  BodyContainer,
  ButtonsContainer,
  Container,
  CardTop,
  LongerDescriptionContainer,
} from './styled'
import { Body, Button, Subtitle } from 'heeds-ds'
import { Modal } from 'components/modals'

// Constants
const MAX_TEXT_LENGTH = 24

type Props = {
  cancelTitle?: string
  confirmTitle?: string
  description: string
  infoCancel?: boolean
  longerDescription?: string
  onCancel?: () => void
  onConfirm: () => void
  title: string
  width?: string
}

const ModalConfirmation: FC<Props> = ({
  cancelTitle = 'Cancelar',
  confirmTitle = 'Alterar',
  description,
  infoCancel,
  longerDescription,
  onCancel,
  onConfirm,
  title,
  width,
}) => {
  const { breakpoints, colors } = useContext(ThemeContext)
  const { closeModal } = useModal()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  const isLongButtonText = (cancelTitle + confirmTitle).length > MAX_TEXT_LENGTH

  const stopPropagation = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const getVariation = () => {
    if (isDesktop || infoCancel) return 'borderless'
    return 'outlined'
  }

  const handleCancel = () => {
    if (onCancel) return onCancel()
    closeModal()
  }

  const handleConfirm = () => {
    onConfirm()
    closeModal()
  }

  return (
    <Modal onClose={handleCancel}>
      <Container onClick={stopPropagation} width={width}>
        <CardTop>
          {isDesktop ? (
            <Subtitle type="sh2" weight={600}>
              {title}
            </Subtitle>
          ) : (
            <Body weight={600}>{title}</Body>
          )}
        </CardTop>
        {longerDescription && (
          <LongerDescriptionContainer>
            <Body type="copy4" align="center" color={colors.text.subdued}>
              {longerDescription}
            </Body>
          </LongerDescriptionContainer>
        )}
        <BodyContainer>{description}</BodyContainer>
        <ButtonsContainer vertical={isLongButtonText && !isDesktop}>
          <Button
            cancel={!infoCancel}
            onClick={handleCancel}
            size="small"
            track={buttonClickTracking}
            trackName={`cancel_modal_${toSnakeCase(title)}`}
            variation={getVariation()}
          >
            {cancelTitle}
          </Button>
          <Button
            onClick={handleConfirm}
            size="small"
            track={buttonClickTracking}
            trackName={`confirm_modal_${toSnakeCase(title)}`}
            className="w-full"
          >
            {confirmTitle}
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  )
}

export default ModalConfirmation
