// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

export const ContainerTitle = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy3',
  weight: 600,
}))``

export const EmptyContainer = styled(Aligner).attrs(() => ({
  padding: '0 0 0.8rem',
  justify: 'center',
  align: 'center',
}))`
  height: calc(100% - 4.8rem);
`

export const EmptyText = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.disabled,
  type: 'copy3',
}))``
