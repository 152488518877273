// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import Desktop, { Props as DesktopProps } from './desktop'
import Mobile, { Props as MobileProps } from './mobile'

type Props = MobileProps & DesktopProps

const RoutineTemplateItem: FC<Props> = (props) => {
  const theme = useContext(ThemeContext)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return isTablet ? <Desktop {...props} /> : <Mobile {...props} />
}

export default RoutineTemplateItem
