// React
import { FC } from 'react'

// Components
import { Skeleton } from 'heeds-ds'

const InfoLoader: FC = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-4">
      <Skeleton height="80px" width="80px" radius="100%" />
      <Skeleton height="26px" width="60vw" radius="4px" />
      <Skeleton height="16px" width="30vw" radius="4px" />
      <Skeleton height="16px" width="30vw" radius="4px" />
      <Skeleton height="32px" width="65px" radius="16px" />
      <Skeleton height="44px" width="143px" radius="4px" />
    </div>
  )
}

export default InfoLoader
