// Models
import { TMuscleGroupFilter } from 'models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Body,
  Button,
  Chip,
  Icon,
  InputSearch,
  Tooltip,
} from 'heeds-ds'

interface Props {
  filters?: TMuscleGroupFilter[]
  hasSelectedFilters?: boolean
  margin?: string
  onClickFilter: () => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  padding?: string
  removeFilter: (label: string) => void
  searchQuery?: string
}

const ExerciseSearchBar: FC<Props> = ({
  filters,
  margin,
  onClickFilter,
  onSearchChange,
  padding,
  removeFilter,
  searchQuery = '',
}) => {
  const { breakpoints, colors } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const activeFilters = filters?.filter(({ active }) => active)

  return (
    <Aligner
      flex="column"
      gap={isDesktop ? '0.4rem' : '1.6rem'}
      margin={margin}
      padding={padding}
    >
      <Aligner
        align="center"
        data-testid="exercise-input-container"
        gap="1.6rem"
      >
        <InputSearch
          margin="0"
          onChange={onSearchChange}
          placeholder="Pesquisar"
          scale="small"
          value={searchQuery}
        />

        <Tooltip
          baseColor={colors.badge.greyText}
          content={
            <Body color={colors.text.onPrimary} type="copy3">
              Filtros
            </Body>
          }
          padding="1rem 2.4rem"
        >
          <Button
            onClick={onClickFilter}
            onlyIcon={isDesktop}
            size="small"
            track={buttonClickTracking}
            trackName="open_muscle_group_filter_modal"
          >
            <Icon iconName="filter" color={colors.icon.onPrimary} size={24} />
            {isDesktop ? '' : 'Filtros'}
          </Button>
        </Tooltip>
      </Aligner>

      {Boolean(activeFilters?.length) && (
        <Styled.FiltersContainer data-testid="exercise-filter-container">
          {activeFilters?.map((filter, index) => (
            <Chip
              active
              closeIcon
              key={index}
              label={filter.label}
              onClick={() => removeFilter(filter.name)}
              scale="small"
            />
          ))}
        </Styled.FiltersContainer>
      )}
    </Aligner>
  )
}

export default ExerciseSearchBar
