export enum EAnamneseAnswerStatusToPortuguese {
  answered = 'Respondido',
  sent = 'Enviado',
  not_sent = 'Não enviado',
}

export enum EAnamneseQuestionTypeToPortuguese {
  text = 'Resposta em texto',
  multiple_choice = 'Múltipla escolha',
  select_box = 'Caixa de seleção',
  dropdown_list = 'Lista suspensa',
  date = 'Data',
  measures = 'Resposta em medidas',
}

export interface IAnamneseQuestion {
  allowed_values?: string[]
  answers?: string[]
  id?: string
  question?: string
  required: boolean
  type: TAnamneseQuestionType
}

export interface IAnamneseSection {
  description?: string
  id?: string
  name?: string
  questions: IAnamneseQuestion[]
}

export type TAnamneseAnswersOrdering =
  | 'title'
  | '-title'
  | 'creation_date'
  | '-creation_date'

export type TAnamneseAnswerStatus = 'answered' | 'sent' | 'not_sent'

export type TAnamneseQuestionType =
  | 'text'
  | 'multiple_choice'
  | 'select_box'
  | 'dropdown_list'
  | 'date'
  | 'measures'

export type TAnamneseTemplatesOrdering = 'title' | '-title' | 'date' | '-date'

export type TFormBlockType = TAnamneseQuestionType | 'section' | 'title'
