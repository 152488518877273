// Models
import { EEffortLevel } from 'models'
import { IWorkoutLog } from 'services/workoutModel/@types'

// React
import { FC } from 'react'

// Libs
import moment from 'moment'
import momentBR from 'lib/moment'

// Misc
import { cn } from 'utils/helpers/classess'
import { handleTimeInterval } from 'utils/functions'

// Components
import { IconButton } from 'heeds-ds'

export type Props = {
  item: IWorkoutLog
  gridTemplate: string
  padding?: string
  onClickComment?: (comment: string) => void
}

const formatDate = (inp: string) =>
  moment(new Date(inp).toLocaleString()).toISOString().replace('Z', '+03:00')

const convertTime = (inp?: string) => {
  if (!inp) return ''
  return moment(formatDate(inp), true).format('HH:mm')
}

const convertDate = (inp?: string) => {
  if (!inp) return ''
  return momentBR(inp).format('ddd, DD [de] MMMM [de] YYYY')
}

const Desktop: FC<Props> = ({
  item,
  gridTemplate,
  padding,
  onClickComment,
}) => {
  const { workout_name, start_time, end_time, effort_perception, comments } =
    item

  return (
    <div
      className={cn(
        'grid w-full items-center border border-b-border-input bg-surface',
        gridTemplate,
      )}
    >
      <div className={cn(padding)}>
        <p className="text-copy4">{workout_name}</p>
      </div>
      <div className={cn(padding)}>
        <p className="text-copy4 first-letter:capitalize">
          {convertDate(start_time)}
        </p>
      </div>
      <div className={cn(padding)}>
        <p className="text-copy4">
          {convertTime(start_time)} às {convertTime(end_time)}
        </p>
      </div>
      <div className={cn(padding)}>
        <p className="text-copy4">{handleTimeInterval(start_time, end_time)}</p>
      </div>
      <div className={cn(padding)}>
        <p className="text-copy4">{EEffortLevel[effort_perception]}</p>
      </div>
      <div className={cn(padding)}>
        <IconButton
          iconName="textSms"
          onClick={() => comments && onClickComment?.(comments)}
          size="small"
          disabled={!comments}
        />
      </div>
    </div>
  )
}

export default Desktop
