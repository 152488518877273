// Libraries
import styled from 'styled-components'

// Components
import { Autocomplete } from 'heeds-ds'

type Props = {
  spaced?: boolean
}

export const InputWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${({ spaced }) => (spaced ? '100%' : '38.5rem')};
`
export const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.text.subdued};
  height: 2px;
  width: 8px;
  margin: 0 12px;
  margin-bottom: 0px;
`

export const RangeHours = styled(Autocomplete).attrs(() => ({
  creatable: true,
  scale: 'small',
  margin: '0',
}))``
