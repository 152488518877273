// Models
import { IFormBlock } from 'filters/assessment/models'

// Libraries
import * as yup from 'yup'

// Misc
import {
  testSomeAnamneseTemplateIsSelected,
  testSomeAthleteIsActive,
} from './@utils'

const _anamneseSectionQuestionSchema = yup.object().shape({
  type: yup.string().required(),
  question: yup.string().required(''),
  required: yup.boolean().required(),
  allowed_values: yup.array(yup.string()),
})

const _anamneseSectionSchema = yup.object().shape({
  name: yup.string().nullable(),
  description: yup.string().nullable(),
  type: yup.string().required(),
})

const _anamneseTitleSchema = yup.object().shape({
  title: yup.string().required('O título do formulário é obrigatório.'),
  description: yup.string().nullable(),
  type: yup.string().required(),
})

const _selectedAthleteSchema = yup.object().shape({
  active: yup.boolean(),
  label: yup.number(),
})

const _optionSchema = yup.object().shape({
  active: yup.boolean(),
  label: yup.string(),
})

export const anamneseNewTemplateSchema = yup.object().shape({
  parts: yup.array(
    yup.lazy((item: IFormBlock) => {
      const { type } = item

      if (type === 'title') return _anamneseTitleSchema

      if (type === 'section') return _anamneseSectionSchema

      return _anamneseSectionQuestionSchema
    }),
  ),
})

export const anamneseModalNewRoutineSchema = yup.object().shape({
  athleteRoutine: yup.string().required(),
})

export const anamneseModalShareTemplateSchema = yup.object().shape({
  selectAll: yup.boolean(),
  selectedAthlete: yup
    .array(_selectedAthleteSchema)
    .test(
      'someAthleteIsActive',
      'Selecione pelo menos um atleta',
      testSomeAthleteIsActive,
    )
    .required(),
})

export const anamneseModalNewAnamneseAnswerSchema = yup.object().shape({
  selectAll: yup.boolean(),
  selectedAnamneses: yup.lazy(
    (
      value: Record<string, { active?: boolean; label?: string }> | undefined,
    ) => {
      if (value && Object.keys(value).length) {
        const newEntries = value
          ? Object.keys(value).reduce(
              (acc, val) => ({ ...acc, [val]: _optionSchema }),
              {},
            )
          : {}

        return yup
          .object()
          .shape(newEntries)
          .test(
            'someAnamneseIsSelected',
            'Selecione pelo menos uma anamnese.',
            testSomeAnamneseTemplateIsSelected,
          )
      }
      return yup.mixed().required()
    },
  ),
})
