// React
import { FC, MouseEvent, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { toSnakeCase } from 'utils/functions'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Body, Button, Icon } from 'heeds-ds'
import { Modal } from 'components/modals'
import { useModal } from 'hooks'

type Props = {
  cancelTitle?: string
  confirmTitle?: string
  description: string
  infoCancel?: boolean
  onCancel?: () => void
  onConfirm: () => void
  onClose?: () => void
  title: string
  longerDescription?: string
}

const ModalDelete: FC<Props> = ({
  cancelTitle,
  confirmTitle,
  description,
  infoCancel,
  onCancel,
  onConfirm,
  onClose,
  title,
  longerDescription,
}) => {
  const { closeModal } = useModal()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  const stopPropagation = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const getVariation = () => {
    if (isDesktop || infoCancel) return 'borderless'
    return 'outlined'
  }

  const handleCancel = () => {
    onCancel?.()
    closeModal()
  }

  const handleConfirm = () => {
    onConfirm()
    closeModal()
  }

  const handleOnClose = () => {
    onClose?.()
    closeModal()
  }

  return (
    <Modal showExit={false} onClose={handleOnClose}>
      <Styled.Container onClick={stopPropagation}>
        <Styled.TopIconsWrapper>
          <Styled.IconWrapper>
            <Icon
              iconName="trashCan"
              color={theme.colors.icon.critical}
              size={32}
            />
          </Styled.IconWrapper>
          <Styled.CloseIconWrapper onClick={handleOnClose}>
            <Icon
              iconName="close"
              size={24}
              color={theme.colors.icon.subdued}
            />
          </Styled.CloseIconWrapper>
        </Styled.TopIconsWrapper>
        <Styled.CardTop>
          <Body weight={600}>{title}</Body>
        </Styled.CardTop>
        {longerDescription && (
          <Styled.LongerDescriptionContainer>
            <Body type="copy4" align="center" color={theme.colors.text.subdued}>
              {longerDescription}
            </Body>
          </Styled.LongerDescriptionContainer>
        )}
        <Styled.BodyContainer>{description}</Styled.BodyContainer>
        <Styled.ButtonsContainer>
          <Button
            cancel={!infoCancel}
            onClick={handleCancel}
            size="xsmall"
            track={buttonClickTracking}
            trackName={`cancel_modal_${toSnakeCase(title)}`}
            variation={getVariation()}
          >
            {cancelTitle || 'Cancelar'}
          </Button>
          <Button
            onClick={handleConfirm}
            size="xsmall"
            track={buttonClickTracking}
            trackName={`confirm_modal_${toSnakeCase(title)}`}
            className="w-full"
          >
            {confirmTitle || 'Alterar'}
          </Button>
        </Styled.ButtonsContainer>
      </Styled.Container>
    </Modal>
  )
}

export default ModalDelete
