// Models
import { EPlanModelToPortuguese, EPlanPeriodicityToPortuguese } from 'models'
import { IAthleteOverview } from 'services/athleteOverview/@types'
import { IFinancialSubscriptionState } from 'storage/financialSubscription/models'
import IStore from 'lib/redux/models'

// React
import { FC, useCallback, useContext, useLayoutEffect, useState } from 'react'

// Libraries
import { generatePath, useNavigate } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { BRL } from 'lib/currency'
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import { triggerGetFinancialSubscription } from 'storage/financialSubscription/duck'
import { urls } from 'routes/paths'
import momentBR from 'lib/moment'

// Components
import { Button, Icon, IconButton, LinkButton } from 'heeds-ds'
import FinancialLoader from './loader'

type Props = {
  overview: IAthleteOverview
}

const Financial: FC<Props> = ({ overview }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, subscription } = useSelector<
    IStore,
    IFinancialSubscriptionState
  >((state) => state.financialSubscription)

  const theme = useContext(ThemeContext)
  const [open, setOpen] = useState(false)

  const navigateToFinancial = useCallback(
    () =>
      navigate(
        generatePath(urls.athleteFinancial, {
          id: overview.id,
        }),
      ),
    [navigate, overview.id],
  )

  useLayoutEffect(() => {
    if (overview.subscription_pk) {
      dispatch(
        triggerGetFinancialSubscription({ id: overview.subscription_pk }),
      )
    }
  }, [overview.subscription_pk, dispatch])

  if (loading) {
    return <FinancialLoader />
  }

  return (
    <div className="relative flex flex-col rounded-[8px] border border-border-input bg-surface p-4">
      <div
        className={cn(
          'mb-[22px] flex justify-between',
          { 'mb-0': !open },
          'md:mb-[22px]',
        )}
        onClick={() => setOpen(!open)}
      >
        <p className="text-copy3 font-semibold">Financeiro</p>
        <div className="md:hidden">
          <Icon iconName={open ? 'expandMore' : 'expandLess'} />
        </div>
      </div>
      {subscription?.total_price ? (
        <div
          className={cn(
            'flex flex-col gap-[22px]',
            { hidden: !open },
            'md:flex',
          )}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <Icon
                iconName="circleCheck"
                color={theme.colors.icon.success}
                size={20}
              />
              <p className="font-barlow text-h3 font-semibold text-text-secondary">
                {BRL(subscription?.total_price).format()}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-copy4 text-text-subdued">Vencimento:</p>
              <p className="text-copy4 font-bold text-text-subdued">
                {momentBR(subscription?.due_date).format(
                  'DD [de] MMMM [de] YYYY',
                )}
              </p>
            </div>
          </div>
          <div className="flex gap-1">
            {overview.plan_model && (
              <div
                className={cn(
                  'w-[125px] rounded-[30px] bg-badge-purpleBackground px-4 py-2 text-center',
                  'text-copy4 font-semibold text-badge-purpleText',
                )}
              >
                {EPlanModelToPortuguese[overview.plan_model]}
              </div>
            )}
            {overview.plan_periodicity && (
              <div
                className={cn(
                  'w-[125px] rounded-[30px] bg-badge-purpleBackground px-4 py-2 text-center',
                  'text-copy4 font-semibold text-badge-purpleText',
                )}
              >
                {EPlanPeriodicityToPortuguese[overview.plan_periodicity]}
              </div>
            )}
          </div>
          <div className="absolute right-4 top-4 hidden md:flex">
            <IconButton
              iconName="openInNew"
              size="small"
              onClick={navigateToFinancial}
              track={buttonClickTracking}
              trackName="athlete_profile_financial_link"
            />
          </div>
          <Button
            size="xsmall"
            variation="borderless"
            onClick={navigateToFinancial}
            track={buttonClickTracking}
            trackName="athlete_profile_financial_link"
            className="md:hidden"
          >
            Visualizar financeiro
          </Button>
        </div>
      ) : (
        <div>
          <p className="mx-auto my-8 text-center text-copy3 text-text-disabled">
            Esse aluno não possui um plano ativo.
          </p>
          <LinkButton
            size="xsmall"
            variation="borderless"
            to={generatePath(urls.athleteFinancial, { id: overview.id })}
            track={buttonClickTracking}
            trackName="athlete_profile_empty_financial_link"
            className="mx-auto my-0"
          >
            Ir para financeiro
          </LinkButton>
        </div>
      )}
    </div>
  )
}

export default Financial
