// Models
import { IExerciseData } from 'storage/exercise/models'

// React
import { FC, useMemo } from 'react'

// Misc
import { capitalizeFirstLetter } from 'utils/helpers/string'
import { cn } from 'utils/helpers/classess'
import { getMuscleGroup } from 'utils/functions'

// Components
import * as Styled from './styled'
import { Aligner } from 'heeds-ds'

interface Props {
  exercise: IExerciseData
  onClick?: (exercise: IExerciseData) => void
  size?: 'xsmall' | 'small'
}

const Exercise: FC<Props> = ({ exercise, onClick, size = 'xsmall' }) => {
  const { name, agonists_muscle } = exercise

  const renderMuscleGroups = useMemo(
    () =>
      agonists_muscle
        ?.reduce((acc: string[], muscleId) => {
          const groupName = getMuscleGroup(muscleId)
          if (acc.includes(groupName)) return acc
          return [...acc, groupName]
        }, [])
        .join(' - '),
    [agonists_muscle],
  )
  return (
    <Aligner align="center" gap="1.6rem">
      <Styled.PlayIconButton onClick={() => onClick?.(exercise)} size={size} />

      <Aligner flex="column" gap="0.4rem">
        <p
          className={cn('text-copy4 font-bold text-text-secondary', {
            'text-copy3': size === 'small',
          })}
        >
          {capitalizeFirstLetter(name)}
        </p>

        {agonists_muscle && (
          <Styled.Description>{renderMuscleGroups}</Styled.Description>
        )}
      </Aligner>
    </Aligner>
  )
}

export default Exercise
