// Models
import { TPaymentInputs, TPreparePaymentToPayload } from './models'

// Libraries
import moment from 'moment'

export const preparePaymentToPayload: TPreparePaymentToPayload = (
  dataForm: TPaymentInputs,
) => {
  const { date, ...dataRest } = dataForm

  const newRoutine = {
    ...dataRest,
    date: moment(date).format('YYYY-MM-DD'),
  }

  return newRoutine
}
