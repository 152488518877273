import IStore from 'lib/redux/models'

// SELECTORS

export const anamneseAnswerSelector = (state: IStore) =>
  state.assessmentAnamneseAnswer

export const anamneseTemplateSelector = (state: IStore) =>
  state.assessmentAnamneseTemplate

export const physcialAssessmentSelector = (state: IStore) =>
  state.assessmentPhysicalAssessment

export const athleteInfoSelector = (state: IStore) => state.athleteInfo

export const athleteOverviewSelector = (state: IStore) => state.athleteOverview

export const athletePlansSelector = (state: IStore) => state.athletePlans

export const athleteScheduleSelector = (state: IStore) => state.athleteSchedule

export const athletesSelector = (state: IStore) => state.athletes

export const authSelector = (state: IStore) => state.auth

export const exerciseSelector = (state: IStore) => state.exercise

export const financialSelector = (state: IStore) => state.financial

export const financialSubscriptionSelector = (state: IStore) =>
  state.financialSubscription

export const generalSelector = (state: IStore) => state.general

export const profileSelector = (state: IStore) => state.profile

export const workoutModelSelector = (state: IStore) => state.workoutModel

export const workoutRoutineSelector = (state: IStore) => state.workoutRoutine
