// Libraries
import * as yup from 'yup'
import moment from 'moment'

// Misc
import { greaterThan } from 'utils/helpers/date'

export const testDueDateIsRequired = (
  value: Date | undefined,
  context: yup.TestContext,
) => {
  const { autoRenovation, selected_plan, instalmentPayment } = context.parent
  const spread =
    instalmentPayment === undefined || instalmentPayment === '0' ? false : true

  if (
    (!autoRenovation && selected_plan?.label === 'hourly_rate') ||
    (selected_plan?.label !== 'hourly_rate' && !spread) ||
    (selected_plan?.periodicity === 'monthly' &&
      selected_plan?.label !== 'hourly_rate')
  ) {
    if (!value) return false
    return true
  }

  return true
}

export const testDueDayIsRequired = (
  value: string | undefined,
  context: yup.TestContext,
) => {
  const { autoRenovation, selected_plan, instalmentPayment } = context.parent
  const spread =
    instalmentPayment === undefined || instalmentPayment === '0' ? false : true

  if (
    selected_plan?.periodicity === 'monthly' &&
    selected_plan?.label !== 'hourly_rate'
  ) {
    return true
  }

  if (
    (autoRenovation && selected_plan?.label === 'hourly_rate') ||
    (selected_plan?.label !== 'hourly_rate' && spread)
  ) {
    if (!value) return false
    return true
  }

  return true
}

export const testFrequencyIsRequired = (
  value: string | undefined,
  context: yup.TestContext,
) => {
  const { selected_plan } = context.parent

  if (selected_plan && selected_plan.label === 'hourly_rate') {
    if (!value) {
      return context.createError({ message: 'Frequência é obrigatória' })
    }
  }
  return true
}

export const testFullName = (value: string | undefined) => {
  if (!value) return true

  return value.trim().split(' ').length > 1
}

export const testIsAValidTime = (
  value: string | undefined,
  context: yup.TestContext,
) => {
  const { start } = context.parent
  if (!start || !value) return true

  const startTime = new Date()
  const startTimeValue = start.split(':')

  const endTime = new Date()
  const endTimeValue = value.split(':')

  startTime.setHours(parseInt(startTimeValue[0]), parseInt(startTimeValue[1]))
  endTime.setHours(parseInt(endTimeValue[0]), parseInt(endTimeValue[1]))

  return startTime < endTime
}

export const testIsGreaterThanStartDate = (
  value: Date | null | undefined,
  context: yup.TestContext,
) => {
  const { start_date } = context.parent

  if (!start_date || !value) return true

  return greaterThan(
    moment(start_date).format('DD/MM/YYYY'),
    moment(value).format('DD/MM/YYYY'),
  )
}

export const testHasMoreThanOneExercise = (value: object | undefined) => {
  if (!value) return true

  return Object.values(value).length > 1
}

export const testIsGreaterThanStartTime = (
  value: string | undefined,
  context: yup.TestContext,
) => {
  const { start_time } = context.parent

  if (!start_time || !value) return true

  const startTime = new Date()
  const startTimeValue = start_time.split(':')

  const endTime = new Date()
  const endTimeValue = value.split(':')

  startTime.setHours(parseInt(startTimeValue[0]), parseInt(startTimeValue[1]))
  endTime.setHours(parseInt(endTimeValue[0]), parseInt(endTimeValue[1]))

  return startTime < endTime
}

export const testIsPositiveNumber = (value: string | undefined) => {
  if (value === null) return true

  const intValue = parseInt(value?.replace(' seg', '') ?? '')

  if (isNaN(intValue)) return true

  return value !== undefined && intValue > 0
}

export const testIsDueDateNotInPast = (value: Date | undefined) => {
  if (!value) return true
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return value >= today
}

export const testIsStartDateBeforeDueDate = (
  value: Date | undefined,
  context: yup.TestContext,
) => {
  const start_date = context.parent?.start_date
  const { autoRenovation, selected_plan, instalmentPayment } = context.parent
  const spread =
    instalmentPayment === undefined || instalmentPayment === '0' ? false : true

  if (!value || !start_date) {
    return true
  }

  const dueDate = new Date(value)
  const startDate = new Date(start_date)

  if (
    (!autoRenovation && selected_plan?.label === 'hourly_rate') ||
    (selected_plan?.label !== 'hourly_rate' && !spread) ||
    (selected_plan?.periodicity === 'monthly' &&
      selected_plan?.label !== 'hourly_rate')
  ) {
    if (dueDate < startDate) {
      return false
    }
  }

  return true
}

export const testSomeAthleteIsActive = (
  value:
    | {
        active?: boolean
        label?: number
      }[]
    | undefined,
) => {
  if (!value) return false

  return value.some((atlete) => atlete.active)
}

export const testSomeAnamneseTemplateIsSelected = (
  value: Record<string, { active?: boolean; label?: string }> | undefined,
) => {
  if (!value) return false

  return Object.values(value).some((option) => option.active)
}

export const testWorkoutSetIsEmpty = (
  value: string | undefined,
  context: yup.TestContext,
) => {
  if (!value) return true

  if (!context.parent.workout_set) return false

  if (Object.keys(context.parent.workout_set).length) return true

  return false
}

export const testIsWorkoutSetWithExercises = (
  value?: { workout_set?: Record<string, object> }[],
) => {
  if (!value) return false
  return value.some((model) => {
    if (!model.workout_set) return false
    return Object.values(model.workout_set).length > 0
  })
}

export const tranformNaN = (value: number) => (isNaN(value) ? null : value)
