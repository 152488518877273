// Models
import { IBaseState } from 'lib/redux/models'
import { IPaginatedResponse } from 'models/api'
import { IEquipmentResponse, IPresignedData } from 'services/exercise/@types'

export enum EExerciseActionTypes {
  CLEANUP = 'EXERCISE/CLEANUP',
  CREATE_EXERCISE = 'EXERCISE/CREATE_EXERCISE',
  DELETE_EXERCISE = 'EXERCISE/DELETE_EXERCISE',
  FAILURE = 'EXERCISE/FAILURE',
  FULFILL = 'EXERCISE/FULFILL',
  GET_EQUIPMENTS = 'EXERCISE/GET_EQUIPMENTS',
  GET_EXERCISE = 'EXERCISE/GET_EXERCISE',
  GET_EXERCISES = 'EXERCISE/GET_EXERCISES',
  GET_EXERCISES_LIBRARY = 'EXERCISE/GET_EXERCISES_LIBRARY',
  GET_MORE_EXERCISES = 'EXERCISE/GET_MORE_EXERCISE',
  GET_MORE_EXERCISES_LIBRARY = 'EXERCISE/GET_MORE_EXERCISE_LIBRARY',
  GET_MUSCLE = 'EXERCISE/GET_MUSCLE',
  PARTIAL_UPDATE_EXERCISE = 'EXERCISE/PARTIAL_UPDATE_EXERCISE',
  REMOVE_FROM_STATE = 'EXERCISE/REMOVE_FROM_STATE',
  REQUEST = 'EXERCISE/REQUEST',
  SUCCESS = 'EXERCISE/SUCCESS',
  SUCCESS_LOAD_MORE = 'EXERCISE/SUCCESS_LOAD_MORE',
  SUCCESS_LOAD_MORE_EXERCISES_LIBRARY = 'EXERCISE/SUCCESS_LOAD_MORE_EXERCISES_LIBRARY',
  UPDATE_EXERCISE = 'EXERCISE/UPDATE_EXERCISE',
  SUCCESS_ADD_TO_STATE = 'EXERCISE/SUCCESS_ADD_TO_STATE',
}

export interface IExerciseState extends IBaseState {
  exercises?: IExerciseListResponse
  exercisesLibrary?: IExerciseListResponse
  exercise?: IExerciseData
  equipments?: IEquipmentResponse[]
}

export interface IExerciseListPayload {
  muscle?: string | string[]
  name?: string
  body_part?: string | string[]
  page?: number
  page_size?: number
  library?: boolean
}

export interface IExerciseListResponse extends IPaginatedResponse {
  results: IExerciseData[]
}

export interface IExerciseData {
  agonists_muscle_specific?: number[]
  agonists_muscle?: number[]
  date?: string
  difficulty?: string
  exercise_type?: string
  force_type?: string
  mechanic?: string
  equipment_id?: number
  equipment?: string
  file_extension?: string
  file?: string | File | Blob
  id: number
  muscle_id?: number[]
  name: string
  pre_signed_url?: IPresignedData
  synergists_muscle?: number[]
  thumbnail?: string
  muscle?: string
  body_part?: string
  instructions?: string
}
