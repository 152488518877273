// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

type Props = {
  color?: string
  width?: string
}

export const Chart = styled.div`
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(32, 34, 35, 0.18);
  height: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    box-shadow: none;
  }
`

export const ChartContainer = styled.div`
  height: 6.5rem;
  min-width: 6.5rem;
  padding: 0.8rem;
  width: 6.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 13.4rem;
    min-width: 13.4rem;
    padding: 1.4rem;
    width: 13.4rem;
  }
`

export const Content = styled(Aligner).attrs(() => ({
  align: 'center',
  gap: '3.2rem',
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    gap: 2.4rem;
  }
`

export const ContentLegend = styled(Aligner).attrs(() => ({
  align: 'center',
  flex: 'column',
  gap: '1.6rem',
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    gap: 2.4rem;
  }
`

export const LegendCircle = styled.div<Props>`
  background: ${({ color, theme }) => color || theme.colors.text.secondary};
  border-radius: 50%;
  height: 1.2rem;
  width: 1.2rem;
`

export const LegendText = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy3',
}))``

export const Month = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.disabled,
  type: 'copy5',
}))``

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy3',
  weight: 600,
}))``

export const Value = styled(Body).attrs(() => ({
  margin: '0 0 0 0.8rem',
  type: 'copy3',
  weight: 600,
}))`
  white-space: nowrap;
`
