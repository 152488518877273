// Models
import { IAction } from 'lib/redux/models'
import {
  ICreateFinancialExtraPlanPayload,
  ICreateFinancialPlanPayload,
  ICreatePaymentPayload,
  IFinancialExtraPlan,
  IFinancialOverview,
  IFinancialPlan,
  IGetFinancialExtraPlanPayload,
  IGetFinancialPlan,
  IGetFinancialPlanPayload,
  IInstallmentPayload,
  IInstallmentResponse,
  IListFinancialExtraPlansPayload,
  IListFinancialExtraPlansResponse,
  ILoadFinancialAthletesPayload,
  ILoadFinancialAthletesResponse,
  ILoadFinancialMetricsPayload,
  ILoadFinancialOverview,
  IPartialUpdateFinancialExtraPlanPayload,
  IPayment,
} from 'services/financial/@types'
import { IWorkoutData } from 'storage/profile/models'

// Libraries
import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'

// Misc
import {
  addExtraPlans,
  deleteExtraPlans,
  failure,
  fulfill,
  request,
  success,
  successLoadMore,
  successLoadMoreInstallments,
  updateExtraPlans,
  Types,
} from '../duck'
import { IFinancialMetrics } from '../models'
import {
  activateFinancialPlan,
  createFinancialExtraPlan,
  createFinancialPlan,
  createPayment,
  deactivateFinancialPlan,
  deleteFinancialExtraPlan,
  deleteFinancialPlan,
  getAthleteInstallments,
  getFinancialExtraPlan,
  getFinancialPlan,
  getOverview,
  listFinancialExtraPlans,
  loadFinancialAthletes,
  loadFinancialMetrics,
  partialUpdateFinancialExtraPlan,
  updateFinancialExtraPlan,
  updateFinancialPlan,
} from 'services/financial'
import { triggerToastError, triggerToastSuccess } from 'storage/general/duck'

export function* loadFinancialAthletesSaga(
  action: IAction<ILoadFinancialAthletesPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: ILoadFinancialAthletesResponse = yield call(
        loadFinancialAthletes,
        action.payload,
      )
      yield put(success({ athletes: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadMoreFinancialAthletesSaga(
  action: IAction<ILoadFinancialAthletesPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: ILoadFinancialAthletesResponse = yield call(
        loadFinancialAthletes,
        action.payload,
      )
      yield put(successLoadMore(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadFinancialMetricsSaga(
  action: IAction<ILoadFinancialMetricsPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IFinancialMetrics = yield call(
        loadFinancialMetrics,
        action.payload,
      )
      yield put(success({ metrics: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* createFinancialExtraPlanSaga(
  action: IAction<ICreateFinancialExtraPlanPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IFinancialExtraPlan<number> = yield call(
        createFinancialExtraPlan,
        action.payload,
      )
      yield put(success({ extraPlan: response }))
      yield put(addExtraPlans(response))
      yield put(
        triggerToastSuccess({
          message: 'Treino extra criado com sucesso !',
        }),
      )
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* getFinancialPlanSaga(
  action: IAction<ICreateFinancialPlanPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IGetFinancialPlanPayload = yield call(
        getFinancialPlan,
        action.payload,
      )
      yield put(success({ planList: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* createFinancialPlanSaga(
  action: IAction<ICreateFinancialPlanPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback } = action.payload

      const response: IGetFinancialPlanPayload = yield call(
        createFinancialPlan,
        action.payload,
      )
      yield put(success({ planList: response }))
      successCallback?.()
      yield put(
        triggerToastSuccess({
          customTitle: action.payload.priceChanged
            ? 'Notificação enviada!'
            : '',
          message: action.payload.priceChanged
            ? 'Seus alunos assinantes do plano receberam um e-mail informando sobre a alteração.'
            : 'Financeiro do personal atualizado com sucesso',
        }),
      )
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* activateFinancialPlanSaga(action: IAction<IGetFinancialPlan>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IFinancialPlan = yield call(
        activateFinancialPlan,
        action.payload,
      )
      yield put(success({ plan: response }))
      yield put(
        triggerToastSuccess({
          message: 'Seu plano foi ativado.',
        }),
      )
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* deactivateFinancialPlanSaga(
  action: IAction<IGetFinancialPlan>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IFinancialPlan = yield call(
        deactivateFinancialPlan,
        action.payload,
      )
      yield put(success({ plan: response }))
      yield put(
        triggerToastSuccess({
          message: 'Seu plano foi desativado.',
        }),
      )
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* deleteFinancialPlanSaga(action: IAction<IGetFinancialPlan>) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IFinancialPlan = yield call(deleteFinancialPlan, payload)
      yield put(success({ plan: response }))
      yield put(
        triggerToastSuccess({
          message: 'Seu plano foi excluído.',
        }),
      )
      if (successCallback) {
        successCallback()
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadFinancialExtraPlansSaga(
  action: IAction<IListFinancialExtraPlansPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IListFinancialExtraPlansResponse = yield call(
        listFinancialExtraPlans,
        action.payload,
      )
      yield put(success({ extraPlans: response.results }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadFinancialExtraPlanSaga(
  action: IAction<IGetFinancialExtraPlanPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IFinancialExtraPlan<number> = yield call(
        getFinancialExtraPlan,
        action.payload,
      )
      yield put(success({ extraPlan: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* updateFinancialExtraPlanSaga(
  action: IAction<IWorkoutData<number>>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IFinancialExtraPlan<number> = yield call(
        updateFinancialExtraPlan,
        action.payload,
      )
      yield put(success({ extraPlan: response }))
      yield put(updateExtraPlans(response))
      yield put(
        triggerToastSuccess({
          message: 'Treino extra atualizado com sucesso !',
        }),
      )
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* updateFinancialPlanSaga(
  action: IAction<ICreateFinancialPlanPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IGetFinancialPlanPayload = yield call(
        updateFinancialPlan,
        action.payload,
      )
      yield put(success({ planList: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* partialUpdateFinancialExtraPlanSaga(
  action: IAction<IPartialUpdateFinancialExtraPlanPayload<number>>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IFinancialExtraPlan<number> = yield call(
        partialUpdateFinancialExtraPlan,
        action.payload,
      )
      yield put(success({ extraPlan: response }))
      yield put(updateExtraPlans(response))
      yield put(
        triggerToastSuccess({
          message: 'Treino extra atualizado com sucesso !',
        }),
      )
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* removeFinancialExtraPlanSaga(
  action: IAction<IGetFinancialExtraPlanPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      yield call(deleteFinancialExtraPlan, action.payload)
      yield put(success({ extraPlan: undefined }))
      yield put(deleteExtraPlans(action.payload))
      yield put(
        triggerToastSuccess({
          message: 'Treino extra removido com sucesso !',
        }),
      )
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* getFinancialOverviewSaga(
  action: IAction<ILoadFinancialOverview>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IFinancialOverview = yield call(
        getOverview,
        action.payload,
      )
      yield put(success({ overview: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* getAthleteInstallmentsSaga(
  action: IAction<IInstallmentPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IInstallmentResponse = yield call(
        getAthleteInstallments,
        action.payload,
      )
      yield put(success({ installments: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export function* getMoreAthleteInstallmentsSaga(
  action: IAction<IInstallmentPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IInstallmentResponse = yield call(
        getAthleteInstallments,
        action.payload,
      )
      yield put(successLoadMoreInstallments(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* createPaymentSaga(action: IAction<ICreatePaymentPayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IPayment = yield call(createPayment, payload)

      yield put(success({ payment: response }))
      successCallback?.(response)
      yield put(triggerToastSuccess({ message: 'Pagamento registrado' }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* financialAthletesSaga(): SagaIterator {
  yield all([
    takeLatest(Types.GET_ATHLETES, loadFinancialAthletesSaga),
    takeLatest(Types.GET_MORE_ATHLETES, loadMoreFinancialAthletesSaga),
    takeLatest(Types.CREATE_EXTRA_PLAN, createFinancialExtraPlanSaga),
    takeLatest(Types.CREATE_PLAN, createFinancialPlanSaga),
    takeLatest(Types.ACTIVATE_PLAN, activateFinancialPlanSaga),
    takeLatest(Types.DEACTIVATE_PLAN, deactivateFinancialPlanSaga),
    takeLatest(Types.DELETE_PLAN, deleteFinancialPlanSaga),
    takeLatest(Types.GET_EXTRA_PLAN, loadFinancialExtraPlanSaga),
    takeLatest(Types.GET_EXTRA_PLANS, loadFinancialExtraPlansSaga),
    takeLatest(
      Types.PARTIAL_UPDATE_EXTRA_PLAN,
      partialUpdateFinancialExtraPlanSaga,
    ),
    takeLatest(Types.DELETE_EXTRA_PLAN, removeFinancialExtraPlanSaga),
    takeLatest(Types.UPDATE_EXTRA_PLAN, updateFinancialExtraPlanSaga),
    takeLatest(Types.UPDATE_PLAN, updateFinancialPlanSaga),
    takeLatest(Types.GET_PLAN, getFinancialPlanSaga),
    takeLatest(Types.GET_OVERVIEW, getFinancialOverviewSaga),
    takeLatest(Types.GET_INSTALLMENTS, getAthleteInstallmentsSaga),
    takeLatest(Types.GET_MORE_INSTALLMENTS, getMoreAthleteInstallmentsSaga),
    takeLatest(Types.CREATE_PAYMENT, createPaymentSaga),
  ])
}
