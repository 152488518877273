// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'hooks'

// Components
import { Skeleton } from 'heeds-ds'

const FinancialLoader: FC = () => {
  const { breakpoints } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  return isDesktop ? (
    <div className="relative flex flex-col rounded-[8px] border border-border-input bg-surface p-4">
      <div className="mb-3 flex justify-between">
        <Skeleton height="19px" width="85px" radius="4px" />
        <Skeleton height="32px" width="32px" radius="8px" />
      </div>
      <div className="flex flex-col gap-[22px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <Skeleton height="20px" width="20px" radius="100%" />
            <Skeleton height="38px" width="100px" radius="4px" />
          </div>
          <div className="flex flex-col">
            <Skeleton height="16px" width="80px" radius="4px" />
            <Skeleton height="16px" width="125px" radius="4px" />
          </div>
        </div>
        <div className="flex gap-1">
          <Skeleton height="32px" width="100px" radius="16px" />
          <Skeleton height="32px" width="100px" radius="16px" />
          <Skeleton height="32px" width="100px" radius="16px" />
        </div>
      </div>
    </div>
  ) : (
    <div className="relative flex flex-col rounded-[8px] border border-border-input bg-surface p-4">
      <div className="flex items-center justify-between">
        <Skeleton height="19px" width="85px" radius="4px" />
        <Skeleton height="24px" width="24px" radius="8px" />
      </div>
    </div>
  )
}

export default FinancialLoader
