// Models
import { IAction } from 'lib/redux/models'
import { IAnamneseTemplate } from '../models'
import {
  ICreateAnamneseTemplatePayload,
  IDeleteAnamneseTemplatePayload,
  ILoadAnamnese,
  ILoadAnamneseTemplatePayload,
  ILoadAnamneseTemplateResponse,
  ILoadAnamneseTemplatesSendResponse,
  IPartialUpdateAnamneseTemplatePayload,
  ISendAnamneseTemplatesPayload,
  IUpdateAnamneseTemplatePayload,
} from 'services/assessmentAnamneseTemplate/@types'

// Libraries
import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'

// Misc
import {
  createAnamneseTemplate,
  deleteAnamneseTemplate,
  getAnamneseTemplate,
  getAnamneseTemplates,
  loadAnamneseTemplatesSent,
  partilaUpdateAnamneseTemplate,
  sendAnamneseTemplate,
  updateAnamneseTemplate,
} from 'services/assessmentAnamneseTemplate'
import {
  failure,
  fulfill,
  request,
  success,
  successLoadMore,
  Types,
} from '../duck'
import { triggerToastError } from 'storage/general/duck'

export function* createAnamneseTemplateSaga(
  action: IAction<ICreateAnamneseTemplatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IAnamneseTemplate = yield call(
        createAnamneseTemplate,
        payload,
      )

      yield put(success({ anamneseTemplate: response }))

      successCallback?.(response)
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getAnamneseTemplateSaga(action: IAction<ILoadAnamnese>) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IAnamneseTemplate = yield call(
        getAnamneseTemplate,
        payload.id,
      )
      if (response) {
        successCallback?.(response)
        yield put(success({ anamneseTemplate: response }))
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* updateAnamneseTemplateSaga(
  action: IAction<IUpdateAnamneseTemplatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IAnamneseTemplate = yield call(
        updateAnamneseTemplate,
        payload,
      )

      yield put(success({ anamneseTemplate: response }))

      successCallback?.()
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* partialUpdateAnamneseTemplateSaga(
  action: IAction<IPartialUpdateAnamneseTemplatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IAnamneseTemplate = yield call(
        partilaUpdateAnamneseTemplate,
        action.payload,
      )

      yield put(success({ anamneseTemplate: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* deleteAnamneseTemplateSaga(
  action: IAction<IDeleteAnamneseTemplatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { id, successCallback } = action.payload
      yield call(deleteAnamneseTemplate, id)

      successCallback?.()
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadAnamneseTemplatesSaga(
  action: IAction<ILoadAnamneseTemplatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { results, ...paginateResponse }: ILoadAnamneseTemplateResponse =
        yield call(getAnamneseTemplates, action.payload)

      yield put(success({ ...paginateResponse, anamneseTemplates: results }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadMoreAnamneseTemplatesSaga(
  action: IAction<ILoadAnamneseTemplatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: ILoadAnamneseTemplateResponse = yield call(
        getAnamneseTemplates,
        action.payload,
      )

      yield put(successLoadMore(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadAnamneseTemplatesSentSaga(action: IAction<string>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: ILoadAnamneseTemplatesSendResponse = yield call(
        loadAnamneseTemplatesSent,
        action.payload,
      )

      yield put(success({ athletesSendTemplate: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* sendAnamneseTemplatesSaga(
  action: IAction<ISendAnamneseTemplatesPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: ILoadAnamneseTemplatesSendResponse = yield call(
        sendAnamneseTemplate,
        payload,
      )

      yield put(success({ athletesSendTemplate: response }))

      successCallback?.()
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* anamneseTemplateSaga(): SagaIterator {
  yield all([
    takeLatest(Types.CREATE, createAnamneseTemplateSaga),
    takeLatest(Types.GET, getAnamneseTemplateSaga),
    takeLatest(Types.UPDATE, updateAnamneseTemplateSaga),
    takeLatest(Types.PARTIAL_UPDATE, partialUpdateAnamneseTemplateSaga),
    takeLatest(Types.DELETE, deleteAnamneseTemplateSaga),
    takeLatest(Types.LOAD, loadAnamneseTemplatesSaga),
    takeLatest(Types.LOAD_SENT, loadAnamneseTemplatesSentSaga),
    takeLatest(Types.LOAD_MORE, loadMoreAnamneseTemplatesSaga),
    takeLatest(Types.SEND, sendAnamneseTemplatesSaga),
  ])
}
