// Models
import { BodyVariationsTypes } from 'lib/styled-components/models'
import { TTailwindSpacing } from '../../models'

// Misc
import { cn } from '../../utils/classes'

// React
import { FC } from 'react'

type Props = {
  active?: string
  className?: string
  distanceOfBar?: TTailwindSpacing
  fontSize?: BodyVariationsTypes
  setActive: (tab: string) => void
  showInactiveBar?: boolean
  tabs: string[]
}

const Tabs: FC<Props> = ({
  active,
  className,
  distanceOfBar,
  fontSize,
  setActive,
  showInactiveBar,
  tabs,
}) => {
  const fontSizeClass = fontSize ? `text-${fontSize}` : 'text-copy3'
  const distanceOfBarClass = distanceOfBar ? `pb-${distanceOfBar}` : 'pb-6'

  return (
    <div
      data-testid="container"
      className={cn(
        'flex w-full overflow-x-auto',
        showInactiveBar && 'gap-5',
        className,
      )}
    >
      {tabs.map((tab) => {
        const isSelected = tab === active
        return (
          <div
            key={tab}
            onClick={() => setActive(tab)}
            data-testid={`tab-${tab}`}
            className={cn(
              'cursor-pointer border-b-4 border-white px-4 pt-6',
              distanceOfBarClass,
              isSelected
                ? 'border-action-secondaryDefault'
                : showInactiveBar && 'border-border-input',
            )}
          >
            <p
              className={cn(
                'font-roboto font-bold text-text-subdued',
                fontSizeClass,
                { 'text-text': isSelected },
              )}
            >
              {tab}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default Tabs
