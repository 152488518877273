import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 1.6rem 1.6rem 2.2rem;
  border-radius: 2px;
  border-left: 4px solid ${({ theme }) => theme.colors.icon.default};
`
