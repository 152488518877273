// Models
import { IAction } from 'lib/redux/models'
import {
  ICreateFinancialSubscription,
  IGetSubscriptionPayload,
  IListFinancialSubscriptions,
  IListFinancialSubscriptionsResponse,
  ISubscriptionDeactivatePayload,
} from 'services/financialSubscription/@types'

// Libraries
import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'

// Misc
import {
  activateFinancialSubscription,
  createFinancialSubscription,
  deactivateFinancialSubscription,
  getFinancialSubscription,
  getFinancialSubscriptions,
  updateFinancialSubscription,
} from 'services/financialSubscription'
import {
  failure,
  fulfill,
  request,
  success,
  successLoadMoreSubscriptions,
  Types,
} from '../duck'
import { triggerToastError, triggerToastSuccess } from 'storage/general/duck'
import { IFinancialSubscription } from '../models'
import { triggerLoadAthletePlans } from 'storage/athletePlans/duck'

export function* deactivateFinancialSubscriptionSaga(
  action: IAction<ISubscriptionDeactivatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback } = action.payload
      const response: IFinancialSubscription = yield call(
        deactivateFinancialSubscription,
        action.payload,
      )
      yield put(success({ subscription: response }))

      if (successCallback) {
        successCallback()
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* activateFinancialSubscriptionSaga(
  action: IAction<ISubscriptionDeactivatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback } = action.payload
      const response: IFinancialSubscription = yield call(
        activateFinancialSubscription,
        action.payload,
      )
      yield put(success({ subscription: response }))

      if (successCallback) {
        successCallback()
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* createFinancialSubscriptionSaga(
  action: IAction<ICreateFinancialSubscription>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IFinancialSubscription = yield call(
        createFinancialSubscription,
        action.payload,
      )
      if (response) {
        yield put(success({ subscription: response }))
        yield put(triggerLoadAthletePlans({ id: action.payload.athlete }))
        yield put(
          triggerToastSuccess({
            message: 'Financeiro do aluno criado com sucesso',
          }),
        )
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* updateFinancialSubscriptionSaga(
  action: IAction<ICreateFinancialSubscription>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IFinancialSubscription = yield call(
        updateFinancialSubscription,
        action.payload,
      )
      if (response) {
        yield put(success({ subscription: response }))
        yield put(
          triggerToastSuccess({
            message: 'Financeiro do aluno atualizado com sucesso',
          }),
        )
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getFinancialSubscriptionSaga(
  action: IAction<IGetSubscriptionPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, id } = action.payload
      const response: IFinancialSubscription = yield call(
        getFinancialSubscription,
        id,
      )

      yield put(success({ subscription: response }))

      successCallback?.(response)
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getFinancialSubscriptionsSaga(
  action: IAction<IListFinancialSubscriptions>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { results, ...response }: IListFinancialSubscriptionsResponse =
        yield call(getFinancialSubscriptions, action.payload)

      yield put(success({ ...response, subscriptions: results }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getMoreFinancialSubscriptionsSaga(
  action: IAction<IListFinancialSubscriptions>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IListFinancialSubscriptionsResponse = yield call(
        getFinancialSubscriptions,
        action.payload,
      )
      yield put(successLoadMoreSubscriptions(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* financialAthletesSaga(): SagaIterator {
  yield all([
    takeLatest(Types.DEACTIVATE, deactivateFinancialSubscriptionSaga),
    takeLatest(Types.ACTIVATE, activateFinancialSubscriptionSaga),
    takeLatest(Types.UPDATE, updateFinancialSubscriptionSaga),
    takeLatest(Types.CREATE, createFinancialSubscriptionSaga),
    takeLatest(Types.GET, getFinancialSubscriptionSaga),
    takeLatest(Types.LIST, getFinancialSubscriptionsSaga),
    takeLatest(Types.LIST_MORE, getMoreFinancialSubscriptionsSaga),
  ])
}
