// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from 'utils/helpers/classess'
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import { Skeleton } from 'heeds-ds'

export type Props = {
  gridTemplate?: string
  padding?: string
}

const HEIGHT = '20px'

const Loader: FC<Props> = ({ gridTemplate, padding }) => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return (
    <div>
      {isDesktop ? (
        <div className={cn('grid w-full', gridTemplate)}>
          <div className={padding}>
            <Skeleton height={HEIGHT} />
          </div>
          <div className={padding}>
            <Skeleton height={HEIGHT} />
          </div>
          <div className={padding}>
            <Skeleton height={HEIGHT} />
          </div>
          <div className={padding}>
            <Skeleton height={HEIGHT} />
          </div>
        </div>
      ) : (
        <div className="mx-6 my-2 flex rounded-[8px] border border-border-input p-4">
          <div className="flex flex-col gap-1">
            <div className="mb-1">
              <Skeleton width="220px" height={HEIGHT} />
            </div>
            <Skeleton width="100px" height={HEIGHT} />
            <Skeleton width="130px" height={HEIGHT} />
            <Skeleton width="120px" height={HEIGHT} />
            <Skeleton width="160px" height={HEIGHT} />
          </div>
          <div className="ml-auto">
            <Skeleton width="40px" height="40px" />
          </div>
        </div>
      )}
    </div>
  )
}

export default Loader
