// Models
import { EPlanModelsPortuguese } from 'blocks/dashboard/athlete/AthleteFinancial/Plans'
import { IAthleteItem, IGetAthletesPayload } from 'services/athletes/@types'
import { IAthletesState } from 'storage/athletes/models'
import { TAthleteStatus } from 'models'
import { IFilter } from 'heeds-ds/src/components/Inputs/InputSearch'
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import { TModalProps } from 'views/dashboard/profile/ProfilePlans'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect, useMemo, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'

// Misc
import {
  triggerLoadAthletes,
  triggerLoadMoreAthletes,
  cleanup,
} from 'storage/athletes/duck'
import {
  triggerActivateSubscription,
  triggerDeactivateSubscription,
} from 'storage/financialSubscription/duck'
import { urls } from 'routes/paths'
import { useDebounceFunction, useMenu, useModal } from 'hooks'
import useFilters from 'hooks/useFilters'
import useMediaQuery from 'heeds-ds/src/hooks/useMediaQuery'

// Components
import * as Blocks from 'blocks/dashboard/athlete/Athletes'
import * as Styled from './styled'
import { Aligner, Body, Button, CommonList, Loading, Subtitle } from 'heeds-ds'
import { AthleteItem } from 'components/'
import { ModalConfirmation, ModalMoreOptions } from 'components/modals'
import Loader from 'components/lists/AthleteItem/loader'

// Constants
const COLUMNS = [
  { label: 'Nome' },
  { label: 'Aulas' },
  { label: 'Plano' },
  { label: 'Data de vencimento' },
  { label: 'Status aluno' },
]

const modalProps = {
  active: {
    title: 'Desativar Aluno',
    description: 'Tem certeza que deseja desativar este aluno?',
    longerDescription:
      'O status do aluno ficará como desativado para o sistema. Você poderá reativar esse aluno a qualquer momento.',
    confirmTitle: 'Desativar',
  },
  inactive: {
    title: 'Reativar Aluno',
    description: 'Tem certeza que deseja reativar este aluno?',
    confirmTitle: 'Ativar',
  },
}

const page_size = 20

const Athletes: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)
  const { athletes, loading, error } = useSelector<IStore, IAthletesState>(
    (state) => state.athletes,
  )
  const { setPagename } = useMenu()
  const { openModal, isVisible, closeModal } = useModal()
  const { state } = useLocation()
  const { filters, hasFilters, isValueIncluded, setFilter, toggleFilter } =
    useFilters<IGetAthletesPayload>(window.location.search)

  const [typing, setTyping] = useState(false)
  const [showMoreModal, setShowMoreModal] = useState(false)
  const [mobileOptions, setMobileOptions] = useState<TMenuDropdownOption[]>()
  const [modalOptions, setModalOptions] = useState<TModalProps>({
    title: '',
    description: '',
    confirmTitle: '',
    onConfirm: () => {
      return
    },
  })

  const editPlan = state?.planEdit

  const activeAthletesCount = useMemo(() => {
    const total = athletes?.active_athletes

    if (!total) return ''

    if (total === 1) return `${total} aluno ativo`

    return `${total} alunos ativos`
  }, [athletes?.active_athletes])

  const generateListItemOptions = (
    id: number,
    subscription_pk: number,
    status?: TAthleteStatus,
  ) => {
    if (!status || status === 'invited') return []

    const isActive = status === 'active'
    const options: TMenuDropdownOption[] = [
      {
        icon: 'eye',
        label: 'Perfil',
        onClick: () => {
          navigateToProfile(id), closeModal()
        },
      },
      {
        icon: 'dumbbell',
        label: 'Treinos',
        onClick: () => {
          navigate(generatePath(urls.athleteRoutines, { id })), closeModal()
        },
      },
      {
        icon: 'analytics',
        label: 'Relatórios das rotinas',
        onClick: () =>
          navigate(
            generatePath(urls.athleteRoutinesReport, {
              id,
            }),
          ),
      },
      {
        icon: 'edit',
        label: 'Editar',
        onClick: () => {
          navigateToEditProfile(id), closeModal()
        },
      },
      {
        icon: isActive ? 'userOff' : 'userAdd',
        label: isActive ? 'Desativar' : 'Reativar',
        color: theme.colors.text[isActive ? 'critical' : 'default'],
        onClick: () => {
          openModal('athlete-status-handler-modal')
          handleOptionsAction(status, subscription_pk)
        },
      },
    ]
    return editPlan ? options.slice(-2) : options
  }

  const handleOptionsAction = (status: 'active' | 'inactive', id: number) => {
    const payload = {
      id,
      successCallback: () =>
        dispatch(triggerLoadAthletes({ ...filters, page_size })),
    }

    setModalOptions({
      ...modalProps[status],
      onConfirm: () =>
        dispatch(
          status === 'active'
            ? triggerDeactivateSubscription(payload)
            : triggerActivateSubscription(payload),
        ),
    })
  }

  const navigateToAthleteCreation = () => {
    navigate(generatePath(urls.athleteGeneral, { id: 'cadastro' }))
  }

  const navigateToEditProfile = (id: number) => {
    navigate(
      generatePath(editPlan ? urls.athleteFinancial : urls.athleteGeneral, {
        id,
      }),
      {
        state: { origin: 'list' },
      },
    )
  }

  const navigateToProfile = (id: number) => {
    navigate(
      generatePath(editPlan ? urls.athleteFinancial : urls.athleteProfile, {
        id,
      }),
    )
  }

  const openListItemOptionsMobile = (
    id: number,
    subscription_pk: number,
    status?: TAthleteStatus,
  ) => {
    setMobileOptions(generateListItemOptions(id, subscription_pk, status))
    setShowMoreModal(true)
    openModal('athlete-list-more-options-modal')
  }

  const onClickFilter = (filter: IFilter) => {
    const newFilters = toggleFilter(
      filter.param,
      filter.value,
      filter.param !== 'plan_periodicity',
    )
    handleReloadAthletes(newFilters)
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTyping(true)
    const filters = setFilter('name', event.target.value, true)
    debouncedLoad(filters)
  }

  const handleEndReached = () => {
    if (athletes && !loading && athletes?.next) {
      const query = {
        ...filters,
        page: athletes.next,
        page_size,
      }
      dispatch(triggerLoadMoreAthletes(query))
    }
  }

  const handleReloadAthletes = (newFilters: IGetAthletesPayload) => {
    setTyping(false)
    const query = {
      ...newFilters,
      page_size,
    }
    dispatch(triggerLoadAthletes(query))
  }

  const debouncedLoad = useDebounceFunction<
    (newFilters: IGetAthletesPayload) => void
  >(handleReloadAthletes, 1000)

  function renderItems(item: IAthleteItem) {
    return (
      <AthleteItem
        key={item.id}
        item={item}
        handleClose={() => setShowMoreModal(false)}
        handleOptions={(id, subscription_pk, status) =>
          generateListItemOptions(id, subscription_pk, status)
        }
        mobileOptions={mobileOptions}
        onClick={() => navigateToProfile(item.id)}
        onSwipeClick={() =>
          openListItemOptionsMobile(
            item.id,
            item.subscription_pk || 0,
            item.status,
          )
        }
        showMoreModal={showMoreModal}
      />
    )
  }

  useLayoutEffect(() => {
    if (!athletes?.results && !loading && !error) {
      const query = {
        ...filters,
        page_size,
      }
      dispatch(triggerLoadAthletes(query))
    }
  }, [athletes?.results, dispatch, error, filters, loading])

  useLayoutEffect(() => {
    setPagename('Alunos')

    return () => {
      dispatch(cleanup())
      setPagename('Dashboard')
    }
  }, [dispatch, setPagename])

  if (loading && athletes === undefined) {
    return <Loading active />
  }

  if (
    !athletes?.results?.length &&
    !Object.keys(filters).length &&
    !loading &&
    !typing
  ) {
    return (
      <Styled.Container>
        <Blocks.EmptyState />
      </Styled.Container>
    )
  }

  return (
    <Styled.Container>
      {!editPlan && (
        <Blocks.SearchBar
          addAthlete={navigateToAthleteCreation}
          athletesCount={activeAthletesCount}
          isFilterSelected={isValueIncluded}
          hasSelectedFilters={hasFilters}
          onClickFilter={onClickFilter}
          onSearchChange={onSearchChange}
          searchQuery={filters?.name}
        />
      )}
      {editPlan && (
        <Aligner
          flex="column"
          gap="8px"
          margin="0 0 32px 0"
          padding={!isDesktop ? '0 2.4rem' : '4rem 0 0'}
        >
          <Subtitle type="sh3" weight={700}>
            Plano{' '}
            {
              EPlanModelsPortuguese[
                filters?.plan_periodicity as keyof typeof EPlanModelsPortuguese
              ]
            }
          </Subtitle>
          <Body type="copy2">
            Para desativar este plano, é necessário que você desative ou mude o
            plano dos alunos associados a ele.
          </Body>
        </Aligner>
      )}
      <Styled.Content>
        {isDesktop && (
          <Aligner
            align="flex-start"
            margin="1.6rem 0 1.6rem 2.4rem"
            flex="column"
            gap="4"
            width="auto"
          >
            <Body margin="0 1.6rem 0 0" type="copy2" weight={700}>
              Alunos
            </Body>
            <Body
              color={theme.colors.text.subdued}
              type="copy5"
              margin="0 0 0.2rem 0"
              className="h-4"
            >
              {activeAthletesCount}
            </Body>
          </Aligner>
        )}
        <CommonList<IAthleteItem>
          columns={COLUMNS}
          columnsFormat={
            'grid-cols-[minmax(19.9rem,1.7fr)minmax(12rem,1fr)minmax(5.5rem,1fr)minmax(12.4rem,1fr)minmax(9rem,1fr)minmax(9rem,0.5fr)]'
          }
          columnsGap="gap-x-6"
          data={athletes?.results ?? []}
          hasMore={!!athletes?.next}
          loaderComponent={<Loader />}
          refreshing={loading}
          onEndReached={handleEndReached}
          padding="p-4 pl-6"
          renderItem={renderItems}
          showHeader={isDesktop}
          listEmptyComponent={
            <Aligner
              justify="center"
              align="center"
              width="100%"
              margin="25vh 0 0 0"
            >
              <Aligner width="406px" flex="column" align="center" gap="22px">
                <Body
                  align="center"
                  type="copy2"
                  color={theme.colors.text.subdued}
                  weight={500}
                >
                  Não há mais alunos associados a este plano. Agora você pode
                  desativá-lo.
                </Body>
                <Button
                  onClick={() => navigate(urls.profilePlans)}
                  size="small"
                >
                  Voltar para planos
                </Button>
              </Aligner>
            </Aligner>
          }
        />
      </Styled.Content>

      {isVisible === 'athlete-status-handler-modal' && (
        <ModalConfirmation {...modalOptions} />
      )}

      {isVisible === 'athlete-list-more-options-modal' && (
        <ModalMoreOptions options={mobileOptions} />
      )}
    </Styled.Container>
  )
}

export default Athletes
