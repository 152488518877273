// React
import { FC } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'

// Components
import { DropdownMenu, IconButton } from 'heeds-ds'
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import { buttonClickTracking } from 'utils/tracking'

type TabsInfoCardProps = {
  title: string
  description: string[]
  options?: TMenuDropdownOption[]
}

const TabsInfoCard: FC<TabsInfoCardProps> = ({
  title,
  description,
  options,
}) => {
  return (
    <div
      className={cn(
        'flex items-start justify-between gap-4 break-words rounded-[15px] border',
        'border-border-input bg-surface p-4 md:max-h-[140px]  md:w-[calc(50%-10px)]',
      )}
    >
      <div className="flex w-[85%] flex-col gap-2">
        <p className="text-copy3 font-bold text-text-secondary">{title}</p>
        <div className="w-full overflow-hidden break-words">
          {description.map((item, index) => {
            return (
              <p key={index} className="truncate text-copy4 text-text-subdued">
                {item}
              </p>
            )
          })}
        </div>
      </div>
      <div>
        <DropdownMenu items={options as TMenuDropdownOption[]}>
          <IconButton
            size="small"
            iconName="moreVertical"
            track={buttonClickTracking}
            trackName="open_model_model_options"
          />
        </DropdownMenu>
      </div>
    </div>
  )
}

export default TabsInfoCard
