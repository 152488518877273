// Models
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import { IExerciseData } from 'storage/exercise/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { handleDisplayMuscleGroup } from 'filters/exercises'

// Components
import * as Styled from './styled'
import { DropdownMenu, IconButton } from 'heeds-ds'

export type Props = {
  exerciseTemplate: IExerciseData
  onEdit?: (modelID: string) => void
  onClickImage?: (exercise: IExerciseData) => void
  onRemove?: () => void
}

const Mobile: FC<Props> = ({
  exerciseTemplate,
  onEdit,
  onRemove,
  onClickImage,
}) => {
  const { colors } = useContext(ThemeContext)

  const {
    name,
    id,
    date,
    equipment,
    agonists_muscle,
    thumbnail,
    synergists_muscle,
  } = exerciseTemplate

  const options: TMenuDropdownOption[] = [
    {
      icon: 'edit',
      label: 'Editar',
      onClick: () => onEdit?.(String(id)),
    },
    {
      color: colors.text.critical,
      icon: 'delete',
      label: 'Excluir',
      onClick: () => onRemove?.(),
      disabled: name === 'Model modelo',
    },
  ]

  const handleClickImage = (event: React.MouseEvent) => {
    event.stopPropagation()
    onClickImage?.(exerciseTemplate)
  }

  return (
    <Styled.Container data-testid="routine-item">
      <Styled.Media onClick={handleClickImage} thumbnail={thumbnail}>
        <Styled.PlayIcon size={48} />
      </Styled.Media>
      <Styled.TitlesContainer>
        <Styled.Title>{name}</Styled.Title>
        <Styled.Creator>{date}</Styled.Creator>
        {!!agonists_muscle?.length && (
          <Styled.Creator>
            {handleDisplayMuscleGroup(agonists_muscle, 'size')} grupos agonistas
          </Styled.Creator>
        )}
        {!!synergists_muscle?.length && (
          <Styled.Creator>
            {handleDisplayMuscleGroup(synergists_muscle, 'size')} grupos
            sinergista
          </Styled.Creator>
        )}
        <Styled.Creator>{equipment}</Styled.Creator>
      </Styled.TitlesContainer>
      <Styled.OptionsContainer>
        <DropdownMenu items={options}>
          <IconButton
            size="small"
            iconName="moreVertical"
            track={buttonClickTracking}
            trackName="open_model_model_options"
          />
        </DropdownMenu>
      </Styled.OptionsContainer>
    </Styled.Container>
  )
}

export default Mobile
