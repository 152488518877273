// Libraries
import { cva } from 'class-variance-authority'

export const avatarStyles = cva(
  [
    'flex items-center justify-center rounded-full border-2 border-solid bg-surface border-text-secondary',
    'shadow-lg aspect-square',
  ],
  {
    variants: {
      scale: {
        xXSmall: 'h-5 w-5',
        xSmall: 'h-10 w-10',
        small: 'h-[52px] w-[52px]',
        medium: 'h-16 w-16',
        large: 'h-20 w-20',
        xLarge: 'h-40 w-40',
        xXLarge: 'h-48 w-48',
      },
      hoverMode: {
        true: 'border-border-input hover:shadow-lg hover:shadow-indigo-500/50',
      },
      clickable: {
        true: 'cursor-pointer',
        false: 'cursor-auto',
      },
      disabled: {
        true: 'opacity-50',
        false: 'opacity-100',
      },
    },
    defaultVariants: {
      scale: 'medium',
      clickable: false,
      disabled: false,
      hoverMode: false,
    },
  },
)

export const initialsStyles = cva(['select-none font-barlow font-extrabold'], {
  variants: {
    scale: {
      xXSmall: 'text-small',
      xSmall: 'text-copy4',
      small: 'text-copy2',
      medium: 'text-copy2',
      large: 'text-h2',
      xLarge: 'text-h2',
      xXLarge: 'text-h2',
    },
  },
  defaultVariants: {
    scale: 'medium',
  },
})
