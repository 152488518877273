// Models
import { IAction } from 'lib/redux/models'
import {
  EWorkoutRoutineActionTypes,
  IWorkoutRoutineState,
  IWorkoutRoutineTemplateResponse,
  IWorkoutRoutinesResponse,
} from '../models'
import {
  IGetWorkoutRoutineTemplatePayload,
  IPartialUpdateWorkoutRoutineTemplatePayload,
  IWorkoutCreateRoutinePayload,
  IWorkoutDeleteRoutinePayload,
  IWorkoutGetRoutinePayload,
  IWorkoutLatestWorkoutPayload,
  IWorkoutPartialUpdateRoutinePayload,
  IWorkoutRoutineListPayload,
  IWorkoutUpdateRoutinePayload,
} from 'services/workoutRoutine/@types'

// ACTION TYPES
export const Types = {
  CLEANUP: EWorkoutRoutineActionTypes.CLEANUP,
  FAILURE: EWorkoutRoutineActionTypes.FAILURE,
  FULFILL: EWorkoutRoutineActionTypes.FULFILL,
  REQUEST: EWorkoutRoutineActionTypes.REQUEST,
  SUCCESS: EWorkoutRoutineActionTypes.SUCCESS,
  CREATE_WORKOUT_ROUTINE: EWorkoutRoutineActionTypes.CREATE_WORKOUT_ROUTINE,
  DELETE_WORKOUT_ROUTINE: EWorkoutRoutineActionTypes.DELETE_WORKOUT_ROUTINE,
  DELETE_WORKOUT_ROUTINE_TEMPLATE:
    EWorkoutRoutineActionTypes.DELETE_WORKOUT_ROUTINE_TEMPLATE,
  GET_LATEST_WORKOUT_ROUTINE:
    EWorkoutRoutineActionTypes.GET_LATEST_WORKOUT_ROUTINE,
  GET_DETAIL_WORKOUT_ROUTINE:
    EWorkoutRoutineActionTypes.GET_DETAIL_WORKOUT_ROUTINE,
  GET_LIST_WORKOUT_ROUTINE: EWorkoutRoutineActionTypes.GET_LIST_WORKOUT_ROUTINE,
  GET_LIST_WORKOUT_ROUTINE_TEMPLATE:
    EWorkoutRoutineActionTypes.GET_LIST_WORKOUT_ROUTINE_TEMPLATE,
  GET_LIST_MORE_WORKOUT_ROUTINE:
    EWorkoutRoutineActionTypes.GET_LIST_MORE_WORKOUT_ROUTINE,
  PARTIAL_UPDATE_WORKOUT_ROUTINE:
    EWorkoutRoutineActionTypes.PARTIAL_UPDATE_WORKOUT_ROUTINE,
  UPDATE_WORKOUT_ROUTINE: EWorkoutRoutineActionTypes.UPDATE_WORKOUT_ROUTINE,
  SUCCESS_LOAD_MORE: EWorkoutRoutineActionTypes.SUCCESS_LOAD_MORE,
  SUCCESS_LOAD_MORE_ROUTINE_TEMPLATE:
    EWorkoutRoutineActionTypes.SUCCESS_LOAD_MORE_ROUTINE_TEMPLATE,
  GET_WORKOUT_ROUTINE_TEMPLATE:
    EWorkoutRoutineActionTypes.GET_WORKOUT_ROUTINE_TEMPLATE,
  PARTIAL_UPDATE_WORKOUT_ROUTINE_TEMPLATE:
    EWorkoutRoutineActionTypes.PARTIAL_UPDATE_WORKOUT_ROUTINE_TEMPLATE,
  REMOVE_ROUTINE_TEMPLATE_FROM_STATE:
    EWorkoutRoutineActionTypes.REMOVE_ROUTINE_TEMPLATE_FROM_STATE,
  GET_LIST_MORE_WORKOUT_ROUTINE_TEMPLATE:
    EWorkoutRoutineActionTypes.GET_LIST_MORE_WORKOUT_ROUTINE_TEMPLATE,
}

// INITIAL STATE
const initialState: IWorkoutRoutineState = {}

// REDUCER
export default (
  state: IWorkoutRoutineState = initialState,
  action?: IAction<unknown>,
): IWorkoutRoutineState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        workoutRoutine: undefined,
        workoutRoutines: undefined,
        workoutRoutineTemplate: undefined,
        success: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IWorkoutRoutineState),
      }
    case Types.SUCCESS_LOAD_MORE: {
      const { results, ...paginatedPayload } =
        action?.payload as IWorkoutRoutinesResponse
      const routinesFromState = state.workoutRoutines || []
      const routinesFromPayload = results || []

      return {
        ...state,
        ...paginatedPayload,
        workoutRoutines: [...routinesFromState, ...routinesFromPayload],
      }
    }
    case Types.SUCCESS_LOAD_MORE_ROUTINE_TEMPLATE: {
      const { results, ...paginatedPayload } =
        action?.payload as IWorkoutRoutineTemplateResponse
      const routinesFromState = state.workoutRoutineTemplates || []
      const routinesFromPayload = results || []

      return {
        ...state,
        ...paginatedPayload,
        workoutRoutineTemplates: [...routinesFromState, ...routinesFromPayload],
      }
    }
    case Types.REMOVE_ROUTINE_TEMPLATE_FROM_STATE: {
      const { routine_pk } = action.payload as { routine_pk: number }
      const templates = state.workoutRoutineTemplates || []
      const newRoutine = templates.filter(
        (routine) => routine.id !== routine_pk,
      )
      return {
        ...state,
        workoutRoutineTemplates: newRoutine,
      }
    }
    default:
      return state
  }
}

// BASE ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<undefined> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<undefined> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (
  payload: IWorkoutRoutineState,
): IAction<IWorkoutRoutineState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

export const successLoadMore = (
  payload: IWorkoutRoutinesResponse,
): IAction<IWorkoutRoutinesResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE,
    payload,
  }
}

export const successLoadMoreRoutineTemplates = (
  payload: IWorkoutRoutineTemplateResponse,
): IAction<IWorkoutRoutineTemplateResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE_ROUTINE_TEMPLATE,
    payload,
  }
}

// CUSTOM ACTIONS
export const triggerCreateWorkoutRoutine = (
  payload: IWorkoutCreateRoutinePayload,
): IAction<IWorkoutCreateRoutinePayload> => {
  return {
    type: Types.CREATE_WORKOUT_ROUTINE,
    payload,
  }
}

export const triggerDeleteWorkoutRoutine = (
  payload: IWorkoutDeleteRoutinePayload,
): IAction<IWorkoutDeleteRoutinePayload> => {
  return {
    type: Types.DELETE_WORKOUT_ROUTINE,
    payload,
  }
}

export const triggerLoadLatestWorkoutRoutine = (
  payload: IWorkoutLatestWorkoutPayload,
): IAction<IWorkoutLatestWorkoutPayload> => {
  return {
    type: Types.GET_LATEST_WORKOUT_ROUTINE,
    payload,
  }
}

export const triggerLoadWorkoutRoutine = (
  payload: IWorkoutGetRoutinePayload,
): IAction<IWorkoutGetRoutinePayload> => {
  return {
    type: Types.GET_DETAIL_WORKOUT_ROUTINE,
    payload,
  }
}

export const triggerPartialUpdateWorkoutRoutine = (
  payload: IWorkoutPartialUpdateRoutinePayload,
): IAction<IWorkoutPartialUpdateRoutinePayload> => {
  return {
    type: Types.PARTIAL_UPDATE_WORKOUT_ROUTINE,
    payload,
  }
}

export const triggerUpdateWorkoutRoutine = (
  payload: IWorkoutUpdateRoutinePayload,
): IAction<IWorkoutUpdateRoutinePayload> => {
  return {
    type: Types.UPDATE_WORKOUT_ROUTINE,
    payload,
  }
}

export const triggerLoadListWorkoutRoutines = (
  payload: IWorkoutRoutineListPayload,
): IAction<IWorkoutRoutineListPayload> => {
  return {
    type: Types.GET_LIST_WORKOUT_ROUTINE,
    payload,
  }
}

export const triggerLoadMoreListWorkoutRoutine = (
  payload: IWorkoutRoutineListPayload,
): IAction<IWorkoutRoutineListPayload> => {
  return {
    type: Types.GET_LIST_MORE_WORKOUT_ROUTINE,
    payload,
  }
}

export const triggerLoadMoreListWorkoutRoutineTemplates = (
  payload: IWorkoutRoutineListPayload,
): IAction<IWorkoutRoutineListPayload> => {
  return {
    type: Types.GET_LIST_MORE_WORKOUT_ROUTINE_TEMPLATE,
    payload,
  }
}

export const triggerListWorkoutRoutineTemplates = (
  payload: IWorkoutRoutineListPayload,
): IAction<IWorkoutRoutineListPayload> => {
  return {
    type: Types.GET_LIST_WORKOUT_ROUTINE_TEMPLATE,
    payload,
  }
}

export const triggerGetWorkoutRoutineTemplate = (
  payload: IGetWorkoutRoutineTemplatePayload,
): IAction<IGetWorkoutRoutineTemplatePayload> => {
  return {
    type: Types.GET_WORKOUT_ROUTINE_TEMPLATE,
    payload,
  }
}

export const triggerDeleteWorkoutRoutineTemplate = (
  payload: IWorkoutDeleteRoutinePayload,
): IAction<IWorkoutDeleteRoutinePayload> => {
  return {
    type: Types.DELETE_WORKOUT_ROUTINE_TEMPLATE,
    payload,
  }
}

export const triggerPartialUpdateWorkoutRoutineTemplate = (
  payload: IPartialUpdateWorkoutRoutineTemplatePayload,
): IAction<IPartialUpdateWorkoutRoutineTemplatePayload> => {
  return {
    type: Types.PARTIAL_UPDATE_WORKOUT_ROUTINE_TEMPLATE,
    payload,
  }
}

export const removeWorkoutRoutineTemplateFromState = (
  payload: IWorkoutDeleteRoutinePayload,
): IAction<IWorkoutDeleteRoutinePayload> => {
  return {
    type: Types.REMOVE_ROUTINE_TEMPLATE_FROM_STATE,
    payload,
  }
}
