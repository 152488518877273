// Models
import { TModalIconVariation } from 'models'

// Libraries
import styled from 'styled-components'

// Components
import { Body, Button, Icon } from 'heeds-ds'

type Props = {
  variation?: TModalIconVariation
}

enum EIconName {
  delete = 'delete',
  error = 'circleX',
  info = 'info',
  success = 'circleCheck',
  warning = 'warningSolid',
}

enum EBaseColor {
  delete = 'red',
  error = 'red',
  info = 'purple',
  success = 'green',
  warning = 'yellow',
}

export const Header = styled.div<{ hasIcon?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    align-items: flex-start;
    gap: 0.4rem;
    padding: 2.4rem;
    ${({ hasIcon }) => !hasIcon && 'padding-right: 7.2rem;'}
  }
`

export const HeaderIcon = styled(Icon).attrs<Props>(({ variation, theme }) => ({
  color: theme.colors.badge[`${EBaseColor[variation ?? 'info']}Text`],
  iconName: EIconName[variation ?? 'info'],
  size: 24,
}))<Props>``

export const HeaderTitle = styled(Body).attrs({
  weight: 600,
})``

export const HeaderDescription = styled(Body).attrs({
  align: 'center',
  type: 'copy2',
})`
  color: ${({ theme }) => theme.colors.text.default};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    color: ${({ theme }) => theme.colors.text.subdued};
    text-align: left;
  }
`

export const IconBackground = styled.div<Props>`
  background-color: ${({ theme, variation }) =>
    theme.colors.badge[`${EBaseColor[variation ?? 'info']}Background`]};
  border-radius: 0.8rem;
  display: flex;
  padding: 0.8rem;

  :not(:last-child) {
    margin-bottom: 0.8rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    :not(:last-child) {
      margin-bottom: 1.4rem;
    }
  }
`

export const Footer = styled.div`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  display: flex;
  flex-direction: column-reverse;
  gap: 2.4rem;
  justify-content: center;
  padding: 2.4rem 3.2rem 8rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
    gap: 3.2rem;
    justify-content: flex-end;
    padding: 1.6rem 2.4rem;
  }
`

export const PrimaryButton = styled(Button).attrs((props) => ({
  margin: '0',
  width: '100%',
  ...props,
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: fit-content;
  }
`

export const SecondaryButton = styled(Button).attrs((props) => ({
  cancel: true,
  margin: '0',
  variation: 'borderless',
  ...props,
}))``
