// Models
import { TInputMode } from '../../../models'

// React
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useController, useFormContext } from 'react-hook-form'
import MaskedInput, { conformToMask } from 'react-text-mask'

// Misc
import { maskCollection, TMasks } from '../../../utils/mask'

// Components
import * as Styled from './styled'
import { Icon, InputWrapper } from '../../'
import Input from '../@primitives/Input'

// Constants
const userAgent = navigator.userAgent
const iOS = !!userAgent.match(/iPhone|iPad|iPod/)

const INPUT_MODE: Record<TMasks, TInputMode> = {
  cadence: 'numeric',
  cep: 'numeric',
  currency: 'decimal',
  cref: 'text',
  date: 'numeric',
  integer: 'numeric',
  number: 'numeric',
  none: 'text',
  percentage: 'decimal',
  phone: 'numeric',
  repetitions: iOS ? 'text' : 'numeric',
  seconds: 'numeric',
  time: 'numeric',
  weight: 'numeric',
}

type Props = {
  borderLess?: boolean
  disabled?: boolean
  displayError?: boolean
  label?: string
  mandatory?: boolean
  margin?: string
  mask?: TMasks
  name: string
  placeholder?: string
  readOnly?: boolean
  scale?: 'small' | 'medium' | 'large'
  type?: 'password' | 'text' | 'email'
  width?: string
}

enum EIconSizes {
  small = 20,
  medium = 21.6,
  large = 25,
}

enum EFieldStates {
  default = 'default',
  error = 'critical',
  focus = 'focused',
  success = 'success',
}

const InputText: FC<Props> = (props) => {
  const {
    borderLess,
    displayError = true,
    disabled,
    mask = 'none',
    name,
    placeholder,
    readOnly,
    scale = 'medium',
    type,
    ...restWrapperProps
  } = props
  const inputRef = useRef<MaskedInput>(null)
  const theme = useContext(ThemeContext)

  const [fieldState, setFieldState] = useState(EFieldStates.default)
  const [focused, setFocused] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const { control } = useFormContext()
  const {
    field: { onBlur, onChange, value },
    fieldState: { isDirty, error },
  } = useController({ control, name, defaultValue: '' })

  const maskProps = maskCollection[mask]
  const maskedValue = useMemo(
    () =>
      maskProps.mask && value
        ? conformToMask(`${value}`, maskProps.mask).conformedValue
        : value,
    [maskProps.mask, value],
  )

  const isPassword = type === 'password'
  const COLORS_SCHEMA = {
    critical: theme.colors.text.critical,
    default: theme.colors.border.input,
    focused: theme.colors.text.default,
    success: theme.colors.text.success,
  }

  const handleBlur = () => {
    onBlur()
    setFocused(false)
  }

  const handleFocus = () => {
    setFocused(true)
    setFieldState(EFieldStates.focus)
  }

  const onIconClick = () => {
    if (!disabled) {
      setShowPassword((prev) => !prev)
    }
  }

  useEffect(() => {
    if (error) {
      inputRef.current?.inputElement.focus()
    }
  }, [error])

  useEffect(() => {
    switch (true) {
      case !!error:
        setFieldState(EFieldStates.error)
        break
      case focused:
        setFieldState(EFieldStates.focus)
        break
      case isDirty:
        setFieldState(EFieldStates.success)
        break
      default:
        setFieldState(EFieldStates.default)
        break
    }
  }, [disabled, error, focused, isDirty])

  return (
    <InputWrapper
      {...restWrapperProps}
      color={COLORS_SCHEMA[fieldState]}
      disabled={disabled}
      displayError={displayError}
      error={error?.message}
      name={name}
      type={type}
      size={scale}
    >
      <Styled.InputContainer
        aria-disabled={disabled}
        borderColor={COLORS_SCHEMA[fieldState]}
        borderLess={borderLess}
      >
        <MaskedInput
          {...maskProps}
          aria-type={type}
          disabled={disabled}
          id={name}
          ref={inputRef}
          name={name}
          onBlur={handleBlur}
          onChange={onChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          type={showPassword ? 'text' : type}
          value={maskedValue}
          render={(ref, props) => (
            <Input
              {...props}
              data-testid="input"
              ref={ref as (instance: HTMLInputElement | null) => void}
              color={COLORS_SCHEMA[fieldState]}
              inputMode={INPUT_MODE[mask]}
              readOnly={readOnly}
              scale={scale}
              value={maskedValue}
            />
          )}
        />
        {isPassword && (
          <Styled.IconWrapper
            data-testid="icon"
            disabled={disabled}
            onClick={onIconClick}
          >
            <Icon
              iconName={showPassword ? 'crossedEye' : 'eye'}
              color={
                disabled
                  ? theme.colors.icon.disabled
                  : theme.colors.icon.default
              }
              size={EIconSizes[scale]}
            />
          </Styled.IconWrapper>
        )}
      </Styled.InputContainer>
    </InputWrapper>
  )
}

export default InputText
