// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from 'utils/helpers/classess'
import { useMediaQuery } from 'hooks'

// Components
import { Skeleton } from 'heeds-ds'

const Loader: FC = () => {
  const { breakpoints } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  const cellBase = cn('flex items-center gap-1 p-4')

  return isDesktop ? (
    <div className="grid w-full grid-cols-[repeat(6,minmax(0,1fr))68px] border-b border-border-input text-copy4 text-text">
      <div className={cellBase}>
        <Skeleton width="60px" height="16px" />
      </div>

      <div className={cellBase}>
        <Skeleton width="60px" height="16px" className="rounded-full" />
      </div>

      <div className={cellBase}>
        <Skeleton width="80px" height="16px" />
      </div>

      <div className={cellBase}>
        <Skeleton width="80px" height="16px" />
      </div>

      <div className={cellBase}>
        <Skeleton width="80px" height="16px" />
      </div>

      <div className={cellBase}>
        <Skeleton width="82px" height="30px" radius="15px" />
      </div>

      <div className={cellBase}>
        <Skeleton width="36px" height="36px" />
      </div>
    </div>
  ) : (
    <div className="flex w-full flex-col gap-4 rounded-3xl border border-border-input bg-surface p-4">
      <Skeleton width="82px" height="30px" radius="15px" />

      <div className="flex items-center gap-4">
        <div className="mr-auto flex flex-col gap-1">
          <Skeleton width="80px" height="18px" />
          <Skeleton width="175px" height="18px" />
        </div>

        <Skeleton width="36px" height="36px" />
      </div>
    </div>
  )
}

export default Loader
