// Libraries
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 9.3rem);
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    min-height: 100vh;
    padding: 4rem 4.8rem 0 4rem;
  }
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 0 0 0 4rem;
  }
`

export const TabsWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 15px 15px 0 0;
`
