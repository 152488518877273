// Models
import { IAnamneseAnswer } from 'storage/assessmentAnamneseAnswer/models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useMemo } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { dateFormatEnglishToPortuguese } from 'utils/functions'

// Components
import * as Styled from './styled'
import { DropdownMenu, IconButton } from 'heeds-ds'
import { StatusContainer, StatusText } from '../styled'

export type Props = {
  anamneseAnswer: IAnamneseAnswer
  handleOptions: (anamneseAnswer: IAnamneseAnswer) => TMenuDropdownOption[]
  onClick: () => void
}

const Desktop: FC<Props> = ({ handleOptions, anamneseAnswer, onClick }) => {
  const { creation_date, answer_date, title, personal } = anamneseAnswer

  const options = useMemo(
    () => handleOptions(anamneseAnswer),
    [anamneseAnswer, handleOptions],
  )

  return (
    <Styled.Row data-testid="row" onClick={onClick}>
      <Styled.Cell data-testid="columnTitle">
        <Styled.Text>{title}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnCreatedBy">
        <Styled.Text>{personal !== 0 ? 'Você' : 'CADU'}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell justify="flex-start" data-testid="columnPlanType">
        <Styled.Text>
          {dateFormatEnglishToPortuguese(creation_date)}
        </Styled.Text>
      </Styled.Cell>

      <Styled.Cell justify="flex-start" data-testid="columnDueDate">
        <Styled.Text>
          {answer_date ? dateFormatEnglishToPortuguese(answer_date) : '-'}
        </Styled.Text>
      </Styled.Cell>

      <Styled.Cell justify="flex-start" data-testid="columnDueDate">
        <StatusContainer answered={!!answer_date}>
          <StatusText answered={!!answer_date}>
            {answer_date ? 'Respondido' : 'Não respondido'}
          </StatusText>
        </StatusContainer>
      </Styled.Cell>

      <Styled.Cell onClick={(e) => e.stopPropagation()}>
        <DropdownMenu items={options}>
          <IconButton
            iconName="moreVertical"
            size="small"
            track={buttonClickTracking}
            trackName="open_athlete_item_options"
          />
        </DropdownMenu>
      </Styled.Cell>
    </Styled.Row>
  )
}

export default Desktop
