// Models
import { FieldType } from 'components/WorkoutSetFormTag'
import { IExerciseData } from 'storage/exercise/models'
import {
  IFormInputs,
  IModelInput,
  IWorkoutSetInput,
  TFormValues,
  TMuscleGroupFilter,
  TSetSpecialSet,
  TSpecialSetInput,
} from 'models'
import { TSetStateModels } from 'hooks/useWorkoutModelsForm/@types'
import { WorkoutLayoutContext } from 'layouts/WorkoutLayout'

// React
import { FC, Fragment, useEffect, useMemo } from 'react'

// Libraries
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useNavigate, useOutletContext } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useModal } from 'hooks'

// Components
import * as Styled from './styled'
import {
  WorkoutModelsMobileHome,
  WorkoutModelsMobileSets,
} from 'views/dashboard/workout'

type Props = {
  addExerciseToWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exercise: IExerciseData,
  ) => void
  addOrRemoveFieldFromWorkoutSetToExercise?: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  addWorkoutModel: (model: IModelInput) => void
  addWorkoutSet: (modelId: string, set: IWorkoutSetInput) => void
  changePage: (page: string) => void
  duplicateWorkoutModel: () => void
  filters: TMuscleGroupFilter[]
  inputValue: string
  loadMoreExercises: () => void
  methods: UseFormReturn<IFormInputs, TFormValues>
  mobilePage?: string | null
  onChangeFilters: (filters: TMuscleGroupFilter[]) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (formData: IFormInputs) => void
  removeExerciseFromWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
  ) => void
  removeFilter: (label: string) => void
  removeWorkoutModel: () => void
  removeWorkoutSet: (modelId: string, workoutSetId: string) => void
  renameWorkoutModel: () => void
  replicateWorkoutSetFields: (modelId: string, set: IWorkoutSetInput) => void
  selectWorkoutModel: (index: number) => void
  selectedModelIndex: number
  setSpecialSet: TSetSpecialSet
  setStateModels: TSetStateModels
  specialSet?: TSpecialSetInput
  stateModels: IModelInput[]
  updateWorkoutModel: (id: string, updatedModel: IModelInput) => void
  updateWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
}

const Mobile: FC<Props> = ({
  addExerciseToWorkoutSet,
  addOrRemoveFieldFromWorkoutSetToExercise,
  addWorkoutModel,
  duplicateWorkoutModel,
  methods,
  mobilePage,
  onSubmit,
  removeExerciseFromWorkoutSet,
  removeWorkoutModel,
  removeWorkoutSet,
  renameWorkoutModel,
  replicateWorkoutSetFields,
  selectWorkoutModel,
  specialSet,
  stateModels,
  updateWorkoutSet,
  ...commonProps
}) => {
  const { blocked, setBlocked } = useOutletContext<WorkoutLayoutContext>()
  const { openModal } = useModal()
  const navigate = useNavigate()

  const {
    formState: { isValid },
    handleSubmit,
  } = methods

  const isDisabled = specialSet !== undefined

  const hasEmptyModels = useMemo(
    () =>
      stateModels.some((model) =>
        Boolean(Object.values(model.workout_set ?? {}).length === 0),
      ),
    [stateModels],
  )

  const renderPage = useMemo(() => {
    switch (mobilePage?.toString()) {
      case '2':
        return (
          <WorkoutModelsMobileSets
            {...commonProps}
            addExerciseToWorkoutSet={addExerciseToWorkoutSet}
            addOrRemoveFieldFromWorkoutSetToExercise={
              addOrRemoveFieldFromWorkoutSetToExercise
            }
            removeExerciseFromWorkoutSet={removeExerciseFromWorkoutSet}
            removeWorkoutSet={removeWorkoutSet}
            replicateWorkoutSetFields={replicateWorkoutSetFields}
            specialSet={specialSet}
            stateModels={stateModels}
            updateWorkoutSet={updateWorkoutSet}
          />
        )
      default:
        return (
          <WorkoutModelsMobileHome
            {...commonProps}
            addWorkoutModel={addWorkoutModel}
            duplicateWorkoutModel={duplicateWorkoutModel}
            removeWorkoutModel={removeWorkoutModel}
            renameWorkoutModel={renameWorkoutModel}
            selectWorkoutModel={selectWorkoutModel}
            stateModels={stateModels}
          />
        )
    }
  }, [
    addExerciseToWorkoutSet,
    addOrRemoveFieldFromWorkoutSetToExercise,
    addWorkoutModel,
    commonProps,
    duplicateWorkoutModel,
    mobilePage,
    removeExerciseFromWorkoutSet,
    removeWorkoutModel,
    removeWorkoutSet,
    renameWorkoutModel,
    replicateWorkoutSetFields,
    selectWorkoutModel,
    specialSet,
    stateModels,
    updateWorkoutSet,
  ])

  useEffect(() => {
    if (isValid) setBlocked(false)
    else setBlocked(true)
  }, [isValid, setBlocked])

  return (
    <Fragment>
      <FormProvider {...methods}>
        {renderPage}
        <Styled.ButtonContainer>
          <Styled.CancelButton
            disabled={isDisabled}
            onClick={() => navigate(-1)}
            track={buttonClickTracking}
            trackName="navigate_to_athlete_routines"
          >
            Cancelar
          </Styled.CancelButton>

          <Styled.NextButton
            onClick={
              hasEmptyModels
                ? () => openModal('empty-model')
                : handleSubmit(onSubmit)
            }
            disabled={blocked || isDisabled}
            track={buttonClickTracking}
            trackName="update_state_and_navigate_to_workout_review"
          >
            Ir para revisão
          </Styled.NextButton>
        </Styled.ButtonContainer>
      </FormProvider>
    </Fragment>
  )
}

export default Mobile
