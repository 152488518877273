// Models
import { IAction } from 'lib/redux/models'
import {
  IWorkoutRoutine,
  IWorkoutRoutinesResponse,
  IWorkoutRoutineTemplateResponse,
} from '../models'
import { SagaIterator } from '@redux-saga/types'
import {
  IGetWorkoutRoutineTemplatePayload,
  IPartialUpdateWorkoutRoutineTemplatePayload,
  IWorkoutCreateRoutinePayload,
  IWorkoutDeleteRoutinePayload,
  IWorkoutGetRoutinePayload,
  IWorkoutLatestWorkoutPayload,
  IWorkoutPartialUpdateRoutinePayload,
  IWorkoutRoutinesPayload,
  IWorkoutUpdateRoutinePayload,
} from 'services/workoutRoutine/@types'

// Libraries
import { all, call, put, takeLatest } from 'redux-saga/effects'

// Misc
import {
  createWorkoutRoutine,
  deleteWorkoutRoutine,
  deleteWorkoutRoutineTemplate,
  getLatestWorkoutRoutine,
  getWorkoutRoutine,
  getWorkoutRoutineTemplate,
  loadWorkoutRoutines,
  loadWorkoutRoutineTemplates,
  partialUpdateWorkoutRoutine,
  partialUpdateWorkoutRoutineTemplate,
  updateWorkoutRoutine,
} from 'services/workoutRoutine'
import { cleanup } from 'storage/workoutModel/duck'
import {
  failure,
  fulfill,
  removeWorkoutRoutineTemplateFromState,
  request,
  success,
  successLoadMore,
  successLoadMoreRoutineTemplates,
  Types,
} from '../duck'
import { triggerToastError } from 'storage/general/duck'

export function* createWorkoutRoutineSaga(
  action: IAction<IWorkoutCreateRoutinePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...restPayload } = action.payload

      const response: IWorkoutRoutine = yield call(
        createWorkoutRoutine,
        restPayload,
      )

      if (response) {
        yield put(success({ workoutRoutine: response, success: true }))
        yield put(cleanup())
      }

      if (successCallback) successCallback(response)
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* deleteWorkoutRoutineSaga(
  action: IAction<IWorkoutDeleteRoutinePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { routine_pk, successCallback } = action.payload

      const response: IWorkoutRoutine = yield call(deleteWorkoutRoutine, {
        routine_pk,
      })

      if (response) {
        yield put(success({ workoutRoutine: response }))
      }

      if (successCallback) successCallback()
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* deleteWorkoutRoutineTemplateSaga(
  action: IAction<IWorkoutDeleteRoutinePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { routine_pk, successCallback } = action.payload

      const response: IWorkoutRoutine = yield call(
        deleteWorkoutRoutineTemplate,
        {
          routine_pk,
        },
      )

      yield put(removeWorkoutRoutineTemplateFromState({ routine_pk }))

      if (response) {
        yield put(success({ workoutRoutineTemplate: response }))
      }

      if (successCallback) successCallback()
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getWorkoutRoutineSaga(
  action: IAction<IWorkoutGetRoutinePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback } = action.payload
      const response: IWorkoutRoutine = yield call(
        getWorkoutRoutine,
        action.payload,
      )

      if (response) {
        yield put(success({ workoutRoutine: response }))

        successCallback?.(response)
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* partialUpdateWorkoutRoutineSaga(
  action: IAction<IWorkoutPartialUpdateRoutinePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...restPayload } = action.payload

      const response: IWorkoutRoutine = yield call(
        partialUpdateWorkoutRoutine,
        restPayload,
      )

      if (response) {
        yield put(success({ workoutRoutine: response }))
      }

      if (successCallback) successCallback(response)
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* updateWorkoutRoutineSaga(
  action: IAction<IWorkoutUpdateRoutinePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...restPayload } = action.payload

      const response: IWorkoutRoutine = yield call(
        updateWorkoutRoutine,
        restPayload,
      )

      if (response) {
        yield put(success({ workoutRoutine: response, success: true }))
      }

      if (successCallback) {
        successCallback(response)
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getLatestWorkoutRoutineSaga(
  action: IAction<IWorkoutLatestWorkoutPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IWorkoutRoutine = yield call(
        getLatestWorkoutRoutine,
        action.payload,
      )

      if (response) {
        yield put(success({ latestWorkoutRoutine: response }))
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadWorkoutRoutinesSaga(
  action: IAction<IWorkoutRoutinesPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { results, ...paginatedResponse }: IWorkoutRoutinesResponse =
        yield call(loadWorkoutRoutines, action.payload)
      yield put(success({ ...paginatedResponse, workoutRoutines: results }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadWorkoutRoutineTemplatesSaga(
  action: IAction<IWorkoutRoutinesPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { results, ...paginatedResponse }: IWorkoutRoutineTemplateResponse =
        yield call(loadWorkoutRoutineTemplates, action.payload)
      yield put(
        success({ ...paginatedResponse, workoutRoutineTemplates: results }),
      )
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadMoreWorkoutRoutinesSaga(
  action: IAction<IWorkoutRoutinesPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IWorkoutRoutinesResponse = yield call(
        loadWorkoutRoutines,
        action.payload,
      )
      yield put(successLoadMore(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadMoreWorkoutRoutinesTemplatesSaga(
  action: IAction<IWorkoutRoutinesPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IWorkoutRoutineTemplateResponse = yield call(
        loadWorkoutRoutineTemplates,
        action.payload,
      )
      yield put(successLoadMoreRoutineTemplates(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getWorkoutRoutineTemplateSaga(
  action: IAction<IGetWorkoutRoutineTemplatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IWorkoutRoutine = yield call(
        getWorkoutRoutineTemplate,
        payload,
      )
      yield put(success({ workoutRoutineTemplate: response }))
      successCallback?.(response)
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* partialUpdateWorkoutRoutineTemplateSaga(
  action: IAction<IPartialUpdateWorkoutRoutineTemplatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IWorkoutRoutine = yield call(
        partialUpdateWorkoutRoutineTemplate,
        payload,
      )
      yield put(success({ workoutRoutineTemplate: response }))
      successCallback?.(response)
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* workoutRoutineSaga(): SagaIterator {
  yield all([
    takeLatest(Types.CREATE_WORKOUT_ROUTINE, createWorkoutRoutineSaga),
    takeLatest(Types.DELETE_WORKOUT_ROUTINE, deleteWorkoutRoutineSaga),
    takeLatest(
      Types.DELETE_WORKOUT_ROUTINE_TEMPLATE,
      deleteWorkoutRoutineTemplateSaga,
    ),
    takeLatest(Types.GET_LATEST_WORKOUT_ROUTINE, getLatestWorkoutRoutineSaga),
    takeLatest(Types.GET_DETAIL_WORKOUT_ROUTINE, getWorkoutRoutineSaga),
    takeLatest(
      Types.PARTIAL_UPDATE_WORKOUT_ROUTINE,
      partialUpdateWorkoutRoutineSaga,
    ),
    takeLatest(Types.UPDATE_WORKOUT_ROUTINE, updateWorkoutRoutineSaga),
    takeLatest(Types.GET_LIST_WORKOUT_ROUTINE, loadWorkoutRoutinesSaga),
    takeLatest(
      Types.GET_LIST_WORKOUT_ROUTINE_TEMPLATE,
      loadWorkoutRoutineTemplatesSaga,
    ),
    takeLatest(
      Types.GET_LIST_MORE_WORKOUT_ROUTINE,
      loadMoreWorkoutRoutinesSaga,
    ),
    takeLatest(
      Types.GET_LIST_MORE_WORKOUT_ROUTINE_TEMPLATE,
      loadMoreWorkoutRoutinesTemplatesSaga,
    ),
    takeLatest(
      Types.GET_WORKOUT_ROUTINE_TEMPLATE,
      getWorkoutRoutineTemplateSaga,
    ),
    takeLatest(
      Types.PARTIAL_UPDATE_WORKOUT_ROUTINE_TEMPLATE,
      partialUpdateWorkoutRoutineTemplateSaga,
    ),
  ])
}
