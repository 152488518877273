// Models
import { TPlanPeriodicity } from 'models'

// Libraries
import { Moment } from 'moment'

// Misc
import { capitalizeFirstLetter } from '../string'

// Constants
const DATE_LANGUAGE = 'pt-BR'

export function extractDate(date: string | null) {
  if (date?.includes('/')) return date?.split('/')

  if (date?.includes('-')) return date?.split('-').reverse()

  return ['0', '0', '0']
}

export const getMonthName = (date: Date, size?: 'long' | 'short') => {
  return capitalizeFirstLetter(
    date.toLocaleString(DATE_LANGUAGE, { month: size ?? 'long' }),
  )
}

export function greaterThan(start_date: string, end_date: string) {
  const [startDay, startMonth, startYear] = start_date.split('/')
  const [endDay, endMonth, endYear] = end_date.split('/')
  const startDate = new Date(`${startYear}-${startMonth}-${startDay}`)
  const endDate = new Date(`${endYear}-${endMonth}-${endDay}`)
  return endDate > startDate
}

export function intervalDateToString(
  startDate: string | null,
  endDate: string | null,
) {
  if (!startDate && !endDate) return ''

  if (startDate && !endDate) {
    const start = new Date(
      `${extractDate(startDate).reverse().join('-')} 00:01`,
    )

    return `A partir de ${start.toLocaleDateString(DATE_LANGUAGE, {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })}`
  }

  if (!startDate && endDate) {
    const end = new Date(`${extractDate(endDate).reverse().join('-')} 00:01`)

    return `Até ${end.toLocaleDateString(DATE_LANGUAGE, {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })}`
  }

  const startString = extractDate(startDate).join('/')
  const endString = extractDate(endDate).join('/')

  return `${startString} - ${endString}`
}

export function removeYearFromDateString(date: Moment) {
  return date.format('LL').replace(/[a-z]{2} \d{4}/, '')
}

export const getReferenceMonthByTimeInterval = (
  timeInterval: TPlanPeriodicity = 'monthly',
) => {
  const months = {
    monthly: 1,
    quarterly: 3,
    semiannual: 6,
    yearly: 12,
  }
  const today = new Date()
  const month = today.getMonth()
  const year = today.getFullYear()

  const currentMonth = getMonthName(today, 'short')
  const currentLongMonth = getMonthName(today)

  const lastDate = new Date(year, month - 1)
  const lastMonth = getMonthName(lastDate, 'short')

  const firstDate = new Date(year, month - months[timeInterval])
  const firstMonth = getMonthName(firstDate, 'short')

  const formattedReference = {
    quarterly: `${firstMonth}, ${lastMonth}, ${currentMonth}`,
    semiannual: `${firstMonth} a ${currentMonth}`,
    yearly: `${year}`,
    monthly: currentLongMonth,
  }

  return formattedReference[timeInterval]
}

export const getDueDate = (date: string) => {
  if (!date) {
    return ''
  }

  const today = new Date()
  const dateTarget = new Date(date)

  today.setHours(0, 0, 0, 0)
  dateTarget.setHours(0, 0, 0, 0)

  const difference = dateTarget.getTime() - today.getTime()
  const daysToDue = Math.floor(difference / (1000 * 60 * 60 * 24)) + 1

  if (daysToDue === 1) {
    return `Vence em ${daysToDue} dia`
  } else if (daysToDue > 0) {
    return `Vence em ${daysToDue} dias`
  } else if (daysToDue === 0) {
    return 'Hoje'
  } else {
    return 'Vencido'
  }
}
