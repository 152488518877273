// React
import { forwardRef } from 'react'

//Misc
import { cn } from '../../../utils/classes'

// Components
import { Props as ButtonProps } from '../BaseButton'
import StylizedIcons from '../../../components/UI/Icons/StylizedIcons'

export type Props = ButtonProps & {
  provider?: 'google' | 'facebook' | 'apple'
}

const SSOButton = forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const { provider = 'google' } = props
  return (
    <button
      ref={ref}
      type="button"
      data-testid="SSOButton"
      {...props}
      className={cn(
        'order-1 flex w-[60px] flex-1 cursor-pointer content-center justify-center ',
        'rounded-lg border border-solid border-border-input p-2 shadow-sm',
        'disabled:cursor-not-allowed disabled:opacity-50',
        'active:bg-surface-pressed',
        'focus:bg-surface-pressed',
        'hover:bg-surface-hovered',
      )}
    >
      <StylizedIcons iconName={provider} size={24} viewBox={24} />
    </button>
  )
})
SSOButton.displayName = 'SSOButton'

export default SSOButton
