// React
import { FC, useContext } from 'react'

import { ThemeContext } from 'styled-components'

import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from './styled'
import { Aligner, Skeleton } from 'heeds-ds'

const Loader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return (
    <Styled.LoadContainer data-testid="row">
      {isDesktop ? (
        <>
          <Aligner gap="16px" margin="16px 24px">
            <Styled.LoadAvatar />
            <Skeleton width="180px" height="36px" />
          </Aligner>

          <Skeleton width="80px" height="36px" margin="16px 24px" />
          <Skeleton width="60px" height="36px" margin="16px 24px" />
          <Skeleton width="60px" height="36px" margin="16px 24px" />
          <Skeleton width="100px" height="36px" margin="16px 24px" />
        </>
      ) : (
        <>
          <Styled.LoadAvatar />

          <Aligner flex="column" gap="8px">
            <Skeleton width="160px" height="16px" />
            <Skeleton width="100px" height="14px" />
          </Aligner>
        </>
      )}
    </Styled.LoadContainer>
  )
}

export default Loader
