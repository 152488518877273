// Models
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// React
import { FC } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { workoutRoutineSchema } from 'schemas'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { prepareWorkoutRoutineToPayload } from 'filters/workouts'
import { triggerCreateWorkoutRoutine } from 'storage/workoutRoutine/duck'

// Components
import { InputText, Select } from 'heeds-ds'
import { Modal } from 'components'

// Constants
import { DIFFICULTY_OPTIONS, GOAL_OPTIONS } from 'utils/constants'

type IWorkoutRoutineFormData = {
  id?: number
  name: string
  difficulty: string
  goal: string
  start_date: string
  end_date: string
  comments: string
}

type ModalNewRoutineTemplateProps = {
  callBack?: (routine: IWorkoutRoutine) => void
}

const ModalNewRoutineTemplate: FC<ModalNewRoutineTemplateProps> = ({
  callBack,
}) => {
  const methods = useForm<IWorkoutRoutineFormData>({
    resolver: yupResolver(workoutRoutineSchema),
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, reset } = methods

  const dispatch = useDispatch()

  const onSubmit = (form: IWorkoutRoutineFormData) => {
    const payloadData = prepareWorkoutRoutineToPayload(form)

    dispatch(
      triggerCreateWorkoutRoutine({
        ...payloadData,
        successCallback: callBack,
      }),
    )
  }

  return (
    <Modal
      title="Informações do modelo de rotina"
      description="Você pode escolher o nome, objetivo e o nível dessa rotina."
      maxWidth="53rem"
      onClose={reset}
      primaryButton={{
        name: 'Salvar',
        onClick: handleSubmit(onSubmit),
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="px-8">
          <div className="flex flex-col gap-2">
            <label className="font-roboto text-copy4 font-semibold text-text">
              Nome do modelo
            </label>
            <InputText
              name="name"
              margin="0"
              scale="small"
              placeholder="Digite o nome do modelo de treino"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-roboto text-copy4 font-semibold text-text">
              Objetivo do aluno
            </label>
            <Select
              name="goal"
              options={GOAL_OPTIONS || []}
              margin="8px 0 0 0"
              scale="small"
              placeholder="Selecione um objetivo"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-roboto text-copy4 font-semibold text-text">
              Nível de dificuldade
            </label>
            <Select
              name="difficulty"
              options={DIFFICULTY_OPTIONS || []}
              margin="8px 0 0 0"
              scale="small"
              placeholder="Iniciante, intermediário ou avançado?"
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default ModalNewRoutineTemplate
