// Libraries
import styled from 'styled-components'

enum EFontSizes {
  small = '14px',
  medium = '16px',
  large = '18px',
}

enum EPaddings {
  small = '12px 16px',
  medium = '15px 16px',
  large = '18px 16px',
}

type InputProps = {
  color: string
  disabled?: boolean
  scale?: 'small' | 'medium' | 'large'
  height?: number
}

type WrapperProps = {
  borderColor: string
}

export const Input = styled.textarea<InputProps>`
  background-color: transparent;
  resize: none;
  height: ${({ height }) => height}px;
  border: none;
  caret-color: #202223;
  color: ${({ theme }) => theme.colors.text.default};
  cursor: text;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ scale }) => (scale ? EFontSizes[scale] : EFontSizes.medium)};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: 100%;
  outline: none;
  padding: ${({ scale }) => (scale ? EPaddings[scale] : EPaddings.medium)};
  transition: all 0.3s ease;
  ::placeholder {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.text.disabled : theme.colors.text.subdued};
  }
  :disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.surface.disabled};
    color: ${({ theme }) => theme.colors.text.subdued};
  }
`

export const InputContainer = styled.div<WrapperProps>`
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 3px;
  display: flex;
  position: relative;
  transition: all 0.3s ease;
  &[aria-disabled='true'] {
    border: 1px solid ${({ theme }) => theme.colors.icon.disabled};
  }
`
