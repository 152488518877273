// Models
import { IAnamneseTemplate } from 'storage/assessmentAnamneseTemplate/models'
import {
  IAnamneseTemplatePayload,
  IAnamneseTemplateFormInputs,
  IFormBlock,
} from './models'

export const prepareAnamneseTemplateToPayload = (
  formData: IAnamneseTemplateFormInputs,
) => {
  return formData.parts.reduce((acc: IAnamneseTemplatePayload, field) => {
    switch (field.type) {
      case 'title': {
        const { description, ...rest } = field
        const validField = description ? field : rest
        return {
          ...acc,
          ...validField,
        }
      }
      case 'section': {
        const { description, ...rest } = field
        const validField = description ? field : rest
        return { ...acc, sections: [...(acc.sections || []), validField] }
      }
      default:
        return {
          ...acc,
          sections: [
            ...(acc.sections?.map((section, index) => {
              if (acc.sections && index === acc.sections.length - 1) {
                const { description, ...rest } = section
                const validSection = description ? section : rest
                return {
                  ...validSection,
                  questions: [
                    ...(validSection?.questions || []),
                    {
                      ...field,
                      question: field?.question || 'Pergunta vazia',
                    },
                  ],
                }
              }

              return section
            }) || [{ questions: [field] }]),
          ],
        }
    }
  }, {})
}

export const prepareAnamneseTemplateToDisplay = (
  template: IAnamneseTemplate,
) => {
  const parts = []

  const { sections, ...rest } = template

  parts.push({ ...rest, type: 'title' })

  sections.forEach((section) => {
    const { questions, ...sect } = section

    if (sect.name || sect.description) {
      parts.push({ ...sect, type: 'section' })
    }

    questions.forEach((question) => parts.push({ ...question }))
  })

  return parts as IFormBlock[]
}

export const prepareAnamneseTemplateToDuplicate = (
  template: IAnamneseTemplate,
) => {
  const { description, sections, ...rest } = template
  const validData = description ? template : rest

  return {
    ...validData,
    sections: sections.map((section) => {
      const { description, ...rest } = section
      const validaData = description ? section : rest

      return { ...validaData }
    }),
  }
}
