// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Button, FormButton, IconButton } from 'heeds-ds'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 2.4rem 4rem;
  width: 100%;
`

export const FixedContainer = styled.div`
  background: ${({ theme }) => theme.colors.background.default};
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 2.4rem 0;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 1;
`

export const Form = styled.form``

export const ExercisesCard = styled(Aligner).attrs({
  align: 'center',
  gap: '0.8rem',
  justify: 'space-between',
  padding: '1.6rem 2.4rem',
})`
  background: ${({ theme }) => theme.colors.surface.default};
  border-left: 4px solid ${({ theme }) => theme.colors.icon.default};
  border-radius: 2px;
`

export const ExercisesText = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.secondary,
  type: 'copy3',
  weight: 600,
}))``

export const ArrowButton = styled(IconButton).attrs({
  iconName: 'chevronRight',
  margin: '0',
  size: 'small',
  variation: 'main',
})``

export const ButtonContainer = styled.form`
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 4rem;
  position: sticky;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    background: ${({ theme }) => theme.colors.surface.default};
  }
`

export const CancelButton = styled(Button).attrs(() => ({
  cancel: true,
  margin: '0',
  size: 'small',
  variation: 'outlined',
}))``

export const NextButton = styled(FormButton).attrs(() => ({
  margin: '0',
  size: 'small',
}))``

export const EmptyModelContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  margin: 16px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
