// Models
import IStore from 'lib/redux/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useSelector } from 'react-redux'

// Misc
import { cn } from 'utils/helpers/classess'
import { useMediaQuery } from 'hooks'

// Components
import { Avatar, IconButton } from 'heeds-ds'

interface Props {
  openModal: () => void
  showInfo?: boolean
}

const WorkoutAthleteInfo: FC<Props> = ({ openModal, showInfo }) => {
  const { info } = useSelector((state: IStore) => state.athleteInfo)
  const { breakpoints } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  return (
    <div
      className={cn([
        'flex items-center gap-4 rounded-[8px] border border-border-input bg-surface px-6 py-4',
        'lg:w-[450px] lg:min-w-[425px] lg:px-4 lg:py-2 lg:shadow-sm',
      ])}
    >
      <Avatar
        className="aspect-square lg:h-[32px] lg:min-w-[32px]"
        name={info?.name ?? ''}
        scale={isDesktop ? 'xXSmall' : 'xSmall'}
      />

      <p className="w-full text-copy3 font-semibold">{info?.name || ''}</p>

      {showInfo && (
        <IconButton
          className="aspect-square h-8 w-[32px]"
          iconName="info"
          onClick={openModal}
          size="small"
        />
      )}
    </div>
  )
}

export default WorkoutAthleteInfo
