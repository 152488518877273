// Libraries
import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
    gap: 3.2rem;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  background-color: #fafafa;
  flex-grow: 1;
  overflow-y: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 4rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  margin-bottom: 24px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-radius: 0.8rem;
    box-shadow: 0px 4px 80px 0px rgba(43, 42, 99, 0.1);
    padding: 0 0 24px 0;
  }
`
