// Models
import { IFinancialSubscription } from 'storage/financialSubscription/models'
import {
  ICreateFinancialSubscription,
  IListFinancialSubscriptions,
  IListFinancialSubscriptionsResponse,
  ISubscriptionDeactivatePayload,
} from './@types'

// Misc
import api from 'lib/axios/api'
import { objectToQueryString } from 'lib/query-string'

export const createFinancialSubscription = async (
  formData: ICreateFinancialSubscription,
): Promise<IFinancialSubscription> => {
  try {
    const { personal } = formData
    const { data }: { data: IFinancialSubscription } = await api.post(
      `financial/personal/${personal}/subscription/`,
      formData,
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getFinancialSubscription = async (
  id: number,
): Promise<IFinancialSubscription> => {
  try {
    const { data }: { data: IFinancialSubscription } = await api.get(
      `financial/subscription/${id}/`,
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateFinancialSubscription = async (
  formData: ICreateFinancialSubscription,
): Promise<IFinancialSubscription> => {
  try {
    const { id } = formData
    const { data }: { data: IFinancialSubscription } = await api.put(
      `financial/subscription/${id}/`,
      formData,
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getFinancialSubscriptions = async (
  formData: IListFinancialSubscriptions,
): Promise<IListFinancialSubscriptionsResponse> => {
  try {
    const { profile_pk, ...query } = formData
    const { data }: { data: IListFinancialSubscriptionsResponse } =
      await api.get(`financial/personal/${profile_pk}/subscription/`, {
        params: query,
        paramsSerializer: (params) => objectToQueryString(params),
      })
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const deactivateFinancialSubscription = async (
  formData: ISubscriptionDeactivatePayload,
): Promise<IFinancialSubscription> => {
  try {
    const { id, ...restForm } = formData
    const response = await api.patch(
      `financial/subscription/${id}/deactivate/`,
      restForm,
    )
    const data: IFinancialSubscription = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const activateFinancialSubscription = async (
  formData: ISubscriptionDeactivatePayload,
): Promise<IFinancialSubscription> => {
  try {
    const { id, ...restForm } = formData
    const response = await api.patch(
      `financial/subscription/${id}/activate/`,
      restForm,
    )
    const data: IFinancialSubscription = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
