// Models
import { IProfileState } from 'storage/profile/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect, useLayoutEffect } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMenu } from 'hooks'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { triggerUserInfo } from 'storage/profile/duck'
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import { Aligner, Body, Button, Icon } from 'heeds-ds'
import { PendingSummary, InsidersCard } from 'components'

const HomePage: FC = () => {
  const { info } = useSelector<IStore, IProfileState>((state) => state.profile)
  const { setPagename } = useMenu()
  const { has_appointment_time, has_plans, has_completed_profile_data } =
    info || {}

  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fullName = info?.name
  const hasCompleteProfile = Boolean(has_completed_profile_data)
  const hasCompleteSettings =
    Boolean(has_appointment_time) || Boolean(has_plans)

  const getIconColor = (condition: boolean) => {
    return condition
      ? theme.colors.text.primary
      : theme.colors.interactive.criticalDisabled
  }

  const navigateToAthleteCreation = () =>
    navigate(generatePath(urls.athleteGeneral, { id: 'cadastro' }))

  const pendingSummary = [
    {
      hideButton: hasCompleteProfile,
      title: 'Meu perfil',
      text: 'Adicione mais algumas informações pessoais para a gente te conhecer melhor!',
      buttonText: 'Ir para meu perfil',
      onClick: () => navigate(urls.profileGeneral),
      track: buttonClickTracking,
      trackName: 'navigate_to_profile_general',
      colorIcon: getIconColor(hasCompleteProfile),
    },
    {
      hideButton: hasCompleteSettings,
      title: 'Configurações',
      text: 'Nesse espaço, você pode adicionar seu horário de trabalho e os planos oferecidos aos seus alunos.',
      buttonText: 'Ir para configurações',
      onClick: () => navigate(urls.profileSchedule),
      track: buttonClickTracking,
      trackName: 'navigate_to_schedules',
      colorIcon: getIconColor(hasCompleteSettings),
    },
  ]

  useEffect(() => {
    dispatch(triggerUserInfo())
  }, [dispatch])

  useLayoutEffect(() => {
    return () => setPagename('Dashboard')
  }, [setPagename])

  return (
    <Styled.Container>
      <Styled.Header>
        <Aligner align="center">
          <Styled.Name>Olá, </Styled.Name>
          <Styled.Name weight={700}>{fullName}</Styled.Name>
        </Aligner>
        {/* TODO: Notifications */}
        {/* <IconButton
          iconName="notificationsNone"
          size={isDesktop ? 'medium' : 'small'}
          margin="0"
        /> */}
      </Styled.Header>
      <Styled.Content>
        <Styled.Block>
          <Styled.Title>Complete seu perfil!</Styled.Title>

          {pendingSummary.map((card, index) => (
            <PendingSummary key={index} {...card} />
          ))}
        </Styled.Block>

        <Styled.Block>
          <Styled.Title>Adicione seus alunos!</Styled.Title>

          <Body
            color={theme.colors.text.subdued}
            margin="0.8rem 0 0 0"
            type="copy4"
            weight={500}
          >
            Ao adicionar os seus alunos ao aplicativo, você terá todas as
            informações, suas rotinas de treino e financeiro em um único lugar.
          </Body>

          <Button
            onClick={navigateToAthleteCreation}
            size="small"
            track={buttonClickTracking}
            trackName="navigate_to_create_athlete"
            className="mt-8"
          >
            <Icon iconName="add" color={theme.colors.background.default} />
            adicionar aluno
          </Button>
        </Styled.Block>
        <InsidersCard />
      </Styled.Content>
    </Styled.Container>
  )
}

export default HomePage
