// Models
import { EWorkoutDifficulty } from 'models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { intervalDateToString, getDueDate } from 'utils/helpers/date'

// Components
import * as Styled from './styled'
import { Aligner, Avatar, Swipe } from 'heeds-ds'

export type Props = {
  onClick: () => void
  openOptions: () => void
  routine: IWorkoutRoutine
}

const Mobile: FC<Props> = ({ onClick, openOptions, routine }) => {
  const { colors } = useContext(ThemeContext)

  const { athlete_name, difficulty, name, start_date, end_date } = routine

  return (
    <Swipe
      active
      actions={[
        {
          icon: 'moreHorizontal',
          color: colors.interactive.hovered,
          onClick: openOptions,
        },
      ]}
      maxHeight="12.9rem"
    >
      <Styled.Container data-testid="routine-item" onClick={onClick}>
        <Aligner gap="1.6rem" align="center">
          <Avatar name={athlete_name || ''} scale="small" />

          <Aligner flex="column" gap="0.8rem" justify="center">
            <Styled.Title>{athlete_name || ''}</Styled.Title>

            <Styled.Text>{name}</Styled.Text>

            <Styled.Text>{EWorkoutDifficulty[difficulty]}</Styled.Text>
            <Styled.Text>{getDueDate(end_date)}</Styled.Text>
            <Styled.Text>
              {intervalDateToString(start_date, end_date)}
            </Styled.Text>
          </Aligner>
        </Aligner>
      </Styled.Container>
    </Swipe>
  )
}

export default Mobile
