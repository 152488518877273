// Models
import {
  EFirebaseProviderOptions,
  IAuthState,
  IRegisterPayload,
} from 'storage/auth/models'
import { TypeUser } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect, useState } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { authAthleteSignupSchema } from 'schemas'
import { buttonClickTracking } from 'utils/tracking'
import { cleanup, triggerLogin, triggerRegister } from 'storage/auth/duck'
import { urls } from 'routes/paths'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Body,
  Checkbox,
  FormButton,
  InputText,
  LogoFull,
  PasswordValidator,
  SSOButton,
} from 'heeds-ds'

// Assets
import backgroundImage from 'assets/images/athlete-signup-background-01.svg'
import logoFull from 'assets/images/logo-cadu-beta.svg'

// Constants
const TERMS_OF_USE = 'https://get.cadu.fit/termos-de-uso/'
const PRIVACY_POLICE = 'https://get.cadu.fit/politica-de-privacidade/'

interface IFormInputs {
  name: string
  email: string
  password: string
}

interface IFormFields {
  name: string
  email: string
  password: string
  readAndAccepted: boolean
}

const AthleteSignup: FC = () => {
  const { error } = useSelector<IStore, IAuthState>(({ auth }) => auth)
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.desktopSmall}px)`,
  )

  const [passwordValid, setPasswordValid] = useState<boolean>()

  const inviteToken = String(searchParams.get('token'))

  const methods = useForm<IFormFields>({
    resolver: yupResolver(authAthleteSignupSchema),
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const {
    formState: { isValid },
    handleSubmit,
  } = methods

  const submitLogin = (
    provider: EFirebaseProviderOptions,
    data?: IFormInputs,
  ) => {
    const to = generatePath(urls.athleteSignupConfirmation, {
      token: 'acesso',
    })

    const payload = {
      ...data,
      provider: provider,
      redirection: { navigate, to },
      type: 'athlete' as TypeUser,
      invite_token: inviteToken,
    }

    dispatch(triggerLogin(payload))
  }

  const onSubmit = (form: IFormFields) => {
    if (passwordValid) {
      const to = generatePath(urls.athleteSignupConfirmation, {
        token: 'email',
      })

      const payload: IRegisterPayload = {
        ...form,
        provider: EFirebaseProviderOptions.EMAIL,
        type: 'athlete',
        redirection: { navigate, to },
        has_plans: false,
        has_appointment_time: false,
        invite_token: inviteToken,
      }

      dispatch(triggerRegister(payload))
    }
  }

  useEffect(() => {
    if (error?.includes('Token')) {
      navigate(
        generatePath(urls.athleteSignupConfirmation, {
          token: 'expired',
        }),
      )
    }

    return () => {
      dispatch(cleanup())
    }
  }, [dispatch, error, navigate])

  return (
    <Styled.Container>
      <Styled.Header>
        {isDesktop ? (
          <LogoFull src={logoFull} />
        ) : (
          <Styled.Title>CRIE SUA CONTA</Styled.Title>
        )}
      </Styled.Header>

      <Styled.Content>
        {isDesktop ? <Styled.LayoutImage src={backgroundImage} /> : null}

        <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
          {isDesktop ? (
            <Styled.HeaderTitle>Crie sua conta</Styled.HeaderTitle>
          ) : null}

          <FormProvider {...methods}>
            <InputText
              label="Nome Completo"
              margin="0 0 0.6rem"
              name="name"
              placeholder="Digite seu nome"
              scale="small"
            />

            <InputText
              label="E-mail"
              margin="0 0 0.6rem"
              name="email"
              placeholder="Digite seu melhor e-mail"
              scale="small"
              type="email"
            />

            <InputText
              label="Crie sua senha"
              margin="0 0 2.4rem"
              name="password"
              placeholder="Crie algo fácil de lembrar"
              scale="small"
              type="password"
            />

            <PasswordValidator
              callback={setPasswordValid}
              value={methods.watch('password')}
            />

            <Styled.CheckRow>
              <Checkbox id="readAndAccepted" name="readAndAccepted" />

              <Styled.CheckLabel htmlFor="readAndAccepted">
                Li e estou de acordo com os{' '}
                <Styled.Link href={TERMS_OF_USE}>Termos de uso</Styled.Link>
                {' e '}
                <Styled.Link href={PRIVACY_POLICE}>
                  Política de Privacidade
                </Styled.Link>
              </Styled.CheckLabel>
            </Styled.CheckRow>

            <FormButton
              disabled={!passwordValid || !isValid}
              track={buttonClickTracking}
              trackName="create_user"
              className="w-full"
            >
              Cadastrar
            </FormButton>
            <Body
              type="copy5"
              align="center"
              color={theme.colors.text.subdued}
              margin="2.4rem 0"
            >
              ou
            </Body>
            <Aligner
              gap="1.2rem"
              justify={isDesktop ? 'center' : 'space-between'}
            >
              <SSOButton
                onClick={() => submitLogin(EFirebaseProviderOptions.GOOGLE)}
                provider="google"
              />
              <SSOButton
                onClick={() => submitLogin(EFirebaseProviderOptions.FACEBOOK)}
                provider="facebook"
              />
              <SSOButton disabled provider="apple" />
            </Aligner>
          </FormProvider>
        </Styled.FormContainer>
      </Styled.Content>
    </Styled.Container>
  )
}

export default AthleteSignup
