// Models
import { SetContentProps } from '..'

// React
import { FC, Fragment, useEffect } from 'react'

// Libraries
import { useFormContext } from 'react-hook-form'

// Components
import * as Styled from '../styled'
import { Aligner, InputCheckbox } from 'heeds-ds'
import { Exercise } from '../../../'
import { GenericInput } from '../../primitives'

// Constants
import CustomFields from '../../primitives/CustomFields'

const INVALID_FIELDS = [
  'exercise',
  'fields',
  'id',
  'interval',
  'isometric_time',
  'last_execution_weight',
  'repetitions',
  'speed',
  'time',
]

type Props = Omit<SetContentProps, 'type'>

const NormalSetContent: FC<Props> = ({
  addCustomField,
  handleCheckboxChange,
  name,
  onClickImage,
  readonly,
  visible,
  workoutSet,
}) => {
  const { setValue, getFieldState, watch } = useFormContext()

  const { isDirty: someFieldIsDirty } = getFieldState(name)
  const { isDirty: repeatFieldIsDirty } = getFieldState(`${name}.repeat`)

  const prefix = `${name}.exercises.0`

  const commonInputProps = {
    fixed: true,
    prefix,
    readonly,
  }

  useEffect(() => {
    if (someFieldIsDirty && !repeatFieldIsDirty) {
      setValue(`${name}.repeat`, false)
    }
  }, [someFieldIsDirty, name, repeatFieldIsDirty, setValue])

  return (
    <Aligner flex="column" padding="1.6rem" gap="1.6rem">
      <Exercise
        exercise={workoutSet.exercises[0].exercise}
        onClick={onClickImage}
      />

      {visible && (
        <Fragment>
          <Styled.InputContent>
            <GenericInput {...commonInputProps} prefix={name} type="quantity" />

            <GenericInput {...commonInputProps} type="repetitions" />

            <GenericInput {...commonInputProps} prefix={name} type="interval" />

            <CustomFields
              addOrRemoveCustomField={addCustomField}
              index={0}
              invalidInputs={INVALID_FIELDS}
              name={name}
              readonly={readonly}
            />
          </Styled.InputContent>

          {!readonly && (
            <Styled.Label>
              <InputCheckbox
                checked={Boolean(watch(`${name}.repeat`))}
                onChange={handleCheckboxChange}
              />
              Repetir para todos os exercícios
            </Styled.Label>
          )}
        </Fragment>
      )}
    </Aligner>
  )
}

export default NormalSetContent
