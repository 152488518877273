// Models
import {
  EInstallmentStatusToPortuguese,
  EPaymentTypeToPortuguese,
  EPlanPeriodicityToPortuguese,
} from 'models'
import { IFinancialAthleteProps } from '..'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC } from 'react'

// Misc
import { BRL } from 'lib/currency'
import { cn } from 'utils/helpers/classess'
import { dateFormatEnglishToPortuguese } from 'utils/functions'

// Components
import { DropdownMenu, IconButton } from 'heeds-ds'

// Constants
import { STATUS_VARIATION } from '..'

interface Props extends IFinancialAthleteProps {
  options: TMenuDropdownOption[]
}

const Desktop: FC<Props> = ({
  installment: {
    id,
    due_date,
    payment_date,
    payment_method,
    plan_periodicity,
    status,
    value,
  },
  options,
}) => {
  const cellBaseClass = cn('flex flex-col justify-center gap-1 p-4')

  return (
    <div
      className={cn(
        'grid w-full grid-cols-[repeat(6,minmax(0,1fr))68px] border-b border-border-input',
        'text-copy4 text-text',
      )}
      data-testid={`financial-athlete-item-${id}`}
    >
      <div className={cellBaseClass}>
        <p>
          {plan_periodicity
            ? EPlanPeriodicityToPortuguese[plan_periodicity]
            : '-'}
        </p>
      </div>

      <div className={cellBaseClass}>
        <p>{BRL(value).format()}</p>
      </div>

      <div className={cellBaseClass}>
        <p>
          {payment_date ? dateFormatEnglishToPortuguese(payment_date) : '-'}
        </p>
      </div>

      <div className={cellBaseClass}>
        <p>{due_date ? dateFormatEnglishToPortuguese(due_date) : '-'}</p>
      </div>

      <div className={cellBaseClass}>
        <p>{payment_method ? EPaymentTypeToPortuguese[payment_method] : '-'}</p>
      </div>

      <div className={cellBaseClass}>
        {status && (
          <div
            className={cn(
              'w-fit rounded-full px-4 py-2',
              STATUS_VARIATION[status],
            )}
          >
            <p className={cn('text-copy4 font-semibold leading-6')}>
              {EInstallmentStatusToPortuguese[status]}
            </p>
          </div>
        )}
      </div>

      <div className={cellBaseClass} onClick={(e) => e.stopPropagation()}>
        <DropdownMenu items={options}>
          <IconButton iconName="moreVertical" size="small" />
        </DropdownMenu>
      </div>
    </div>
  )
}

export default Desktop
