// Models
import { IInstallment } from 'services/financial/@types'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from 'utils/helpers/classess'
import { useMediaQuery } from 'hooks'

// Components
import Desktop from './desktop'
import Mobile from './mobile'

// Constants
export const STATUS_VARIATION = {
  0: cn('bg-[#FFCEC9] text-tag-critical'),
  1: cn('bg-[#FFF9E4] text-tag-warning'),
  2: cn('bg-border-successDepressed text-tag-success'),
  3: cn('bg-surface-neutralHovered text-text-subdued'),
}

export interface IFinancialAthleteProps {
  installment: IInstallment
  openPaymentModal: () => void
  openReminderModal: () => void
}

const FinancialInstallmentItem: FC<IFinancialAthleteProps> = (props) => {
  const { openPaymentModal, installment } = props
  const { breakpoints } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const options: TMenuDropdownOption[] = [
    {
      disabled: String(installment.status) === '2',
      icon: 'add',
      label: 'Adicionar pagamento',
      onClick: openPaymentModal,
    },
    // TODO: descomentar quando os endpoints para notificações forem feitos
    // {
    //   icon: 'notificationsNone',
    //   label: 'Enviar lembrete',
    //   onClick: openReminderModal,
    // },
  ]

  const commonProps = {
    ...props,
    options,
  }

  return isDesktop ? <Desktop {...commonProps} /> : <Mobile {...commonProps} />
}

export default FinancialInstallmentItem
