// Models
import { useController, useFormContext } from 'react-hook-form'
import { TPlanModel, TPlanPeriodicity, TPlanType } from 'models'

// React
import { FC, InputHTMLAttributes, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'
import { Input, Wrapper, WrapperInner, Label } from './styled'
import Tag from 'heeds-ds/src/components/Tag'

export type labeledOptions = {
  label: string
  value?: string
  price?: number
  planID?: number
  frequency?: string
  type?: TPlanType
  model?: TPlanModel
  periodicity?: TPlanPeriodicity
}

type Props = InputHTMLAttributes<HTMLInputElement> & {
  disabled?: boolean
  name: string
  options?: labeledOptions[]
  textRadio?: boolean
  column?: boolean
  tagLess?: boolean
}

const RadioTag: FC<Props> = (props: Props) => {
  const { disabled, name, options, textRadio = false, column, tagLess } = props

  const { control } = useFormContext()
  const {
    field: { onChange, ref, value },
  } = useController({
    name,
    control,
  })

  const theme = useContext(ThemeContext)

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    price?: number,
    planID?: number,
    frequency?: string,
    type?: TPlanType,
    model?: TPlanModel,
    periodicity?: TPlanPeriodicity,
  ) => {
    onChange({
      label: event.currentTarget.value,
      value: price,
      planID: planID,
      frequency: frequency,
      type: type,
      model: model,
      periodicity: periodicity,
    })
  }

  const innerValue = (items: labeledOptions, index: number) => {
    return (
      <>
        <Input
          data-testid={`radioTagInput${index}`}
          disabled={disabled}
          type="radio"
          id={items.value}
          value={items.value}
          onChange={(e) =>
            handleOnChange(
              e,
              items?.price,
              items?.planID,
              items?.frequency,
              items?.type,
              items?.model,
              items?.periodicity,
            )
          }
          checked={value && value.label === items.value}
          ref={ref}
          name={name}
        />
        <Body
          type="copy4"
          color={theme.colors.text.secondary}
          weight={600}
          margin={!items.price ? '0 auto 0 16px' : '0 0 0 16px'}
        >
          {items.label}
        </Body>
        {items.price && (
          <Body
            type="copy3"
            margin={tagLess ? '0 61px 0 auto' : '0 80px 0 auto'}
          >
            R$ {items.price}
          </Body>
        )}
      </>
    )
  }

  return (
    <Wrapper textRadio={textRadio} column={column}>
      {options?.map((items, index) => {
        return (
          <WrapperInner key={index} radio textRadio={textRadio}>
            <Label htmlFor={items.value} disabled={disabled}>
              {tagLess ? (
                <Aligner align="center" margin="0 0 10px 0">
                  {innerValue(items, index)}
                </Aligner>
              ) : (
                <Tag active={value && value.label === items.value}>
                  {innerValue(items, index)}
                </Tag>
              )}
            </Label>
          </WrapperInner>
        )
      })}
    </Wrapper>
  )
}

export default RadioTag
