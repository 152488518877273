// Models
import {
  IWorkoutModelData,
  IWorkoutModelState,
} from 'storage/workoutModel/models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'
import IStore from 'lib/redux/models'
import { IWorkoutListModelPayload } from 'services/workoutModel/@types'

// React
import { FC, useCallback, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { generatePath, useNavigate } from 'react-router-dom'
import { useDebounceFunction, useFilters, useMediaQuery, useModal } from 'hooks'
import {
  triggerClearWorkoutModels,
  triggerCreateWorkoutsModel,
  triggerDeleteWorkoutsModel,
  triggerListWorkoutModelTemplate,
} from 'storage/workoutModel/duck'
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import { Button, CommonList, InputSearch } from 'heeds-ds'
import { LibraryHeader, Modal, WorkoutModelTemplateItem } from 'components'
import Loader from 'components/lists/WorkoutModelTemplateItem/loader'
import ModalNewRoutineTemplate from 'components/modals/ModalNewRoutineTemplate'

// Constants
const COLUMNS_GRID =
  'grid-cols-[repeat(3,minmax(20rem,1fr))minmax(7rem,0.33fr)]'

const COLUMNS_HEADERS = [
  { label: 'Nome do modelo' },
  { label: 'Data de criação' },
  { label: 'Criado por' },
  { label: '' },
]

const MODAL_DELETE_CONTENT = {
  title: 'Excluir modelo',
  description:
    'Você está excluindo um modelo de treino, essa ação não poderá ser desfeita. Não se preocupe, as rotinas dos alunos com esse modelo não serão afetadas. Deseja prosseguir?',
  secondaryButton: {
    name: 'Voltar',
    cancel: false,
  },
}

const page_size = 20

const LibraryWorkoutModelTemplate: FC = () => {
  const { workoutModelsTemplates: modelList, loading } = useSelector<
    IStore,
    IWorkoutModelState
  >((state) => state.workoutModel)
  const { filters, setFilter } = useFilters<IWorkoutListModelPayload>(
    window.location.search,
  )
  const { breakpoints } = useContext(ThemeContext)
  const { openModal, isVisible, closeModal } = useModal()

  const dispatch = useDispatch()
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)
  const navigate = useNavigate()

  const [selected, setSelected] = useState<IWorkoutModelData>()
  const [selectedModels, setSelectedModels] = useState<IWorkoutModelData[]>([])

  const handleRemoveWorkoutModel = () => {
    setSelectedModels([])
    selected &&
      dispatch(
        triggerDeleteWorkoutsModel({
          model_pk: selected.id,
          isATemplate: true,
        }),
      )
    closeModal()
  }

  const handleEndReached = () => {
    if (modelList && !loading && modelList?.next) {
      dispatch(
        triggerListWorkoutModelTemplate({
          ...filters,
          page_size,
        }),
      )
    }
  }

  const handleSelectedWorkouts = (item: IWorkoutModelData) => {
    const itemIndex = selectedModels.findIndex(
      (existingItem) => existingItem.id === item.id,
    )
    if (itemIndex > -1) {
      return setSelectedModels(
        selectedModels.filter((existingItem) => existingItem.id !== item.id),
      )
    } else {
      return setSelectedModels([...selectedModels, item])
    }
  }

  const handleSelectedAllWorkouts = (item: IWorkoutModelData[]) => {
    if (selectedModels.length > 0) {
      return setSelectedModels([])
    }
    return setSelectedModels(item)
  }

  const renderModelItem = useCallback(
    (item: IWorkoutModelData) => {
      const handleEdit = () => {
        dispatch(triggerClearWorkoutModels())
        navigate(
          generatePath(urls.libraryWorkoutModelCreateEdit, { id: item.id }),
        )
      }
      return (
        <WorkoutModelTemplateItem
          key={item.id}
          modelTemplate={item}
          onClick={handleEdit}
          onEdit={handleEdit}
          onRemove={() => {
            setSelected(item)
            openModal('delete-modal')
          }}
        />
      )
    },
    [dispatch, navigate, openModal],
  )

  const handleReloadAthletes = useCallback(
    (newFilters: IWorkoutListModelPayload) => {
      const query = {
        ...newFilters,
        page_size,
      }
      dispatch(triggerListWorkoutModelTemplate(query))
    },
    [dispatch],
  )

  const handleCreateRoutineTemplate = async (routine: IWorkoutRoutine) => {
    for (let i = 0; i < selectedModels.length; i++) {
      const workoutModel = selectedModels[i]
      delete workoutModel.creation_date

      await new Promise((resolve) => {
        dispatch(
          triggerCreateWorkoutsModel({
            ...workoutModel,
            workout_set:
              workoutModel.workout_set?.map((set) => {
                return {
                  ...set,
                  exercises: set.exercises.map((setTo) => {
                    return {
                      ...setTo,
                      exercise: setTo.exercise.id,
                    }
                  }),
                }
              }) || [],
            routine_pk: routine.id,
            successCallback: resolve,
          }),
        )
      })
    }

    navigate(urls.libraryRoutinesTemplates, { replace: true })
    closeModal()
  }

  const debouncedLoad = useDebounceFunction<
    (newFilters: IWorkoutListModelPayload) => void
  >(handleReloadAthletes, 1000)

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('name', event.target.value, true)
    debouncedLoad(filters)
  }

  useEffect(() => {
    dispatch(triggerListWorkoutModelTemplate({ page_size }))
  }, [dispatch])

  return (
    <Styled.Container>
      <LibraryHeader page="Treinos">
        <InputSearch
          placeholder="Pesquisar"
          scale="small"
          onChange={onSearchChange}
          value={filters?.name || ''}
        />
      </LibraryHeader>
      <CommonList<IWorkoutModelData>
        bottomDistance={isTablet ? 'mb-0' : 'mb-24'}
        columns={COLUMNS_HEADERS}
        columnsFormat={COLUMNS_GRID}
        data={modelList?.results || []}
        hasMore={!!modelList?.next}
        loaderComponent={<Loader />}
        onEndReached={handleEndReached}
        padding="py-4 pr-4 pl-6"
        renderItem={renderModelItem}
        showHeader={isTablet}
        selectable
        onSelect={(item) => handleSelectedWorkouts(item)}
        onSelectAll={(item) => handleSelectedAllWorkouts(item)}
        resetSelection={selectedModels.length === 0}
        listEmptyComponent={
          <Styled.EmptyContainer>
            <Styled.EmptyText>
              Você ainda não cadastrou nenhum treino novo.
            </Styled.EmptyText>
          </Styled.EmptyContainer>
        }
      />
      {selectedModels.length > 0 && (
        <div className="absolute bottom-0 z-[5] flex w-full justify-center border-t border-border-input bg-surface py-6 xl:relative xl:justify-end xl:py-4 xl:pr-10">
          <Button onClick={() => openModal('create-model-modal')} size="small">
            Novo modelo de rotina
          </Button>
        </div>
      )}
      {isVisible === 'create-model-modal' && (
        <Modal
          icon="info"
          maxWidth="650px"
          title="Criar modelo de rotina"
          description="Você está criando um modelo de rotina com os treinos selecionados, esse modelo ficará salvo em outra página. Deseja prosseguir?"
          primaryButton={{
            name: 'Sim, quero criar',
            onClick: () => openModal('create_new_routine_modal'),
          }}
          secondaryButton={{ name: 'voltar', cancel: false }}
        />
      )}
      {isVisible === 'delete-modal' && (
        <Modal
          {...MODAL_DELETE_CONTENT}
          icon="delete"
          maxWidth="53rem"
          onClose={() => setSelected(undefined)}
          primaryButton={{
            name: 'Sim, quero excluir',
            cancel: true,
            onClick: handleRemoveWorkoutModel,
          }}
        />
      )}
      {isVisible === 'create_new_routine_modal' && (
        <ModalNewRoutineTemplate callBack={handleCreateRoutineTemplate} />
      )}
    </Styled.Container>
  )
}

export default LibraryWorkoutModelTemplate
