// React
import { FC, useContext } from 'react'

// Libraries
import { ChartOptions } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { ThemeContext } from 'styled-components'

// Misc
import { BRL } from 'lib/currency'
import { cn } from 'utils/helpers/classess'

// Components
import * as Styled from './styled'
import { Aligner } from 'heeds-ds'

// Constants
const CHART_OPTIONS: ChartOptions<'doughnut'> = {
  animation: false,
  cutout: '77%',
  maintainAspectRatio: false,
  plugins: {
    tooltip: { enabled: false },
    legend: { display: false },
  },
  responsive: true,
  spacing: -2.5,
}

type Props = {
  className?: string
  received?: number
  reference: string
  toReceive?: number
  width?: string
}

const FinancialAmountReceived: FC<Props> = ({
  className,
  received,
  reference,
  toReceive,
}) => {
  const { colors } = useContext(ThemeContext)

  return (
    <div
      className={cn(
        'flex w-full flex-col gap-4 rounded-[4px] border border-border-input',
        'bg-surface p-4 shadow-sm',
        className,
      )}
      data-testid="financial-amount-received-container"
    >
      <Aligner justify="space-between">
        <Styled.Title>Valor Recebido</Styled.Title>

        <Styled.Month>{reference}</Styled.Month>
      </Aligner>

      <Styled.Content>
        <Styled.ChartContainer>
          <Styled.Chart>
            {
              <Doughnut
                options={CHART_OPTIONS}
                data={{
                  datasets: [
                    {
                      data: [toReceive || -1, received],
                      backgroundColor: [
                        colors.icon.disabled,
                        colors.text.secondary,
                      ],
                      borderRadius: [0, 5],
                      borderWidth: [0, 0],
                    },
                  ],
                }}
              />
            }
          </Styled.Chart>
        </Styled.ChartContainer>

        <Styled.ContentLegend>
          <Aligner gap="0.8rem" align="center">
            <Styled.LegendCircle color={colors.text.secondary} />

            <Styled.LegendText
              data-testid={`financial-amount-received-label-0`}
            >
              Recebido
            </Styled.LegendText>
            <Styled.Value data-testid={`financial-amount-received-value-0`}>
              {BRL(received).format()}
            </Styled.Value>
          </Aligner>

          <Aligner gap="0.8rem" align="center">
            <Styled.LegendCircle color={colors.icon.disabled} />

            <Styled.LegendText
              data-testid={`financial-amount-received-label-1`}
            >
              Falta receber
            </Styled.LegendText>

            <Styled.Value data-testid={`financial-amount-received-value-1`}>
              {BRL(toReceive).format()}
            </Styled.Value>
          </Aligner>
        </Styled.ContentLegend>
      </Styled.Content>
    </div>
  )
}

export default FinancialAmountReceived
