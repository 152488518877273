// Libraries
import { ClassValue, clsx } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

// Constants
import { THEME_CUSTOM_COLORS, THEME_CUSTOM_FONT_SIZES } from 'utils/constants'

const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [{ text: THEME_CUSTOM_FONT_SIZES }],
      'text-color': [{ text: THEME_CUSTOM_COLORS }],
    },
  },
})

export const cn = (...indexs: ClassValue[]) => customTwMerge(clsx(indexs))
