// Models
import { TFinancialNotices } from 'storage/financial/models'

// React
import { FC } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'

// Components
import * as Styled from './styled'

type Props = {
  className?: string
  notices?: TFinancialNotices[]
}

const FinancialNotices: FC<Props> = ({ className }) => {
  const idPrefix = 'financial-notices'

  return (
    <div
      className={cn(
        'flex w-full flex-col gap-6 rounded-[4px] border border-border-input',
        'bg-surface p-4 pb-0 shadow-sm lg:min-w-full',
        className,
      )}
      data-testid={idPrefix}
    >
      <Styled.ContainerTitle>Avisos</Styled.ContainerTitle>

      <Styled.EmptyContainer>
        <Styled.EmptyText>Ainda não há avisos.</Styled.EmptyText>
      </Styled.EmptyContainer>
    </div>
  )
}

export default FinancialNotices
