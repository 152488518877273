// Libraries
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 0 0 16rem;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 2.4rem;
  }
`

export const FormContainer = styled.form`
  display: flex;
  position: relative;
  width: 100%;
  div {
    transition: none;
  }
`

export const FormCardInner = styled.div`
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 50%;
    margin-bottom: 73px;
    div {
      justify-content: flex-start;
    }
  }
`
