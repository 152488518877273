// Libraries
import styled from 'styled-components'

type Props = {
  color?: string
}

export const Container = styled.div<Props>`
  width: 100%;
  padding: 2.4rem;
  background-color: ${({ color }) => (color ? color : 'transparent')};
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  justify-items: center;
  align-items: center;
  gap: 1.6rem;
  align-items: flex-start;
  border-radius: ${({ color }) => (color ? '0 0 50px 50px' : '0')};
  box-shadow: ${({ color }) =>
    color ? '2px 2px 5px 1px rgba(205, 205, 205, 0.25)' : '0'};
  margin-bottom: ${({ color }) => (color ? 16 : 0)}px;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const Pagetitle = styled.h1`
  color: ${({ theme }) => theme.colors.text.default};
  font-family: ${({ theme }) => theme.fontFamily.barlow};
  font-size: 1.8rem;
  font-weight: 800;
  letter-spacing: 1.8px;
  text-align: center;
  text-transform: uppercase;
`
