// Models
import { IAthleteItem } from 'services/athletes/@types'
import {
  EPlanModelToPortuguese,
  EPlanPeriodicityToPortuguese,
  TAthleteStatus,
} from 'models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useContext, useMemo } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { dateFormatEnglishToPortuguese } from 'utils/functions'
import {
  ConvertRecurringTime,
  handleDisplayDays,
  handleSelectedDays,
} from 'utils/calendarUtils'
import { generatePath } from 'react-router-dom'
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Avatar,
  Body,
  DropdownMenu,
  IconButton,
  LinkButton,
} from 'heeds-ds'
import { StatusTag } from 'components'

export type Props = {
  handleOptions: (
    id: number,
    subscriptionId: number,
    status?: TAthleteStatus,
  ) => TMenuDropdownOption[]
  item: IAthleteItem
  onClick: (id: number) => void
}

const Desktop: FC<Props> = ({
  handleOptions,
  item: {
    id,
    name,
    subscription_end_date,
    plan_model,
    plan_periodicity,
    recurring_schedule,
    status,
    subscription_pk,
  },
  onClick,
}) => {
  const theme = useContext(ThemeContext)

  const expired = new Date() > new Date(subscription_end_date || '')
  const recurringDays = recurring_schedule?.[0]
  const days = useMemo(
    () =>
      recurringDays
        ? handleDisplayDays(
            handleSelectedDays(ConvertRecurringTime(recurringDays)),
          )
        : '-',
    [recurringDays],
  )
  const options = useMemo(
    () => handleOptions(id, subscription_pk || 0, status),
    [handleOptions, id, status, subscription_pk],
  )

  return (
    <Styled.Row data-testid="row" onClick={() => onClick(id)}>
      <Aligner
        align="center"
        data-testid="columnName"
        gap="1.6rem"
        margin="16px 24px"
      >
        <Avatar disabled={!status} name={name ?? ''} scale="xSmall" />
        <Aligner flex="column" gap="4px">
          <p className="break-words text-copy4 font-bold text-text">{name}</p>

          {plan_model && (
            <Body type="copy4" color={theme.colors.text.subdued}>
              {EPlanModelToPortuguese[plan_model]}
            </Body>
          )}
        </Aligner>
      </Aligner>

      <Styled.Cell data-testid="columnPlanModel">
        <Body type="copy4" weight={500}>
          {plan_model ? (plan_model === 'online' ? 'Online' : days) : '-'}
        </Body>
      </Styled.Cell>

      <Styled.Cell data-testid="columnPlanType">
        {plan_periodicity || status === 'invited' ? (
          <Body type="copy4" weight={500}>
            {plan_periodicity
              ? EPlanPeriodicityToPortuguese[plan_periodicity]
              : '-'}
          </Body>
        ) : (
          <LinkButton
            to={generatePath(urls.athleteFinancial, { id })}
            variation="borderless"
            size="xsmall"
            onlyIcon
          >
            Ir para planos
          </LinkButton>
        )}
      </Styled.Cell>

      <Styled.Cell data-testid="columnDueDate">
        <Body
          type="copy4"
          weight={500}
          color={theme.colors.text[expired ? 'critical' : 'secondary']}
        >
          {subscription_end_date
            ? dateFormatEnglishToPortuguese(subscription_end_date)
            : '-'}
        </Body>
      </Styled.Cell>

      {status && <StatusTag status={status} margin="16px 24px" />}

      <Styled.Cell onClick={(e) => e.stopPropagation()}>
        {status && status !== 'invited' && (
          <DropdownMenu items={options}>
            <IconButton
              iconName="moreVertical"
              size="small"
              track={buttonClickTracking}
              trackName="open_athlete_item_options"
            />
          </DropdownMenu>
        )}
      </Styled.Cell>
    </Styled.Row>
  )
}

export default Desktop
