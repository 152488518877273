//Libraries
import { PDFDownloadLink } from '@react-pdf/renderer'
import { FC, ReactElement, useContext } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'

// Components
import { Aligner, Button, Icon } from 'heeds-ds'

// Assets
import { ThemeContext } from 'styled-components'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PDF: ReactElement<any>
  errorMessage?: string
  loadingMessage?: string
  buttonName?: string
  fileName?: string
  size?: 'xsmall' | 'small' | 'medium' | 'large'
  className?: string
}

const PdfDownloadButton: FC<Props> = (props: Props) => {
  const {
    PDF,
    buttonName = 'BAIXAR PDF',
    errorMessage = 'PDF NÃO ENCONTRADO',
    loadingMessage = 'CARREGANDO PDF...',
    fileName,
    className,
    size,
  } = props
  const theme = useContext(ThemeContext)
  return (
    <Aligner data-testid="downloadPDF" width="auto" className={className}>
      <Button
        size={size}
        track={buttonClickTracking}
        trackName="download_pdf"
        variation="borderless"
      >
        <Icon iconName="download" color={theme.colors.interactive.default} />
        <PDFDownloadLink
          document={PDF}
          fileName={`${fileName ? fileName : 'fileName'}.pdf`}
          style={{
            textDecoration: 'none',
            color: theme.colors.interactive.default,
          }}
        >
          {({ loading, error }) =>
            error ? errorMessage : loading ? loadingMessage : buttonName
          }
        </PDFDownloadLink>
      </Button>
    </Aligner>
  )
}

export default PdfDownloadButton
