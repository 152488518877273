// Libraries
import styled, { css } from 'styled-components'

// Components
import { Body, Icon, Skeleton } from 'heeds-ds'

// Assets
import workoutImage from 'assets/images/exercise-223.png'

type Props = {
  endLine?: boolean
  margin?: string
  padding?: string
  shadow?: boolean
  thumbnail?: string
}

export const CheckIcon = styled(Icon).attrs((props) => ({
  iconName: 'circleCheck',
  color: props.theme.colors.icon.success,
  size: 20,
}))``

export const Container = styled.div<Props>`
  ${({ theme, margin }) => css`
    align-items: center;
    background: ${theme.colors.surface.default};
    border-radius: 8px;
    border: 1px solid ${theme.colors.border.input};
    display: flex;
    justify-content: space-between;
    margin: ${margin || '0px'};
    padding: 1.6rem;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: 0.8rem 1.6rem;
    }
  `}
`

export const Image = styled.img`
  padding: 32px 24px 56px;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-height: 377px;
  }
`

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 0 0 16px;
  width: 100%;
`

export const LoadAvatar = styled(Skeleton)`
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  border-radius: 6px;
`

export const LoadContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.surface.default};
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 1.6rem 0.8rem 1.6rem 0;
  }
`

export const Media = styled.div<Props>`
  align-items: center;
  background: linear-gradient(rgba(43, 42, 99, 0.3), rgba(43, 42, 99, 0.3)),
    url(${({ thumbnail }) => thumbnail || workoutImage});
  background-size: cover;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  min-width: 48px;

  svg {
    filter: drop-shadow(0px 0px 1px #000000);
  }
`

export const Description = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy5',
}))``

export const PlayIcon = styled(Icon).attrs((props) => ({
  iconName: 'playArrow',
  color: props.theme.colors.icon.onPrimary,
}))``

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy4',
  weight: 700,
}))``
