// Models
import {
  IAnamneseTemplate,
  IAnamneseTemplateState,
} from 'storage/assessmentAnamneseTemplate/models'
import { FormProvider, useForm } from 'react-hook-form'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { anamneseModalNewAnamneseAnswerSchema } from 'schemas'
import { buttonClickTracking } from 'utils/tracking'
import {
  triggerLoadAnamneseTemplates,
  triggerLoadMoreAnamneseTemplates,
} from 'storage/assessmentAnamneseTemplate/duck'
import { useDebounceFunction, useMediaQuery, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Body,
  Button,
  Checkbox,
  CommonList,
  InputSearch,
  Tag,
} from 'heeds-ds'
import { Modal } from 'components/modals'

interface ISelectedAnamneses {
  label: string
  active: boolean
}

export interface IModalNewRoutineInputs {
  selectAll: boolean
  selectedAnamneses?: Record<string, ISelectedAnamneses>
}

type Props = {
  onSend: (id: string[]) => void
  width?: string
}

const page_size = 7

const ModalNewAnamneseTemplate: FC<Props> = ({ onSend, width }) => {
  const { anamneseTemplates, next, loading, error, count } = useSelector<
    IStore,
    IAnamneseTemplateState
  >(({ assessmentAnamneseTemplate }) => assessmentAnamneseTemplate)
  const { breakpoints, colors } = useContext(ThemeContext)
  const { closeModal } = useModal()
  const dispatch = useDispatch()
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  const [title, setTitle] = useState('')

  const methods = useForm<IModalNewRoutineInputs>({
    resolver: yupResolver(anamneseModalNewAnamneseAnswerSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    delayError: 800,
  })
  const {
    formState: { isValid },
    handleSubmit,
    setValue,
    watch,
  } = methods

  const selectAll = watch('selectAll')

  const stopPropagation = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const handleSend = (formData: IModalNewRoutineInputs) => {
    const selectedAnamnese = Object.values(
      formData.selectedAnamneses || [],
    ).reduce((acc: string[], athlete) => {
      if (athlete?.active) return [...acc, athlete.label]

      return acc
    }, [])
    onSend(selectedAnamnese)
    closeModal()
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
    debouncedLoad(event.target.value)
  }

  const renderItems = (item: IAnamneseTemplate) => {
    return (
      <Tag
        active={watch(`selectedAnamneses`)?.[item.id]?.active}
        key={item.id}
        margin="0 0 0.8rem"
      >
        <Aligner gap="1.6rem">
          <Checkbox
            id={`selectedAnamneses.${item.id}.active`}
            name={`selectedAnamneses.${item.id}.active`}
          />
          <Styled.EllipsisText>{item.title}</Styled.EllipsisText>
        </Aligner>
      </Tag>
    )
  }

  const handleEndReached = useCallback(() => {
    if (anamneseTemplates && !loading && next) {
      dispatch(
        triggerLoadMoreAnamneseTemplates({
          title,
          page: next,
          page_size,
        }),
      )
    }
  }, [anamneseTemplates, dispatch, loading, title, next])

  const handleReloadAnamnese = useCallback(
    () => dispatch(triggerLoadAnamneseTemplates({ title, page_size })),
    [dispatch, title],
  )

  const debouncedLoad = useDebounceFunction<(newFilters: string) => void>(
    handleReloadAnamnese,
    1000,
  )

  useEffect(() => {
    if (
      anamneseTemplates &&
      count &&
      selectAll &&
      anamneseTemplates.length < count &&
      !loading
    ) {
      handleEndReached()
    }

    const allSelected = anamneseTemplates?.reduce(
      (accumulator: Record<string, ISelectedAnamneses>, anamnese) => {
        return {
          ...accumulator,
          [anamnese.id]: {
            label: anamnese.id,
            active: selectAll,
          },
        }
      },
      {},
    )

    setValue('selectedAnamneses', allSelected)
  }, [anamneseTemplates, count, handleEndReached, loading, selectAll, setValue])

  useLayoutEffect(() => {
    if (!anamneseTemplates && !loading && !error) {
      const query = {
        title,
        page_size,
      }
      dispatch(triggerLoadAnamneseTemplates(query))
    }
  }, [anamneseTemplates, dispatch, error, loading, title])

  return (
    <Modal onClose={closeModal}>
      <FormProvider {...methods}>
        <Styled.Container onClick={stopPropagation} width={width}>
          <Styled.CardTop>
            <Styled.ModalTitle>Adicionar anamnese</Styled.ModalTitle>
          </Styled.CardTop>
          <Body type="copy1" color={colors.text.subdued}>
            Selecione a avaliação que o seu aluno responderá.
          </Body>
          <InputSearch
            onChange={onSearchChange}
            value={title}
            margin="32px 0"
            placeholder="Pesquisar"
            scale="small"
          />
          <Aligner margin="0 0 32px 0" justify="flex-end">
            <Checkbox
              id="selectAll"
              name="selectAll"
              label="Selecionar todos"
            />
          </Aligner>
          <Styled.AnamneseTemplateList>
            <CommonList<IAnamneseTemplate>
              columns={[]}
              columnsGap="gap-x-12"
              data={anamneseTemplates || []}
              hasMore={Boolean(count)}
              height="12.0rem"
              onEndReached={handleEndReached}
              renderItem={renderItems}
              padding="py-4 pr-[1px] pl-6"
              showHeader={false}
            />
          </Styled.AnamneseTemplateList>
          <Styled.ButtonsContainer>
            <Button
              cancel
              onClick={closeModal}
              track={buttonClickTracking}
              trackName={`cancel_modal_new_routine`}
              variation={isTablet ? 'borderless' : 'outlined'}
            >
              Cancelar
            </Button>
            <Button
              disabled={!isValid}
              onClick={handleSubmit(handleSend)}
              track={buttonClickTracking}
              trackName={`add_anamneses_model_modal`}
              className="w-full"
            >
              Adicionar
            </Button>
          </Styled.ButtonsContainer>
        </Styled.Container>
      </FormProvider>
    </Modal>
  )
}

export default ModalNewAnamneseTemplate
