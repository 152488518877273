// Components
import * as AuthViews from 'views/auth'
import * as AthleteViews from 'views/dashboard/athlete'
import * as FinancialViews from 'views/dashboard/financial'
import * as GeneralViews from 'views/general'
import * as LibraryViews from 'views/dashboard/library'
import * as ProfileViews from 'views/dashboard/profile'
import * as WorkoutViews from 'views/dashboard/workout'
import { SimpleContainer } from 'components/UI/Containers'
import GlobalRoutineView from 'views/dashboard/routine/GlobalRoutines'
import HomeView from 'views/dashboard/home'
import ScheduleView from 'views/dashboard/schedule'

export const urls = {
  // AUTH URLS
  forgotPassword: '/recuperar-senha',
  forgotPasswordSuccess: '/recuperar-senha-email-enviado',
  home: '/',
  login: '/login',
  resetPassword: '/redefinir-senha/:token',
  signup: '/registro',
  signupConfirmation: '/confirmacao/:token',
  signupSuccess: '/confirmacao-email-enviado',
  athleteSignup: '/registro-atleta',
  athleteSignupConfirmation: '/confirmacao-atleta/:token',
  loadingWebView: '/webview',
  // DASHBOARD URLS
  dashboard: '/dashboard',
  athletes: '/dashboard/alunos',
  financial: '/dashboard/financeiro',
  library: '/dashboard/biblioteca',
  schedule: '/dashboard/agenda',
  routines: '/dashboard/rotinas',
  // GENERAL URLS
  unauthorized: '/401',
  // PROFILE URLS
  profileGeneral: '/dashboard/perfil/dados-gerais',
  profileSchedule: '/dashboard/perfil/horarios',
  profilePlans: '/dashboard/perfil/planos',
  profileExtraWorkouts: '/dashboard/perfil/treinos-extra',
  profileStorage: '/dashboard/perfil/armazenamento',
  profilePassword: '/dashboard/perfil/mudar-senha',
  // ATHLETE URLS
  athleteProfile: '/dashboard/alunos/:id',
  athleteGeneral: '/dashboard/alunos/:id/dados-gerais',
  athleteFinancial: '/dashboard/alunos/:id/financeiro',
  athleteSchedule: '/dashboard/alunos/:id/horarios',
  athleteAssessment: '/dashboard/alunos/:id/avaliacao',
  athleteRoutines: '/dashboard/alunos/:id/rotinas',
  athleteAnamneses: '/dashboard/alunos/:id/anamneses',
  athleteRoutinesReport: '/dashboard/alunos/:id/relatorio-rotina',
  athleteWorkoutsReport: '/dashboard/alunos/:id/relatorio-treino/:model_id',
  athleteAssessmentAnamneseAnswer:
    '/dashboard/alunos/:id/avaliacao/anamneses/:anamnese_id',
  athleteAssessmentAnamneseAnswers: '/dashboard/alunos/:id/avaliacao/anamneses',
  athleteAssessmentPhysicalAssessments:
    '/dashboard/alunos/:id/avaliacao/avaliacoes-fisicas',
  athleteComments: '/dashboard/alunos/:id/comentarios',
  athleteStorage: '/dashboard/alunos/:id/arquivos',
  // FINANCIAL URLS
  financialAthlete: '/dashboard/financeiro/:id',
  //LIBRARY URLS
  libraryAthleteAnamneseCreate:
    '/dashboard/biblioteca/anamneses/nova-anamnese/:athlete_id',
  libraryAnamneseCreate: '/dashboard/biblioteca/anamneses/nova-anamnese/',
  libraryAnamneseEdit: '/dashboard/biblioteca/anamneses/:id/editar',
  libraryAnamneseDisplay:
    '/dashboard/biblioteca/anamneses/:anamnese_id/display',
  libraryAnamneseTemplates: '/dashboard/biblioteca/anamneses',
  LibraryWorkoutModels: '/dashboard/biblioteca/treinos',
  libraryWorkoutModelCreateEdit: '/dashboard/biblioteca/treinos/:id/editar',
  libraryWorkoutModelReview: '/dashboard/biblioteca/treinos/:id/revisar',

  libraryWorkoutRoutineModels: '/dashboard/biblioteca/rotinas/:id/treinos',
  libraryWorkoutRoutineReview: '/dashboard/biblioteca/rotinas/:id/revisar',

  LibraryExerciseTemplate: '/dashboard/biblioteca/exercicios',
  LibraryExerciseCreate: '/dashboard/biblioteca/exercicios/novo-exercicio',
  LibraryExerciseEdit: '/dashboard/biblioteca/exercicios/:id/editar',
  libraryWorkoutModelCreate: '/dashboard/biblioteca/treinos/novo-treino',
  libraryRoutinesTemplates: '/dashboard/biblioteca/rotinas',
  // WORKOUTS URLS
  workoutRoutine: '/dashboard/alunos/:id/rotina/:routine_id',
  workoutRoutineCreate: '/dashboard/alunos/:id/nova-rotina',
  workoutRoutineEdit: '/dashboard/alunos/:id/rotina/:routine_id/editar',
  workoutRoutineModels: '/dashboard/alunos/:id/rotina/:routine_id/novo-treino',
  workoutRoutineReview:
    '/dashboard/alunos/:id/rotina/:routine_id/revisao-de-treino',
}

export const athleteAssessments = [
  {
    path: urls.athleteAssessmentAnamneseAnswer,
    element: AthleteViews.AthleteAssessmentAnamneseAnswer,
    private: true,
  },
  {
    path: urls.athleteAssessmentAnamneseAnswers,
    element: AthleteViews.AthleteAssessmentAnamneseAnswers,
    private: true,
  },
  {
    path: urls.athleteAssessmentPhysicalAssessments,
    element: AthleteViews.AthleteAssessmentPhysicalAssessments,
    private: true,
  },
]

export const athleteRegister = [
  {
    path: urls.athleteGeneral,
    element: AthleteViews.AthleteGeneral,
    private: true,
  },
  {
    path: urls.athleteFinancial,
    element: AthleteViews.AthleteFinancial,
    private: true,
  },
  {
    path: urls.athleteSchedule,
    element: AthleteViews.AthleteSchedule,
    private: true,
  },
  {
    path: urls.athleteAssessment,
    element: AthleteViews.AthleteAssessment,
    private: true,
  },
  {
    path: urls.athleteComments,
    element: AthleteViews.AthleteComment,
    private: true,
  },
  {
    path: urls.athleteAnamneses,
    element: AthleteViews.AthleteAnamneses,
  },
  {
    path: urls.athleteStorage,
    element: SimpleContainer,
    private: true,
  },
]

export const auth = [
  {
    path: urls.home,
    element: AuthViews.Login,
  },
  {
    path: urls.login,
    element: AuthViews.Login,
  },
  {
    path: urls.signup,
    element: AuthViews.Signup,
  },
  {
    path: urls.signupConfirmation,
    element: AuthViews.SignupConfirmation,
  },
  {
    path: urls.forgotPassword,
    element: AuthViews.ForgotPassword,
  },
  {
    path: urls.forgotPasswordSuccess,
    element: AuthViews.ForgotPasswordSuccess,
  },
  {
    path: urls.resetPassword,
    element: AuthViews.ResetPassword,
  },
  {
    path: urls.signupSuccess,
    element: AuthViews.SignupSuccess,
  },
]

export const authAthlete = [
  {
    path: urls.athleteSignup,
    element: AuthViews.AthleteSignup,
  },
  {
    path: urls.athleteSignupConfirmation,
    element: AuthViews.AthleteSignupConfirmation,
  },
]

export const dashboard = [
  {
    path: urls.dashboard,
    element: HomeView,
    private: true,
  },
  {
    path: urls.athleteProfile,
    element: AthleteViews.AthleteProfile,
    private: true,
  },
  {
    path: urls.athleteRoutinesReport,
    element: AthleteViews.AthleteRoutinesReport,
  },
  {
    path: urls.athleteWorkoutsReport,
    element: AthleteViews.AthleteWorkoutsReport,
  },
  {
    path: urls.athleteRoutines,
    element: AthleteViews.AthleteRoutines,
  },
  {
    path: urls.workoutRoutine,
    element: WorkoutViews.WorkoutRoutine,
    private: true,
  },
  {
    path: urls.workoutRoutine,
    element: WorkoutViews.WorkoutRoutine,
    private: true,
  },
  {
    path: urls.athletes,
    element: AthleteViews.Athletes,
    private: true,
  },
  {
    path: urls.routines,
    element: GlobalRoutineView,
    private: true,
  },
  {
    path: urls.financial,
    element: FinancialViews.FinancialGeneral,
    private: true,
  },
  {
    path: urls.financialAthlete,
    element: FinancialViews.FinancialAthlete,
    private: true,
  },
  {
    path: urls.library,
    element: LibraryViews.LibraryHome,
    private: true,
  },
  {
    path: urls.libraryAthleteAnamneseCreate,
    element: LibraryViews.LibraryAnamneseCreate,
    private: true,
  },
  {
    path: urls.libraryAnamneseCreate,
    element: LibraryViews.LibraryAnamneseCreate,
    private: true,
  },
  {
    path: urls.LibraryExerciseCreate,
    element: LibraryViews.LibraryExerciseCreate,
    private: true,
  },
  {
    path: urls.LibraryExerciseEdit,
    element: LibraryViews.LibraryExerciseCreate,
    private: true,
  },
  {
    path: urls.libraryAnamneseDisplay,
    element: AthleteViews.AthleteAssessmentAnamneseAnswer,
    private: true,
  },
  {
    path: urls.libraryAnamneseEdit,
    element: LibraryViews.LibraryAnamneseCreate,
    private: true,
  },
  {
    path: urls.libraryWorkoutModelCreateEdit,
    element: LibraryViews.LibraryWorkoutModelCreate,
    private: true,
  },
  {
    path: urls.libraryWorkoutModelReview,
    element: LibraryViews.LibraryWorkoutModelReview,
    private: true,
  },
  {
    path: urls.libraryWorkoutRoutineModels,
    element: LibraryViews.LibraryWorkoutRoutineModel,
    private: true,
  },
  {
    path: urls.libraryWorkoutRoutineReview,
    element: LibraryViews.LibraryWorkoutRoutineModelReview,
    private: true,
  },
  {
    path: urls.schedule,
    element: ScheduleView,
    private: true,
  },
]

export const workoutRoutine = [
  {
    path: urls.workoutRoutineCreate,
    element: WorkoutViews.WorkoutCreateRoutine,
    private: true,
  },
  {
    path: urls.workoutRoutineEdit,
    element: WorkoutViews.WorkoutCreateRoutine,
    private: true,
  },
  {
    path: urls.workoutRoutineModels,
    element: WorkoutViews.WorkoutModels,
    private: true,
  },
  {
    path: urls.workoutRoutineReview,
    element: WorkoutViews.WorkoutReview,
    private: true,
  },
]

export const general = [
  {
    path: urls.unauthorized,
    element: GeneralViews.Unauthorized,
  },
]

export const library = [
  {
    path: urls.libraryRoutinesTemplates,
    element: LibraryViews.LibraryRoutinesTemplates,
    private: true,
  },
  {
    path: urls.libraryAnamneseTemplates,
    element: LibraryViews.LibraryAnamneseTemplates,
    private: true,
  },
  {
    path: urls.LibraryWorkoutModels,
    element: LibraryViews.LibraryWorkoutModelTemplate,
    private: true,
  },
  {
    path: urls.LibraryExerciseTemplate,
    element: LibraryViews.LibraryExerciseTemplate,
    private: true,
  },
]

export const profile = [
  {
    path: urls.profileGeneral,
    element: ProfileViews.ProfileGeneral,
    private: true,
  },
  {
    path: urls.profileStorage,
    element: ProfileViews.ProfileStorage,
    private: true,
  },
  {
    path: urls.profilePassword,
    element: ProfileViews.ProfilePasswordChange,
    private: true,
  },
]

export const settings = [
  {
    path: urls.profileSchedule,
    element: ProfileViews.ProfileSchedule,
    private: true,
  },
  {
    path: urls.profilePlans,
    element: ProfileViews.ProfilePlans,
    private: true,
  },
  {
    path: urls.profileExtraWorkouts,
    element: ProfileViews.ProfileExtraWorkouts,
    private: true,
  },
]

export const webview = {
  path: urls.loadingWebView,
  element: AuthViews.LoadingWebview,
}
