// Models
import { HTMLButtonProps, VarButtonProps } from './@types'

// React
import { MouseEvent, forwardRef } from 'react'

// Misc
import { buttonStyles } from './styles'
import { cn } from '../../../utils/classes'

export type ButtonSizesType = 'xsmall' | 'small' | 'medium' | 'large'

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  HTMLButtonProps &
  VarButtonProps & {
    cancel?: boolean
    className?: string
    onlyIcon?: boolean
    size?: ButtonSizesType
    tracking?: () => void
    variation?: 'main' | 'outlined' | 'borderless' | 'success'
  }

const BaseButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    cancel,
    children,
    className,
    disabled,
    onClick,
    onlyIcon,
    size,
    tracking,
    variation,
    ...restProps
  } = props

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    tracking?.()
    onClick?.(event)
  }

  return (
    <button
      ref={ref}
      data-testid="mainButton"
      onClick={handleClick}
      {...restProps}
      disabled={disabled}
      className={cn(
        buttonStyles({
          size,
          variation,
          cancel,
          onlyIcon,
          disabled,
        }),
        className,
      )}
    >
      {children}
    </button>
  )
})
BaseButton.displayName = 'BaseButton'

export default BaseButton
