// Models
import { IAuthState } from 'storage/auth/models'
import { IFinancialState } from 'storage/financial/models'
import { TModalProps } from 'views/dashboard/profile/ProfilePlans'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

// Misc
import { objectToQueryString } from 'lib/query-string'
import {
  triggerActiveProfilePlan,
  triggerDeactivateProfilePlan,
  triggerDeleteProfilePlan,
  triggerGetProfilePlan,
} from 'storage/financial/duck'
import { useModal } from 'hooks'
import { urls } from 'routes/paths'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Checkbox,
  DropdownMenu,
  IconButton,
  InputText,
  Loading,
  Swipe,
  Tabs,
  Tag,
} from 'heeds-ds'
import { ModalConfirmation, ModalDelete } from 'components'

const TABS = {
  0: 'PRESENCIAL',
  1: 'CONSULTORIA ONLINE',
}

type Props = {
  selectedPlan?: (plan: string) => void
}

const PlanTypes: FC<Props> = ({ selectedPlan }) => {
  const { userData } = useSelector<IStore, IAuthState>(({ auth }) => auth)
  const { loading } = useSelector<IStore, IFinancialState>(
    ({ financial }) => financial,
  )
  const { watch, setValue } = useFormContext()
  const { openModal, isVisible, closeModal } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [modalOptions, setModalOptions] = useState<TModalProps>({
    title: '',
    description: '',
    confirmTitle: '',
    onConfirm: () => {
      return
    },
    onCancel: () => {
      return
    },
  })

  const hourlyRateStatus =
    watch('global.online.status') === 'active' ||
    watch('global.online.status') === undefined
      ? true
      : false

  const initialPlanValues = [
    {
      label: 'Mensal',
      name: 'monthly',
      info: watch('monthly.online'),
      hasStudent: false,
    },
    {
      label: 'Trimestral',
      name: 'quarterly',
      info: watch('quarterly.online'),
      hasStudent: false,
    },
    {
      label: 'Semestral',
      name: 'semiannual',
      info: watch('semiannual.online'),
      hasStudent: false,
    },
    {
      label: 'Anual',
      name: 'yearly',
      info: watch('yearly.online'),
      hasStudent: false,
    },
  ]

  const [activeTab, setActiveTab] = useState(TABS[0])

  useEffect(() => {
    selectedPlan?.(activeTab)
  }, [activeTab, selectedPlan])

  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const inputProps = {
    displayError: false,
    margin: '0',
    placeholder: 'R$ 0,00',
    width: '15rem',
  }

  const handlePlanStatus = (type: string) => {
    const initialStatus = watch(`${type}.status`)
    const id = watch(`${type}.id`)

    setValue(
      `${type}.status`,
      initialStatus === 'active' ? 'inactive' : 'active',
    )
    closeModal()

    dispatch(
      initialStatus === 'active'
        ? triggerDeactivateProfilePlan({ id: id })
        : triggerActiveProfilePlan({ id: id }),
    )
  }

  const handleModal = (type: string) => {
    const initialStatus = watch(`${type}.status`) === 'active' ? true : false
    const hasActive = watch(`${type}.has_active_subscriptions`)
    openModal('online-plan-status-modal')
    setModalOptions(handleModalInfo(type, hasActive, initialStatus))
  }

  const handleModalInfo = (
    type: string,
    hasActive: boolean,
    initialStatus: boolean,
  ) => {
    switch (true) {
      case hasActive: {
        const splitType = type.split('.')
        const search: { plan_model?: string; plan_periodicity?: string } = {
          plan_model: 'in_person',
        }

        if (splitType[0] !== 'global') {
          search.plan_periodicity = splitType[0]
          search.plan_model = 'online'
        }

        return {
          title: 'Desativar plano',
          description:
            'O desativamento do plano só será possível quando não houver mais alunos ativos cadastrados nele.',
          confirmTitle: 'Lista de alunos',
          onConfirm: () => {
            closeModal()
            navigate(
              {
                pathname: urls.athletes,
                search: objectToQueryString(search),
              },
              { state: { planEdit: true } },
            )
          },
          onCancel: () => {
            closeModal(), setValue(`${type}.active`, true)
          },
        }
      }
      case initialStatus:
        return {
          title: `Deseja desativar esse plano?`,
          description:
            'Ele não será excluído permanentemente do nosso sistema, você poderá reativá-lo a qualquer momento.',
          confirmTitle: 'Desativar plano',
          onConfirm: () => {
            handlePlanStatus(type)
          },
        }
      default:
        return {
          title: 'Deseja ativar esse plano?',
          description:
            'Ele voltará com as informações e valores que você adicionou anteriormente.',
          confirmTitle: 'Ativar plano',
          onConfirm: () => {
            handlePlanStatus(type)
          },
        }
    }
  }

  const handleDeleteOnToggle = (type: string) => {
    const id = watch(`${type}.id`)
    const hasActive = watch(`${type}.has_active_subscriptions`)
    const initialStatus = watch(`${type}.status`) === 'active' ? true : false
    if (hasActive) {
      openModal('online-plan-status-modal')
      setModalOptions(handleModalInfo(type, hasActive, initialStatus))
      setValue(`${type}.active`, true)
    }
    if (id && !hasActive) {
      openModal('delete-online-plan-modal'),
        setModalOptions({
          title: 'Excluir',
          description:
            'Você está excluindo um plano que não possui alunos cadastrados.',
          confirmTitle: 'Excluir',
          onConfirm: () => {
            dispatch(
              triggerDeleteProfilePlan({
                id: id,
                successCallback: () => (
                  dispatch(
                    triggerGetProfilePlan({
                      profile_pk: userData?.profileId || 0,
                    }),
                  ),
                  setValue(`${type}.value.standard`, ''),
                  setValue(`${type}.active`, false)
                ),
              }),
            ),
              closeModal()
          },
          onCancel: () => {
            setValue(`${type}.active`, true)
          },
        })
    }
  }

  if (loading) {
    return <Loading active />
  }

  return (
    <Aligner flex="column">
      <Tabs
        active={activeTab}
        setActive={setActiveTab}
        tabs={Object.values(TABS)}
        className="mb-6"
        fontSize="copy5"
        showInactiveBar
      />
      {activeTab === TABS[1] ? (
        <>
          {initialPlanValues.map((plan) => {
            const planStatus =
              plan.info?.status === 'active' || plan.info?.status === undefined
                ? true
                : false
            return (
              <Styled.SelectTagWrapper key={plan.name}>
                <Swipe
                  active={plan.info?.status !== undefined && !isDesktop}
                  actions={[
                    {
                      color: planStatus
                        ? theme.colors.icon.critical
                        : theme.colors.interactive.default,
                      icon: planStatus ? 'close' : 'check',
                      onClick: () => handleModal(`${plan.name}.online`),
                    },
                  ]}
                  margin="0 0 22px 0"
                >
                  <Tag
                    id={plan.name}
                    active={watch(`${plan.name}.online.active`)}
                    disabled={!planStatus}
                    margin="0"
                    padding={
                      isDesktop
                        ? '1.6rem 18.1rem 1.6rem 1.6rem'
                        : '1.5rem 1.6rem'
                    }
                  >
                    <Aligner align="center" gap="1.6rem">
                      <Aligner
                        onClick={() =>
                          handleDeleteOnToggle(`${plan.name}.online`)
                        }
                        width="20px"
                      >
                        <Checkbox
                          label={
                            planStatus
                              ? plan.label
                              : `${plan.label}(desativado)`
                          }
                          id={`${plan.name}.online.active`}
                          name={`${plan.name}.online.active`}
                        />
                      </Aligner>
                      <Aligner
                        align="center"
                        justify="flex-end"
                        gap={isDesktop ? '117px' : '4px'}
                      >
                        <InputText
                          mask="currency"
                          name={`${plan.name}.online.value.standard`}
                          scale="small"
                          {...inputProps}
                        />
                      </Aligner>
                    </Aligner>
                  </Tag>
                </Swipe>
                <Styled.TagOptionsWrapper>
                  {plan.info?.status !== undefined && (
                    <DropdownMenu
                      items={[
                        {
                          color: planStatus
                            ? theme.colors.icon.critical
                            : theme.colors.text.default,
                          icon: planStatus ? 'close' : 'check',
                          label: `${planStatus ? 'Desativar' : 'Ativar'} plano`,
                          onClick: () => handleModal(`${plan.name}.online`),
                        },
                      ]}
                    >
                      <IconButton size="small" iconName="moreVertical" />
                    </DropdownMenu>
                  )}
                </Styled.TagOptionsWrapper>
              </Styled.SelectTagWrapper>
            )
          })}
        </>
      ) : (
        <Styled.TagWrapper>
          <Swipe
            active={watch('global.online.id') && !isDesktop}
            actions={[
              {
                color: hourlyRateStatus
                  ? theme.colors.icon.critical
                  : theme.colors.interactive.default,
                icon: hourlyRateStatus ? 'close' : 'check',
                onClick: () => handleModal('global.online'),
              },
            ]}
          >
            <Tag
              disabled={!hourlyRateStatus}
              active={watch('global.online.active')}
              padding={
                isDesktop
                  ? '1.6rem 18.1rem 1.6rem 1.6rem'
                  : '1.5rem 1.6rem 1.5rem 1.6rem'
              }
            >
              <Aligner align="center" gap="1.6rem">
                <Aligner
                  onClick={() => handleDeleteOnToggle('global.online')}
                  width="100%"
                >
                  <Checkbox
                    label={`Hora/Aula ${
                      !hourlyRateStatus ? '(desativado)' : ''
                    }`}
                    id={'global'}
                    name={'global.online.active'}
                  />
                </Aligner>
              </Aligner>
              <Aligner
                align="center"
                justify="flex-end"
                gap={isDesktop ? '117px' : '4px'}
              >
                <InputText
                  mask="currency"
                  name="global.online.value.standard"
                  scale="small"
                  {...inputProps}
                />
              </Aligner>
            </Tag>
          </Swipe>
          <Styled.TagOptionsWrapper>
            {watch('global.online.id') && (
              <DropdownMenu
                items={[
                  {
                    color: hourlyRateStatus
                      ? theme.colors.icon.critical
                      : theme.colors.text.default,
                    icon: hourlyRateStatus ? 'close' : 'check',
                    label: `${hourlyRateStatus ? 'Desativar' : 'Ativar'} plano`,
                    onClick: () => handleModal('global.online'),
                  },
                ]}
              >
                <IconButton size="small" iconName="moreVertical" />
              </DropdownMenu>
            )}
          </Styled.TagOptionsWrapper>
        </Styled.TagWrapper>
      )}
      {isVisible === 'delete-online-plan-modal' && (
        <ModalDelete
          description={modalOptions.description}
          onConfirm={modalOptions.onConfirm}
          onCancel={modalOptions.onCancel}
          onClose={modalOptions.onCancel}
          confirmTitle={modalOptions?.confirmTitle}
          title={modalOptions.title}
        />
      )}
      {isVisible === 'online-plan-status-modal' && (
        <ModalConfirmation
          width="100%"
          description={modalOptions.description}
          onConfirm={modalOptions.onConfirm}
          onCancel={modalOptions.onCancel}
          confirmTitle={modalOptions?.confirmTitle}
          title={modalOptions.title}
        />
      )}
    </Aligner>
  )
}

export default PlanTypes
