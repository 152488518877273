// Models
import { EPaymentStatusToPortuguese } from 'models'
import { IFinancialAthleteProps } from '..'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'
import { dateFormatEnglishToPortuguese } from 'utils/functions'

// Components
import { Avatar, DropdownMenu, IconButton } from 'heeds-ds'

// Constants
import { STATUS_VARIATION } from '..'

interface Props extends IFinancialAthleteProps {
  options: TMenuDropdownOption[]
}

const Mobile: FC<Props> = ({
  subscription: {
    id,
    athlete_name,
    installments_next_due_date,
    payment_status,
    status,
  },
  onClick,
  options,
}) => {
  return (
    <div
      className={cn(
        'flex w-full flex-col gap-4 rounded-3xl border border-border-input',
        'bg-surface p-4',
      )}
      data-testid={`financial-athlete-item-${id}`}
      onClick={onClick}
    >
      {payment_status && (
        <div
          className={cn(
            'w-fit rounded-full px-4 py-2',
            STATUS_VARIATION[payment_status],
          )}
        >
          <p className={cn('text-copy5 font-semibold leading-6')}>
            {EPaymentStatusToPortuguese[payment_status]}
          </p>
        </div>
      )}

      <div className="flex items-center gap-4">
        <Avatar
          disabled={status === 'inactive'}
          name={athlete_name}
          scale="small"
        />

        <div
          className={cn('mr-auto flex flex-col gap-1', {
            'opacity-60': status === 'inactive',
          })}
        >
          <p
            className={cn(
              'max-w-[200px] break-words text-copy3 font-semibold',
              'leading-7 sm:max-w-full',
            )}
          >
            {athlete_name}
          </p>
          <p className="text-copy3 text-text-subdued">
            {installments_next_due_date &&
              `Vencimento: ${dateFormatEnglishToPortuguese(
                installments_next_due_date,
              )}`}
          </p>
        </div>

        <div onClick={(e) => e.stopPropagation()}>
          <DropdownMenu items={options}>
            <IconButton iconName="moreVertical" size="small" />
          </DropdownMenu>
        </div>
      </div>
    </div>
  )
}

export default Mobile
