// Libraries
import styled from 'styled-components'

// Components
import { Body } from 'heeds-ds'

// Misc
import { theme } from 'lib/styled-components/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.4rem;
  overflow-y: auto;
  padding: 0;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    gap: 0;
  }
`

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20rem 0;
  text-align: center;
`

export const EmptyText = styled(Body).attrs(() => ({
  align: 'center',
  type: 'copy4',
  color: theme.colors.text.subdued,
}))``
