/* eslint-disable no-case-declarations */
// Models
import { EFinancialActionTypes, IFinancialState } from '../models'
import { IAction } from 'lib/redux/models'
import {
  ICreateFinancialExtraPlanPayload,
  ICreateFinancialPlanPayload,
  IFinancialExtraPlan,
  IGetFinancialExtraPlanPayload,
  IGetFinancialPlan,
  IListFinancialExtraPlansPayload,
  ILoadFinancialAthletesPayload,
  ILoadFinancialAthletesResponse,
  ILoadFinancialOverview,
  IPartialUpdateFinancialExtraPlanPayload,
  IPartialUpdateIFinancialPlan,
  IInstallmentResponse,
  IInstallmentPayload,
  ICreatePaymentPayload,
} from 'services/financial/@types'

// ACTION TYPES
export const Types = {
  CLEANUP: EFinancialActionTypes.CLEANUP,
  FAILURE: EFinancialActionTypes.FAILURE,
  FULFILL: EFinancialActionTypes.FULFILL,
  REQUEST: EFinancialActionTypes.REQUEST,
  SUCCESS: EFinancialActionTypes.SUCCESS,
  SUCCESS_LOAD_MORE: EFinancialActionTypes.SUCCESS_LOAD_MORE,
  GET_ATHLETES: EFinancialActionTypes.GET_ATHLETES,
  GET_METRICS: EFinancialActionTypes.GET_METRICS,
  GET_MORE_ATHLETES: EFinancialActionTypes.GET_MORE_ATHLETES,
  CREATE_EXTRA_PLAN: EFinancialActionTypes.CREATE_EXTRA_PLAN,
  GET_PLAN: EFinancialActionTypes.GET_PLAN,
  CREATE_PLAN: EFinancialActionTypes.CREATE_PLAN,
  UPDATE_PLAN: EFinancialActionTypes.UPDATE_PLAN,
  ACTIVATE_PLAN: EFinancialActionTypes.ACTIVATE_PLAN,
  DEACTIVATE_PLAN: EFinancialActionTypes.DEACTIVATE_PLAN,
  REMOVE_PLANS: EFinancialActionTypes.REMOVE_PLANS,
  PARTIAL_UPDATE_PLAN: EFinancialActionTypes.PARTIAL_UPDATE_PLAN,
  DELETE_PLAN: EFinancialActionTypes.DELETE_PLAN,
  GET_EXTRA_PLAN: EFinancialActionTypes.GET_EXTRA_PLAN,
  GET_EXTRA_PLANS: EFinancialActionTypes.GET_EXTRA_PLANS,
  UPDATE_EXTRA_PLAN: EFinancialActionTypes.UPDATE_EXTRA_PLAN,
  PARTIAL_UPDATE_EXTRA_PLAN: EFinancialActionTypes.PARTIAL_UPDATE_EXTRA_PLAN,
  DELETE_EXTRA_PLAN: EFinancialActionTypes.DELETE_EXTRA_PLAN,
  ADD_EXTRA_PLANS_STATE: EFinancialActionTypes.ADD_EXTRA_PLANS_STATE,
  DELETE_EXTRA_PLANS_STATE: EFinancialActionTypes.DELETE_EXTRA_PLANS_STATE,
  UPDATE_EXTRA_PLANS_STATE: EFinancialActionTypes.UPDATE_EXTRA_PLANS_STATE,
  GET_OVERVIEW: EFinancialActionTypes.GET_OVERVIEW,
  GET_INSTALLMENTS: EFinancialActionTypes.GET_INSTALLMENTS,
  GET_MORE_INSTALLMENTS: EFinancialActionTypes.GET_MORE_INSTALLMENTS,
  SUCCESS_LOAD_MORE_INSTALLMENTS:
    EFinancialActionTypes.SUCCESS_LOAD_MORE_INSTALLMENTS,
  CREATE_PAYMENT: EFinancialActionTypes.CREATE_PAYMENT,
}

// INITIAL STATE
const initialState: IFinancialState = {}

// REDUCER
export default (
  state: IFinancialState = initialState,
  action?: IAction<unknown>,
): IFinancialState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        athletes: undefined,
        metrics: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IFinancialState),
      }
    case Types.SUCCESS_LOAD_MORE:
      const { results, ...paginatedPayload } =
        action.payload as ILoadFinancialAthletesResponse
      const athletesFromState = state.athletes?.results || []
      const athletesFromPayload = results || []

      return {
        ...state,
        ...paginatedPayload,
        athletes: {
          results: [...athletesFromState, ...athletesFromPayload],
        },
      }
    case Types.ADD_EXTRA_PLANS_STATE: {
      const oldList = [...(state.extraPlans || [])]
      const newPlan = action.payload as IFinancialExtraPlan<number>
      return {
        ...state,
        extraPlans: [...oldList, newPlan],
      }
    }
    case Types.DELETE_EXTRA_PLANS_STATE: {
      const oldList = [...(state.extraPlans || [])]
      const { id } = action.payload as IGetFinancialExtraPlanPayload
      return {
        ...state,
        extraPlans: oldList.filter((plan) => plan.id !== id),
      }
    }
    case Types.REMOVE_PLANS: {
      const oldList = [...(state.planList?.results || [])]
      const { id } = action.payload as IGetFinancialPlan
      return {
        ...state,
        planList: { results: oldList.filter((plan) => plan.id !== id) },
      }
    }
    case Types.UPDATE_EXTRA_PLANS_STATE: {
      const oldList = [...(state.extraPlans || [])]
      const planEdited = action.payload as IFinancialExtraPlan<number>
      return {
        ...state,
        extraPlans: oldList.map((plan) => {
          if (plan.id === planEdited.id) {
            return planEdited
          }
          return plan
        }),
      }
    }
    case Types.SUCCESS_LOAD_MORE_INSTALLMENTS: {
      const { results, ...response } = action.payload as IInstallmentResponse
      const loadMoreAthletesFromState = state.installments?.results || []
      const loadMoreAthletesFromPayload = results || []
      return {
        ...state,
        installments: {
          ...response,
          results: [
            ...loadMoreAthletesFromState,
            ...loadMoreAthletesFromPayload,
          ],
        },
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<unknown> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<unknown> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (payload: IFinancialState): IAction<IFinancialState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const successLoadMore = (
  payload: ILoadFinancialAthletesResponse,
): IAction<ILoadFinancialAthletesResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE,
    payload,
  }
}

export const triggerLoadFinancialAthletes = (
  payload: ILoadFinancialAthletesPayload,
): IAction<ILoadFinancialAthletesPayload> => {
  return {
    type: Types.GET_ATHLETES,
    payload,
  }
}

export const triggerLoadFinancialMetrics = (): IAction<undefined> => {
  return {
    type: Types.GET_METRICS,
  }
}

export const triggerLoadMoreFinancialAthletes = (
  payload: ILoadFinancialAthletesResponse,
): IAction<ILoadFinancialAthletesResponse> => {
  return {
    type: Types.GET_MORE_ATHLETES,
    payload,
  }
}

export const triggerCreateProfileExtraPlan = (
  payload: ICreateFinancialExtraPlanPayload,
): IAction<ICreateFinancialExtraPlanPayload> => {
  return {
    type: Types.CREATE_EXTRA_PLAN,
    payload,
  }
}

export const triggerLoadProfileExtraPlan = (
  payload: IGetFinancialExtraPlanPayload,
): IAction<IGetFinancialExtraPlanPayload> => {
  return {
    type: Types.GET_EXTRA_PLAN,
    payload,
  }
}

export const triggerLoadProfileExtraPlans = (
  payload: IListFinancialExtraPlansPayload,
): IAction<IListFinancialExtraPlansPayload> => {
  return {
    type: Types.GET_EXTRA_PLANS,
    payload,
  }
}

export const triggerUpdateProfileExtraPlan = (
  payload: IFinancialExtraPlan<number>,
): IAction<IFinancialExtraPlan<number>> => {
  return {
    type: Types.UPDATE_EXTRA_PLAN,
    payload,
  }
}

export const triggerPartialUpdateProfileExtraPlan = (
  payload: IPartialUpdateFinancialExtraPlanPayload<number>,
): IAction<IPartialUpdateFinancialExtraPlanPayload<number>> => {
  return {
    type: Types.PARTIAL_UPDATE_EXTRA_PLAN,
    payload,
  }
}

export const triggerDeleteProfileExtraPlan = (
  payload: IGetFinancialExtraPlanPayload,
): IAction<IGetFinancialExtraPlanPayload> => {
  return {
    type: Types.DELETE_EXTRA_PLAN,
    payload,
  }
}

export const addExtraPlans = (
  payload: IFinancialExtraPlan<number>,
): IAction<IFinancialExtraPlan<number>> => {
  return {
    type: Types.ADD_EXTRA_PLANS_STATE,
    payload,
  }
}

export const deleteExtraPlans = (
  payload: IGetFinancialExtraPlanPayload,
): IAction<IGetFinancialExtraPlanPayload> => {
  return {
    type: Types.DELETE_EXTRA_PLANS_STATE,
    payload,
  }
}

export const triggerDeletePlans = (
  payload: IGetFinancialExtraPlanPayload,
): IAction<IGetFinancialExtraPlanPayload> => {
  return {
    type: Types.REMOVE_PLANS,
    payload,
  }
}

export const updateExtraPlans = (
  payload: IFinancialExtraPlan<number>,
): IAction<IFinancialExtraPlan<number>> => {
  return {
    type: Types.UPDATE_EXTRA_PLANS_STATE,
    payload,
  }
}

export const triggerGetProfilePlan = (
  payload: ICreateFinancialPlanPayload,
): IAction<ICreateFinancialPlanPayload> => {
  return {
    type: Types.GET_PLAN,
    payload,
  }
}

export const triggerCreateProfilePlan = (
  payload: ICreateFinancialPlanPayload,
): IAction<ICreateFinancialPlanPayload> => {
  return {
    type: Types.CREATE_PLAN,
    payload,
  }
}

export const triggerActiveProfilePlan = (
  payload: IGetFinancialPlan,
): IAction<IGetFinancialPlan> => {
  return {
    type: Types.ACTIVATE_PLAN,
    payload,
  }
}

export const triggerDeactivateProfilePlan = (
  payload: IGetFinancialPlan,
): IAction<IGetFinancialPlan> => {
  return {
    type: Types.DEACTIVATE_PLAN,
    payload,
  }
}

export const triggerUpdateProfilePlan = (
  payload: ICreateFinancialPlanPayload,
): IAction<ICreateFinancialPlanPayload> => {
  return {
    type: Types.UPDATE_PLAN,
    payload,
  }
}

export const triggerPartialUpdateProfilePlan = (
  payload: IPartialUpdateIFinancialPlan,
): IAction<IPartialUpdateIFinancialPlan> => {
  return {
    type: Types.PARTIAL_UPDATE_PLAN,
    payload,
  }
}

export const triggerDeleteProfilePlan = (
  payload: IGetFinancialPlan,
): IAction<IGetFinancialPlan> => {
  return {
    type: Types.DELETE_PLAN,
    payload,
  }
}

export const triggerLoadFinancialOverview = (
  payload: ILoadFinancialOverview,
): IAction<ILoadFinancialOverview> => {
  return {
    type: Types.GET_OVERVIEW,
    payload,
  }
}

export const triggerLoadInstallments = (
  payload: IInstallmentPayload,
): IAction<IInstallmentPayload> => {
  return {
    type: Types.GET_INSTALLMENTS,
    payload,
  }
}

export const triggerLoadMoreInstallments = (
  payload: IInstallmentPayload,
): IAction<IInstallmentPayload> => {
  return {
    type: Types.GET_MORE_INSTALLMENTS,
    payload,
  }
}

export const successLoadMoreInstallments = (
  payload: IInstallmentResponse,
): IAction<IInstallmentResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE_INSTALLMENTS,
    payload,
  }
}

export const triggerCreatePayment = (
  payload: ICreatePaymentPayload,
): IAction<ICreatePaymentPayload> => {
  return {
    type: Types.CREATE_PAYMENT,
    payload,
  }
}
