// Libraries
import styled from 'styled-components'

// Components
import { Body, Button } from 'heeds-ds'

export const AddModelButton = styled(Button).attrs(() => ({
  size: 'small',
  type: 'button',
}))``

export const Block = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 1.5rem;
  box-shadow: 0 3.5px 5.5px rgba(0, 0, 0, 0.02);
  display: flex;
  gap: 1.6rem;
  padding: 0.8rem 2.4rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 36.4rem;
  }
`

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.default,
  type: 'copy4',
  weight: 700,
}))``

export const Topbar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
  }
`

export const Type = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy5',
}))``
