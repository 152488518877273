// Models
import { ThemeContext } from 'styled-components'

// React
import { ReactNode, useContext, useMemo } from 'react'

// Lib
import { useLocation } from 'react-router-dom'

// Misc
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from '../styled'
import { Icon, IconsType } from 'heeds-ds'

type Props = {
  avatar?: ReactNode
  children: ReactNode
  handleClick?: () => void
  href: string
  icon?: IconsType
  stacked?: boolean
  logout?: boolean
}

const NavItem = ({
  avatar,
  children,
  handleClick,
  href,
  icon,
  logout,
  stacked = true,
}: Props) => {
  const location = useLocation()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const isActive = useMemo(() => {
    const localPath = location.pathname.split('/')[2]
    const refPath = href.split('/')[2]

    return localPath === refPath
  }, [href, location.pathname])

  const isStacked = isDesktop ? stacked : false

  return (
    <Styled.ItemWrapper stacked={isStacked}>
      <Styled.NavLink
        asChild
        data-active={isActive}
        logout={logout}
        stacked={isStacked}
      >
        <Styled.NavItem to={href} onClick={handleClick}>
          <Styled.IconWrapper>
            {icon && (
              <Icon
                color={
                  logout ? theme.colors.tag.critical : theme.colors.text.default
                }
                iconName={icon}
                size={24}
              />
            )}
            {avatar}
          </Styled.IconWrapper>
          <Styled.Label>{children}</Styled.Label>
        </Styled.NavItem>
      </Styled.NavLink>
    </Styled.ItemWrapper>
  )
}

export default NavItem
