// Models
import { IFinancialSubscription } from 'storage/financialSubscription/models'
import { IInstallment } from 'services/financial/@types'
import { TPaymentPayload } from 'filters/financial/models'
import {
  EPlanModelToPortuguese,
  EPlanPeriodicityToPortuguese,
  TPlanPeriodicity,
} from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect, useMemo, useState } from 'react'

// Libraries
import {
  generatePath,
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { BRL } from 'lib/currency'
import { cn } from 'utils/helpers/classess'
import { currencyMask } from 'utils/masks'
import { dateFormatEnglishToPortuguese } from 'utils/functions'
import {
  triggerCreatePayment,
  triggerLoadInstallments,
  triggerLoadMoreInstallments,
} from 'storage/financial/duck'
import { triggerGetFinancialSubscription } from 'storage/financialSubscription/duck'
import { triggerLoadAthleteOverview } from 'storage/athleteOverview/duck'
import { urls } from 'routes/paths'
import { useFilters, useMediaQuery, useMenu, useModal } from 'hooks'

// Components
import * as Block from 'blocks/dashboard/financial'
import { Button, Chip, CommonList, Icon, IconButton, Loading } from 'heeds-ds'
import {
  FinancialInstallmentItem,
  ModalPayment,
  ModalSendReminder,
  LoaderFinancialInstallmentItem,
} from 'components'

// Constants
const FILTERS = [
  { label: '30 dias', param: 'time_interval', value: 'monthly' },
  { label: '90 dias', param: 'time_interval', value: 'quarterly' },
  { label: '180 dias', param: 'time_interval', value: 'semiannual' },
  { label: '365 dias', param: 'time_interval', value: 'yearly' },
  { label: 'Pendente', param: 'status', value: '0' },
  { label: 'A Vencer', param: 'status', value: '1' },
  { label: 'Pago', param: 'status', value: '2' },
  { label: 'Cancelado', param: 'status', value: '3' },
]

const COLUMNS = [
  { label: 'Plano' },
  { label: 'Valor da parcela' },
  { label: 'Data de pagamento' },
  { label: 'Data de vencimento' },
  { label: 'Forma de pagamento' },
  { label: 'Status' },
  { label: '' },
]

const page_size = 20

interface IFilters {
  time_interval?: TPlanPeriodicity
  status?: 0 | 1 | 2 | 3
}

const FinancialAthlete: FC = () => {
  const { id = '' } = useParams()
  const { loading: loadingInstallments, installments } = useSelector(
    (state: IStore) => state.financial,
  )
  const { loading: loadingPlans } = useSelector(
    (state: IStore) => state.athletePlans,
  )
  const {
    error: errorSubscription,
    loading: loadingSubscription,
    subscription,
  } = useSelector((state: IStore) => state.financialSubscription)
  const {
    error: errorOverview,
    loading: loadingOverview,
    overview,
  } = useSelector((state: IStore) => state.athleteOverview)
  const [searchParams] = useSearchParams()
  const { breakpoints } = useContext(ThemeContext)
  const { setPagename } = useMenu()
  const { closeModal, isVisible, openModal } = useModal()
  const { filters, isValueIncluded, toggleFilter } =
    useFilters<IFilters>(searchParams)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const [selected, setSelected] = useState<IInstallment>()

  const hasActiveFilters = useMemo(
    () =>
      FILTERS.some((filter) => {
        return isValueIncluded?.(filter.param, filter.value)
      }),
    [isValueIncluded],
  )
  const [showFilters, setShowFilters] = useState(hasActiveFilters)

  const renderItem = (item: IInstallment) => {
    const openPaymentModal = () => {
      setSelected(item)
      openModal('financial-athlete-payment-modal')
    }
    const openReminderModal = () =>
      openModal('financial-athlete-reminder-modal')

    return (
      <FinancialInstallmentItem
        installment={item}
        key={item.id}
        openPaymentModal={openPaymentModal}
        openReminderModal={openReminderModal}
      />
    )
  }

  const onClickFilter = (param: string, value: string) => {
    const newFilters = toggleFilter(param, value, true)
    handleReloadInstallments(newFilters)
  }

  const handleEndReached = () => {
    if (!loadingInstallments && installments?.next && overview) {
      const query = {
        ...filters,
        athlete_pk: overview.id,
        page: installments.next,
        page_size,
      }
      dispatch(triggerLoadMoreInstallments(query))
    }
  }

  const handleReloadInstallments = (newFilters: IFilters) => {
    if (overview) {
      const query = {
        ...newFilters,
        athlete_pk: overview.id,
        page_size,
      }
      dispatch(triggerLoadInstallments(query))
    }
  }

  const handlePaymentInstallment = (payload: TPaymentPayload) => {
    if (selected) {
      const successCallback = () => {
        handleReloadInstallments(filters)
        closeModal()
      }
      dispatch(
        triggerCreatePayment({
          ...payload,
          value: BRL(payload.value).value,
          installment: selected.id,
          successCallback,
        }),
      )
    }
  }

  useLayoutEffect(() => {
    const subscriptionId = Number(id)

    const successCallback = (subscription: IFinancialSubscription) => {
      dispatch(triggerLoadInstallments({ athlete_pk: subscription.athlete }))
      dispatch(triggerLoadAthleteOverview(subscription.athlete))
    }

    dispatch(
      triggerGetFinancialSubscription({ id: subscriptionId, successCallback }),
    )
  }, [dispatch, id])

  useLayoutEffect(() => {
    setPagename('Financeiro')
    return () => setPagename('Dashboard')
  }, [setPagename])

  const planInfo = [
    {
      label: 'Modelo do plano',
      value:
        EPlanModelToPortuguese?.[
          overview?.plan_model as keyof typeof EPlanModelToPortuguese
        ] || '-',
    },
    {
      label: 'Tipo do plano',
      value:
        EPlanPeriodicityToPortuguese?.[
          overview?.plan_periodicity as keyof typeof EPlanPeriodicityToPortuguese
        ] || '-',
    },
    {
      label: 'Data início',
      value: dateFormatEnglishToPortuguese(subscription?.start_date) || '-',
    },
    {
      label: 'Data fim',
      value: dateFormatEnglishToPortuguese(subscription?.end_date) || '-',
    },
    {
      label: 'Pagamento',
      value: subscription?.spread ? 'Parcelado' : 'A vista',
    },
    {
      label: 'Renovação automática',
      value: subscription?.auto_renew ? 'Sim' : 'Não',
    },
  ]

  const planInfoFilter = () => {
    if (subscription?.auto_renew) {
      return planInfo.filter((plan) => plan.label !== 'Data fim')
    }
    return planInfo
  }

  if (
    id &&
    subscription &&
    subscription?.id === Number(id) &&
    (errorSubscription || errorOverview) === '404'
  ) {
    return <Navigate to={urls.financial} replace />
  }

  if (loadingOverview || loadingPlans || loadingSubscription) {
    return <Loading active />
  }

  return (
    <div
      id="financial-athlete-list"
      className={cn(
        'flex h-full flex-1 flex-col gap-6 overflow-scroll p-6 pb-32',
        'w-full xl:gap-6 xl:overflow-hidden xl:pb-6 xl:pl-10 xl:pr-12 xl:pt-10',
      )}
    >
      <Block.FinancialAthleteHeader />

      <div className="flex flex-col gap-4 xl:flex-row">
        <div className="rounded-[8px] border border-border-input bg-surface xl:w-1/5">
          <div className="flex items-center justify-between px-4 py-3 ">
            <p className="text-copy3 font-semibold">Plano Atual</p>
            <IconButton
              iconName="edit"
              size="xsmall"
              onClick={() =>
                overview &&
                navigate(
                  generatePath(urls.athleteFinancial, { id: overview.id }),
                )
              }
            />
          </div>
          <div className="flex flex-col gap-1 border-t border-border-input p-4">
            <p className="text-copy4 text-text-subdued">Valor total</p>
            <p className="text-copy3 font-semibold">
              {currencyMask(String(subscription?.total_price ?? 0))}
            </p>
          </div>
        </div>
        <div className="rounded-[8px] border border-border-input bg-surface xl:w-4/5">
          <div className="p-4">
            <p className="text-copy3 font-semibold">Detalhes do plano</p>
          </div>
          <div className="grid grid-cols-2 gap-4 border-t border-border-input p-4 xl:flex xl:gap-6">
            {planInfoFilter()?.map((plan, index) => {
              return (
                <div key={index} className="flex flex-col gap-1">
                  <p className="text-copy4 text-text-subdued">{plan.label}</p>
                  <p className="text-copy4 font-semibold">{plan.value}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div
        className={cn(
          'flex flex-1 flex-col rounded-[8px] border-border-input',
          'xl:overflow-auto xl:border xl:bg-surface xl:shadow-sm',
        )}
      >
        <div className="flex w-full flex-col-reverse items-center gap-4 pb-6 xl:flex-row xl:gap-8 xl:px-6 xl:py-4">
          {isDesktop && (
            <p className="text-copy3 font-semibold leading-9 text-text">
              Pagamentos
            </p>
          )}

          <Button
            className="ml-auto"
            onClick={() => setShowFilters((currentValue) => !currentValue)}
            size="xsmall"
            variation="outlined"
          >
            <Icon iconName="filter" />
            Filtro
          </Button>
        </div>

        {(showFilters || hasActiveFilters) && (
          <div className="no-scrollbar flex gap-2 overflow-x-auto pb-2 xl:flex-wrap xl:overflow-x-clip xl:px-6">
            {FILTERS.map((filter) => {
              const isActive = isValueIncluded?.(filter.param, filter.value)
              return (
                <Chip
                  active={isActive}
                  key={filter.label}
                  label={filter.label}
                  onClick={() => onClickFilter(filter.param, filter.value)}
                  scale="small"
                />
              )
            })}
          </div>
        )}

        <CommonList<IInstallment>
          columns={COLUMNS}
          columnsFormat="grid-cols-[repeat(6,1fr)68px]"
          data={installments?.results || []}
          hasMore={!!installments?.next}
          loaderComponent={<LoaderFinancialInstallmentItem />}
          onEndReached={handleEndReached}
          padding="py-3 px-4"
          renderItem={renderItem}
          scrollableDiv={isDesktop ? undefined : 'financial-athlete-list'}
          showHeader={isDesktop}
          rowsGap="gap-2 xl:gap-0"
          bottomDistance="200px"
          listEmptyComponent={
            <div className="flex flex-1">
              <p className="m-auto text-copy4 text-text-subdued">
                {Object.values(filters).length
                  ? 'Nenhum dado encontrado.'
                  : 'Ainda não há dados para mostrar nessa área.'}
              </p>
            </div>
          }
        />
      </div>

      {isVisible === 'financial-athlete-payment-modal' && (
        <ModalPayment
          onConfirm={handlePaymentInstallment}
          installment={selected}
        />
      )}

      {isVisible === 'financial-athlete-reminder-modal' && (
        <ModalSendReminder
          athleteName={overview?.name}
          onConfirm={(payload) => console.log(payload)}
        />
      )}
    </div>
  )
}

export default FinancialAthlete
