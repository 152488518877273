// Libraries
import { InputSearch } from 'heeds-ds'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.4rem;
  overflow-y: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 4rem 4.8rem 2.4rem 4rem;
  }
`

export const TabsWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surface.default};
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 15px 15px 0 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-top: 24px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border: 1px solid ${({ theme }) => theme.colors.border.input};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-radius: 0.8rem;
    box-shadow: 0px 4px 80px 0px rgba(43, 42, 99, 0.1);
  }
`

export const CardHeader = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 24px;
  padding: 16px;
  box-shadow: 0px 4px 80px 0px rgba(43, 42, 99, 0.1);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0;
  }
`

export const CardTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ContentHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  padding: 11px 16px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 60px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
    margin-bottom: 0;
  }
`

export const SearchContainer = styled.div`
  padding: 16px 8px;
  position: absolute;

  top: 100%;
  left: 16px;
  right: 16px;
  align-self: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 375px;
    padding: 0;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  }
`

export const Search = styled(InputSearch).attrs({
  placeholder: 'Pesquisar',
  margin: '0 0 11px 0',
  scale: 'small',
})``

export const ContentBody = styled.div``

export const ButtonContainer = styled.div`
  background: ${({ theme }) => theme.colors.surface.default};
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  bottom: 0px;
  display: flex;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 2.4rem 3.2rem 2.4rem;
  position: fixed;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    border-top: none;
    margin: 0 0 0 auto;
    padding: 0;
    position: relative;
    width: auto;
  }
`
