// Models
import { IAuthState } from 'storage/auth/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext } from 'react'

// Lib
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

// Misc
import { cn } from 'utils/helpers/classess'
import { triggerLogout } from 'storage/auth/duck'
import { urls } from 'routes/paths'
import { useMediaQuery } from 'heeds-ds/src/hooks'
import { useMenu } from 'hooks'

// Components
import * as Styled from './styled'
import { Avatar } from 'heeds-ds'
import NavItem from './primitives/NavItem'
import Submenu from './primitives/Submenu'

const Navbar: FC = () => {
  const { closeMenu } = useMenu()
  const { pathname } = useLocation()
  const { userData } = useSelector<IStore, IAuthState>((state) => state.auth)
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const profilePaths = [
    urls.profileSchedule,
    urls.profilePlans,
    urls.profileGeneral,
  ]

  const handleLogout = () => {
    closeMenu()
    dispatch(triggerLogout())
  }

  if (isDesktop) {
    return (
      <Styled.Wrapper orientation="vertical">
        <Styled.List>
          <NavItem href={urls.dashboard} icon="home">
            Início
          </NavItem>

          <NavItem href={urls.athletes} icon="person">
            Alunos
          </NavItem>

          <NavItem href={urls.routines} icon="dumbbell">
            Treinos
          </NavItem>

          <NavItem href={urls.libraryAnamneseTemplates} icon="book">
            Biblioteca
          </NavItem>

          <NavItem href={urls.financial} icon="money">
            Financeiro
          </NavItem>
        </Styled.List>

        <Styled.List>
          <Submenu
            label="Meu perfil"
            icon={
              <Avatar
                className={cn('text-text-primary hover:border-text-primary', {
                  'border-text-primary': profilePaths.includes(pathname),
                })}
                hoverMode
                name={userData?.name ?? ''}
                scale="xSmall"
              />
            }
            position="bottom"
          >
            <NavItem
              handleClick={handleLogout}
              href={urls.login}
              icon="logout"
              logout
              stacked={false}
            >
              Sair
            </NavItem>

            <NavItem
              href={urls.profileSchedule}
              icon="settings"
              stacked={false}
            >
              Configurações
            </NavItem>

            <NavItem
              href={urls.profileGeneral}
              avatar={
                <Avatar
                  className={cn('text-text-primary hover:border-text-primary', {
                    'border-text-primary': [urls.profileGeneral].includes(
                      pathname,
                    ),
                  })}
                  hoverMode
                  name={userData?.name ?? ''}
                  scale="xSmall"
                />
              }
              stacked={false}
            >
              Meu perfil
              <Styled.Br />
              <Styled.Span>{userData?.name}</Styled.Span>
            </NavItem>
          </Submenu>
        </Styled.List>
      </Styled.Wrapper>
    )
  }

  return (
    <Styled.Wrapper orientation="vertical">
      <Styled.List>
        <NavItem handleClick={closeMenu} href={urls.dashboard} icon="home">
          Início
        </NavItem>

        <NavItem handleClick={closeMenu} href={urls.athletes} icon="person">
          Alunos
        </NavItem>

        <NavItem handleClick={closeMenu} href={urls.routines} icon="dumbbell">
          Treinos
        </NavItem>

        <NavItem
          handleClick={closeMenu}
          href={urls.libraryAnamneseTemplates}
          icon="book"
        >
          Biblioteca
        </NavItem>

        <NavItem
          handleClick={closeMenu}
          href={`${urls.financial}?time_interval=monthly`}
          icon="money"
        >
          Financeiro
        </NavItem>

        <NavItem
          handleClick={closeMenu}
          href={urls.profileSchedule}
          icon="settings"
        >
          Configurações
        </NavItem>

        <NavItem
          handleClick={closeMenu}
          href={urls.profileGeneral}
          icon="manageAccounts"
        >
          Meu perfil
        </NavItem>

        <NavItem
          handleClick={handleLogout}
          href={urls.login}
          icon="logout"
          logout
          stacked={false}
        >
          Sair
        </NavItem>
      </Styled.List>
    </Styled.Wrapper>
  )
}

export default Navbar
