// React
import { FC } from 'react'

// Misc
import {
  createDatesInteval,
  getDatesDistance,
  WEEKDAYS_COLLECTION,
} from '../../../utils/helpers/date'
import { cn } from '../../../utils/classes'

type Props = {
  days: Date[]
  displayingMonth: number
  displayingYear: number
  endDate?: Date | null
  range?: boolean
  setDisplayingMonth: React.Dispatch<React.SetStateAction<number>>
  setDisplayingYear: React.Dispatch<React.SetStateAction<number>>
  setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>
  startDate?: Date | null
  today: Date
}

const CalendarDayPage: FC<Props> = ({
  days,
  displayingMonth,
  displayingYear,
  endDate,
  range,
  setDisplayingMonth,
  setDisplayingYear,
  setEndDate,
  setStartDate,
  startDate,
  today,
}) => {
  const intevalDays =
    startDate && endDate && createDatesInteval(startDate, endDate)

  const handleClick = (isEdgeDay: boolean, day: Date) => {
    if (isEdgeDay) {
      const isOnNextMonth =
        getDatesDistance(new Date(displayingYear, displayingMonth), day) > 0
      const modifier = isOnNextMonth ? 1 : -1
      const auxDate = new Date(displayingYear, displayingMonth + 1 * modifier)

      setDisplayingMonth(auxDate.getMonth())
      setDisplayingYear(auxDate.getFullYear())
      return
    }

    if (range) {
      if (startDate && !endDate) {
        if (getDatesDistance(startDate, day) > 0) {
          setEndDate(day)
        } else {
          setStartDate(day)
        }
        return
      }

      if (startDate && endDate) {
        if (getDatesDistance(endDate, day) > 0) {
          setEndDate(day)
        } else {
          setStartDate(day)
          setEndDate(undefined)
        }
        return
      }
    }

    setStartDate(day)
  }

  return (
    <>
      {Object.values(WEEKDAYS_COLLECTION).map((weekday) => (
        <div
          key={weekday.full}
          className="flex h-[30px] w-[36px] items-center justify-center font-roboto text-icon-hovered"
        >
          {weekday.symbol}
        </div>
      ))}

      {days.map((day) => {
        const isEdgeDay = day.getMonth() !== displayingMonth
        const isIncludedOnRange =
          range && intevalDays?.includes(day.toDateString())

        const isSelected = day.toDateString() === startDate?.toDateString()
        const isStartDate =
          range && day.toDateString() === startDate?.toDateString()
        const isEndDate =
          range && day.toDateString() === endDate?.toDateString()
        const isToday = !(today > day) && !(today < day)
        const ariaSelected = isSelected || isEndDate

        return (
          <button
            key={day.toString()}
            role="gridcell"
            type="button"
            onClick={() => handleClick(isEdgeDay, day)}
            aria-selected={isSelected || isEndDate}
            className={cn(
              'h-[30px] w-9 rounded bg-transparent font-roboto text-[12px] font-normal text-text',
              'focus:border focus:border-black',
              {
                'text-[10px] text-text-disabled': isEdgeDay,
                'font-semibold text-badge-purpleText bg-badge-purpleBackground':
                  isToday,
                'rounded-none bg-badge-purpleBackground': isIncludedOnRange,
                'rounded-l-[15px]': isStartDate,
                'rounded-r-[15px]': isEndDate,
                'bg-action-secondaryDefault font-semibold text-white':
                  !isEdgeDay && ariaSelected,
                'hover:bg-interactive-hovered hover:font-semibold hover:text-white focus:border focus:border-black':
                  !isEdgeDay,
              },
            )}
          >
            {day.getDate()}
          </button>
        )
      })}
    </>
  )
}

export default CalendarDayPage
