// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Button } from 'heeds-ds'

export const AlignRow = styled(Aligner).attrs(() => ({
  flex: 'column',
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: row;
    gap: 2.4rem;
  }
`

export const ButtonContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 4rem;
  position: sticky;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    background: ${({ theme }) => theme.colors.surface.default};
  }
`

export const CancelButton = styled(Button).attrs(() => ({
  cancel: true,
  margin: '0',
  size: 'small',
  variation: 'outlined',
}))``

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
`

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    border: none;
    box-shadow: 0px 4px 80px 0px #0000001a;
  }
`

export const DescriptionText = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  margin: '0.8rem 0 0',
  type: 'copy3',
}))``
