// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

export const Cell = styled(Aligner)`
  padding: 1.6rem 1.6rem 1.6rem 2.4rem;
`

export const Row = styled.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns:
    minmax(200px, 1fr) minmax(240px, 1fr) minmax(200px, 1fr)
    minmax(150px, 1fr) minmax(10px, 0.5fr) minmax(8px, 0.35fr);
  transition: all 0.3s ease-in-out;
  width: 100%;
  ${({ theme }) => css`
    background: ${theme.colors.surface.default};
    border-bottom: 1px solid ${theme.colors.border.input};
  `}
`

export const Text = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.default,
  type: 'copy4',
  weight: 600,
}))``
