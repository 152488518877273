// Libs
import { BrowserRouter } from 'react-router-dom'

// Misc
import './index.css'
import { GlobalStyle } from 'heeds-ds/src/lib/styled-components/theme'

// Components
import AppProviders from 'AppProviders'
import AppRoutes from 'routes'

// Fonts
import '@fontsource/barlow'
import '@fontsource/roboto'

function App() {
  return (
    <AppProviders>
      <GlobalStyle />
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </AppProviders>
  )
}

export default App
