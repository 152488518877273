// React
import { FC } from 'react'

// Misc
import { cn } from '../../utils/classes'
import { renderInitials } from 'utils/functions'

// Libraries
import { AvatarImage, Root, Fallback } from '@radix-ui/react-avatar'

// Components
import * as Styles from './styles'

type Sizes =
  | 'xXSmall'
  | 'xSmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xLarge'
  | 'xXLarge'

export type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  className?: string
  disabled?: boolean
  hoverMode?: boolean
  name: string
  onClick?: () => void
  scale?: Sizes
}

const Avatar: FC<Props> = ({
  className,
  disabled,
  hoverMode,
  name,
  onClick,
  scale = 'medium',
  ...props
}) => {
  return (
    <Root
      onClick={onClick}
      className={cn(
        Styles.avatarStyles({
          scale,
          disabled,
          clickable: !!onClick,
          hoverMode,
        }),
        className,
      )}
    >
      <AvatarImage className="h-full rounded-full object-contain" {...props} />
      <Fallback className={cn(Styles.initialsStyles({ scale }))}>
        {renderInitials(name)}
      </Fallback>
    </Root>
  )
}

export default Avatar
