// Models
import { IAnamneseAnswer } from 'storage/assessmentAnamneseAnswer/models'
import { IAnamneseTemplate } from 'storage/assessmentAnamneseTemplate/models'

// React
import { FC, Fragment } from 'react'

// Components
import * as Styled from './styled'
import { Body, Skeleton, Subtitle, Title } from 'heeds-ds'
import QuestionItem from 'components/QuestionItem'

type Props = {
  anamnese?: IAnamneseAnswer | IAnamneseTemplate
  loading?: boolean
}

const AnamnesePreview: FC<Props> = ({ anamnese, loading }) => {
  if (loading || !anamnese) {
    return (
      <Styled.Container>
        <Styled.SectionWrapper>
          <Skeleton height="4rem" width="80%" />
          <Skeleton height="2rem" width="60%" />
        </Styled.SectionWrapper>

        <Skeleton height="40rem" width="100%" margin="2rem 0" />
        <Skeleton height="20rem" width="100%" margin="6rem 0 0" />
      </Styled.Container>
    )
  }

  return (
    <Styled.Container>
      <Styled.SectionWrapper>
        <Title type="h3" weight={700}>
          {anamnese.title}
        </Title>
        {anamnese.description && (
          <Body type="copy3">{anamnese.description}</Body>
        )}
      </Styled.SectionWrapper>
      {anamnese.sections.map((section, index) => (
        <Fragment key={index}>
          {(section.name || section.description) && (
            <Styled.SectionWrapper>
              <Subtitle type="sh2">{section.name}</Subtitle>
              {section.description && (
                <Body type="copy3">{section.description}</Body>
              )}
            </Styled.SectionWrapper>
          )}

          {section.questions?.map((question) => {
            return <QuestionItem key={question.question} {...question} />
          })}
        </Fragment>
      ))}
    </Styled.Container>
  )
}

export default AnamnesePreview
