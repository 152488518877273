// Models
import { prepareWorkoutRoutineToDisplay } from 'filters/workouts'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// React
import { FC, useMemo } from 'react'

// Libraries
import { generatePath, useNavigate, useParams } from 'react-router-dom'

// Misc
import { cn } from 'utils/helpers/classess'
import { intervalDateToString } from 'utils/helpers/date'
import { urls } from 'routes/paths'

// Components
import { IconButton } from 'heeds-ds'

export type Props = {
  item: IWorkoutRoutine
  gridTemplate: string
  padding?: string
}

const Desktop: FC<Props> = ({ item, gridTemplate, padding }) => {
  const navigate = useNavigate()
  const { id: athleteId } = useParams()
  const { name, start_date, end_date, id } = item
  const { goal, difficulty } = prepareWorkoutRoutineToDisplay(item)

  const period = useMemo(
    () => intervalDateToString(start_date, end_date),
    [end_date, start_date],
  )

  return (
    <div
      className={cn(
        'grid w-full cursor-pointer items-center border border-b-border-input bg-surface',
        gridTemplate,
      )}
    >
      <div className={cn(padding)}>
        <p className="text-copy4">{name}</p>
      </div>
      <div className={cn(padding)}>
        <p className="text-copy4">{period}</p>
      </div>
      <div className={cn(padding)}>
        <p className="text-copy4">{goal}</p>
      </div>
      <div className={cn(padding)}>
        <p className="text-copy4">{difficulty}</p>
      </div>
      <div className={cn(padding)}>
        <p className="text-copy4">
          <IconButton
            iconName="analytics"
            size="xsmall"
            onClick={() =>
              navigate(
                generatePath(urls.athleteWorkoutsReport, {
                  id: athleteId,
                  model_id: id,
                }),
              )
            }
          />
        </p>
      </div>
    </div>
  )
}

export default Desktop
