// React
import { FC, ReactNode, useEffect, useRef, useState } from 'react'

// Components
import * as Styled from './styled'

type InputFileProps = {
  accept?: string
  dragText?: ReactNode | ReactNode[]
  setFile: (file: File | FileList) => void
  preview?: (item: string | FileList) => void
  singleFile?: boolean
  size?: number
  error?: string
}

const InputFile: FC<InputFileProps> = ({
  accept = '',
  dragText,
  setFile,
  preview,
  singleFile,
  size,
  error,
}) => {
  const [dragging, setDragging] = useState(false)
  const [fileError, setFileError] = useState('')
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleFileFormat = (files: File | FileList): boolean => {
    const acceptedTypes = accept?.split(',').map((type) => type.trim())

    const checkFile = (file: File) => {
      return acceptedTypes?.some((type) => {
        if (type.endsWith('/*')) {
          const generalType = type.split('/')[0]
          return file.type.startsWith(generalType)
        } else {
          return file.type === type
        }
      })
    }

    if (files instanceof FileList) {
      return Array.from(files).every((file) => checkFile(file))
    }

    return checkFile(files)
  }

  const handleFileSize = (files: File | FileList): boolean => {
    if (!size) {
      return false
    }

    const checkFileSize = (file: File) => {
      return file.size > size * 1024 * 1024
    }

    if (files instanceof FileList) {
      return Array.from(files).every((file) => checkFileSize(file))
    }

    return checkFileSize(files)
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = () => {
    setDragging(false)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragging(false)
    const file = event.dataTransfer.files

    if (handleFileSize(file)) {
      return setFileError('Imagem com tamanho maior que o permitido.')
    }

    if (file && handleFileFormat(file)) {
      setFile(singleFile ? file[0] : file)
      handlePreview(singleFile ? file[0] : file)
      setFileError('')
    } else {
      setFileError('Formato de arquivo não aceito')
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files

    if (file && handleFileSize(file)) {
      return setFileError('Imagem com tamanho maior que o permitido.')
    }

    if (file) {
      setFile(singleFile ? file[0] : file)
      handlePreview(singleFile ? file[0] : file)
      setFileError('')
    }
  }

  const handleVideoThumbnail = (
    file: File,
    onThumbnailReady: (thumbnail: string) => void,
  ) => {
    if (!file.type.startsWith('video')) {
      return
    }

    const video = document.createElement('video')
    video.src = URL.createObjectURL(file)
    video.currentTime = 1

    video.onloadeddata = () => {
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      const ctx = canvas.getContext('2d')
      if (!ctx) {
        return
      }
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

      const thumbnail = canvas.toDataURL('image/png')
      onThumbnailReady(thumbnail)

      URL.revokeObjectURL(video.src)
    }
  }

  const handlePreview = (file: Blob | File | FileList) => {
    const isVideo = file instanceof File && file.type.startsWith('video')

    if (isVideo) {
      handleVideoThumbnail(file, (thumbnail) => {
        preview?.(thumbnail)
      })
    } else {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (reader.result) {
          preview?.(reader.result as string)
        }
      }
      reader.readAsDataURL(file as Blob)
    }
  }

  useEffect(() => {
    if (error) {
      setFileError(error)
    }
  }, [error])

  return (
    <Styled.DropZone
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={fileInputRef.current?.click}
      data-testid="input-file-container"
    >
      <Styled.FileLabel
        htmlFor="file"
        isDragging={dragging}
        error={!!fileError}
      >
        {dragText}
      </Styled.FileLabel>
      <Styled.FileInput
        type="file"
        name="file"
        id="file"
        accept={accept}
        onChange={handleFileChange}
      />
      {fileError && (
        <Styled.ErrorContainer>
          <Styled.IconError />
          <Styled.TextError>{fileError}</Styled.TextError>
        </Styled.ErrorContainer>
      )}
    </Styled.DropZone>
  )
}

export default InputFile
