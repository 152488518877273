// Model
import { TooltipContentProps } from '@radix-ui/react-tooltip'

// React
import { FC } from 'react'

// Components
import * as Styled from './styled'

type Props = TooltipContentProps & {
  arrowSize?: number
  baseColor?: string
  borderRadius?: string
  children?: React.ReactNode
  content?: React.ReactNode
  delay?: number
  padding?: string
  shadow?: string
  width?: string
  open?: boolean
}

const Tooltip: FC<Props> = (props: Props) => {
  const {
    arrowSize = 15,
    baseColor,
    children,
    content,
    delay = 400,
    open,
    ...contentProps
  } = props

  return (
    <Styled.Provider>
      <Styled.Root delayDuration={delay} open={open}>
        <Styled.Trigger asChild onClick={(e) => e.stopPropagation()}>
          {children}
        </Styled.Trigger>
        <Styled.Portal>
          <Styled.Content
            {...contentProps}
            baseColor={baseColor}
            data-testid="tooltip-content"
          >
            {content}
            <Styled.Arrow
              baseColor={baseColor}
              height={Math.floor(arrowSize / 2)}
              width={arrowSize}
            />
          </Styled.Content>
        </Styled.Portal>
      </Styled.Root>
    </Styled.Provider>
  )
}

export default Tooltip
