// Models
import { IExerciseData, IExerciseState } from 'storage/exercise/models'
import {
  IModelInput,
  IWorkoutSetInput,
  TMuscleGroupFilter,
  TSpecialSetInput,
} from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useCallback, useMemo } from 'react'

// Libraries
import { useSelector } from 'react-redux'

// Misc
import { uid } from 'utils/functions'
import { useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { CommonList } from 'heeds-ds'
import { ExerciseItem, LoaderExerciseItem, ModalMuscleGroups } from 'components'
import ExerciseSearchBar from '../../ExerciseSearchBar'

type Props = {
  addWorkoutSet: (modelId: string, set: IWorkoutSetInput) => void
  addExerciseToWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exercise: IExerciseData,
  ) => void
  filters: TMuscleGroupFilter[]
  inputValue: string
  loadMoreExercises: () => void
  onChangeFilters: (filters: TMuscleGroupFilter[]) => void
  onClickFilter: () => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  openExerciseGifModal: (exercise: IExerciseData) => void
  removeFilter: (label: string) => void
  selectedModelIndex: number
  specialSet?: TSpecialSetInput
  stateModels: IModelInput[]
}

const WorkoutExercises: FC<Props> = ({
  addExerciseToWorkoutSet,
  addWorkoutSet,
  filters,
  inputValue,
  loadMoreExercises,
  onChangeFilters,
  onClickFilter,
  onSearchChange,
  openExerciseGifModal,
  removeFilter,
  selectedModelIndex,
  specialSet,
  stateModels,
}) => {
  const { exercises, loading } = useSelector<IStore, IExerciseState>(
    (state) => state.exercise,
  )
  const { isVisible } = useModal()

  const items = useMemo(() => exercises?.results || [], [exercises?.results])

  const addNewWorkoutSet = useCallback(
    (exercise: IExerciseData) => {
      const modelId = stateModels[selectedModelIndex].id
      const setId = 'NEW' + uid()

      if (specialSet) {
        addExerciseToWorkoutSet(modelId, setId, exercise)
        return
      }

      const newWorkoutSet: IWorkoutSetInput = {
        id: setId,
        type: 'normal',
        quantity: '',
        interval: { value: '' },
        exercises: [{ id: uid(), exercise, repetitions: '' }],
      }
      addWorkoutSet(modelId, newWorkoutSet)
    },
    [
      addExerciseToWorkoutSet,
      addWorkoutSet,
      selectedModelIndex,
      specialSet,
      stateModels,
    ],
  )

  const renderExercise = useCallback(
    (exercise: IExerciseData) => {
      return (
        <ExerciseItem
          endLine
          exercise={exercise}
          key={exercise.id}
          onClick={addNewWorkoutSet}
          onClickImage={openExerciseGifModal}
        />
      )
    },
    [addNewWorkoutSet, openExerciseGifModal],
  )

  return (
    <Styled.Container>
      <ExerciseSearchBar
        filters={filters}
        onClickFilter={onClickFilter}
        onSearchChange={onSearchChange}
        padding="16px 24px"
        removeFilter={removeFilter}
        searchQuery={inputValue}
      />

      <div className="flex grow flex-col overflow-y-auto px-6 pt-2">
        {loading ? (
          <div className="flex flex-col gap-2 overflow-auto pr-2">
            {Array(7)
              .fill(1)
              .map((_, index) => (
                <LoaderExerciseItem key={index} />
              ))}
          </div>
        ) : (
          <CommonList<IExerciseData>
            columns={[]}
            data={items}
            hasMore={!!exercises?.next}
            loaderComponent={<LoaderExerciseItem />}
            onEndReached={loadMoreExercises}
            renderItem={renderExercise}
            showHeader={false}
            rowsGap="gap-y-2"
          />
        )}
      </div>
      {isVisible === 'exercises-filter-modal' && (
        <ModalMuscleGroups filters={filters} onChange={onChangeFilters} />
      )}
    </Styled.Container>
  )
}

export default WorkoutExercises
