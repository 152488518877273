// Models
import { FieldType } from 'components/WorkoutSetFormTag'
import { IExerciseData } from 'storage/exercise/models'
import {
  IFormInputs,
  IModelInput,
  IWorkoutSetInput,
  TFormValues,
  TMuscleGroupFilter,
  TSetSpecialSet,
  TSpecialSetInput,
} from 'models'
import { IWorkoutModelData } from 'storage/workoutModel/models'
import { TSetStateModels } from 'hooks/useWorkoutModelsForm/@types'
import { UseFormReturn } from 'react-hook-form'

// React
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

// Libraries
import {
  generatePath,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import { prepareModelsToDisplay } from 'filters/workouts'
import { triggerToastError } from 'storage/general/duck'
import { urls } from 'routes/paths'
import { useModal } from 'hooks'

// Components
import * as Blocks from 'blocks/dashboard/workout/routine'
import * as Styled from './styled'
import { Aligner } from 'heeds-ds'
import {
  ModalConfirmation,
  ModalExerciseImage,
  ModalWorkoutModelTemplates,
  WorkoutAthleteInfo,
  WorkoutTabs,
} from 'components'
import { WorkoutLayoutContext } from 'layouts/WorkoutLayout'

// Constants
const TOAST_INFO = {
  bothErrors: {
    customTitle: 'Faltam informações no treino',
    message:
      'Série é um campo obrigatório. Evite treinos vazios, adicione exercícios para revisar.',
    time: 10000,
  },
  emptyWorkout: {
    customTitle: 'Faltam informações no treino',
    message: 'Evite treinos vazios, adicione exercícios para revisar.',
  },
  fieldRequired: {
    customTitle: 'Série é um campo obrigatório!',
    message:
      'Lembre-se de adicionar essa informação no exercício para o seu aluno.',
  },
}

type Props = {
  addOrRemoveFieldFromWorkoutSetToExercise: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  addExerciseToWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exercise: IExerciseData,
  ) => void
  addWorkoutModel: (model: IModelInput) => void
  addWorkoutSet: (modelId: string, set: IWorkoutSetInput) => void
  duplicateWorkoutModel: () => void
  filters: TMuscleGroupFilter[]
  inputValue: string
  loadMoreExercises: () => void
  methods: UseFormReturn<IFormInputs, TFormValues>
  onChangeFilters: (filters: TMuscleGroupFilter[]) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (formData: IFormInputs) => void
  removeFilter: (label: string) => void
  removeExerciseFromWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
  ) => void
  removeWorkoutModel: () => void
  removeWorkoutSet: (modelId: string, workoutSetId: string) => void
  renameWorkoutModel: () => void
  replicateWorkoutSetFields: (modelId: string, set: IWorkoutSetInput) => void
  selectedModelIndex: number
  selectWorkoutModel: (index: number) => void
  setSpecialSet: TSetSpecialSet
  setStateModels: TSetStateModels
  specialSet?: TSpecialSetInput
  stateModels: IModelInput[]
  updateWorkoutModel: (modelId: string, updatedModel: IModelInput) => void
  updateWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
}

const Desktop: FC<Props> = ({
  addExerciseToWorkoutSet,
  addWorkoutModel,
  duplicateWorkoutModel,
  filters,
  inputValue,
  loadMoreExercises,
  methods,
  onChangeFilters,
  onSearchChange,
  onSubmit,
  removeExerciseFromWorkoutSet,
  removeFilter,
  removeWorkoutModel,
  removeWorkoutSet,
  renameWorkoutModel,
  selectWorkoutModel,
  setSpecialSet,
  updateWorkoutSet,
  selectedModelIndex,
  updateWorkoutModel,
  ...commonProps
}) => {
  const { specialSet, stateModels } = commonProps
  const { id = '' } = useParams()
  const { blocked, setBlocked, steps } =
    useOutletContext<WorkoutLayoutContext>()
  const { closeModal, isVisible, openModal } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedExercise, setSelectedExercise] = useState<IExerciseData>()

  const {
    formState: { errors, isValid },
    handleSubmit,
  } = methods

  const hasEmptyModels = useMemo(
    () =>
      stateModels.some((model) =>
        Boolean(Object.values(model.workout_set ?? {}).length === 0),
      ),
    [stateModels],
  )

  const isDisabled = specialSet !== undefined

  const goBack = useCallback(
    () => navigate(generatePath(urls.workoutRoutineCreate, { id })),
    [id, navigate],
  )

  const toastType = useMemo(() => {
    let emptyErrors = 0
    let fieldErrors = 0

    if (errors?.models?.type === 'someExercise') return 'someExercise'

    errors.models?.forEach?.((fieldModel) => {
      if (fieldModel?.name) return (emptyErrors += 1)
      return (fieldErrors += 1)
    })

    if (fieldErrors && emptyErrors) return 'bothErrors'

    if (emptyErrors) return 'emptyWorkout'

    return 'fieldRequired'
  }, [errors?.models])

  const showToast = useCallback(() => {
    if (!isValid && toastType !== 'someExercise') {
      dispatch(triggerToastError({ ...TOAST_INFO[toastType] }))
    }
  }, [dispatch, isValid, toastType])

  const handleNextButton = () => {
    navigate(generatePath(urls.athleteRoutines, { id }), {
      replace: true,
    })
  }

  const openExerciseGifModal = (exercise: IExerciseData) => {
    setSelectedExercise(exercise)
    openModal('desktop-exercise-gif-modal')
  }

  const openWorkoutModelTemplateModal = () => {
    openModal('add-new-model-modal')
  }

  const closeExerciseGifModal = () => {
    setSelectedExercise(undefined)
    closeModal()
  }

  const handleLibraryModel = (templateModel: IWorkoutModelData) => {
    const preparedModel = prepareModelsToDisplay([templateModel])[0]

    if (stateModels && selectedModelIndex !== undefined) {
      const selectedModel = stateModels?.[selectedModelIndex]
      updateWorkoutModel?.(selectedModel.id, {
        ...preparedModel,
        id: selectedModel.id,
      })
    }
  }

  useEffect(() => {
    if (isValid) setBlocked(false)
    else setBlocked(true)
  }, [isValid, setBlocked])

  useEffect(() => {
    if (Object.keys(errors).length) showToast()
  }, [errors, showToast])

  return (
    <div className="flex w-full flex-1 flex-col">
      <div className="relative flex flex-1 gap-5">
        <div className="flex h-[calc(100vh-140px)] w-[452px] max-w-[452px] flex-1 flex-col gap-4">
          <WorkoutAthleteInfo
            openModal={() => openModal('routine_info_modal')}
            showInfo
          />

          <WorkoutTabs steps={steps} blocked={blocked} />

          <div
            className={cn(
              'flex h-[calc(100vh-268px)] max-h-[calc(100vh-268px)] rounded-t-[8px]',
              'border border-b-0 border-border-input bg-surface',
            )}
          >
            <Blocks.WorkoutExercises
              {...commonProps}
              addExerciseToWorkoutSet={addExerciseToWorkoutSet}
              filters={filters}
              inputValue={inputValue}
              loadMoreExercises={loadMoreExercises}
              onChangeFilters={onChangeFilters}
              onClickFilter={() => openModal('exercises-filter-modal')}
              onSearchChange={onSearchChange}
              openExerciseGifModal={openExerciseGifModal}
              removeFilter={removeFilter}
              selectedModelIndex={selectedModelIndex}
            />
          </div>
        </div>

        <div
          className={cn(
            'flex h-full max-h-[calc(100vh-140px)] flex-1 rounded-t-[8px]',
            'border border-b-0 border-border-input bg-surface',
          )}
        >
          <Blocks.WorkoutModels
            {...commonProps}
            addWorkoutModel={addWorkoutModel}
            duplicateWorkoutModel={duplicateWorkoutModel}
            methods={methods}
            openExerciseGifModal={openExerciseGifModal}
            openWorkoutModelTemplateModal={openWorkoutModelTemplateModal}
            removeExerciseFromWorkoutSet={removeExerciseFromWorkoutSet}
            removeWorkoutModel={removeWorkoutModel}
            removeWorkoutSet={removeWorkoutSet}
            renameWorkoutModel={renameWorkoutModel}
            selectWorkoutModel={selectWorkoutModel}
            selectedModelIndex={selectedModelIndex}
            setSpecialSet={setSpecialSet}
            updateWorkoutModel={updateWorkoutModel}
            updateWorkoutSet={updateWorkoutSet}
          />
        </div>
      </div>

      <div
        className={cn(
          'z-[1] flex w-full justify-between rounded-b-[8px]',
          'border border-border-input bg-surface px-10 py-2',
        )}
      >
        <Styled.CancelButton
          disabled={isDisabled}
          onClick={() => openModal('workout-model-go-back-confirmation-modal')}
          track={buttonClickTracking}
          trackName="navigate_to_athlete_routines"
        >
          Cancelar
        </Styled.CancelButton>

        <Aligner gap="32px" width="auto">
          <Styled.GoBackButton
            disabled={isDisabled}
            onClick={goBack}
            track={buttonClickTracking}
            trackName="navigate_to_create_routine_with_id"
          >
            Voltar
          </Styled.GoBackButton>

          <Styled.NextButton
            disabled={blocked || isDisabled}
            form="sets-form"
            onClick={
              hasEmptyModels
                ? () => openModal('empty-model')
                : handleSubmit(onSubmit)
            }
            track={buttonClickTracking}
            trackName="update_state_and_navigate_to_workout_review"
          >
            Ir para revisão
          </Styled.NextButton>
        </Aligner>
      </div>

      {isVisible === 'add-new-model-modal' && (
        <ModalWorkoutModelTemplates
          onSelectWorkoutModel={(model) => handleLibraryModel(model)}
        />
      )}

      {isVisible === 'desktop-exercise-gif-modal' && (
        <ModalExerciseImage
          exercise={selectedExercise}
          handleClose={closeExerciseGifModal}
        />
      )}

      {isVisible === 'workout-model-go-back-confirmation-modal' && (
        <ModalConfirmation
          confirmTitle="Excluir"
          description="Tem certeza que deseja sair? Saindo agora você vai perder todo seu progresso."
          onConfirm={handleNextButton}
          title="Confirmação"
        />
      )}
    </div>
  )
}

export default Desktop
