// Models
import { TAthleteCalendar } from 'models'
import { TWeekdays } from 'heeds-ds/src/models'
import { useFormContext } from 'react-hook-form'

// React
import { FC, useContext, useEffect } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import moment from 'moment'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { handleSelectedDays } from 'utils/calendarUtils'
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Body,
  Button,
  FormButton,
  InputText,
  Subtitle,
} from 'heeds-ds'
import { InputTimeRange } from 'components'
import { Modal } from 'components/modals'
import ConflictCard from 'components/ConflictCard'
import { useModal } from 'hooks'

// Constants
const LONG_WEEK_DAYS = [
  { label: 'SEGUNDA', value: 'mon' },
  { label: 'TERÇA', value: 'tue' },
  { label: 'QUARTA', value: 'wed' },
  { label: 'QUINTA', value: 'thu' },
  { label: 'SEXTA', value: 'fri' },
  { label: 'SÁBADO', value: 'sat' },
  { label: 'DOMINGO', value: 'sun' },
]

const SHORT_WEEK_DAYS = [
  { label: 'S', value: 'mon' },
  { label: 'T', value: 'tue' },
  { label: 'Q', value: 'wed' },
  { label: 'Q', value: 'thu' },
  { label: 'S', value: 'fri' },
  { label: 'S', value: 'sat' },
  { label: 'D', value: 'sun' },
]

type TimeModalProps = {
  athleteListSaved: TAthleteCalendar[]
  editId: number
  variation?: 'conflict' | 'singleDayConflict' | 'edit' | string
  selectedDay?: string
}

// TODO: Refatorar componente
const ModalTimeSchedule: FC<TimeModalProps> = ({
  athleteListSaved,
  variation,
  editId,
  selectedDay,
}) => {
  const { closeModal } = useModal()
  const { getValues, setValue, watch } = useFormContext()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const oldValue = getValues()

  const currentAthlete = athleteListSaved.filter(
    (athlete) => athlete?.athletePk === editId,
  )
  const currentDate = moment().format('YYYY-MM-DD')
  const name = selectedDay || 'allDays'

  // TODO: usar o objeto de conflito refatorado da view para facilitar essa logica
  const conflictList = athleteListSaved.filter(
    (athlete) =>
      athlete.athletePk !== editId &&
      ((watch(`${name}.start`) >= athlete.start &&
        watch(`${name}.start`) < athlete.end) ||
        (watch(`${name}.end`) > athlete.start &&
          watch(`${name}.end`) <= athlete.end) ||
        (watch(`${name}.start`) <= athlete.start &&
          watch(`${name}.end`) >= athlete.end)) &&
      (athlete?.initialDate <= currentAthlete[0]?.dueDate || currentDate) &&
      (watch(`${name}.days`)?.some((item: TWeekdays) =>
        handleSelectedDays(athlete?.recurringTimes)?.includes(item),
      ) ||
        handleSelectedDays(athlete?.recurringTimes)?.includes(
          watch(`${name}.day`),
        )),
  )

  const daysList = Array.from(
    new Set(conflictList.flatMap((item) => item.recurringTimes)),
  )

  const handleClose = () => {
    setValue(name, {
      start: oldValue[name].start,
      end: oldValue[name].end,
      day: name,
      days: oldValue[name].days,
    })
    closeModal()
  }

  useEffect(() => {
    if (name) {
      setValue(name, {
        start: watch(`${name}.start`),
        end: watch(`${name}.end`),
        day: name,
        days: watch(`${name}.days`),
      })
    }
  }, [name, setValue, watch])

  return (
    <Modal>
      <Styled.NewTimeContainer data-testid="modal-time-schedule">
        <Styled.ModalTop>
          {isDesktop ? (
            <Subtitle type="sh2" weight={600}>
              Conflito de Horário
            </Subtitle>
          ) : (
            <Body weight={600}>Conflito de Horário</Body>
          )}
          {isDesktop && (
            <Body weight={400} color={theme.colors.text.subdued}>
              O horário escolhido está em conflito com outro aluno, selecione um
              novo.
            </Body>
          )}
        </Styled.ModalTop>
        {variation !== 'singleDayConflict' && (
          <Styled.DaysToggle
            alignment={'row'}
            gap={isDesktop ? '1.7rem' : '0.5rem'}
            label="Selecione um dia diferente para seu aluno:"
            margin="0 0 2rem"
            name="allDays.days"
            optionSize={isDesktop ? '42px' : '85px'}
            options={isDesktop ? SHORT_WEEK_DAYS : LONG_WEEK_DAYS}
            radius={isDesktop ? '15px' : '15px'}
            type="multiple"
            conflicts={handleSelectedDays(daysList)}
            ignoreConflict
          />
        )}
        {variation === 'edit' && (
          <InputText label="Local do treino" name={'address'} />
        )}
        <Styled.InputWrapper>
          <InputTimeRange
            nameStart={selectedDay ? `${selectedDay}.start` : 'allDays.start'}
            nameEnd={selectedDay ? `${selectedDay}.end` : 'allDays.end'}
            placeholderStart={`Horário de início`}
            placeholderEnd={`Horário de Término`}
            labelEnd={`Horário de Término`}
            labelStart={`Horário de início`}
            displayLine={false}
            width="48%"
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper></Styled.InputWrapper>
        <ConflictCard conflict conflictList={conflictList} />
        {conflictList.length > 0 ? (
          <Button
            onClick={closeModal}
            track={buttonClickTracking}
            trackName="ignore_conflict_and_close_time_schedule_modal"
            variation="borderless"
            className="mx-auto mb-10 mt-[50px]"
          >
            Ignorar conflito
          </Button>
        ) : (
          <Aligner
            justify="space-between"
            align="center"
            margin="0 0 32px 0"
            gap="24px"
          >
            <Button
              onClick={handleClose}
              cancel
              variation="borderless"
              track={buttonClickTracking}
              trackName="cancel_athlete_time_schedule_and_close_time_schedule_modal"
            >
              Cancelar
            </Button>
            {/* TODO: nao sei se a melhor forma de salvar seria submeter o form inteiro */}
            <FormButton
              form="athlete-schedule"
              onClick={handleClose}
              track={buttonClickTracking}
              trackName="update_athlete_time_schedule"
              className="w-full"
            >
              SALVAR
            </FormButton>
          </Aligner>
        )}
      </Styled.NewTimeContainer>
    </Modal>
  )
}

export default ModalTimeSchedule
