// React
import { FC, useLayoutEffect } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { athleteModalSendReminderSchema } from 'schemas'
import { cn } from 'utils/helpers/classess'

// Components
import { Chip } from 'heeds-ds'
import { Modal } from 'components'

// Constants
import {
  SEND_REMIND_NOTIFICATION_TYPE_OPTIONS,
  SEND_REMIND_SUBJECT_OPTIONS,
} from 'utils/constants'

const DEFAULT_MESSAGE = `Olá, <athlete_name>.
    
Passando para te lembrar do pagamento do seu plano no CADU.
          
Qualquer informação que você precisar, entre em contato com a gente que te ajudamos.
          
Ótimo dia para você.`

const DEFAULT_VALUES = {
  notificationType: '',
  subject: '',
  message: '',
}

interface FormInputs {
  message: string
  notificationType: string
  subject: string
}

type Props = {
  athleteName?: string
  onConfirm?: (formData: FormInputs) => void
}

const ModalSendReminder: FC<Props> = ({ athleteName, onConfirm }) => {
  const methods = useForm<FormInputs>({
    defaultValues: DEFAULT_VALUES,
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(athleteModalSendReminderSchema),
  })
  const { setValue, register, reset, handleSubmit, watch } = methods

  const formValues = watch()

  const onSubmit = (formData: FormInputs) => onConfirm?.(formData)

  useLayoutEffect(() => {
    reset({
      notificationType: '',
      subject: '',
      message: DEFAULT_MESSAGE.replace(
        '<athlete_name>',
        athleteName || 'aluno',
      ),
    })
  }, [athleteName, reset])

  return (
    <Modal
      showExit
      title="Enviar lembrete"
      description="Você pode enviar uma mensagem pré-definida para o seu aluno
              diretamente, selecione a forma de envio, o assunto e clique em
              enviar."
      secondaryButton={{
        name: 'Cancelar',
      }}
      primaryButton={{
        name: 'Enviar mensagem',
        onClick: handleSubmit(onSubmit),
      }}
      maxWidth="574px"
    >
      <FormProvider {...methods}>
        <form
          className="flex flex-col overflow-y-auto px-6 py-4"
          data-testid="modal-send-reminder-container"
          onSubmit={handleSubmit(onSubmit)}
        >
          <p className="text-copy4 font-semibold">
            Selecione por onde o aluno receberá a notificação
          </p>

          <div className="flex flex-wrap gap-2 pb-4 pt-2">
            {SEND_REMIND_NOTIFICATION_TYPE_OPTIONS.map((item) => {
              return (
                <Chip
                  key={item.label}
                  active={formValues.notificationType === item.value}
                  label={item.label}
                  onClick={() => setValue('notificationType', item.value)}
                />
              )
            })}
          </div>

          <p className="text-copy4 font-semibold">Selecione o assunto</p>

          <div className="flex flex-wrap gap-2 pb-4 pt-2">
            {SEND_REMIND_SUBJECT_OPTIONS.map((item) => {
              return (
                <Chip
                  key={item.label}
                  active={formValues.subject === item.value}
                  label={item.label}
                  onClick={() => setValue('subject', item.value)}
                />
              )
            })}
          </div>

          <p className="text-copy4 font-semibold">Corpo da Mensagem</p>

          <textarea
            {...register('message')}
            data-testid="modal-send-reminder-textarea"
            name="message"
            className={cn(
              'mt-2 h-[170px] max-h-[170px] resize-none rounded-[4px] border',
              'border-border-disabled bg-surface-disabled px-4 pt-4 text-copy4',
              'leading-6 text-text-disabled outline-none',
            )}
          />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default ModalSendReminder
