// Models
import {
  IWorkoutRoutine,
  IWorkoutRoutineState,
} from 'storage/workoutRoutine/models'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { dateFormatEnglishToPortuguese } from 'utils/functions'
import { prepareWorkoutRoutineToPayload } from 'filters/workouts'
import {
  triggerCreateWorkoutRoutine,
  triggerPartialUpdateWorkoutRoutine,
} from 'storage/workoutRoutine/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useMenu, useModal } from 'hooks'
import { workoutRoutineSchema } from 'schemas'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Button,
  DatePicker,
  FormButton,
  Icon,
  InputText,
  Loading,
  Select,
  TextArea,
} from 'heeds-ds'
import { ModalConfirmation, NewContentBox } from 'components'

// Constants
import { DIFFICULTY_OPTIONS, GOAL_OPTIONS } from 'utils/constants'

export interface IWorkoutRoutineFormData {
  id?: number
  name: string
  difficulty: string
  goal: string
  start_date: string
  end_date: string
  comments: string
}

const WorkoutCreateRoutine: FC = () => {
  const { id = '', routine_id = '' } = useParams()
  const { workoutRoutine, loading: routineLoading } = useSelector<
    IStore,
    IWorkoutRoutineState
  >((state) => state.workoutRoutine)

  const { isVisible, openModal } = useModal()
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const { breakpoints, colors } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const navigate = useNavigate()

  const formMethods = useForm<IWorkoutRoutineFormData>({
    delayError: 800,
    mode: 'onChange',
    resolver: yupResolver(workoutRoutineSchema),
    reValidateMode: 'onChange',
  })
  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = formMethods

  const navigateToAnamneses = useCallback(
    () =>
      window.open(
        generatePath(urls.athleteAssessmentAnamneseAnswers, {
          id,
        }),
        '_blank',
        'noopener,noreferrer',
      ),
    [id],
  )

  const sectionDescription = useMemo(
    () => (
      <Aligner flex="column" gap="2.4rem" align="flex-start">
        <Styled.DescriptionText>
          Configure o treino do seu aluno adicionando as informações.
        </Styled.DescriptionText>

        <Button
          onClick={navigateToAnamneses}
          size="xsmall"
          className={isDesktop ? 'm-0' : 'mx-0 my-auto'}
        >
          <Icon iconName="article" color={colors.icon.onPrimary} />
          Ver anamneses
        </Button>
      </Aligner>
    ),
    [colors, isDesktop, navigateToAnamneses],
  )

  const onSubmit = useCallback(
    (dataForm: IWorkoutRoutineFormData) => {
      const payloadData = prepareWorkoutRoutineToPayload(dataForm)

      const successCallback = (routine: IWorkoutRoutine) => {
        if (isVisible === 'save-editions') {
          navigate(generatePath(urls.athleteRoutines, { id }), {
            replace: true,
          })
        } else {
          navigate(
            generatePath(urls.workoutRoutineModels, {
              id,
              routine_id: routine.id,
            }),
          )
        }
      }

      if (workoutRoutine) {
        dispatch(
          triggerPartialUpdateWorkoutRoutine({
            ...payloadData,
            routine_pk: workoutRoutine.id,
            successCallback,
          }),
        )
      } else {
        id &&
          dispatch(
            triggerCreateWorkoutRoutine({
              ...payloadData,
              athlete_profile: Number(id),
              successCallback,
            }),
          )
      }
    },
    [dispatch, id, isVisible, navigate, workoutRoutine],
  )

  useLayoutEffect(() => {
    setPagename('NOVO TREINO')

    return () => setPagename('Dashboard')
  }, [setPagename])

  useEffect(() => {
    if (routine_id && workoutRoutine) {
      const { id, name, difficulty, goal, start_date, end_date, comments } =
        workoutRoutine
      reset({
        id,
        name,
        difficulty,
        goal,
        start_date: dateFormatEnglishToPortuguese(start_date),
        end_date: dateFormatEnglishToPortuguese(end_date),
        comments,
      })
    }
  }, [dispatch, reset, routine_id, workoutRoutine])

  if (routineLoading) {
    return <Loading active />
  }

  return (
    <FormProvider {...formMethods}>
      <Styled.Container onSubmit={handleSubmit(onSubmit)}>
        <Styled.Content>
          <NewContentBox
            description={sectionDescription}
            maxWidth={isDesktop ? '57rem' : '100%'}
            padding={isDesktop ? '3.2rem 4rem' : '2.4rem'}
            startsOpen
            title="Criação da Rotina de Treino"
          >
            <InputText
              name="name"
              label="Nome da Rotina"
              margin="4px 0"
              placeholder="Digite o nome desse treino"
              scale="small"
              mandatory
            />

            <Select
              name={'goal'}
              label="Objetivo do Aluno"
              margin="4px 0"
              options={GOAL_OPTIONS}
              placeholder="Selecione um objetivo"
              scale="small"
              width="100%"
            />

            <Select
              name={'difficulty'}
              label="Nível de dificuldade"
              margin="4px 0"
              options={DIFFICULTY_OPTIONS}
              placeholder="Será iniciante, intermediário ou avançado?"
              scale="small"
              width="100%"
            />

            <Styled.AlignRow align="flex-end">
              <DatePicker
                displayError
                label="Início da Rotina"
                margin="4px 0"
                name="start_date"
                placeholder="Selecione o início do treino"
                scale="small"
              />

              <DatePicker
                displayError
                label="Final da Rotina"
                margin="4px 0"
                name="end_date"
                placeholder="Selecione o fim do treino"
                scale="small"
              />
            </Styled.AlignRow>

            <TextArea
              height={124}
              label="Comentários sobre a Rotina"
              margin="4px 0"
              name="comments"
              placeholder="Personal, esse espaço tem como função passar orientações gerais para o seu aluno em relação aos treinos. 
            Comentários sobre os exercícios específicos terão seu próprio espaço."
              scale="small"
            />
          </NewContentBox>
        </Styled.Content>

        <Styled.ButtonContainer>
          <Styled.CancelButton
            onClick={() => {
              if (isDirty) {
                openModal('save-editions')
              } else {
                window.postMessage('CLOSE_WEBVIEW')
                navigate(-1)
              }
            }}
            track={buttonClickTracking}
            trackName="navigate_to_athlete_routines"
          >
            Cancelar
          </Styled.CancelButton>

          <FormButton
            size="small"
            track={buttonClickTracking}
            trackName={`${workoutRoutine ? 'update' : 'create'}_routine`}
          >
            Avançar
          </FormButton>
        </Styled.ButtonContainer>

        <ModalConfirmation
          cancelTitle="Salvar rascunho"
          confirmTitle="Sim, cancelar"
          description="Tem certeza que deseja cancelar a rotina criada? Saindo agora, você perderá todo seu progresso."
          infoCancel
          onCancel={handleSubmit(onSubmit)}
          onConfirm={() => navigate(-1)}
          title="Cancelar Rotina"
          width="49rem"
        />
      </Styled.Container>
    </FormProvider>
  )
}

export default WorkoutCreateRoutine
