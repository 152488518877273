// Models
import IStore from 'lib/redux/models'
import { IExerciseData, IExerciseState } from 'storage/exercise/models'
import { IWorkoutListModelPayload } from 'services/workoutModel/@types'

// React
import { FC, useCallback, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { useDebounceFunction, useFilters, useMediaQuery, useModal } from 'hooks'
import {
  triggerDeleteExercise,
  triggerLoadExercisesLibrary,
  triggerLoadMoreExercisesLibrary,
} from 'storage/exercise/duck'
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import { CommonList, InputSearch } from 'heeds-ds'
import {
  LibraryExerciseItem,
  LibraryHeader,
  ModalExerciseImage,
  LoaderLibraryExerciseItem,
  Modal,
} from 'components'
import { generatePath, useNavigate } from 'react-router-dom'

const COLUMNS_GRID =
  'grid-cols-[minmax(7.5rem,0.1fr)repeat(4,minmax(15.25rem,0.21fr))minmax(10rem,0.15fr)minmax(6rem,0.1fr)]'

const COLUMNS_HEADERS = [
  { label: 'Imagem' },
  { label: 'Nome do modelo' },
  { label: 'Grupo agonista' },
  { label: 'Grupo sinergista' },
  { label: 'Equipamentos' },
  { label: 'Data de criação' },
  { label: '' },
]

const page_size = 20

const LibraryExerciseTemplate: FC = () => {
  const { exercisesLibrary, loading } = useSelector<IStore, IExerciseState>(
    (state) => state.exercise,
  )
  const { filters, setFilter } = useFilters<IWorkoutListModelPayload>(
    window.location.search,
  )
  const navigate = useNavigate()
  const { breakpoints } = useContext(ThemeContext)
  const { openModal, isVisible, closeModal } = useModal()

  const dispatch = useDispatch()
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  const [selected, setSelected] = useState<IExerciseData>()

  const handleEndReached = () => {
    if (exercisesLibrary && !loading && exercisesLibrary?.next) {
      dispatch(
        triggerLoadMoreExercisesLibrary({
          ...filters,
          page: exercisesLibrary.next,
          page_size,
        }),
      )
    }
  }

  const renderModelItem = useCallback(
    (item: IExerciseData) => {
      const handleEdit = () => {
        setSelected(item)
        navigate(generatePath(urls.LibraryExerciseEdit, { id: item.id }))
      }

      const handleDelete = () => {
        setSelected(item)
        openModal('delete-modal')
      }

      const openExerciseGifModal = () => {
        setSelected(item)
        openModal('mobile-exercise-gif-modal-list')
      }

      return (
        <LibraryExerciseItem
          exerciseTemplate={item}
          onClick={handleEdit}
          onRemove={handleDelete}
          onClickImage={openExerciseGifModal}
          onEdit={handleEdit}
        />
      )
    },
    [navigate, openModal],
  )

  const handleReloadAthletes = useCallback(
    (newFilters: IWorkoutListModelPayload) => {
      const query = {
        ...newFilters,
        page_size,
      }
      dispatch(triggerLoadExercisesLibrary(query))
    },
    [dispatch],
  )

  const debouncedLoad = useDebounceFunction<
    (newFilters: IWorkoutListModelPayload) => void
  >(handleReloadAthletes, 1000)

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('name', event.target.value, true)
    debouncedLoad(filters)
  }

  const handleDeleteExercise = () => {
    closeModal()
    setSelected(undefined)
    dispatch(
      triggerDeleteExercise({
        id: selected?.id || 0,
      }),
    )
  }

  useEffect(() => {
    dispatch(triggerLoadExercisesLibrary({ page_size }))
  }, [dispatch])

  return (
    <Styled.Container>
      <LibraryHeader page="Exercícios">
        <InputSearch
          placeholder="Pesquisar"
          scale="small"
          onChange={onSearchChange}
          value={filters?.name || ''}
        />
      </LibraryHeader>
      <CommonList<IExerciseData>
        bottomDistance="mb-[7.2rem]"
        columns={COLUMNS_HEADERS}
        columnsFormat={COLUMNS_GRID}
        data={exercisesLibrary?.results || []}
        refreshing={loading}
        hasMore={!!exercisesLibrary?.next}
        loaderComponent={<LoaderLibraryExerciseItem />}
        onEndReached={handleEndReached}
        padding="p-4 pl-6"
        renderItem={renderModelItem}
        showHeader={isTablet}
        listEmptyComponent={
          <Styled.EmptyContainer>
            <Styled.EmptyText>
              Você ainda não cadastrou nenhum exercício novo.
            </Styled.EmptyText>
          </Styled.EmptyContainer>
        }
      />
      {isVisible === 'delete-modal' && (
        <Modal
          icon="delete"
          title="Excluir exercicio"
          description="Você está excluindo um exercicio criado por você. Deseja prosseguir?"
          onClose={() => setSelected(undefined)}
          primaryButton={{
            name: 'Sim, quero excluir',
            onClick: handleDeleteExercise,
          }}
          secondaryButton={{ name: 'Voltar' }}
          maxWidth="530px"
        />
      )}
      {isVisible === 'mobile-exercise-gif-modal-list' && (
        <ModalExerciseImage exercise={selected} handleClose={closeModal} />
      )}
    </Styled.Container>
  )
}

export default LibraryExerciseTemplate
