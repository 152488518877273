// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Icon } from 'heeds-ds'

type Props = {
  handCursor?: boolean
  negative?: boolean
  width?: string
}

export const ArrowDown = styled(Icon).attrs((props) => ({
  color: props.theme.colors.icon.critical,
  iconName: 'arrowDownward',
}))``

export const ArrowUp = styled(Icon).attrs((props) => ({
  color: props.theme.colors.icon.success,
  iconName: 'arrowUpward',
  size: 18,
}))``

export const LeftColumn = styled(Aligner).attrs(() => ({
  flex: 'column',
  gap: '1.6rem',
}))``

export const Month = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.disabled,
  type: 'copy5',
}))``

export const Percent = styled.div<Props>`
  align-items: flex-end;
  background: ${({ negative, theme }) =>
    negative
      ? theme.colors.surface.criticalSubduedHovered
      : theme.colors.surface.successSubduedHovered};
  border-radius: 8px;
  display: flex;
  gap: 0.4rem;
  padding: 0.4rem 0.8rem;
`

export const RightColumn = styled(Aligner).attrs(() => ({
  align: 'flex-end',
  flex: 'column',
  gap: '1.6rem',
}))``

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy3',
  weight: 600,
}))``

export const Value = styled(Body).attrs(() => ({
  type: 'copy1',
  weight: 700,
}))``
