// Models
import { TDisplayModes } from '..'

// React
import { FC } from 'react'

// Misc
import { cn } from '../../../utils/classes'

// Constants
import { MONTHS_COLLECTION } from '../../../utils/helpers/date'

type Props = {
  displayingYear: number
  setDisplayingMonth: React.Dispatch<React.SetStateAction<number>>
  setDisplayMode: React.Dispatch<React.SetStateAction<TDisplayModes>>
  startDate?: Date | null
  today: Date
}

const CalendarMonthPage: FC<Props> = ({
  displayingYear,
  setDisplayingMonth,
  setDisplayMode,
  startDate,
  today,
}) => {
  return (
    <>
      {Object.keys(MONTHS_COLLECTION).map((monthString) => {
        const month = parseInt(monthString)
        const isSelected =
          month === startDate?.getMonth() &&
          displayingYear === startDate?.getFullYear()
        const isToday =
          displayingYear === today.getFullYear() && month === today.getMonth()

        return (
          <button
            aria-selected={isSelected}
            key={month.toString()}
            onClick={() => {
              setDisplayingMonth(month)
              setDisplayMode('day')
            }}
            className={cn(
              'h-[52px] w-[62px] cursor-pointer rounded font-roboto text-copy4 font-normal text-text',
              'hover:bg-interactive-hovered hover:font-semibold hover:text-white',
              'focus:border focus:border-black',
              {
                'text-badge-purpleText bg-badge-purpleBackground': isToday,
                'bg-action-secondaryDefault font-semibold text-white':
                  isSelected,
              },
            )}
          >
            {MONTHS_COLLECTION[month].short}
          </button>
        )
      })}
    </>
  )
}

export default CalendarMonthPage
