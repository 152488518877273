/* eslint-disable no-case-declarations */
// Models
import {
  EFinancialSubscriptionActionTypes,
  IFinancialSubscriptionState,
} from '../models'
import { IAction } from 'lib/redux/models'
import {
  ICreateFinancialSubscription,
  IGetSubscriptionPayload,
  IListFinancialSubscriptions,
  IListFinancialSubscriptionsResponse,
  ISubscriptionDeactivatePayload,
} from 'services/financialSubscription/@types'

// ACTION TYPES
export const Types = {
  CLEANUP: EFinancialSubscriptionActionTypes.CLEANUP,
  FAILURE: EFinancialSubscriptionActionTypes.FAILURE,
  FULFILL: EFinancialSubscriptionActionTypes.FULFILL,
  REQUEST: EFinancialSubscriptionActionTypes.REQUEST,
  SUCCESS: EFinancialSubscriptionActionTypes.SUCCESS,
  ACTIVATE: EFinancialSubscriptionActionTypes.ACTIVATE,
  DEACTIVATE: EFinancialSubscriptionActionTypes.DEACTIVATE,
  CREATE: EFinancialSubscriptionActionTypes.CREATE,
  UPDATE: EFinancialSubscriptionActionTypes.UPDATE,
  GET: EFinancialSubscriptionActionTypes.GET,
  LIST: EFinancialSubscriptionActionTypes.LIST,
  LIST_MORE: EFinancialSubscriptionActionTypes.LIST_MORE,
  SUCCESS_LOAD_MORE: EFinancialSubscriptionActionTypes.SUCCESS_LOAD_MORE,
}

// INITIAL STATE
const initialState: IFinancialSubscriptionState = {}

// REDUCER
export default (
  state: IFinancialSubscriptionState = initialState,
  action?: IAction<unknown>,
): IFinancialSubscriptionState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        subscription: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IFinancialSubscriptionState),
      }
    case Types.SUCCESS_LOAD_MORE: {
      const { results, ...response } =
        action.payload as IListFinancialSubscriptionsResponse
      return {
        ...state,
        ...response,
        subscriptions: [...(state.subscriptions || []), ...(results || [])],
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<unknown> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<unknown> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (
  payload: IFinancialSubscriptionState,
): IAction<IFinancialSubscriptionState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const successLoadMoreSubscriptions = (
  payload: IListFinancialSubscriptionsResponse,
): IAction<IListFinancialSubscriptionsResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE,
    payload,
  }
}

export const triggerCreateFinancialSubscription = (
  payload: ICreateFinancialSubscription,
): IAction<ICreateFinancialSubscription> => {
  return {
    type: Types.CREATE,
    payload,
  }
}

export const triggerUpdateFinancialSubscription = (
  payload: ICreateFinancialSubscription,
): IAction<ICreateFinancialSubscription> => {
  return {
    type: Types.UPDATE,
    payload,
  }
}

export const triggerGetFinancialSubscription = (
  payload: IGetSubscriptionPayload,
): IAction<IGetSubscriptionPayload> => {
  return {
    type: Types.GET,
    payload,
  }
}

export const triggerLoadFinancialSubscriptions = (
  payload: IListFinancialSubscriptions,
): IAction<IListFinancialSubscriptions> => {
  return {
    type: Types.LIST,
    payload,
  }
}

export const triggerLoadMoreFinancialSubscriptions = (
  payload: IListFinancialSubscriptions,
): IAction<IListFinancialSubscriptions> => {
  return {
    type: Types.LIST_MORE,
    payload,
  }
}

export const triggerActivateSubscription = (
  payload: ISubscriptionDeactivatePayload,
): IAction<ISubscriptionDeactivatePayload> => {
  return {
    type: Types.ACTIVATE,
    payload,
  }
}

export const triggerDeactivateSubscription = (
  payload: ISubscriptionDeactivatePayload,
): IAction<ISubscriptionDeactivatePayload> => {
  return {
    type: Types.DEACTIVATE,
    payload,
  }
}
