// Models
import { IconsType } from 'heeds-ds/src/components/UI/Icons/StylizedIcons/values'

// React
import { FC } from 'react'

// Misc
import { useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Button, StylizedIcons } from 'heeds-ds'
import { Modal } from 'components/modals'

// Assets
import insiderBackground from 'assets/images/insider-banner-background.png'

const MODAL_TEXTS = [
  {
    icon: 'exercise',
    title: 'O que significa ser um Insider?',
    text: `Ser um Insider significa que você, como profissional de educação física, terá a oportunidade de usar o CADU antes de todos, 
          testar funcionalidades, dar feedbacks e ajudar a aprimorar o sistema para atender melhor às necessidades da profissão. É a chance 
          de contribuir com o desenvolvimento de uma ferramenta essencial para o seu dia a dia.`,
  },
  {
    icon: 'fitnessApp',
    title: 'Qual seria sua função?',
    text: `Sua função como Insider é explorar a ferramenta nas versões web e celular, fazer simulações com alunos e fornecer feedback sincero 
          sobre o uso, acessibilidade e recursos. Informe se algo importante está faltando ou se encontrar bugs, enviando prints ou relatando os 
          problemas encontrados.`,
  },
  {
    icon: 'app',
    title: 'Tenho que substituir meu app ou sistema atual?',
    text: `Não se preocupe! Participar do nosso grupo exclusivo de testadores não significa que você precisa substituir o seu app ou sistema atual. 
          Estamos apenas coletando o seu feedback, sem necessidade de implantações, migrações ou contratos.`,
  },
  {
    icon: 'sign',
    title: 'Vamos juntos nessa jornada?',
    text: `Venha fazer parte dessa iniciativa e ajude a criar uma ferramenta que realmente atenda às suas necessidades e a dos seus colegas 
          de profissão. Estamos ansiosos para contar com a sua expertise!`,
  },
]

const InsidersCard: FC = () => {
  const { openModal, isVisible, closeModal } = useModal()

  return (
    <Styled.Container
      backgroundImage={insiderBackground}
      data-testid="insiders-card-container"
    >
      <Styled.Title>Seja um Insider!</Styled.Title>
      <Styled.Text margin="0 0 16px 0">
        Participe do nosso canal de whatsapp exclusivo e fique por dentro das
        novidades do CADU em primeira mão! Além disso, você pode compartilhar
        suas sugestões para melhorar ainda mais o sistema.
      </Styled.Text>
      <Styled.Text>
        E tem mais: inscreva-se e concorra a sorteios exclusivos para personal
        trainers!
      </Styled.Text>
      <Styled.ButtonContainer>
        <Button
          size="xsmall"
          variation="outlined"
          onClick={() => openModal('insider-modal-info')}
          className="mt-[24px]"
        >
          Clique aqui e saiba mais
        </Button>
      </Styled.ButtonContainer>
      {isVisible === 'insider-modal-info' && (
        <Modal>
          <Styled.ModalContainer>
            <Styled.ModalTitle margin="24px 0">
              Conheça mais sobre ser um Insider.
            </Styled.ModalTitle>
            <Styled.ModalContent>
              {MODAL_TEXTS.map((info, index) => {
                return (
                  <Styled.ExpandableCard key={index}>
                    <Styled.ExpandableCardHeader>
                      <StylizedIcons iconName={info.icon as IconsType} />
                      <Styled.ModalCardTitle>
                        {info.title}
                      </Styled.ModalCardTitle>
                      <Styled.IconContainer>
                        <Styled.ArrowUp>
                          <Styled.ArrowIcon iconName="expandLess" />
                        </Styled.ArrowUp>
                        <Styled.ArrowDown>
                          <Styled.ArrowIcon iconName="expandMore" />
                        </Styled.ArrowDown>
                      </Styled.IconContainer>
                    </Styled.ExpandableCardHeader>
                    <Styled.ModalCardText>{info.text}</Styled.ModalCardText>
                  </Styled.ExpandableCard>
                )
              })}
            </Styled.ModalContent>
            <Styled.ModalFooter>
              <Button size="xsmall" variation="borderless" onClick={closeModal}>
                Voltar
              </Button>
              <Styled.WhatsappButton
                to="https://chat.whatsapp.com/KHa8qGJRc5j4jWmURFnU2B"
                target="_blank"
              >
                <Styled.WhatsappIcon />
                Entre no canal do whatsapp
              </Styled.WhatsappButton>
            </Styled.ModalFooter>
          </Styled.ModalContainer>
        </Modal>
      )}
    </Styled.Container>
  )
}

export default InsidersCard
