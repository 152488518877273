// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

type Props = {
  header?: boolean
}

export const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
`

export const FieldItem = styled(Aligner).attrs({
  flex: 'column',
  gap: '0.8rem',
  width: 'fit-content',
})`
  overflow-wrap: anywhere;
  max-wdith: 100%;
`

export const Text = styled(Body).attrs<Props>(({ header }) => ({
  type: header ? 'copy4' : 'copy3',
  weight: 600,
}))<Props>`
  color: ${({ theme, header }) =>
    theme.colors.text[header ? 'subdued' : 'default']};
`
