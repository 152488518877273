// Models
import { IAthleteOutletContext } from 'layouts/AthleteRegisterLayout'
import { IAthletePlansState } from 'storage/athletePlans/models'
import { IAuthState } from 'storage/auth/models'
import { IFinancialState } from 'storage/financial/models'
import { IProfileState } from 'storage/profile/models'
import { TPlanModel, TPlanPeriodicity, TPlanType, TWeekdays } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react'

// Libraries
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import {
  generatePath,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { athleteFinancialSchema } from 'schemas'
import { buttonClickTracking } from 'utils/tracking'
import { cleanup, triggerLoadAthletePlans } from 'storage/athletePlans/duck'
import { getPersonalProfile } from 'storage/profile/duck'
import {
  prepareAthleteFinancialToDisplay,
  prepareAthleteFinancialToPayload,
} from 'filters/athlete'
import {
  triggerCreateFinancialSubscription,
  triggerUpdateFinancialSubscription,
} from 'storage/financialSubscription/duck'
import { triggerLoadProfileExtraPlans } from 'storage/financial/duck'
import { urls } from 'routes/paths'

// Components
import * as Blocks from 'blocks/dashboard/athlete/AthleteFinancial'
import * as Styled from './styled'
import { NewContentBox, SubscriptionCard } from 'components'
import { Button, FormButton, Loading } from 'heeds-ds'

export interface IFrequency {
  label: string
  value: string
  planID?: number
}

export interface IPlan {
  label: string
  value?: string
  planID?: number
  type?: TPlanType
  model?: TPlanModel
  periodicity: TPlanPeriodicity
  price?: number
  frequency?: string
}

export interface IAthleteFinancialInputs {
  id: number
  athlete?: number
  plan_type?: TPlanType
  plan_model?: TPlanModel
  plan_periodicity?: TPlanPeriodicity
  frequency_hour?: number
  start_date?: string
  due_date_day?: string
  due_date_date?: string
  extra_workouts?: string[]
  value?: number
  discount?: number
  autoRenovation: boolean
  instalmentPayment: string
  frequency: string
  selected_plan?: IPlan
}

export interface IExtraWorkout {
  id: number
  active: boolean
  name: string
  location: string
  cost: number
  days: TWeekdays[]
  start_time: string
  end_time: string
}

// TODO: REFATORAR AQUI USAR MELHOR HOOK FORM
const AthleteFinancial: FC = () => {
  const { extraPlans } = useSelector<IStore, IFinancialState>(
    (state) => state.financial,
  )
  const { plans, loading } = useSelector<IStore, IAthletePlansState>(
    (state) => state.athletePlans,
  )
  const { profile } = useSelector<IStore, IProfileState>(
    (state) => state.profile,
  )
  const { userData } = useSelector<IStore, IAuthState>(({ auth }) => auth)
  const { state } = useLocation()

  const { id, blockNavigation } = useOutletContext<IAthleteOutletContext>()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showForm, setShowForm] = useState(false)

  const formMethods = useForm<IAthleteFinancialInputs>({
    resolver: yupResolver(athleteFinancialSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 800,
  })
  const {
    watch,
    handleSubmit,
    reset,
    formState: { isValid },
  } = formMethods

  const extraWorkouts = watch('extra_workouts')

  const extraWorkoutValues = useMemo(
    () =>
      extraWorkouts
        ?.map((extra) => {
          return JSON.parse(extra).price
        })
        .reduce((acc, value) => acc + value, 0),
    [extraWorkouts],
  )

  const hasActivePlan = useMemo(() => {
    return plans?.some((plan) => plan.status === 'active')
  }, [plans])

  const onSubmit = (data: IAthleteFinancialInputs) => {
    const payload = prepareAthleteFinancialToPayload(
      data,
      id,
      userData?.profileId || 0,
    )

    if (plans && plans?.length === 0) {
      dispatch(triggerCreateFinancialSubscription(payload))
    } else {
      if (data.id) {
        dispatch(triggerUpdateFinancialSubscription(payload))
      }
    }

    state?.planEdit && navigate(-1)
  }

  useLayoutEffect(() => {
    if (!profile) {
      dispatch(getPersonalProfile())
    }
    if (!extraPlans) {
      userData?.profileId &&
        dispatch(
          triggerLoadProfileExtraPlans({ profile_pk: userData?.profileId }),
        )
    }
  }, [dispatch, extraPlans, profile, userData?.profileId])

  useLayoutEffect(() => {
    if (plans === undefined) {
      dispatch(triggerLoadAthletePlans({ id: parseInt(id) }))
    }
  }, [dispatch, id, plans])

  useEffect(() => {
    if (plans) {
      const data: IAthleteFinancialInputs =
        prepareAthleteFinancialToDisplay(plans)
      reset(data)
    }
  }, [dispatch, plans, reset])

  useEffect(() => {
    return () => {
      dispatch(cleanup())
    }
  }, [dispatch])

  useEffect(() => {
    state?.planEdit && blockNavigation(urls.athleteFinancial)
  }, [blockNavigation, state?.planEdit])

  if (loading) {
    return <Loading active />
  }

  const blocks = [
    {
      id: 0,
      title: 'Planos disponíveis',
      subtitle:
        'Selecione o tipo de plano para o seu aluno, o valor total aparecerá para você no final da tela.',
      block: <Blocks.Plans />,
    },
    {
      id: 2,
      title: 'Detalhes do plano',
      subtitle:
        'Defina quantidade de aulas, a data de início do plano e também a data de vencimento do pagamento.',
      block: <Blocks.PlanDates />,
    },
    {
      id: 3,
      title: 'Valores',
      subtitle: 'Valor total',
      block: <Blocks.Values extraWorkoutPrice={extraWorkoutValues} />,
    },
  ]

  //TODO: retirar "xl:ml-[-40px]" quando o layout do perfil do aluno for atualizado
  return (
    <div className="relative flex h-full flex-col border-t border-border-input xl:ml-[-40px]">
      <div className="flex flex-wrap gap-4 overflow-auto px-6 pb-36 pt-6 xl:gap-8 xl:px-[34px] xl:pb-44 xl:pt-6">
        {showForm ? (
          <FormProvider {...formMethods}>
            <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
              {blocks.map((block, index) => {
                const showExtraPLans = Boolean(extraPlans?.length)

                return block.title === 'Treinos extras' &&
                  !showExtraPLans ? null : (
                  <NewContentBox
                    key={block.id}
                    title={block.title}
                    margin={`0 0 ${index === 2 ? 0 : 3.2}rem`}
                    description={block.subtitle}
                  >
                    {block.block}
                  </NewContentBox>
                )
              })}
            </Styled.FormContainer>
          </FormProvider>
        ) : (
          <>
            {!hasActivePlan && (
              <SubscriptionCard
                empty
                className="xl:w-[31.6%]"
                onClick={() => setShowForm(true)}
              />
            )}
            {plans?.map((plan, index) => {
              return (
                <SubscriptionCard
                  key={index}
                  subscription={plan}
                  className="xl:w-[31.6%]"
                  onClick={() => setShowForm(true)}
                />
              )
            })}
          </>
        )}
      </div>
      <div className="absolute inset-x-0 bottom-0 flex justify-center border-t border-border-input bg-surface px-10 py-4 xl:justify-end">
        {showForm ? (
          <div className="flex gap-4">
            <Button
              size="xsmall"
              onClick={() => setShowForm(false)}
              variation="borderless"
              track={buttonClickTracking}
              trackName="go_back_to_history_button"
            >
              voltar para histórico
            </Button>
            <FormButton
              size="xsmall"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
              loading={loading}
              track={buttonClickTracking}
              trackName="save_athlete_financial_button"
            >
              Salvar
            </FormButton>
          </div>
        ) : (
          <Button
            size="xsmall"
            onClick={() =>
              plans &&
              plans?.length > 0 &&
              navigate(
                generatePath(urls.financialAthlete, { id: plans?.[0].id }),
              )
            }
            track={buttonClickTracking}
            trackName="navigate_to_financial_athlete_button"
          >
            Ir para pagamentos
          </Button>
        )}
      </div>
    </div>
  )
}

export default AthleteFinancial
