// Models
import { IAuthState } from 'storage/auth/models'
import { IWorkoutRoutineListPayload } from 'services/workoutRoutine/@types'
import {
  IWorkoutRoutine,
  IWorkoutRoutineState,
} from 'storage/workoutRoutine/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { cn } from 'utils/helpers/classess'
import { urls } from 'routes/paths'
import { useDebounceFunction, useFilters, useMediaQuery, useModal } from 'hooks'
import {
  triggerDeleteWorkoutRoutineTemplate,
  triggerListWorkoutRoutineTemplates,
  triggerLoadListWorkoutRoutines,
  triggerLoadMoreListWorkoutRoutineTemplates,
} from 'storage/workoutRoutine/duck'
import {
  triggerListWorkoutsModel,
  triggerSendRoutineToAthlete,
} from 'storage/workoutModel/duck'

// Components
import { CommonList, InputSearch } from 'heeds-ds'
import {
  RoutineTemplateItem,
  RoutineTemplateItemLoader,
} from 'components/lists'
import { LibraryHeader, Modal } from 'components'

// Constants
import ModalNewRoutineTemplate from 'components/modals/ModalNewRoutineTemplate'
import { ModalShareRoutine } from 'components/modals'

const COLUMNS_GRID =
  'grid-cols-[minmax(20rem,1.7fr)repeat(2,minmax(20rem,1fr))minmax(7rem,0.2fr)]'

const PADDING = 'py-4 pr-4 pl-6'

const COLUMNS_HEADERS = [
  { label: 'Nome do modelo' },
  { label: 'Data de criação' },
  { label: 'Criado por' },
  { label: '' },
]

const page_size = 20

const LibraryRoutinesTemplates: FC = () => {
  const { next, loading, workoutRoutineTemplates } = useSelector<
    IStore,
    IWorkoutRoutineState
  >((state) => state.workoutRoutine)
  const { userData } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { filters, setFilter } = useFilters<IWorkoutRoutineListPayload>(
    window.location.search,
  )
  const navigate = useNavigate()

  const { breakpoints } = useContext(ThemeContext)
  const { openModal, isVisible, closeModal } = useModal()

  const dispatch = useDispatch()
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  const [selected, setSelected] = useState<IWorkoutRoutine>()

  const handleRemoveRoutineTemplates = () => {
    selected &&
      dispatch(
        triggerDeleteWorkoutRoutineTemplate({
          routine_pk: selected.id,
        }),
      )
    closeModal()
  }

  const handleEndReached = () => {
    if (workoutRoutineTemplates && !loading && next) {
      const query = {
        ...filters,
        page: next,
        page_size,
      }
      dispatch(triggerLoadMoreListWorkoutRoutineTemplates(query))
    }
  }

  const handleReloadWorkoutRoutine = (
    newFilters: IWorkoutRoutineListPayload,
  ) => {
    const query = {
      ...newFilters,
      page_size,
      routine_status: newFilters.routine_status || 'current',
    }
    dispatch(triggerLoadListWorkoutRoutines(query))
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('search', event.target.value, true)
    debouncedLoad(filters)
  }

  const debouncedLoad = useDebounceFunction<
    (newFilters: IWorkoutRoutineListPayload) => void
  >(handleReloadWorkoutRoutine, 1000)

  const renderModelItem = (item: IWorkoutRoutine) => {
    const handleOnRemove = () => {
      setSelected(item)
      openModal('modal_delete_routine')
    }

    const handleOpenShare = () => {
      setSelected(item)
      openModal('modal-share-routine')
    }

    const handleEdit = (routineId: number) => {
      const successCallback = () =>
        navigate(
          generatePath(urls.libraryWorkoutRoutineModels, { id: routineId }),
        )
      dispatch(
        triggerListWorkoutsModel({ routine_pk: routineId, successCallback }),
      )
    }

    return (
      <RoutineTemplateItem
        routineTemplate={item}
        gridTemplate={COLUMNS_GRID}
        padding="py-6 pr-4 pl-6"
        onRemove={handleOnRemove}
        onShare={handleOpenShare}
        onEdit={handleEdit}
      />
    )
  }

  const handleCreateRoutineTemplate = (routine: IWorkoutRoutine) => {
    const successCallback = () =>
      navigate(
        generatePath(urls.libraryWorkoutRoutineModels, { id: routine.id }),
      )
    dispatch(
      triggerListWorkoutsModel({ routine_pk: routine.id, successCallback }),
    )
    closeModal()
  }

  const handleShareRoutine = async (athletesID: number[]) => {
    if (selected) {
      dispatch(
        triggerSendRoutineToAthlete({
          athletes: athletesID,
          routine_pk: selected.id,
        }),
      )
    }

    closeModal()
  }

  useLayoutEffect(() => {
    if (userData) {
      const query = {
        page_size,
      }
      dispatch(triggerListWorkoutRoutineTemplates(query))
    }
  }, [dispatch, userData])

  return (
    <div className="flex h-full flex-col">
      <LibraryHeader page="Rotinas">
        <InputSearch
          placeholder="Pesquisar"
          scale="small"
          onChange={onSearchChange}
          value={filters?.search ?? ''}
        />
      </LibraryHeader>
      <div
        className={cn(
          'mt-5 flex w-full flex-col overflow-y-auto',
          'mb-40 h-full xl:my-0',
        )}
      >
        <CommonList<IWorkoutRoutine>
          columns={COLUMNS_HEADERS}
          columnsFormat={COLUMNS_GRID}
          data={workoutRoutineTemplates || []}
          hasMore={!!next}
          loaderComponent={
            <RoutineTemplateItemLoader
              gridTemplate={COLUMNS_GRID}
              padding={'py-6 pr-4 pl-6'}
            />
          }
          onEndReached={handleEndReached}
          padding={PADDING}
          renderItem={renderModelItem}
          showHeader={isTablet}
          refreshing={
            workoutRoutineTemplates &&
            workoutRoutineTemplates?.length === 0 &&
            loading
          }
          listEmptyComponent={
            <div className="flex h-5/6 flex-1 items-center justify-center">
              <p className="text-center text-copy4 text-text-subdued">
                Você ainda não cadastrou nenhum modelo.
              </p>
            </div>
          }
        />
        {isVisible === 'modal_crate_new_routine' && (
          <ModalNewRoutineTemplate callBack={handleCreateRoutineTemplate} />
        )}
        {isVisible === 'modal-share-routine' && (
          <ModalShareRoutine
            onSend={(athletesID) => handleShareRoutine(athletesID)}
          />
        )}
        {isVisible === 'modal_delete_routine' && (
          <Modal
            icon="delete"
            title="Excluir modelo de rotina"
            description="Você está excluindo um modelo de rotina, essa ação não poderá ser desfeita. Não se preocupe, as rotinas dos alunos com esse modelo não serão afetadas. Deseja prosseguir?"
            maxWidth="53rem"
            onClose={() => setSelected(undefined)}
            primaryButton={{
              name: 'Sim, quero excluir',
              cancel: true,
              onClick: handleRemoveRoutineTemplates,
            }}
            secondaryButton={{ name: 'Voltar', cancel: false }}
          />
        )}
      </div>
    </div>
  )
}

export default LibraryRoutinesTemplates
