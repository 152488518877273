// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body, Button, Icon, InputText } from 'heeds-ds'

type Props = {
  visible?: boolean
  hovered?: boolean
  showBorder?: boolean
  span?: number
}

export const Container = styled.div.attrs(() => ({
  draggable: false,
}))<Props>`
  ${({ visible }) =>
    visible && 'box-shadow: 0px 4px 20px rgba(99, 90, 108, 0.1)'};
  background: ${({ hovered, theme }) =>
    hovered ? theme.colors.surface.hovered : theme.colors.surface.default};
  border-left: ${({ showBorder }) => (showBorder ? '4px' : 0)} solid
    ${({ theme }) => theme.colors.icon.default};
  border-radius: 4px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  /* padding: 16px 16px 12px; */
  width: 100%;
`

export const ContentComment = styled.div<Props>`
  display: ${({ visible }) => (visible ? 'grid' : 'none')};
  grid-column: 1 / 3;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    grid-column: 1 / -1;
  }
`

export const ContentInputs = styled.div<Props>`
  column-gap: 8px;
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    grid-template-columns: 3fr 2fr;
  }
`

export const CustomAligner = styled(Aligner)<Props>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  transition: all 0.1s ease-in-out;
`

export const DeleteIcon = styled(Icon).attrs((props) => ({
  color: props.theme.colors.text.critical,
  iconName: 'delete',
  size: 20,
}))``

export const Header = styled(Aligner).attrs({
  align: 'center',
  gap: '16px',
  justify: 'space-between',
  padding: '0.8rem 1.6rem',
})``

export const HiddenButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
`

export const Input = styled(InputText).attrs((props) => ({
  displayError: props.displayError,
  margin: '0',
  scale: 'small',
}))<Props>``

export const FieldContainer = styled(Aligner).attrs({
  align: 'flex-start',
  gap: '0.8rem',
})<Props>`
  grid-column-start: span ${({ span }) => span || 2};
`

export const Label = styled.label`
  align-items: center;
  color: ${({ theme }) => theme.colors.text.default};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy4};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  gap: 1.2rem;
  line-height: 24px;
`

export const RowOne = styled.div`
  column-gap: 8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
`

export const InputContent = styled.div`
  column-gap: 8px;
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(2, minmax(auto, 1fr));

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    padding: 0 50px 0 0;
    position: relative;
  }
`

export const ChipContainer = styled.button`
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  display: inline-flex;
  gap: 16px;
  height: 3.4rem;
  justify-content: center;
  padding: 8px 16px;
  white-space: nowrap;

  ${({ theme }) => css`
    background: ${theme.colors.badge.purpleBackground};
    border: 1px solid ${theme.colors.badge.purpleText};

    :hover {
      background: ${theme.colors.action.highlightSofblue};
    }

    :focus {
      box-shadow: 0px 0px 5px 0px #2b2a63;
      outline: 1px solid ${theme.colors.badge.purpleText};
    }
  `}
`

export const ChipText = styled(Body).attrs({
  type: 'copy5',
  weight: 700,
})``

export const EmptyArea = styled(Aligner).attrs({
  align: 'center',
  justify: 'center',
})`
  border: 1px dashed ${({ theme }) => theme.colors.icon.default};
  height: 11.4rem;
  border-radius: 4px;
`

export const EmptyText = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  type: 'copy2',
}))``

export const MobileExerciseButton = styled(Button).attrs(() => ({
  margin: 'auto auto',
  size: 'small',
  variation: 'borderless',
}))``
