// Libraries
import styled from 'styled-components'

export const Cell = styled.div`
  margin: 16px 24px;
`

export const Row = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns:
    minmax(19.9rem, 1.7fr) minmax(12rem, 1fr) minmax(5.5rem, 1fr)
    minmax(12.4rem, 1fr) minmax(9rem, 1fr) minmax(9rem, 0.5fr);
  gap: 2.4rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  :not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  }
  :hover {
    box-shadow: -5px 0px 8px rgb(0 0 0 / 1.5%);
  }
`
